import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import Login from './pages/Login';
import Home from './pages/Home';
import MapDisplay from './pages/MapDisplay';
import IncidentsPage from './pages/IncidentsPage';
import AssetsPage from './pages/AssetsPage';
// import AdminPage from './pages/AdminPage';
import Alert from './pages/Alert';
import News from './pages/News';
import Report from './pages/Report';
import Company from './pages/Company';
import Assessment from './pages/Assessment';
import RiskAppetite from './pages/RiskAppetite';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
// import Settings from './pages/Settings';
// import User_Settings from './pages/User_Settings';

import Topbar from './components/menu/topbar/Topbar';
import PrivateRoute from './components/PrivateRoute';
import WebSocketProvider from './components/WebSocketProvider';
import NotFoundPage from './pages/404/404';
import SetPassword from './pages/password/SetPassword';
import ForgotPassword from './pages/password/ForgotPassword';
import DownLoadPage from './pages/downloads/DownLoad';
import Privacy from './pages/Privacy';
import FaqPage from './pages/faq/FaqPage';


interface LayoutProps {
    children: React.ReactNode;
}

// Component to conditionally render Topbar
const Layout: React.FC<LayoutProps> = ({ children }) => {
    const location = useLocation();
    const shouldShowTopbar = [
        '/',
        '/map',
        '/company',
        '/alerts',
        '/incidents',
        '/assets',
        '/assessment',
        '/news',
        '/reports',
        '/risk_appetite'
    ].includes(location.pathname);

    const pageRemovePadding = ['/map', '/reports'];

    return (
        <Grid
        >
            {shouldShowTopbar && <Topbar />}
            <Grid
                container
                direction="column"
                justifyContent="center"
                sx={{
                    flex: 1,
                    p: 0,
                    right: 0,
                    paddingLeft: {xs: '40vw', sm : '35vw', md: '25vw', lg: '18vw'},
                    mt:  '80px',
                    pt: pageRemovePadding?.includes(location.pathname) ? 0 : '40px',
                    overflowY: 'auto'
                }}
            >
                {children}
            </Grid>
        </Grid>
    );
};
const App: React.FC = () => {
    return (
        <Router>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <WebSocketProvider>
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/privacy" element={<Privacy />} />
                        <Route path="/" element={<PrivateRoute element={
                            <>
                                <Layout>
                                    <Home />
                                </Layout>
                            </>
                        } />} />
                        <Route path="/map" element={<PrivateRoute element={
                            <>
                                <Layout>
                                    <MapDisplay />
                                </Layout>
                            </>
                        } />} />
                        <Route path="/company" element={<PrivateRoute element={
                            <>
                                <Layout>
                                    <Company />
                                </Layout>
                            </>
                        } />} />
                        <Route path="/alerts" element={<PrivateRoute element={
                            <>
                                <Layout>
                                    <Alert />
                                </Layout>
                            </>
                        } />} />
                        <Route path="/incidents" element={<PrivateRoute element={
                            <>
                                <Layout>
                                    <IncidentsPage />
                                </Layout>
                            </>
                        } />} />
                        <Route path="/assets" element={<PrivateRoute element={
                            <>
                                <Layout>
                                    <AssetsPage />
                                </Layout>
                            </>
                        } />} />
                        <Route path="/assessment" element={<PrivateRoute element={
                            <>
                                <Layout>
                                    <Assessment />
                                </Layout>
                            </>
                        } />} />
                        <Route path="/news" element={<PrivateRoute element={
                            <>
                                <Layout>
                                    <News />
                                </Layout>
                            </>
                        } />} />
                        <Route path="/reports" element={<PrivateRoute element={
                            <>
                                <Layout>
                                    <Report />
                                </Layout>
                            </>
                        } />} />
                        <Route path="/risk_appetite" element={<PrivateRoute element={
                            <>
                                <Layout>
                                    <RiskAppetite />
                                </Layout>
                            </>
                        } />} />
                        <Route path="/passwordreset/*" element={
                            <SetPassword />
                        } />
                        <Route path="/setpassword/*" element={
                            <SetPassword />
                        } />
                        <Route path="/download/*" element={
                            <DownLoadPage />
                        } />
                        <Route path="/forgotpassword" element={
                            <ForgotPassword />
                        } />
                        <Route path="/faq" element={
                            <FaqPage />
                        } />
                        <Route path="/404" element={
                            <><Layout><NotFoundPage />
                            </Layout></>
                        } />
                        {/* catch route */}
                        <Route path="*" element={
                            <NotFoundPage />
                        } />
                    </Routes>
                </WebSocketProvider>
            </LocalizationProvider>
        </Router>
    );
};

export default App;