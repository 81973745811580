import { CancelPresentationOutlined, SearchRounded } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createAsset, deleteAsset, searchAssets } from "../helpers/api";
import { assetTypes } from "../helpers/constants";
import { customDropdown } from "../components/CustomDropdown";
// import DateFnsUtils from '@date-io/date-fns';
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// import MapPicker from 'react-google-map-picker'
import UserTooltip from "../components/UserTooltip";
import DisplayTable from "../components/DisplayTable";
import DeleteModal from "../components/DeleteModal";

import UserIcon from "../components/UserIcon";
import { AnimatePresence, motion } from "framer-motion";

const AssetsPage = (props) => {
  const coordinates = props.coordinates;
  // const [type, setType] = useState('')
  const [description, setDescription] = useState("");
  // const [loading, setLoading] = useState(true);
  const { loading, companyName, profile, role } = props;
  const [sendingData, setSendingData] = useState(false);
  // const [sendingInviteLink, setSendingInviteLink] = useState(false);
  const [editingAsset, setEditingAsset] = useState({ id: null });
  const [deletingAsset, setDeletingAsset] = useState({
    id: null,
    type_name: null,
    name: null,
    creator: null
  });
  const [name, setName] = useState("");
  const [type, setType] = useState({ value: "" });
  const [text, setText] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(true);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [locationLongitude, setLocationLongitude] = useState("0.0");
  const [locationLatitude, setLocationLatitude] = useState("0.0");
  const [assetCount, setAssetCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [assetPage, setAssetPage] = React.useState(0);
  const [assetRowsPerPage, setAssetRowsPerPage] = useState(5);

  const [rowCount, setRowCount] = useState(0);


  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  //table state
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const navigate = useNavigate();
  // const handleCloseConfirmation = () => {
  //   setOpenConfirmation(false);
  // };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setSendingData(true);
      const res = await createAsset(
        name,
        type.value,
        description,
        locationLongitude,
        locationLatitude,
        editingAsset.id
        // navigate
      );
      try {
        if (res.status === 201 || res.status === 200 || res.status === 204) {
          setSendingData(false);
          setErrorText("");
          setOpen(true);
          setName("");
          setText("");
          setDescription("");
          setSelectedStartDate(null);
          setSelectedEndDate(null);
          setReload(true);
          if (editingAsset.id) {
            onCancelClick();
          }
          const assets = await searchAssets(
            "",
            null,
            null,
            navigate,
            // assetPage + 1,
            // assetRowsPerPage
          );
          setRowCount(assets.data.results.length)
          if (assets.status === 200 && assets.data.results.length === 0) {
            setRows([]);
          } else if (assets.status === 200) {
            console.log("FETCHED ASSETS")
            console.log(assets)
            setRows(assets.data.results);
          }
        } else if (res.status === 400 && res.data["email"]) {
          setSendingData(false);
          setErrorText("Email: " + res.data["email"]);
          setErrorOpen(true);
        } else if (res.status === 403 && res.data["results"]) {
          setSendingData(false);
          setErrorText(res.data["results"]);
          setErrorOpen(true);
        } else if (res.status === 404) {
          setSendingData(false);
          setErrorText("Something went wrong");
          setErrorOpen(true);
        } else {
          setSendingData(false);
          setErrorText("Unknown error");
          setErrorOpen(true);
        }
      } catch (err) {
        setSendingData(false);
        setErrorText("Fatal error");
        setErrorOpen(true);
      }
      setSendingData(false);
      setReload(false);
      // dispatch(setLoading(false))

      // handleCloseConfirmation();
    },
    [
      name,
      type.value,
      description,
      locationLongitude,
      locationLatitude,
      editingAsset.id,
      navigate,
      assetPage,
      assetRowsPerPage,
    ]
  );

  const Backdrop = ({ children, onClick }) => {
    return (
      <motion.div
        style={{
          zIndex: 200,
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          background: "#00000056",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={onClick}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {children}
      </motion.div>
    );
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setDeletingAsset({ name: null, id: null, type_name: null, creator: null });
  };

  const handleOpenDeleteModal = (row) => {
    setOpenModal(true);
    setDeletingAsset(row.original);
  };

  const onEditClick = (row) => {
    setEditingAsset(row);
    setName(row.name);
    setType({ value: row.type_name || "" });
    setText(row.text);
    setDescription(row.description);
    // setSelectedStartDate(null);
    // setSelectedEndDate(null);
    setLocationLongitude(row.longitude);
    setLocationLatitude(row.latitude);
  };

  const onCancelClick = () => {
    setEditingAsset({ id: null });
    setName("");
    setText("");
    setType({ value: null });
    setDescription("");
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setLocationLongitude("0.0");
    setLocationLatitude("0.0");
  };

  const handleDelete = async (id) => {
    const res = await deleteAsset(id);
    setReload(true);
    setOpenModal(false);
    onCancelClick();
  };

  const handleChangePage = (event, newPage) => {
    setAssetPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setAssetRowsPerPage(parseInt(event.target.value, 10));
    setAssetPage(0);
  };

  const columns = useMemo(() => [{
    accessorKey: "uid.firstName",
    header: "User",
    Cell: ({ cell, row }) => (
      <>
      <UserTooltip
        // userRole={role}
        users={[row.original.uid]}
        // title="Add"
        placement="top"
        arrow
        describeChild
      >
        <Grid item>
          <UserIcon user={row.original.uid} handleClickProfile={()=>{}}>
          </UserIcon>
        </Grid>
      </UserTooltip>
      </>
      ),
      size:50
    },
    {
      accessorKey: "name",
      header: "Name",
      size:50
    },
    {
      accessorKey: "type_name",
      header: "Type",
      size:50
    },
    {
      accessorKey: "displayLongitude",
      header: "Longitude",
      maxSize:1
    },
    {
      accessorKey: "displayLatitude",
      header: "Latitude",
      maxSize:1
    },
    ],
    []
  );

  const BasicTable = useMemo(() => {
    const displayTable = (
        <DisplayTable
          columns={columns}
          data={rows}
          onEditClick={onEditClick}
          handleOpenDeleteModal={handleOpenDeleteModal}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          sorting={sorting}
          pagination={pagination}
          rowCount={rowCount}
          isRefetching={isRefetching}
          isError={isError}
          isLoading={isLoading}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          enableRowActions={true}
        />
    ); 
    
    return displayTable 
  }, [
    rows,
    pagination,
    rowCount,
    // assetPage,
    // assetRowsPerPage,
    loading,
    role,
    editingAsset,
  ]);

  useEffect(() => {
    // eslint-disable-next-line require-jsdoc
    async function fetchData() {
      setReload(true);
      if (!loading) {
        const assets = await searchAssets(
          searchInput,
          null,
          null,
          navigate,
          // assetPage + 1,
          // assetRowsPerPage
        );
        setRowCount(assets.data.results.length)
        if (assets.data.results === "OK" || !assets.data.results.length) {
          setRows([]);
        } else {

          console.log("FETCHED ASSETS")
          console.log(assets)
          setRows(()=> {
            return assets.data.results.map((asset)=>({
              ...asset,
              displayLongitude: asset.longitude.toFixed(2),
              displayLatitude: asset.latitude.toFixed(2)
            }))
          });
          setAssetCount(assets.data.count);
        }
      }
    }
    if ((!loading && role) || (role && reload)) {
      fetchData();
      setReload(false);
    }

    if (coordinates) {
      setLocationLongitude(coordinates.lng);
      setLocationLatitude(coordinates.lat);
    }
  }, [
    assetPage,
    assetRowsPerPage,
    coordinates,
    loading,
    navigate,
    reload,
    role,
    searchInput,
  ]);

  const handleClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setErrorText("");
  };

  const handleCloseError = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
    setErrorText("");
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescChange = (e) => {
    setDescription(e.target.value);
    // setReload(true);
  };

  return (
    <Grid container justifyContent="center" direction="row">
      {role !== "customer" ? (
        <Grid
          item
          container
          width="30%"
          justifyContent="center"
          // spacing={0}
          // direction="column"
          // alignItems="center"
          // justifyContent="center"
          alignItems="center"
          direction="column"
          sx={{ margin: 5 }}
        >
          <AnimatePresence
            // Disable any initial animations on children that
            // are present when the component is first rendered
            initial={false}
            // Only render one component at a time.
            // The exiting component will finish its exit
            // animation before entering component is rendered
            exitBeforeEnter={true}
            // Fires when all exiting nodes have completed animating out
            onExitComplete={() => null}
          >
            {openModal && (
              <DeleteModal
              titleText={"Are you sure you want to Delete the following Asset?"} 
              bodyText={`${deletingAsset.name} (${deletingAsset.type_name})`}
              handleClose={handleCloseModal} 
              handleDelete={(e) => handleDelete(deletingAsset.id)} 
            ></DeleteModal>
            )}
          </AnimatePresence>
          <Snackbar
            onClose={handleClose}
            open={open && errorText === "" && !sendingData}
            autoHideDuration={6000}
          >
            <Alert severity="success">
              Asset has been successfully created
            </Alert>
          </Snackbar>
          <Snackbar
            onClose={handleCloseError}
            open={errorOpen && errorText !== "" && !sendingData}
            autoHideDuration={6000}
          >
            <Alert severity="error">{errorText}</Alert>
          </Snackbar>
          {/* <Grid container spacing={3} justifyContent={"space-between"}>
            <Grid item justifyContent={"space-between"}>
              <Typography component="h1" variant="h5">
                My Assets
              </Typography>
            </Grid>
          </Grid> */}
          <br></br>
          <Grid>
            <Grid
              container
              sx={{ pt: 5, pl: 2 }}
              justifyContent={"space-between"}
              spacing={2}
            >
              <Typography component="h1" variant="h5">
                {editingAsset.id
                  ? "Edit an existing Asset"
                  : "Create a new Asset"}
              </Typography>
              <Typography component="h1" variant="h5">
                {editingAsset.id ? (
                  <Chip
                    color="warning"
                    label="Editing an Asset"
                    variant="outlined"
                    onDelete={() => {
                      onCancelClick();
                    }}
                  />
                ) : null}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1"></Typography>
          </Grid>
          <Grid item>
            <Box
              component="form"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                value={name}
                required
                error={submitted && !name}
                onChange={handleNameChange}
                label="Name"
                fullWidth
                sx={{ paddingBottom: 2 }}
              >
                Name
              </TextField>
              {customDropdown(
                assetTypes,
                "Asset Type",
                type.value,
                setType,
                profile
              )}
              <TextField
                value={description}
                error={submitted && !description}
                onChange={handleDescChange}
                label="Description"
                fullWidth
                sx={{ paddingBottom: 2 }}
              ></TextField>
              <Grid container direction="row">
                <TextField
                  sx={{ width: "48%", paddingRight: 2, paddingBottom: 2 }}
                  type="number"
                  label="Longitude"
                  value={locationLongitude}
                  variant="outlined"
                  inputProps={{
                    maxLength: 13,
                    step: "1",
                  }}
                  onChange={(e) => setLocationLongitude(e.target.value)}
                />
                <TextField
                  sx={{ width: "48%", paddingBottom: 2 }}
                  type="number"
                  label="Latitude"
                  value={locationLatitude}
                  variant="outlined"
                  inputProps={{
                    maxLength: 13,
                    step: "1",
                  }}
                  onChange={(e) => setLocationLatitude(e.target.value)}
                />
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {sendingData ? (
                  <CircularProgress size="1.55rem" color="inherit" />
                ) : (
                  "Submit"
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      ) : null}
      <Grid item width="50%" sx={{ margin: 5 }}>
        {/* <Grid item>
          <Typography component="h1" variant="h5">
            <br></br>
          </Typography>
        </Grid>
        <br></br> */}
        <Grid container spacing={3}>
          <Grid item>
            <Typography component="h1" variant="h5">
              Company Assets
            </Typography>
            <br></br>
          </Grid>
          <Grid item>
            {reload ? (
              <CircularProgress sx={{ p: 1 }}></CircularProgress>
            ) : null}
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1"></Typography>
        </Grid>
        {BasicTable}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ user }) => ({
  loading: user.loading,
  companyName: user.companyName,
  profile: user.profile,
  role: user.role,
});
export default connect(mapStateToProps)(AssetsPage);
