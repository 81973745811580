import React, { useEffect, useState } from 'react';
import {  MRT_ColumnDef, MRT_SortingState } from "material-react-table";

import { formatDateTimeToString } from '../lib/utils';
import GenericTable from './GenericTable';
import { UserIncident } from '../types/data';
import { Button, Chip } from '@mui/material';
import { AdminUser, PhoneUser, useUserStore } from '../stores/userStore';
import UsernamesCircleContainer from './UsersCircleAlerts';

interface UserIncidentsTableProps {
    title: string;
    data: UserIncident[];
    onEditClick: (userIncident: UserIncident) => void;
    handleOpenDeleteModal: (userIncident: UserIncident) => void;
    pagination: {
        pageIndex: number;
        pageSize: number;
    };
    isRefetching: boolean;
    isError: boolean;
    isLoading: boolean;
    setPagination: (pagination: { pageIndex: number; pageSize: number }) => void;
    handleStageIncident: (userIncident: UserIncident) => void;
}
const UserIncidentsTable = (props:UserIncidentsTableProps) => {
    const { 
        title,
        data,
        onEditClick,
        handleOpenDeleteModal,
        pagination,
        isRefetching,
        isError,
        isLoading,
        setPagination,
        handleStageIncident
    } = props;

    const { user } = useUserStore((state) => ({
        user: state.user,
    }));

    const [currentManagedUsers, setCurrentManagedUsers] = useState([] as PhoneUser[]);
    useEffect(() => {
        if (user.role === "admin") {
            setCurrentManagedUsers((user as AdminUser).managedUsers);
            console.log("Chat managed users: ", currentManagedUsers);
        }
    }, [user]);
    
    const showColumnFilters = false;
    const columns: MRT_ColumnDef<UserIncident>[] =[
        { accessorKey: 'reported_at', header: 'Reported At'},
        {
            accessorKey: "category_name",
            header: "Category",
            maxSize:2
        },
        {
            accessorKey: "type_name",
            header: "Type",
            maxSize:2
        },
        {
            accessorKey: "summary",
            header: "Summary",
        },
        {
            accessorKey: "started_at_timestamp",
            header: "Dates",
            maxSize: 7,
            Cell: ({row}: {row: {original: UserIncident}}) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>Start: {formatDateTimeToString(row.original.started_at_timestamp)}</span>
                        <span>End: {formatDateTimeToString(row.original.ended_at_timestamp)}</span>
                    </div>
                );
            },
        },
        {
            accessorKey: "alerts",
            header: "Alerts",
            Cell: ({row}: {row: {original: UserIncident}}) => {
                if (row.original.alerts == undefined  || row.original.alerts.length === 0){
                    return null;
                }
                if (row.original.creator_id !== user.id){
                    const alertCreator = currentManagedUsers.filter(managedUser=>managedUser.id===row.original.alerts[0]?.creator_id)[0];
                    if (alertCreator === undefined) {
                        return (
                            <div
                                key={row.original.id}
                            >
                            </div>
                        );
                    }
                    return (
                        <div>
                            <Chip 
                                key={row.original.alerts[0].id} 
                                label={`From ${alertCreator?.firstName} ${alertCreator?.surname}`}
                                sx={{
                                    fontWeight: 'bold',
                                    color: "white",
                                    background: "red",
                                }}
                            />
                        </div>
                    );
                } else { 
                    const alertReceivers = row.original.alerts.map(alert=>{
                        const alertReceiver = currentManagedUsers.filter(managedUser=>managedUser.id===alert.receiver_id)[0];
                        return `To ${alertReceiver?.firstName} ${alertReceiver?.surname}`;
                    });
                    if (alertReceivers.length === 0) {
                        return null;
                    }
                    return (
                        <div>   
                            <UsernamesCircleContainer usernames={alertReceivers} />
                        </div>
                    );
                }
            },
        },
        {
            accessorKey: "incident",
            header: "Send Incident",
            maxSize: 7,
            Cell: ({row}: {row: {original: UserIncident}}) => {
                const incident = row.original;
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={()=> handleStageIncident(incident)}
                        sx={{
                            bgcolor: "#194B4B",
                            fontWeight: 'bold',
                        }}
                    >
                        Notify Users
                    </Button>
                );
            },
        },
    ];

    const [sorting, setSorting] = useState<MRT_SortingState>([ 
        { id: 'reported_at', desc: true }
    ]);
    
    const UserIncidentsTable = (
        <GenericTable
            title={title}
            columns={columns}
            data={data}
            onEditClick={onEditClick}
            handleOpenDeleteModal={handleOpenDeleteModal}
            sorting={sorting}
            pagination={pagination}
            rowCount={data.length}
            isRefetching={isRefetching}
            isError={isError}
            isLoading={isLoading}
            showColumnFilters={showColumnFilters}
            setPagination={setPagination}
            setSorting={setSorting}
            enableRowActions={true}
            columnVisibility={{reported_at: false}}
        />
    ); 

    return UserIncidentsTable;
};

export default UserIncidentsTable;