
import React, { useMemo } from "react";

import { 
    Button,
    Box, 
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography
} from "@mui/material";
import { Close } from '@mui/icons-material/';

const DeleteModal = (props:any) => {
    const { 
        titleText, 
        bodyText,
        handleClose, 
        handleDelete, 
    } = props

    const table = useMemo(() => {
        return (
            <Dialog open={true} maxWidth="sm" fullWidth >
                <DialogTitle>{titleText}</DialogTitle>
                <Box position="absolute" top={0} right={0}>
                    <IconButton>
                    <Close onClick={handleClose}/>
                    </IconButton>
                </Box>
                <DialogContent>
                    <Typography>{bodyText}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="contained" onClick={handleClose}>
                    Cancel
                    </Button>
                    <Button color="secondary" variant="contained" onClick={handleDelete}>
                    Confirm
                    </Button >
                </DialogActions>
            </Dialog>
            );
    }, [

    ]);
    return table
}

export default DeleteModal;