import React,  { useCallback, useState } from 'react';
import { useUserStore, AdminUser } from '../stores/userStore';
import GenericTable from './GenericTable';
import { FollowMeHomeEvent } from '../types/data';

import {  MRT_ColumnDef, MRT_PaginationState, MRT_SortingState } from "material-react-table";
import { formatDateTimeToString } from '../lib/utils';
import { Chip } from '@mui/material';

interface FollowMeHomeHistoryTableProps {
    title: string;
    data: FollowMeHomeEvent[];
}
const FollowMeHomeHistoryTable = (props: FollowMeHomeHistoryTableProps) => {   
    const { 
        title,
        data,
    } = props;

    const { user } = useUserStore((state) => ({
        user: state.user,
    }));

    const getInitialsFromID = useCallback((id: number)=>{
        const managedUsers = (user as AdminUser).managedUsers;
        const currentUser = managedUsers.filter(managedUser=>managedUser.id===id)[0];

        let initials = "";
        if (currentUser){
            initials = currentUser.firstName.length > 0 ?  `${currentUser.firstName} ${currentUser.surname}` : "";
        }
        
        return initials;
    },[user]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({pageIndex: 0,pageSize: 5});
    const isRefetching = false;
    const isError = false;
    const isLoading = false;
    const [sorting, setSorting] = useState<MRT_SortingState>([ 
        { id: 'started_at', desc: true }
    ]);
    const columns: MRT_ColumnDef<FollowMeHomeEvent>[] = [
        {
            accessorKey: "user_id",
            header: "FMH User",
            size: 50,
            Cell: ({ row }: {row: { original: FollowMeHomeEvent}}) => (
                <div>
                    {getInitialsFromID(row.original.user_id)}
                </div>
            ),
        },
        {
            accessorKey: "started_at",
            header: "Date Triggered",
            size: 50,
            Cell: ({ row }: { row: { original: FollowMeHomeEvent } }) => (
                <div>
                    {formatDateTimeToString(row.original.started_at)}
                </div>
            ),
            filterVariant:"date-range"
        },
        {
            accessorKey: "ended_at",
            header: "Date Complete",
            size: 50,
            Cell: ({ row }: { row: { original: FollowMeHomeEvent } }) => (
                <div>
                    {formatDateTimeToString(row.original.ended_at)}
                </div>
            ),
            filterVariant:"date-range"
        },
        {
            accessorKey: "active",
            header: "Status",
            size: 50,
            Cell: ({ row }: {row: { original: FollowMeHomeEvent}}) => (
                <div>
                    {
                        row.original.active ? (

                            <Chip
                                label="Active"
                                sx={{
                                    color: "white",
                                    background: "red",
                                    fontWeight: 'bold',
                                }}
                            />
                           
                        ) : (
                            <Chip
                                label="Complete"

                                sx={{
                                    color: "black",
                                    fontWeight: 'bold',
                                }}
                            />
                        )}
                </div>
            ),

        },
    ];
    const enableRowActions = false;
    const FollowMeHomeHistoryTable = (
        <GenericTable
            title={title}
            columns={columns}
            data={data}
            onEditClick={()=>{}}
            handleOpenDeleteModal={()=>{}}
            sorting={sorting}
            pagination={pagination}
            rowCount={data.length}
            isRefetching={isRefetching}
            isError={isError}
            isLoading={isLoading}
            setPagination={setPagination}
            setSorting={setSorting}
            enableRowActions={enableRowActions}
        />
    ); 

    return FollowMeHomeHistoryTable;
};

export default FollowMeHomeHistoryTable;