import React, { useState } from 'react';
import { formatDateTimeToString } from '../lib/utils';
import GenericTable from './GenericTable';
import { UserIncident } from '../types/data';

import {  MRT_ColumnDef, MRT_SortingState } from "material-react-table";
const MapUserIncidentsTable = (props:any) => {
    const { 
        title,
        data,
        onEditClick,
        handleOpenDeleteModal,
        pagination,
        isRefetching,
        isError,
        isLoading,
        setPagination,
        enableRowActions
    } = props;

    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const columns:MRT_ColumnDef<UserIncident>[] =[
        {
            accessorKey: "category_name",
            header: "Category",
            maxSize:2
        },
        {
            accessorKey: "type_name",
            header: "Type",
            maxSize:2
        },
        {
            accessorKey: "summary",
            header: "Summary",
        },
        {
            accessorKey: "started_at_timestamp",
            header: "Start Date",
            Cell: ({row}: {row: {original: UserIncident}}) => {
                return formatDateTimeToString(row.original.started_at_timestamp);
            }
        },
        {
            accessorKey: "ended_at_timestamp",
            header: "End Date",
            Cell: ({row}: {row: {original: UserIncident}}) => {
                return formatDateTimeToString(row.original.ended_at_timestamp);
            }
        },
    ];
    const userIncidentsTable = (
        <GenericTable
            title={title}
            columns={columns}
            data={data}
            onEditClick={onEditClick}
            handleOpenDeleteModal={handleOpenDeleteModal}
            sorting={sorting}
            pagination={pagination}
            rowCount={data.length}
            isRefetching={isRefetching}
            isError={isError}
            isLoading={isLoading}
            setPagination={setPagination}
            setSorting={setSorting}
            enableRowActions={enableRowActions}
        />
    ); 

    return userIncidentsTable;
};

export default MapUserIncidentsTable;