import axios from 'axios';
import cookie from 'js-cookie';
import {
  setAccessToken,
  setRefreshToken,
  setEmail,
  setStaff,
  setCompanyName,
  set2FAStatus,
  setFirstName,
  setSurname,
  setRole,
  setIsLoggedIn,
  setLoading,
  setId,
} from '../actions/userActions';
import store from '../store';
import {logout} from './utils';

const API_URL = process.env.REACT_APP_API_URL;
const getAuth = () => {
  // console.log("token is ", store.getState().user.access_token)
  return {
    // Authorization: 'Bearer '.concat("", 'elkdQATn7mULnXwPETnOu0qWsfkT0q')
    Authorization: 'Bearer ' + store.getState().user.access_token
  }
};
const AUTH_USERNAME = process.env.REACT_APP_CLIENT_ID;
const AUTH_PASSWORD = process.env.REACT_APP_CLIENT_SECRET;
export const landing = process.env.REACT_APP_KOWROO_MANAGER_LANDING;
export const testMode = process.env.REACT_APP_KOWROO_TEST_MODE;

export const tableauAuth = () => {
  return axios.post(
      'http://99.81.101.248:80/trusted/',
      {username: 'admin'},
  ).catch((e) => {
    return e.response;
  });
};

export const tableauAuthTest = () => {
  return axios.get(
      `${API_URL}/user/tableau/`,
      {
        headers: getAuth(),
      },
  ).catch((e) => {
    return e.response;
  });
};

export const getCountriesOfInterest = () => {
  return axios.get(
      `${API_URL}/user/countries/`,
      {
        headers: getAuth(),
      },
  ).catch((e) => {
    return e.response;
  });
};

export const setCountriesOfInterestApi = (code) => {
  return axios.post(
      `${API_URL}/user/countries/`,
      {
        code: code,
      },
      {
        headers: getAuth(),
      },
  ).catch((e) => {
    return e.response;
  });
};


// Reset call for testing
export const resetUser = (
    email,
    title = '',
    firstName = '',
    surname = '',
    position = '',
    phoneNumber = '',
    companyName = '',
    addressLineOne = '',
    addressLineTwo = '',
    stateOrCounty = '',
    postcode = '',
    country = '',
) => {
  if (testMode === 'True') {
    return axios.post(
        `${API_URL}/user/resetuser/?email=` + email,
        {
          title,
          firstName,
          surname,
          position,
          phoneNumber,
          companyName,
          addressLineOne,
          addressLineTwo,
          stateOrCounty,
          postcode,
          country,
        },
    );
  }
};


// Authentication calls.
export const login = (username, password) => {
  return axios.post(
      `${API_URL}/auth/token/`,
      {
        username, password,
        grant_type: 'password',
      },
      {
        auth: {
          username: AUTH_USERNAME,
          password: AUTH_PASSWORD,
        },
      },
  ).catch((e) => {
    return e.response;
  });
};

export const logoutToken = (token) => {
  return axios.post(
      `${API_URL}/auth/revoke_token/`,
      {token},
      {
        auth: {
          username: AUTH_USERNAME,
          password: AUTH_PASSWORD,
        },
      },
  ).catch((e) => {
    return e.response;
  });
};


let failedQueue = [];

const processQueue = (error, token = null) => {
    // console.log("failed queue for each")
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};

const api = axios.create({
    baseURL: API_URL,
});

let retries = 0;

// Add a request interceptor
axios.interceptors.request.use(
  config => {
    // Add access token to headers
    const accessToken = store.getState().user.access_token;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    // console.log(` REQUEST RESET : Bearer ${config.headers}`)
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    const oldRefreshToken = cookie.get("refresh_token");;
    
    // console.log("originalRequest", originalRequest)
    if (error.response.status === 401 && oldRefreshToken && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const response = await refreshToken(oldRefreshToken)

        if (response.status === 200) {
          store.dispatch(setAccessToken(response.data.access_token));
          store.dispatch(setRefreshToken(response.data.refresh_token));
          cookie.set('refresh_token', response.data.refresh_token);
          await getMyUser().then(async (res) => {
            if (res.status === 200) {
              // console.log("setEmail", response.data.email)
              store.dispatch(setEmail(res.data.email));
              // console.log("set2FAStatus")
              store.dispatch(set2FAStatus(res.data.is_two_factor_enabled));
              // console.log("setStaff")
              store.dispatch(setStaff(res.data.is_staff));
              // console.log("setId")
              store.dispatch(setId(res.data.id));
              // console.log("setCompanyName")
              store.dispatch(setCompanyName(res.data.companyName));
              store.dispatch(setFirstName(res.data.firstName));
              store.dispatch(setSurname(res.data.surname));
              // console.log("setCompanyName123")
            } else {
              // await logout(navigate);
              // console.log("**** Profile Error: ", response.status)
            }
          });
        }

        // console.log(`RESET : Bearer ${response.data.access_token}`)
        // Retry the original request with new tokens
        originalRequest.headers.Authorization = `Bearer ${response.data.access_token}`;
        return axios(originalRequest);
      } catch (error) {
        // Handle token refresh error
        console.error('Error refreshing tokens:', error);
        return Promise.reject(error);
      }
    }

    // Return error if non-retryable error or token refresh fails
    return Promise.reject(error);
  }
)

export const refreshToken = async (refresh) => {
  // if (!isRefreshing) {
    // isRefreshing = true;
    const response = await axios.post(
      `${API_URL}/auth/token/`,
      {refresh_token: refresh, grant_type: 'refresh_token'},
      {
        auth: {
          username: AUTH_USERNAME,
          password: AUTH_PASSWORD,
        },
      },
    ).catch((e) => e.response);
    // isRefreshing = false;
    const newToken = response.data.access_token;
    const newRefresh = response.data.refresh_token;
    // console.log(`REFRESH1 *** ${newToken}`)
    // console.log(`REFRESH *** ${newRefresh}`)
    store.dispatch(setAccessToken(newToken));
    store.dispatch(setRefreshToken(newRefresh));
    processQueue(null, newToken);
    return response;
// } else {
//     return new Promise((resolve, reject) => {
//         failedQueue.push({ resolve, reject });
//     }).then(token => {
//         return token;
//     });
// }
};


export const resendAccountActivation = (email) => {
  return axios.post(
      `${API_URL}/auth/account/reactivate/`,
      {
        email,
      },
  ).catch((e) => e.response);
};

export const accountActivation = (uid, token) => {
  return axios.post(
      `${API_URL}/auth/account/activate/`,
      {uid, token},
  ).catch((e) => e.response);
};

export const sendForgotPasswordEmail = (email) => {
  return axios.post(
      `${API_URL}/auth/reset/send/`,
      {
        email,
      },
  ).catch((e) => e.response);
};

export const resetPassword = (
    uid, token, newPassword, reNewPassword, invitedUserId,
) => {
  return axios.post(
      `${API_URL}/auth/reset/confirm/`,
      {
        uid,
        token,
        new_password: newPassword,
        re_new_password: reNewPassword,
        invited_user_id: invitedUserId,
      },
  ).catch((e) => e.response);
};

// const refresh = async (navigate) => {
//   const {user} = store.getState();
//   const refresh = (
//     user.refresh_token ? user.refresh_token : cookie.get('refresh_token')
//   );
//   await refreshToken(refresh).then(async (res) => {
//     if (res.status === 200) {
//       store.dispatch(setAccessToken(res.data.access_token));
//       store.dispatch(setRefreshToken(res.data.refresh_token));
//       cookie.set('refresh_token', res.data.refresh_token);
//       await getMyUser().then(async (res) => {
//         if (res.status === 200) {
//           store.dispatch(setEmail(res.data.email));
//           store.dispatch(set2FAStatus(res.data.is_two_factor_enabled));
//           store.dispatch(setStaff(res.data.is_staff));
//           store.dispatch(setId(res.data.id));
//           store.dispatch(setCompanyName(res.data.companyName));
//         } else {
//           await logout(navigate);
//         }
//       });
//     } else {
//       await logout(navigate);
//     }
//   }).catch((e) => e.response);
// };

// User calls.
export const registerAdmin = (company_name,
    email, password, reNewPassword, firstName, surname,
) => {
  return axios.post(
      `${API_URL}/user/create_u2/`,
      {
        company_name,
        role: 'admin',
        email,
        password,
        re_password: reNewPassword,
        first_name: firstName,
        surname,
      },
      {
          headers: getAuth(),
      },
  ).catch((e) => e.response);
};

export const registerCustomer = (
    companyName, 
    email,
    password,
    rePassword,
    firstName,
    surname,
    phone) => {
  return axios.post(
      `${API_URL}/user/create_u2/`,
      { 
        company_name: companyName,
        role: 'customer',
        email,
        password,
        re_password: rePassword,
        first_name: firstName,
        surname,
        phone_number: phone
      },
      {
        headers: getAuth(),
      },
  ).catch(async (e) => {
    // if (e.response.status === 401) {
    //   await refresh(navigate);
    //   const resp = await registerCustomer(
    //     email,
    //     password,
    //     rePassword,
    //     firstName,
    //     surname,
    //     navigate
    //   );
    //   return resp;
    // }
    return e.response;
  });
};

export const editCustomer = (
  email,
  firstName,
  surname,
  phone) => {
return axios.put(
    `${API_URL}/user/update_u2/`,
    {
      role: 'customer',
      email,
      first_name: firstName,
      surname,
      phone_number: phone
    },
    {
      headers: getAuth(),
    },
).catch(async (e) => {

  return e.response;
});
};

export const postProfile = (id, gender,
  age, nationality, ethnicity, religion, orientation, spokenLangs, medicalCond
) => {
return axios.post(
    `${API_URL}/profile/profile/`,
    {
      id, /* User Profile ID if exists*/
      gender,
      age,
      nationality,
      ethnicity,
      religion,
      orientation,
      spoken_lang: spokenLangs,
      medical_cond: medicalCond,
    },
    {
        headers: getAuth(),
    },
).catch((e) => e.response);
};

export const getUserLocations = () => {
  // console.log("GET LOCATIONS", store.getState())
  return axios.get(
      `${API_URL}/user/getlocations/`,
      {
        headers: getAuth(),
      },
  ).catch(async (e) => {
    return e.response;
  });
};

export const sendUserLocation = (latitude, longitude) => {
  return axios.post(
      `${API_URL}/user/locations/`,
      {
        latitude: latitude,
        longitude: longitude,
      },
      {
        headers: getAuth(),
      },
  ).catch(async (e) => {
    return e.response;
  });
};

export const getMyUser = () => {
  return axios.get(
      `${API_URL}/user/profile/`,
      {
        headers: getAuth(),
      },
  ).catch(async (e) => {
    console.log(`Caught error while fetching profile`)
    console.log(e)
    return e.response;
  });
};


export const getUserAndInvitedUsers = async () => {
  try {
    const [User, invitedUsers] = await axios.all([
      getMyUser(),
      searchUsers("","","","","")
      // Add other Axios requests here
    ]);

    let statusCode = 400 
    if (User.status===200 && invitedUsers.status===200) {
      statusCode = 200
    }
    // const myUserData = User.data;
    // const otherData = invitedUsers.data;

    const combinedData = {
      status:statusCode, 
      data:User.data,
      invitedUsers:invitedUsers.data.results,
    };

    return combinedData;
  } catch (error) {
    console.error('Error fetching combined data:', error);
    throw error;
  }
};

export const getUserProfile = () => {
  return axios.get(
      `${API_URL}/profile/getprofile/`,
      {
        headers: getAuth(),
      },
  ).catch(async (e) => {
    return e.response;
  });
};

export const resendInvite = (email, navigate) => {
  return axios.post(
      `${API_URL}/user/reinvite/`,
      {
        email,
      },
      {
        headers: getAuth(),
      },
  ).catch(async (e) => {
    // if (e.response.status === 401) {
    //   await refresh(navigate);
    //   return await resendInvite(email, navigate);
    // }
    return e.response;
  });
};


export const getInvites = (navigate) => {
  return axios.get(
      `${API_URL}/user/invites/`,
      {
        headers: getAuth(),
      }).catch(async (e) => {
    // if (e.response.status === 401) {
    //   await refresh(navigate);
    //   return await getInvites(navigate);
    // }
    return e.response;
  });
};

export const getInvite = (uid, navigate) => {
  return axios.get(
      `${API_URL}/user/invite/`,
      {
        params: {
          uid: uid,
        },
        headers: getAuth(),
      }).catch(async (e) => {
    // if (e.response.status === 401) {
    //   await refresh(navigate);
    //   return await getInvite(uid, navigate);
    // }
    return e.response;
  });
};

export const searchUsers = (
  firstName,
  surname,
  phoneNumber,
  riskRating,
  status) => {
  return axios.get(
      `${API_URL}/user/search/`,
      {
        params: {
          firstName,
          surname,
          phoneNumber,
          riskRating,
          status
        },
        headers: getAuth(),
      },
  ).catch(async (e) => {
    // if (e.response.status === 401) {
    //   await refresh(navigate);
    //   return await searchUsers(search, start, end, navigate);
    // }
    return e.response;
  });
};

export const searchTrips = (search, start, end, page=1, pageSize=100) => {
  return axios.get(
      `${API_URL}/user/gettrips/`,

      {
        params: {
          search,
          start,
          page: page,
          page_size: pageSize,
          end,
        },
        headers: getAuth(),
      },
  ).catch(async (e) => {
    // if (e.response.status === 401) {
    //   await refresh(navigate);
    //   return await searchTrips(search, start, end, navigate);
    // }
    return e.response;
  });
};

// export const getIntelligenceFusGeoJson = (page,page_size,start_date,end_date) => {
//   const currentToken = getAuth()
//   return axios.get(
//       `${API_URL}/data/intelligence_fusion/`,
//       {
//         params: {
//           // page: page,
//           page_size: page_size,
//           start_date: start_date,
//           // end_date: end_date,
//         },
//         headers: getAuth(),
//       },
//   ).catch(async (e) => {
//     return e.response;
//   });
// };

export const getIntelligenceFusGeoJson = (page,page_size,start_date,end_date) => {
  const currentToken = getAuth()
  return axios.get(
      `${API_URL}/data/current_intelligence_fusion/`,
      {
        params: {
          // page: page,
          page_size: page_size,
          start_date: start_date,
          // end_date: end_date,
        },
        headers: getAuth(),
      },
  ).catch(async (e) => {
    return e.response;
  });
};

export const getIntelligenceFusionPolygon = (page) => {
  return axios.get(
    `${API_URL}/data/intelligence_fusion_polygon/`,
    {
      params: {
        page: page,
        page_size: 2500,
      },
      headers: getAuth(),
    },
).catch(async (e) => {
  return e.response;
});
}

export const getGdeltGeoJson = () => {
  return axios.get(
      `${API_URL}/data/gdelt/`,
      {
        params: {
          page_size: 1000,
        },
        headers: getAuth(),
      },
  ).catch(async (e) => {
    return e.response;
  });
};

// Todo Remove
export const getGdeltGeoGson = () => {
  return axios.get(
      `${API_URL}/data/gdelt/`,
      {
        params: {
          page_size: 1000,
        },
        headers: getAuth(),
      },
  ).catch(async (e) => {
    return e.response;
  });
};

export const getGdeltArticles = (event_id) => {
  return axios.get(
      `${API_URL}/data/gdelt/articles/`,
      {
        params: {
          event_id: event_id,
        },
        headers: getAuth(),
      },
  ).catch(async (e) => {
    return e.response;
  });
};


export const searchAssets = (search, start, end, navigate, page=1, pageSize=1000) => {
  return axios.get(
      `${API_URL}/user/asset/`,
      {
        params: {
          page: page,
          page_size: pageSize,
          search,
          start,
          end,
        },
        headers: getAuth(),
      },
  ).catch(async (e) => {
    // if (e.response.status === 401) {
      // await refresh(navigate);
      // return await searchAssets(search, start, end, navigate);
    // }
    return e.response;
  });
};

export const createIncident = (category_name,
  type_name, summary, location_timezone, started_at,
  ended_at, impact_long_description,
  location_longitude,
  location_latitude, id
) => {
return axios.post(
    `${API_URL}/user/incidents/`,
    {
      category_name: category_name,
      type_name: type_name,
      summary: summary,
      location_timezone: location_timezone,
      started_at: started_at,
      ended_at: ended_at,
      impact_long_description: impact_long_description,
      longitude: location_longitude,
      latitude: location_latitude,
      started_at_timestamp: started_at,
      ended_at_timestamp: ended_at,
      id: id,
    },
    {
        headers: getAuth(),
    },
).catch((e) => e.response);
};

export const searchIncidents = (search, start, end, navigate, page=1, page_size=1000) => {
  return axios.get(
      `${API_URL}/user/incidents/`,
      {
        params: {
          search,
          start,
          end,
          page,
          page_size
        },
        headers: getAuth(),
      },
  ).catch(async (e) => {
    // if (e.response.status === 401) {
    //   await refresh(navigate);
    //   return await searchIncidents(search, start, end, navigate);
    // }
    return e.response;
  });
};

export const createAsset = (name, type,
  description, longitude, latitude, id
) => {
return axios.post(
    `${API_URL}/user/asset/`,
    {
      name: name,
      type: type,
      description: description,
      longitude: longitude,
      latitude: latitude,
      id: id,
      icon: ''
    },
    {
        headers: getAuth(),
    },
).catch((e) => e.response);
};

export const deleteAsset = (id
) => {
return axios.delete(
    `${API_URL}/user/asset/`,
    {
      params: {
      id
    },

        headers: getAuth(),
    },
).catch((e) => e.response);
};
export const deleteIncident = (id
  ) => {
  return axios.delete(
      `${API_URL}/user/incidents/`,
      {
        params: {
        id
      },
          headers: getAuth(),
      },
  ).catch((e) => e.response);
  };



export const searchTeams = (search, start, end, navigate) => {
  return axios.get(
      `${API_URL}/user/getteamslist/`,
      {
        params: {
          search,
          start,
          end,
        },
        headers: getAuth(),
      },
  ).catch(async (e) => {
    // if (e.response.status === 401) {
    //   await refresh(navigate);
    //   return await searchTeams(search, start, end, navigate);
    // }
    return e.response;
  });
};

export const createTeam = (name, description, users, id
) => {
return axios.post(
    `${API_URL}/user/teams/`,
    {
      name,
      description,
      users,
      id
    },
    {
        headers: getAuth(),
    },
).catch((e) => e.response);
};

export const createIncidentNotification = (
    incidentId, receiverId 
  ) => {
  return axios.post(
      `${API_URL}/user/incident_notifications/`,
      {
        incident_id:incidentId, receiver_id:receiverId 
      },
      {
          headers: getAuth(),
      },
  ).catch((e) => e.response);
  };

export const deleteTeam = (id
  ) => {
  return axios.delete(
      `${API_URL}/user/teams/`,
      {
        params: {
        id
      },
          headers: getAuth(),
      },
  ).catch((e) => e.response);
  };




export const createRiskApp = (risk_app
) => {
return axios.post(
    `${API_URL}/user/riskapp/`,
    {
      risk_app:risk_app
    },
    {
        headers: getAuth(),
    },
).catch((e) => e.response);
};

export const getRiskApp = () => {
  return axios.get(
      `${API_URL}/user/riskapp/`,
      {
        headers: getAuth(),
      },
    ).catch((e) => e.response);
  };



export const getAlerts = (check_in=false, page, page_size) => {
  return axios.get(
      `${API_URL}/user/alert/`,
      {
        params: {
          page_size: page_size,
          page: page,
          check_in,
        },
        headers: getAuth(),
      },
  ).catch(async (e) => {
    return e.response;
  });
};

export const getNewAlerts = (check_in=false) => {
  return axios.get(
      `${API_URL}/user/getnewalerts/`,
      {
        params: {
          page_size: 200,
          page: 1,
          check_in,
        },
        headers: getAuth(),
      },
  ).catch(async (e) => {
    return e.response;
  });
};


export const getChatMessages = (page=1, page_size=10000) => {
  return axios.get(
      `${API_URL}/user/get_messages/`,
      {
        params: {
          page_size: page_size,
          page: page
        },
        headers: getAuth(),
      },
  ).catch(async (e) => {
    return e.response;
  });
};


export const getNotifications = (check_in=false) => {
  return axios.get(
      `${API_URL}/user/get_notifications/`,
      {
        params: {
          page_size: 200,
          page: 1,
          check_in,
        },
        headers: getAuth(),
      },
  ).catch(async (e) => {
    return e.response;
  });
};


export const createAlert = (text, is_sos, for_all, incident_id="", check_in=false, user_id) => {
  return axios.post(
      `${API_URL}/user/alert/`,
      {
        text: text,
        is_sos: is_sos,
        for_all: for_all,
        incident_id: incident_id,
        check_in,
        user_id: user_id,
        // subject: subject,
        // uid: uid,
      },
      {
        headers: getAuth(),
      },
  ).catch(async (e) => {
    return e.response;
  });
};

export const postCheckins = (receiver_id, latitude, longitude) => {
  return axios.post(
      `${API_URL}/user/checkin/`,
      {
        receiver_id:receiver_id,
        latitude:latitude,
        longitude:longitude,
      },
      {
        headers: getAuth(),
      },
  ).catch(async (e) => {
    return e.response;
  });
}

export const getCheckins = () => {
  return axios.get(
      `${API_URL}/user/getcheckin/`,
      {
        headers: getAuth(),
      },
  ).catch(async (e) => {
    return e.response;
  });
}

export const getSOS = (search, start, end, page=1, page_size=1000) => {
  return axios.get(
      `${API_URL}/user/getsos/`,
      {
        params: {
          search:search,
          start:start,
          end:end,
          page:page,
          page_size:page_size
        },
        headers: getAuth(),
      },
  ).catch(async (e) => {
    return e.response;
  });
}
export const getIncidentCategories = () => {
  return axios.get(
      `${API_URL}/user/getincidentcategories/?page=1&page_size=500`,
      {
        headers: getAuth(),
      },
  ).catch(async (e) => {
    return e.response;
  });
}

export const getIncidentTypes = (category_name) => {
  return axios.get(
      `${API_URL}/user/getincidenttypescategory/?page=1&page_size=100`,
      {
        params: {
          category_name,
        },
        headers: getAuth(),
      },
  ).catch(async (e) => {
    return e.response;
  });
}

export const approveAlert = (id, check_in) => {
  return axios.put(
      `${API_URL}/user/alert/`,
      {
        id: id,
        check_in: check_in,
        // subject: subject,
        // uid: uid,
      },
      {
        headers: getAuth(),
      },
  ).catch(async (e) => {
    return e.response;
  });
};

export const approveTrip = (id) => {
  return axios.put(
      `${API_URL}/user/trips/`,
      {
        id: id,
        // subject: subject,
        // uid: uid,
      },
      {
        headers: getAuth(),
      },
  ).catch(async (e) => {
    return e.response;
  });
};

export const markReadAlert = (id) => {
  return axios.post(
      `${API_URL}/user/alert/mark_read/`,
      {
        id: id,
      },
      {
        headers: getAuth(),
      },
  ).catch(async (e) => {
    return e.response;
  });
};



export const createTrip = (country,
  type, text, startDate, endDate, country2, start2, end2, country3, start3, end3,id=null
) => {
  let connections =  [{
    country: country,
    text,
    start_date: startDate,
    end_date: endDate,
    },
    start2 && end2 && country2 ?  {
      country: country2,
      start_date: start2,
      end_date: end2,
    } : null,
    start3 && end3 && country3 ?  {
      country: country3,
      start_date: start3,
      end_date: end3,
    } : null,
  ].filter((x) => x !== null);
return axios.post(
    `${API_URL}/user/tripapproval/`,
    {
      type: type,
      connections: connections.filter((x) => x !== null),
      id: id
    },
    {
        headers: getAuth(),
    },
).catch((e) => e.response);
};

export const deleteTrip = (id
  ) => {
  return axios.delete(
      `${API_URL}/user/trips/`,
      {
        params: {
        id
      },
          headers: getAuth(),
      },
  ).catch((e) => e.response);
  };



export const setup2FA = (navigate) => {
  return axios.get(
      `${API_URL}/totp/create/`,
      {
        headers: getAuth(),
      },
  ).catch(async (e) => {
    // if (e.response.status === 401) {
    //   await refresh(navigate);
    //   return await setup2FA(navigate);
    // }
    return e.response;
  });
};

export const login2FA = (code, navigate) => {
  return axios.post(
      `${API_URL}/totp/login/` + code + '/',
      {
        headers: getAuth(),
      },
  ).catch(async (e) => {
    // if (e.response.status === 401) {
    //   await refresh(navigate);
    //   return await login2FA(code, navigate);
    // }
    return e.response;
  });
};

export const disable2FA = (navigate) => {
  return axios.post(
      `${API_URL}/totp/disable/`,
      {},
      {
        headers: getAuth(),
      },
  ).catch(async (e) => {
    // if (e.response.status === 401) {
    //   await refresh(navigate);
    //   return await disable2FA(navigate);
    // }
    return e.response;
  });
};

export const fetchGuestTokenFromBackend = () => {
  return axios.get(
      `${API_URL}/user/dashboard/embed/`,
      {
        headers: getAuth(),
      },
  ).catch((e) => {
    return e.response;
  });
};