
import React, { useMemo } from "react";

import { 
    Button,
    Box, 
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography
} from "@mui/material";
import { Close } from '@mui/icons-material';

const DeleteModal = (props:any) => {
    const { 
        titleText, 
        bodyText,
        handleClose, 
        handleDelete, 
        selectedDeleteObject
    } = props;

    const table = useMemo(() => {
        return (
            <Dialog open={true} maxWidth="sm" fullWidth >
                <DialogTitle>{titleText}</DialogTitle>
                <Box position="absolute" top={0} right={0}>
                    <IconButton onClick={handleClose}>
                        <Close/>
                    </IconButton>
                </Box>
                <DialogContent>
                    <Typography style={{ whiteSpace: 'pre-line' }}>{bodyText}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button 
                        sx={{ 
                            backgroundColor: 'green', 
                            '&:hover': { backgroundColor: 'darkgreen' },
                            fontWeight: 'bold'
                        }} 
                        variant="contained" 
                        onClick={handleClose}
                    >
                    Cancel
                    </Button>
                    <Button 
                        sx={{ 
                            backgroundColor: 'green', 
                            '&:hover': { backgroundColor: 'darkgreen' },
                            fontWeight: 'bold'
                        }} 
                        variant="contained" 
                        onClick={()=>handleDelete(selectedDeleteObject)}
                    >
                    Confirm
                    </Button >
                </DialogActions>
            </Dialog>
        );
    }, [
        selectedDeleteObject
    ]);
    return table;
};

export default DeleteModal;