import React, { useState } from 'react';
import { Chip, Typography } from '@mui/material';
import { MRT_ColumnDef, MRT_SortingState } from "material-react-table";

import { PhoneUser } from "../stores/userStore";
import GenericTable from './GenericTable';
import globalConfig from "../config.json";

const MapUsersTable = (props: any) => {
    const {
        title,
        data,
        onEditClick,
        handleOpenDeleteModal,
        pagination,
        isRefetching,
        isError,
        isLoading,
        setPagination,
        enableRowActions
    } = props;
    const usersColumns: MRT_ColumnDef<PhoneUser>[] = [
        {
            accessorKey: "username",
            header: "Name",
            size: 60,
            Cell: ({ row }: {row: {original: PhoneUser }}) => (
                <div>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            fontFamily: globalConfig["fontFamilies"],
                        }}
                    >
                        {row.original.firstName + " " + row.original.surname}
                    </Typography>
                </div>
            ),
        },
        {
            accessorKey: "connected",
            header: "Connected",
            size: 50,
            Cell: ({ row }: {row: {original: PhoneUser }}) => (
                <div>
                    {
                        row.original.connected ? (
                            <Chip
                                label="Connected"
                                sx={{
                                    fontWeight: 'bold',
                                    color: globalConfig["ratingTextColors"]["1"],
                                    backgroundColor: globalConfig["ratingBGColors"]["low"],
                                }}
                            />
                        ) : (
                            <Chip
                                label="Not Connected"
                                sx={{
                                    color: "black",
                                    fontWeight: 'bold',
                                }}
                            />
                        )}
                </div>
            ),
        },
        {
            accessorKey: "isSOS",
            header: "SOS",
            size: 50,
            Cell: ({ row }: {row: {original: PhoneUser }}) => (
                <div>
                    {
                        row.original.isSOS ? (
                            <Chip
                                label="SOS"
                                sx={{
                                    color: "white",
                                    background: "red",
                                    fontWeight: 'bold',
                                }}
                            />
                        ) : (
                            <Chip
                                label="Not Active"
                                sx={{
                                    color: "black",
                                    fontWeight: 'bold',
                                }}
                            />
                        )}
                </div>
            ),
        },
        {
            accessorKey: "isFMH",
            header: "Follow Me Home",
            size: 50,
            Cell: ({ row }: {row: {original: PhoneUser }}) => (
                <div>
                    {
                        row.original.isFMH ? (
                            <Chip
                                label="Follow me Home"
                                sx={{
                                    color: "white",
                                    background: "red",
                                    fontWeight: 'bold',
                                }}
                            />
                        ) : (
                            <Chip
                                label="Not Active"
                                sx={{
                                    color: "black",
                                    fontWeight: 'bold',
                                }}
                            />
                        )}
                </div>
            ),
        },
        {
            accessorKey: "checkinOpen",
            header: "Checkin Open",
            size: 50,
            Cell: ({ row }: {row: {original: PhoneUser }}) => (
                <div>
                    {
                        row.original.checkinOpen ? (
                            <Chip
                                label="Checkin Pending"
                                sx={{
                                    color: "white",
                                    background: "red",
                                    fontWeight: 'bold',
                                }}
                            />
                        ) : (
                            <Chip
                                label="Not Pending"
                                sx={{
                                    color: "black",
                                    fontWeight: 'bold',
                                }}
                            />
                        )}
                </div>
            ),
        },
        {
            accessorKey: "riskRating",
            header: "Risk Rating",
            size: 50,
            Cell: ({ row }: {row: {original: PhoneUser }}) => (
                <div>
                    {
                        row.original.riskRating === "Low Risk" ? (
                            <Chip
                                label={row.original.riskRating}
                                sx={{
                                    fontWeight: 'bold',
                                    color: globalConfig["ratingTextColors"]["1"],
                                    backgroundColor: globalConfig["ratingBGColors"]["low"],
                                }}
                            />
                        ) : row.original.riskRating === "Medium Risk" ? (
                            <Chip
                                label={row.original.riskRating}
                                sx={{
                                    fontWeight: 'bold',
                                    color: globalConfig["ratingTextColors"]["3"],
                                    backgroundColor: globalConfig["ratingBGColors"]["3"],
                                }}
                            />
                        ) : row.original.riskRating === "High Risk" ? (
                            <Chip
                                label={row.original.riskRating}
                                sx={{
                                    fontWeight: 'bold',
                                    color: globalConfig["ratingTextColors"]["5"],
                                    backgroundColor: globalConfig["ratingBGColors"]["5"],
                                }}
                            />
                        ) : (
                            <Chip
                                label={row.original.riskRating}
                                sx={{
                                    fontWeight: 'bold',
                                    color: globalConfig["ratingTextColors"]["5"],
                                    backgroundColor: globalConfig["ratingBGColors"]["5"],
                                }}
                            />
                        )
                    }
                </div>
            ),
        },
    ];
    
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const userTable = (
        <GenericTable
            title={title}
            columns={usersColumns}
            data={data}
            onEditClick={onEditClick}
            handleOpenDeleteModal={handleOpenDeleteModal}
            sorting={sorting}
            pagination={pagination}
            rowCount={data.length}
            isRefetching={isRefetching}
            isError={isError}
            isLoading={isLoading}
            setPagination={setPagination}
            setSorting={setSorting}
            enableRowActions={enableRowActions}
        />
    );

    return userTable;
};

export default MapUsersTable;