import React, { useState, useEffect } from "react";
import UserIncidentsTable from "../components/UserIncidentsTable";
import Crisis24Table from "../components/Crisis24Table";
import { useDataStore } from "../stores/dataStore";
import { Crisis24Item, UserIncident } from "../types/data";
import {
    MRT_PaginationState,
} from "material-react-table";

import {
    Select, 
    MenuItem, 
    FormControl, 
    InputLabel,
    Box,
    Button,
    Container,
    TextField,
    ThemeProvider,
    createTheme,
    Typography,
    SelectChangeEvent,
    Grid,
    Chip,
    InputAdornment
} from "@mui/material";
import globalConfig from "../config.json";
import { useWebSocketStore } from "../stores/websocketStore";
import { typeNames, impactRatingsMap  } from "../sample_data/constants";

import DeleteModal from "../components/DeleteModal";
import SubmitIncidentModal from "../components/SubmitIncidentModal";

import { AdminUser, PhoneUser, useUserStore } from "../stores/userStore";
import { SearchRounded } from "@mui/icons-material";

const theme = createTheme({
    palette: {
        primary: {
            main: globalConfig["primaryMain"],
            contrastText: globalConfig["background"],
        },
        secondary: {
            main: globalConfig["secondaryMain"],
        },
    },
    typography: {
        fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
    },
});
interface FormData {
    id: string;
    category_name: string;
    type_name: string;
    summary: string;
    started_at_timestamp: string;
    ended_at_timestamp: string;
    impact_rating: string;
    longitude: string;
    latitude: string;
    description: string;
}

type SetFormData = (value: Partial<FormData> | ((prevState: FormData) => Partial<FormData>)) => void;
interface CreateIncidentFormProps {
    initialLatitude: number;
    initialLongitude: number;
    formData: FormData;
    setFormData: SetFormData;
    errors: {[key: string]: string };
    editMode: boolean;
    setEditMode: (editMode: boolean)=>void;
    // handleSubmit: (e: React.FormEvent)=>void;
    selectedSubmitIncident: UserIncident;
    setSelectedSubmitIncident: (userIncident: UserIncident)=>void;
    handleOpenSubmitIncidentModal: ()=> void;
  }
function CreateUserIncidentForm({ 
    initialLatitude, 
    initialLongitude,
    formData, 
    setFormData,
    selectedSubmitIncident,
    setSelectedSubmitIncident,
    errors,
    editMode,
    setEditMode,
    // handleSubmit,
    handleOpenSubmitIncidentModal
}: CreateIncidentFormProps) {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setSelectedSubmitIncident({
            ...selectedSubmitIncident,
            [name]: value,
        });
    };
    const categories = Array.from(new Set(typeNames.map(type => type.category_name)));

    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const handleCategorySelect = (event: SelectChangeEvent<string>) => {
        setSelectedCategory(event.target.value as string);
        setFormData({
            ...formData,
            category_name: event.target.value,
            type_name: ""
        });
    };
    useEffect(() => {
        if (initialLatitude || initialLongitude) {
            setFormData((prevData) => ({
                ...prevData,
                latitude: initialLatitude.toString() || prevData.latitude,
                longitude: initialLongitude.toString() || prevData.longitude,
            }));
        }
    }, [initialLatitude, initialLongitude]);

    const handleTypeChange = (event: SelectChangeEvent<string>) => {
        setFormData({
            ...formData,
            type_name: event.target.value,
        });
    };

    const handleImpactChange = (event: SelectChangeEvent<string>) => {
        setFormData({
            ...formData,
            impact_rating: event.target.value,
        });
    };

    const filteredTypeNames = selectedCategory
        ? typeNames.filter(item => item.category_name === selectedCategory)
        : typeNames;

    return (
        <ThemeProvider theme={theme}>
            <div
                style={{
                    padding: "1rem",
                    width: "100%",
                    maxWidth: "500px",
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 'bold',
                            fontFamily: globalConfig["fontFamilies"],
                            color: globalConfig["primaryMain"],
                            mb: 1,
                        }}
                    >
                  Create Incident
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        {editMode ? (
                            <Chip
                                label="Editing Incident"
                                color="primary"
                                clickable
                                onClick={() => {
                                    setFormData({
                                        id: "",
                                        category_name: "",
                                        type_name: "",
                                        summary: "",
                                        started_at_timestamp: "", // Use empty string for date/time fields
                                        ended_at_timestamp: "",
                                        impact_rating: "", // Initialize as empty string
                                        longitude: initialLongitude?.toString() || "", // Initialize as empty string
                                        latitude: initialLatitude?.toString() || "", // Initialize as empty string
                                        description: "",
                                    });
                                    setEditMode(false);
                                }}
                                sx={{ mb: 2 }}
                            />
                        ) : (
                            <Chip
                                label="Creating Incident"
                                color="default"
                                sx={{ mb: 2 }}
                            />
                        )}
                    </Box>
                </Box>
                <Container
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        p: 0,
                        m: 0,
                    }}
                    style={{
                        padding: "0",
                        width: "100%",
                    }}
                >
                    <Box
                        // component="form"
                        // onSubmit={handleOpenSubmitIncidentModal}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            p: 3,
                            borderRadius: 1,
                            boxShadow: 2,
                            width: '100%',
                        }}
                    >
                        <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                            <InputLabel id="category-select-label">Category</InputLabel>
                            <Select
                                labelId="category-select-label"
                                name="category"
                                value={formData.category_name}
                                onChange={handleCategorySelect}
                                label="Category"
                            >
                                {categories.map((category)=>(
                                    <MenuItem key={category} value={category}>{category}</MenuItem>
                                ))}
                            </Select>
                            {errors.category_name && <Typography variant="body2" color="error">{errors.category_name}</Typography>}
                        </FormControl>

                        <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                            <InputLabel id="type-select-label">Type</InputLabel>
                            <Select
                                labelId="type-select-label"
                                name="type_name"
                                value={formData.type_name}
                                onChange={handleTypeChange}
                                label="type_name"
                            >
                                {filteredTypeNames.map((type)=>(
                                    <MenuItem key={type.type_name} value={type.type_name}>{type.type_name}</MenuItem>
                                ))}
                            </Select>
                            {errors.type_name && <Typography variant="body2" color="error">{errors.type_name}</Typography>}
                        </FormControl>
                        <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                            <InputLabel id="impact-select-label">Impact Rating</InputLabel>
                            <Select
                                labelId="impact-select-label"
                                name="impact_rating"
                                value={formData.impact_rating}
                                onChange={handleImpactChange}
                                label="Impact Rating"
                            >
                                {impactRatingsMap.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.impact_rating && <Typography variant="body2" color="error">{errors.impact_rating}</Typography>}
                        </FormControl>
                        <TextField
                            name="description"
                            label="Description"
                            variant="outlined"
                            value={formData.description}
                            onChange={handleChange}
                            fullWidth
                            error={!!errors.description}
                            helperText={errors.description}
                        />
                        <TextField
                            name="summary"
                            label="Summary"
                            variant="outlined"
                            value={formData.summary}
                            onChange={handleChange}
                            fullWidth

                            error={!!errors.summary}
                            helperText={errors.summary}
                        />
                        <TextField
                            name="started_at_timestamp"
                            label="Started At"
                            type="datetime-local"
                            variant="outlined"
                            value={formData.started_at_timestamp}
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            sx={{ mt: 2 }}
                            error={!!errors.started_at_timestamp}
                            helperText={errors.started_at_timestamp}
                        />
                        <TextField
                            name="ended_at_timestamp"
                            label="Ended At"
                            type="datetime-local"
                            variant="outlined"
                            value={formData.ended_at_timestamp}
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            sx={{ mt: 2 }}
                            error={!!errors.ended_at_timestamp}
                            helperText={errors.ended_at_timestamp}
                        />
                        <Box display="flex" gap={2} mb={4}>
                            <TextField
                                name="longitude"
                                label="Longitude"
                                type="number"
                                variant="outlined"
                                value={formData.longitude}
                                onChange={handleChange}
                                inputProps={{
                                    min: -180,
                                    max: 180,
                                    step: 'any', // Allow decimal values
                                }}
                                fullWidth
                                sx={{ mt: 2 }}
                                error={!!errors.longitude}
                                helperText={errors.longitude}
                            />
                            <TextField
                                name="latitude"
                                label="Latitude"
                                type="number"
                                variant="outlined"
                                value={formData.latitude}
                                onChange={handleChange}
                                inputProps={{
                                    min: -90,
                                    max: 90,
                                    step: 'any', // Allow decimal values
                                }}
                                fullWidth
                                sx={{ mt: 2 }}
                                error={!!errors.latitude}
                                helperText={errors.latitude}
                            />
                        </Box>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 2, fontWeight: 'bold' }}
                            // onClick={handleSubmit}
                            onClick={handleOpenSubmitIncidentModal}
                        >
                          Submit Incident
                        </Button>
                    </Box>
                </Container>
            </div>
        </ThemeProvider>
    );
}

interface IncidentsProps {
  initialLatitude?: number;
  initialLongitude?: number;
}
const Incidents: React.FC<IncidentsProps> = ({ initialLatitude, initialLongitude }) => { 
    const { userIncidents, crisis24 } = useDataStore((state) => ({
        userIncidents: state.userIncidents,
        crisis24: state.crisis24,
    }));   

    const [pageCrisis24, setPageCrisis24] = useState(crisis24);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const filteredData = crisis24.filter((item) => {
            const gistMatch = item.gist.toLowerCase().includes(searchQuery.toLowerCase());
            const summaryMatch = item.summary.toLowerCase().includes(searchQuery.toLowerCase());
            const adviceMatch = item.advice.toLowerCase().includes(searchQuery.toLowerCase());

            const pointRadiusMatch = item.point_radius.some((radius) =>
                radius.centre_description.toLowerCase().includes(searchQuery.toLowerCase())
            );
        
            const categoryMatch = item.categories.some((category) =>
                category.category_name.toLowerCase().includes(searchQuery.toLowerCase())
            );
        
            const locationMatch = item.locations.some((location) =>
                (location.location_name && location.location_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (location.location_countryName && location.location_countryName.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (location.location_countryIso3Code && location.location_countryIso3Code.toLowerCase().includes(searchQuery.toLowerCase()))
            );
        
            return pointRadiusMatch || categoryMatch || locationMatch || gistMatch || summaryMatch || adviceMatch;
        });
    
        setPageCrisis24(filteredData);
    
    }, [searchQuery, crisis24]);


    const { deleteUserIncident } = useWebSocketStore((state) => ({
        deleteUserIncident: state.deleteUserIncident,
    }));
    const [userIncidentsPagination, setUserIncidentsPagination] = useState<MRT_PaginationState>({pageIndex: 0,pageSize: 5});

    const userIncidentsIsRefetching = false;
    const userIncidentsIsError = false;
    const userIncidentsIsLoading = false;
    
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openSubmitIncidentModal, setOpenSubmitIncidentModal] = useState(false);
    const [selectedDeleteUserIncident, setSelectedDeleteUserIncident] = useState<UserIncident>(null);
    const [selectedSubmitIncident, setSelectedSubmitIncident] = useState<UserIncident>(null);
    const [formData, setFormData] = useState({
        id: "",
        category_name: "",
        type_name: "",
        summary: "",
        started_at_timestamp: "", // Use empty string for date/time fields
        ended_at_timestamp: "",
        impact_rating: "", // Initialize as empty string
        longitude: initialLongitude?.toString() || "", // Initialize as empty string
        latitude: initialLatitude?.toString() || "", // Initialize as empty string
        description: "",
    });
    const { sendUserIncident, editUserIncident, sendIncidentNotification, createIncidentWithNotification } = useWebSocketStore((state) => ({
        sendUserIncident: state.sendUserIncident,
        editUserIncident: state.editUserIncident,
        sendIncidentNotification: state.sendIncidentNotification,
        createIncidentWithNotification: state.createIncidentWithNotification
    }));
    const handleOpenDeleteModal = (userIncident: UserIncident) =>{
        setSelectedDeleteUserIncident(userIncident);
        setOpenDeleteModal(true);
    };

    const handleOpenSubmitIncidentModal = (userIncident: UserIncident) =>{
        console.log("[handleOpenSubmitIncidentModal]");
        console.log(userIncident);
        setSelectedSubmitIncident(userIncident);
        setOpenSubmitIncidentModal(true);
    };

    const handleDeleteUserIncident = (userIncident: UserIncident) =>{
        deleteUserIncident(userIncident.id);
        setSelectedDeleteUserIncident(null);
    };

    const [editMode, setEditMode] = useState(false);
    const onEditClick = (userIncident: UserIncident) =>{
        setFormData({
            id: userIncident.id,
            category_name: userIncident.category_name,
            type_name: userIncident.type_name,
            summary: userIncident.summary,
            started_at_timestamp: userIncident.started_at_timestamp.toString().slice(0, -1),
            ended_at_timestamp: userIncident.ended_at_timestamp.toString().slice(0, -1),
            impact_rating: userIncident.impact_rating.toString(),
            longitude: userIncident.longitude.toString(),
            latitude: userIncident.latitude.toString(),
            description: userIncident.description,
        });
        setSelectedSubmitIncident({
            id: userIncident.id,
            category_name: userIncident.category_name,
            type_name: userIncident.type_name,
            summary: userIncident.summary,
            started_at_timestamp: userIncident.started_at_timestamp,
            ended_at_timestamp: userIncident.ended_at_timestamp,
            impact_rating: userIncident.impact_rating,
            longitude: userIncident.longitude,
            latitude: userIncident.latitude,
            description: userIncident.description,
        } as UserIncident);
        setEditMode(true);
    };

    const handleStageUserIncident = (userIncident: UserIncident) =>{
        console.log("[handleStageIncident]");
        console.log(userIncident);
        setFormData({
            id: userIncident.id,
            category_name: userIncident.category_name,
            type_name: userIncident.type_name,
            summary: userIncident.summary,
            started_at_timestamp: userIncident.started_at_timestamp.toString().slice(0, -1),
            ended_at_timestamp: userIncident.ended_at_timestamp.toString().slice(0, -1),
            impact_rating: userIncident.impact_rating.toString(),
            longitude: userIncident.longitude.toString(),
            latitude: userIncident.latitude.toString(),
            description: userIncident.description,
        });
        setSelectedSubmitIncident({
            id: userIncident.id,
            category_name: userIncident.category_name,
            type_name: userIncident.type_name,
            summary: userIncident.summary,
            started_at_timestamp: userIncident.started_at_timestamp,
            ended_at_timestamp: userIncident.ended_at_timestamp,
            impact_rating: userIncident.impact_rating,
            longitude: userIncident.longitude,
            latitude: userIncident.latitude,
            description: userIncident.description,
        } as UserIncident);
    };

    const handleStageCrisis24Incident = (crisis24Item: Crisis24Item) =>{
        console.log("[handleStageIncident]");
        console.log(crisis24Item);
        const parser = new DOMParser();
        const doc = parser.parseFromString(crisis24Item.advice, 'text/html');
        const advicePlaintext = doc.body.textContent || crisis24Item.advice;
        setFormData({
            id: crisis24Item.id,
            category_name: "Other",
            type_name: "Other",
            summary: advicePlaintext,
            started_at_timestamp: crisis24Item.beginDate.toString().slice(0, -1),
            ended_at_timestamp: crisis24Item.endDate.toString().slice(0, -1),
            impact_rating: crisis24Item.impact[0].impact_key.toString(),
            longitude: crisis24Item.point_radius[0]?.centre_longitude.toString(),
            latitude: crisis24Item.point_radius[0]?.centre_latitude.toString(),
            description: crisis24Item.gist,
        });
        setSelectedSubmitIncident({
            id: crisis24Item.id,
            category_name: "Other",
            type_name: "Other",
            summary: advicePlaintext,
            started_at_timestamp: crisis24Item.beginDate,
            ended_at_timestamp: crisis24Item.endDate,
            impact_rating: crisis24Item.impact[0].impact_key,
            longitude: crisis24Item.point_radius[0]?.centre_longitude,
            latitude: crisis24Item.point_radius[0]?.centre_latitude,
            description: crisis24Item.gist,
        } as UserIncident);
    };

    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const validateForm = (): boolean => {
        let isValid = true;
        const newErrors: { [key: string]: string } = {};
        for (const [key, value] of Object.entries(formData)) {

            if (key === 'longitude' || key === 'latitude') {
                if (value === "") {
                    newErrors[key] = `${key} is required.`;
                    isValid = false;
                }
            } else if (key==="id") {
                console.log("id not required");
            } else if (key === 'impact_rating') {
                if (value === "") {
                    newErrors[key] = `${key} is required.`;
                    isValid = false;
                }
            } else if (value === '') {
                newErrors[key] = `${key} is required.`;
                isValid = false;
            }
        }

        if (
            formData.started_at_timestamp && 
      formData.ended_at_timestamp && 
      new Date(formData.started_at_timestamp) >= new Date(formData.ended_at_timestamp)
        ) {
            newErrors.ended_at_timestamp = 'End date must be after start date.';
            isValid = false;
        }
        setErrors(newErrors);
        return isValid;
    };

    const [notifyUsers, setNotifyUsers] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const { user } = useUserStore((state) => ({
        user: state.user,
    }));

    const [currentManagedUsers, setCurrentManagedUsers] = useState([] as PhoneUser[]);
    useEffect(() => {
        if (user.role === "admin") {
            setCurrentManagedUsers((user as AdminUser).managedUsers);
            console.log("Chat managed users: ", currentManagedUsers);
        }
    }, [user]);

    const handleSubmitIncident = (formData: FormData) => {
        console.log("[handleSubmitIncident]");
        console.log(formData);
        console.log(selectedUsers);
        if (validateForm()) {
            if (formData.id?.length > 0){
                if (notifyUsers) {
                    selectedUsers.forEach((email) => {
                        const selectedUser = currentManagedUsers.filter(managedUser=>managedUser.email===email)[0];
                        sendIncidentNotification(
                            selectedUser.id,
                            formData.id,
                            formData.category_name,
                            formData.type_name,
                            formData.summary,
                            new Date(formData.started_at_timestamp),
                            new Date(formData.ended_at_timestamp),
                            parseFloat(formData.impact_rating),
                            parseFloat(formData.longitude),
                            parseFloat(formData.latitude),
                            formData.description,
                        );
                    });
                } else {
                    editUserIncident(
                        formData.id,
                        formData.category_name,
                        formData.type_name,
                        formData.summary,
                        new Date(formData.started_at_timestamp),
                        new Date(formData.ended_at_timestamp),
                        parseFloat(formData.impact_rating),
                        parseFloat(formData.longitude),
                        parseFloat(formData.latitude),
                        formData.description,
                    );
                }
                
            } else {
                if (notifyUsers) {
                    selectedUsers.forEach((email) => {
                        const selectedUser = currentManagedUsers.filter(managedUser=>managedUser.email===email)[0];
                        createIncidentWithNotification(
                            selectedUser.id,
                            formData.category_name,
                            formData.type_name,
                            formData.summary,
                            new Date(formData.started_at_timestamp),
                            new Date(formData.ended_at_timestamp),
                            parseFloat(formData.impact_rating),
                            parseFloat(formData.longitude),
                            parseFloat(formData.latitude),
                            formData.description,
                        );
                    });
                    
                } else {
                    sendUserIncident(
                        formData.category_name,
                        formData.type_name,
                        formData.summary,
                        new Date(formData.started_at_timestamp),
                        new Date(formData.ended_at_timestamp),
                        parseFloat(formData.impact_rating),
                        parseFloat(formData.longitude),
                        parseFloat(formData.latitude),
                        formData.description,
                    );
                }
                
            }
            
            setFormData({
                id:"",
                category_name: "",
                type_name: "",
                summary: "",
                started_at_timestamp: "", // Use empty string for date/time fields
                ended_at_timestamp: "",
                impact_rating: "", // Initialize as empty string
                longitude: "", // Initialize as empty string
                latitude: "", // Initialize as empty string
                description: "",
            });

            setEditMode(false);

        }
    };

    return  (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} sx={{ pb: 2 }}>
                    <Box>
                        <CreateUserIncidentForm 
                            initialLatitude={initialLatitude} 
                            initialLongitude={initialLongitude} 
                            formData={formData}
                            setFormData={setFormData}
                            errors={errors}
                            editMode={editMode}
                            setEditMode={setEditMode}
                            handleOpenSubmitIncidentModal={()=>handleOpenSubmitIncidentModal(selectedSubmitIncident)}
                            selectedSubmitIncident={selectedSubmitIncident}
                            setSelectedSubmitIncident={setSelectedSubmitIncident}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container direction="column" spacing={2} sx={{ pb: 10 }}>
                        <Grid item>
                            <UserIncidentsTable
                                title={"Manually Added Incidents"}
                                data={userIncidents}
                                pagination={userIncidentsPagination}
                                isRefetching={userIncidentsIsRefetching}
                                isError={userIncidentsIsError}
                                isLoading={userIncidentsIsLoading}
                                setPagination={setUserIncidentsPagination}
                                onEditClick={onEditClick}
                                handleOpenDeleteModal={handleOpenDeleteModal}
                                handleStageIncident={handleStageUserIncident}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                placeholder="Search Incidents..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchRounded />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    p: 2,
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <Crisis24Table
                                title={"Incident Alerts"}
                                data={pageCrisis24}
                                handleStageIncident={handleStageCrisis24Incident}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {openDeleteModal && selectedDeleteUserIncident && (
                <DeleteModal
                    titleText="Delete Incident"
                    bodyText={`
                        Are you sure you want to delete following UserIncident? 
                        ${selectedDeleteUserIncident.category_name}: ${selectedDeleteUserIncident.description}
                    `}
                    handleClose={()=>{setSelectedDeleteUserIncident(null);}}
                    handleDelete={handleDeleteUserIncident}
                    selectedDeleteObject={selectedDeleteUserIncident}
                />
            )}
            {openSubmitIncidentModal && selectedSubmitIncident && (
                <SubmitIncidentModal
                    handleClose={()=>{
                        setSelectedSubmitIncident(null);
                        setOpenSubmitIncidentModal(false);
                    }}
                    handleSubmit={()=>handleSubmitIncident(formData)}
                    selectedSubmitIncident={selectedSubmitIncident}
                    notifyUsers={notifyUsers}
                    setNotifyUsers={setNotifyUsers}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                />
            )}
        </>
    );
};

export default Incidents;


// import { SearchRounded, CancelPresentationOutlined } from "@mui/icons-material";

// // import store from "../store"
// import {
//   Alert,
//   Box,
//   Button,
//   Chip,
//   CircularProgress,
//   Grid,
//   IconButton,
//   InputAdornment,
//   Paper,
//   Snackbar,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TablePagination,
//   TableRow,
//   TextField,
//   Typography,
// } from "@mui/material";
// import React, { useCallback, useEffect, useMemo, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import {
  
//   deleteIncident,
//   getIncidentCategories,
//   getIncidentTypes,
//   searchIncidents,
  
// } from "../helpers/api";
// import { incidents, timezones } from "../helpers/constants";
// import { formatDateCustom } from "../helpers/utils";
// import { customDropdown } from "../components/CustomDropdown";
// // import DateFnsUtils from '@date-io/date-fns';
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
// // import MapPicker from 'react-google-map-picker'
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// import { AnimatePresence, motion } from "framer-motion";
// import UserTooltip from "../components/UserTooltip";

// import DisplayTable from "../components/DisplayTable";
// import DeleteModal from "../components/DeleteModal";
// import PushIncidentModal from "../components/PushIncidentModal";

// import {formatDateTimeString} from "../helpers/utils"

// import {
//     MaterialReactTable,
//   MRT_ColumnDef,
//   MRT_ColumnFiltersState,
//   MRT_PaginationState,
//   MRT_SortingState,
// } from "material-react-table";
// import UserIcon from "../components/UserIcon";


// import { useWebSocketStore } from "../stores/websocketStore";
// import { useDataStore } from "../stores/dataStore";
// import dayjs from "dayjs";
// const IncidentsPage: React.FC = (props) => {

//     const { userIncidents, state } = useDataStore((state) => ({

//         userIncidents: state.userIncidents,
//         state: state,
//     }));
        
//   const coordinates = props.coordinates;
//   // const [type, setType] = useState('')
//   const [summary, setSummary] = useState("");
//   const [locationTimezone, setLocationTimezone] = useState("");
//   const [description, setDescription] = useState("");
//   // const [loading, setLoading] = useState(true);
//   const { loading, companyName, profile, role } = props;
//   const [sendingData, setSendingData] = useState(false);
//   // const [sendingInviteLink, setSendingInviteLink] = useState(false);

//   const [categoryName, setCategoryName] = useState("");
//   const [type, setType] = useState("");

//   const [isError, setIsError] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isRefetching, setIsRefetching] = useState(false);
//   const [rowCount, setRowCount] = useState(0);




//     const { sendUserIncident, sendUserIncidentNotification } = useWebSocketStore((state) => ({
//         sendUserIncident: state.sendUserIncident,
//         sendUserIncidentNotification: state.sendIncidentNotification,
//     }));
//     console.log('----state-----')
//     console.log('state', state)

//     // const category_name = "Direct Weapons"
//     // const type_name = "Drone"
//     // const summary = "there was a big incident near me "
//     // const location_timezone = "±0 hours (London)"
//     // const started_at_timestamp = Date("2024-07-23T11:39:46Z")
//     // const ended_at_timestamp = Date("2024-07-23T11:39:46Z")
//     // const impact_rating = "Limited"
//     // const reported_at = "2024-07-23T10:39:57.305666Z"
//     // const longitude = -6.29260506450828
//     // const latitude = 53.33175261115349
//     // const description = "lots of stuff happening"

//     // const handleSubmit = (sampleAsset: any) => {
//     //     sendUserIncident(
//     //         category_name,
//     //         type_name,
//     //         summary,
//     //         location_timezone,
//     //         started_at_timestamp,
//     //         ended_at_timestamp,
//     //         impact_rating,
//     //         reported_at,
//     //         longitude,
//     //         latitude,
//     //         description,
//     //     )
//     // }

//   //table state
//   const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
//     []
//   );
//   const [globalFilter, setGlobalFilter] = useState("");
//   const [sorting, setSorting] = useState<MRT_SortingState>([]);
//   const [pagination, setPagination] = useState<MRT_PaginationState>({
//     pageIndex: 0,
//     pageSize: 10,
//   });
//   const [pushIncident, setPushIncident] = useState(null) 
//   const [errorText, setErrorText] = useState("");
//   const [errorOpen, setErrorOpen] = useState(false);
//   const [rows, setRows] = useState([]);
//   const [oldRows, setOldRows] = useState([
//     {
//       id: "No incidents",
//       creator: {},
//       longitude: null,
//       latitude: null,
//       category_name: "No incidents",
//       type_name: null,
//       started_at_timestamp: null,
//       ended_at_timestamp: null,
//       impact_long_description: null,
//       impact_rating: null,
//       location_timezone: null,
//       reported_at: null,
//       reviewed_at: null,
//       summary: null,
//       approved: null,
//       geometry_as_text: null,
//       impact_short_description: null,
//     },
//   ]);
//   const [searchInput, setSearchInput] = useState("");
//   const [open, setOpen] = useState(false);
//   const [reload, setReload] = useState(true);
//   const [selectedStartDate, setSelectedStartDate] = useState(dayjs());
//   const [selectedEndDate, setSelectedEndDate] = useState(null);
//   const [locationLongitude, setLocationLongitude] = useState("0.0");
//   const [locationLatitude, setLocationLatitude] = useState("0.0");
//   const [timezone, setTimezone] = useState("");

//   const [editingIncident, setEditingIncident] = useState({ id: null });
//   const [deletingIncident, setDeletingIncident] = useState({
//     id: null,
//     name: null,
//     category_name: null,
//     type_name: null,
//     started_at_timestamp: null,
//     summary:null,
    
//   });
//   const [openDeleteModal, setOpenDeleteModal] = useState(false);
//   const [openIncidentModal, setOpenIncidentModal] = useState(false);

//   const [incidentCount, setIncidentCount] = useState(0);
//   // const [rowsPerPage, setRowsPerPage] = React.useState(5);

//   const [incidentPage, setIncidentPage] = React.useState(0);
//   const [incidentRowsPerPage, setIncidentRowsPerPage] = useState(5);
//   const [categories, setCategories] = useState([]);
//   const [types, setTypes] = useState([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchData = async () => {
//       const res = await getIncidentCategories();
//       if (res.status === 200) {
//         setCategories(
//           res.data.results.map((category) => {
//             return { value: category.category_name };
//           })
//         );
//       }
//     };
//     if (!loading) {
//       fetchData();
//     }
//   }, [loading]);

//   useEffect(() => {
//     const fetchData = async () => {
//       const res = await getIncidentTypes(categoryName);
//       if (res.status === 200) {
//         setTypes(
//           res.data.results.map((type) => {
//             return { value: type.type_name };
//           })
//         );
//       }
//     };
//     if (categories.length > 0 && categoryName !== "") {
//       if (!editingIncident.id) {
//         setType("");
//       }

//       fetchData();
//     }
//   }, [categories.length, categoryName, editingIncident.id]);

//   const handleSubmit = useCallback(
//     async (e) => {
//       e.preventDefault();
//       setSendingData(true);

      
//       const res = await sendUserIncident(
//         categoryName,
//         type,
//         summary,
//         locationTimezone,
//         selectedStartDate,
//         selectedEndDate,
//         0,
//         dayjs(),
//         Number(locationLongitude),
//         Number(locationLatitude),
//         description,
//         // editingIncident.id
//         // navigate
//       );
//       console.log(res)
//       try {
//         if (res?.status === 201 || res?.status === 200 || res?.status === 204) {
//           setSendingData(false);
//           setErrorText("");
//           setOpen(true);
//           setCategoryName("");
//           setType("");
//           setSummary("");
//           setLocationTimezone("");
//           setDescription("");
//           setLocationLongitude(null);
//           setLocationLatitude(null);
//           setSelectedStartDate(null);
//           setSelectedEndDate(null);
//           setReload(true);
//           if (editingIncident.id) {
//             onCancelClick();
//           }
//           const incidents = await searchIncidents(
//             "",
//             null,
//             null,
//             navigate,
//             // incidentPage + 1,
//             // incidentRowsPerPage
//           );
//           console.log("TOTAL ROW COUTN IS ")
//           console.log(incidents.data.results.length)
//           setRowCount(incidents.data.results.length)
//           if (incidents.status === 200 || incidents.data.results.length === 0) {
//             setRows([]);
//           } else if (incidents.status === 204 || incidents.status === 200) {
//             setRows(incidents.data.results);
//           }
//         } else if (res?.status === 400 && res?.data["email"]) {
//           setSendingData(false);
//           setErrorText("Email: " + res?.data["email"]);
//           setErrorOpen(true);
//         } else if (res?.status === 403 && res?.data["results"]) {
//           setSendingData(false);
//           setErrorText(res?.data["results"]);
//           setErrorOpen(true);
//         } else if (res?.status === 404) {
//           setSendingData(false);
//           setErrorText("Something went wrong");
//           setErrorOpen(true);
//         } else {
//           setSendingData(false);
//           setErrorText("Unknown error");
//           setErrorOpen(true);
//         }
//       } catch (err) {
//         setSendingData(false);
//         setErrorText("Fatal error");
//         setErrorOpen(true);
//       }
//       setSendingData(false);
//       // handleCloseConfirmation();
//     },
//     [
//       categoryName,
//       type,
//       summary,
//       locationTimezone,
//       selectedStartDate,
//       selectedEndDate,
//       description,
//       locationLongitude,
//       locationLatitude,
//       editingIncident.id,
//       navigate,
//       incidentPage,
//       incidentRowsPerPage,
//     ]
//   );

//   const Backdrop = ({ children, onClick }) => {
//     return (
//       <motion.div
//         style={{
//           zIndex: 200,
//           position: "absolute",
//           top: 0,
//           left: 0,
//           height: "100%",
//           width: "100%",
//           background: "#00000056",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//         }}
//         onClick={onClick}
//         initial={{ opacity: 0 }}
//         animate={{ opacity: 1 }}
//         exit={{ opacity: 0 }}
//       >
//         {children}
//       </motion.div>
//     );
//   };

//     const { deleteUserIncident } = useWebSocketStore((state) => ({
//         deleteUserIncident: state.deleteUserIncident,
//     }));

//     const [selectedIncidentId, setSelectedIncidentId] = useState<string | null>(null);
//     const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//         setSelectedIncidentId(e.target.value);
//     };
//     const handleButtonClick = () => {
//         if (selectedIncidentId) {
//             console.log('Selected Incident ID:', selectedIncidentId);
//             deleteUserIncident(selectedIncidentId)
//             // Add any logic you want to perform with the selected incident ID here
//         } else {
//             console.log('No incident selected');
//         }
//     };

//   const handleCloseDeleteModal = () => {
//     setOpenDeleteModal(false);
//     setDeletingIncident({
//       id: null,
//       name: null,
//       category_name: null,
//       type_name: null,
//       started_at_timestamp: null,
//       summary:null,
//     });
//   };

//   const handleOpenDeleteModal = (row) => {
//     setOpenDeleteModal(true);
//     setDeletingIncident(row.original);
//   };

//   const handleCloseIncidentModal = () => {
//     setOpenIncidentModal(false);
//     setPushIncident(null)
//     // setDeletingIncident({
//     //   id: null,
//     //   name: null,
//     //   category_name: null,
//     //   type_name: null,
//     //   started_at_timestamp: null,
//     //   summary:null,
//     // });
//   };

//   const handleOpenIncidentModal = (row) => {
//     console.log("OPEN MODAL!!!")
//     console.log(row.original)
//     setOpenIncidentModal(true);
//     setPushIncident(row.original)
//     // setDeletingIncident(row.original.original);
//   };

//   const onEditClick = useCallback((row) => {
//     console.log("row for submit is ", row)
//     setEditingIncident(row);
//     setCategoryName(row.category_name);
//     setType(row.type_name);
//     setSummary(row.summary);
//     setLocationTimezone(row.location_timezone);
//     setDescription(row.impact_long_description);
//     setLocationLongitude(row.longitude);
//     setLocationLatitude(row.latitude);
//     setSelectedStartDate(row.selected_start_date);
//     setSelectedEndDate(row.selected_end_date);
//   }, []);

//   const onCancelClick = () => {
//     setEditingIncident({ id: null });
//     setCategoryName("");
//     setType("");
//     setSummary("");
//     setLocationTimezone("");
//     setDescription("");
//     setLocationLongitude("0.0");
//     setLocationLatitude("0.0");
//     setSelectedStartDate(null);
//     setSelectedEndDate(null);
//   };

//   const handleDelete = async (id) => {
//     console.log("delet id is ", id)
//     const res = await deleteIncident(id);
//     console.log(res);
//     console.log(res.status);
//     setReload(true);
//     setOpenDeleteModal(false);
//     onCancelClick();
//   };

//   const handlePushIncident = async (incident, selectedUsers) => {

//       // console.log(user)
//       console.log("PUSHED ")
//       console.log(selectedUsers)
//       console.log(incident)
//       for (const user of selectedUsers){
//         sendUserIncidentNotification(
//             incident.id,
//             user.id
//           );
//           console.log("WRITIGN TO DB NOTI")
          

       
//     }
//     setOpenIncidentModal(false);
//   }

//   const handleChangePage = (event, newPage) => {
//     setIncidentPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setIncidentRowsPerPage(parseInt(event.target.value, 10));
//     setIncidentPage(0);
//   };

//   const columns = useMemo(() => [
//       {
//         accessorKey: "creator.firstName",
//         header: "User",
//         Cell: ({ cell, row }) => (
//           <>
//           <UserTooltip
//             // userRole={role}
//             users={[row.original.creator]}
//             // title="Add"
//             placement="top"
//             arrow
//             describeChild
//           >
//             <Grid item>
//               <UserIcon user={row.original.creator} handleClickProfile={()=>{}}>
//               </UserIcon>
//             </Grid>
//           </UserTooltip>
//           </>
//         ),
//         size:50
//       },
//       {
//         // accessorKey: "display_ended",
//         header: "Notify Users",
//         Cell: ({ cell, row }) => (
//           <>
//           <Button
//             variant="contained"
//             color="primary"
//             sx={{
//               backgroundColor: '#005A31',
//               color: 'white',
//               '&:hover': {
//                 backgroundColor: 'green', // Change background color on hover if desired
//               },
//             }}
//             onClick={()=>{
//               handleOpenIncidentModal(row)
//             }}
//           >
//             Notify
//           </Button>
//           </>
//         ),
//         size:50
//       },
//       {
//         accessorKey: "category_name",
//         header: "Category",
//         // size:50
//         maxSize:2
//       },
//       {
//         accessorKey: "type_name",
//         header: "Type",
//         maxSize:2
//       },
//       {
//         accessorKey: "summary",
//         header: "Summary",
//       },
//       {
//         accessorKey: "display_started",
//         header: "Start Date",
//       },
//       {
//         accessorKey: "display_ended",
//         header: "End Date",
//       },

//     ],
//     []
//   );

//   const incidentsTable = useMemo(() => {
//     const displayTable = (
//         <DisplayTable
//           columns={columns}
//           data={rows}
//           onEditClick={onEditClick}
//           handleOpenDeleteModal={handleOpenDeleteModal}
//           columnFilters={columnFilters}
//           globalFilter={globalFilter}
//           sorting={sorting}
//           pagination={pagination}
//           rowCount={rowCount}
//           isRefetching={isRefetching}
//           isError={isError}
//           isLoading={isLoading}
//           setColumnFilters={setColumnFilters}
//           setGlobalFilter={setGlobalFilter}
//           setPagination={setPagination}
//           setSorting={setSorting}
//           enableRowActions={true}
//         />
      
//     ); 
    
//     return displayTable 
//   }, [
//     editingIncident,
//     // incidentCount,
//     rowCount,
//     pagination,
//     // incidentPage,
//     // incidentRowsPerPage,
//     onEditClick,
//     rows,
//   ]);

//   useEffect(() => {
//     // eslint-disable-next-line require-jsdoc
//     async function fetchData() {
//       setReload(true);
//       if (!loading) {
//         const incidents = await searchIncidents(
//           searchInput,
//           null,
//           null,
//           navigate,
//           // incidentPage + 1,
//           // incidentRowsPerPage
//         );

//         console.log("TOTAL ROW COUTN IS ")
//         console.log(incidents.data.results.length)
//         setRowCount(incidents.data.results.length)
//         if (incidents.status !== 200 || !incidents.data.results.length) {
//           console.log("received incidents", incidents)
//           setRows([]);
//         } else if (incidents.status === 200) {
//           setRows(() => {
//             return incidents.data.results.map((incident)=>({
//             ...incident, 
//             display_started: formatDateTimeString(incident.started_at_timestamp),
//             display_ended: formatDateTimeString(incident.ended_at_timestamp)
//           }))});
//           setIncidentCount(incidents.data.count);
//         }
//       }
//     }
//     if ((!loading && role) || (role && reload)) {
//       fetchData();

//       setReload(false);
//     }

//     if (coordinates) {
//       setLocationLongitude(coordinates.lng);
//       setLocationLatitude(coordinates.lat);
//     }
//   }, [
//     coordinates,
//     incidentPage,
//     incidentRowsPerPage,
//     loading,
//     navigate,
//     reload,
//     role,
//     searchInput,
//   ]);

//   const handleClose = (e, reason) => {
//     if (reason === "clickaway") {
//       return;
//     }
//     setOpen(false);
//     setErrorText("");
//   };

//   const handleCloseError = (e, reason) => {
//     if (reason === "clickaway") {
//       return;
//     }
//     setErrorOpen(false);
//     setErrorText("");
//   };

//   const handleEndDateChange = (date) => {
//     if (date > dayjs()) {
//       setSelectedEndDate(null);
//     } else {
//       setSelectedEndDate(date);
//     }
//     // setReload(true);
//   };

//   const handleStartDateChange = (date) => {
//     setSelectedStartDate(date);
//     // setReload(true);
//   };

//   const [affectingInc, setAffectingInc] = useState(false);
//   const [selectedUsers, setSelectedUsers] = useState([]);
  
// //   const invitedUsers = useSelector((state: RootState) => state.socket.invitedUsers);
// //   const adminId = useSelector((state: RootState) => state.user.id);
//   const handleSelectUser = (event) => {
//     const user = event.target.value
//     console.log("user is ",  user)
//     setSelectedUsers(user)
//   };

//   const handleAffectingIncidents = useCallback(() => {
//     const currentState = affectingInc;
//     setAffectingInc(!currentState);
//     setOldRows(rows);
//     if (!currentState) {
//       setRows(incidents);
//     } else {
//       setRows(oldRows);
//     }
//   }, [oldRows, rows, affectingInc]);

//   return (
//     <Grid container justifyContent="center" direction="row">
//       <Grid
//         item
//         container
//         width="30%"
//         justifyContent="center"
//         // spacing={0}
//         // direction="column"
//         // alignItems="center"
//         // justifyContent="center"
//         alignItems="center"
//         direction="column"
//         sx={{ margin: 5 }}
//       >
//         <AnimatePresence
//           // Disable any initial animations on children that
//           // are present when the component is first rendered
//           initial={false}
//           // Only render one component at a time.
//           // The exiting component will finish its exit
//           // animation before entering component is rendered
//         //   exitBeforeEnter={}
//           // Fires when all exiting nodes have completed animating out
//           onExitComplete={() => null}
//         >
//           {openDeleteModal && (
//             <DeleteModal
//               titleText={"Are you sure you want to Delete the following Incident?"} 
//               bodyText={`${deletingIncident.type_name} (${deletingIncident.started_at_timestamp}) ${deletingIncident.summary}`}
//               handleClose={handleCloseDeleteModal} 
//               handleDelete={(e) => {handleDelete(deletingIncident.id)}} 
//             />
//           )}
//           {openIncidentModal && (
//             <PushIncidentModal
//               incident={pushIncident}
//               handleClose={handleCloseIncidentModal} 
//               handlePush={()=>handlePushIncident(pushIncident, selectedUsers)} 
//               selectedUsers={selectedUsers}
//               handleSelectUser={handleSelectUser}
//               users={[]}
//             />
//           )}
//         </AnimatePresence>
//         <Snackbar
//           onClose={handleClose}
//           open={open && errorText === "" && !sendingData}
//           autoHideDuration={6000}
//         >
//           <Alert severity="success">
//             Incident has been successfully created
//           </Alert>
//         </Snackbar>
//         <Snackbar
//           onClose={handleCloseError}
//           open={errorOpen && errorText !== "" && !sendingData}
//           autoHideDuration={6000}
//         >
//           <Alert severity="error">{errorText}</Alert>
//         </Snackbar>
//         {/* <Grid container spacing={3}>
//           <Grid item>
//             <Typography 
//               component="h1" 
//               variant="h5"
//             >
//               My Incidents
//             </Typography>
//           </Grid>
//         </Grid> */}
//         {/* <br></br> */}
//         <Grid item>
//           <Typography component="h1" variant="h5">
//             {editingIncident.id
//               ? "Edit an existing Incident"
//               : "Create a new Incident"}
//           </Typography>
//           <Typography component="h1" variant="h5">
//             {editingIncident.id ? (
//               <Chip
//                 color="warning"
//                 label="Editing an Incident"
//                 variant="outlined"
//                 onDelete={() => {
//                   onCancelClick();
//                 }}
//               />
//             ) : null}
//           </Typography>
//         </Grid>
//         <Grid item>
//           <Typography variant="subtitle1"></Typography>
//         </Grid>
//         <Grid item>
//           <Box
//             component="form"
//             onSubmit={(e) => {
//               handleSubmit(e);
//               setSearchInput("");
//             }}
//             noValidate
//             sx={{ mt: 1 }}
//           >
//             {customDropdown(categories,"Category Name",categoryName,setCategoryName, profile)}
//             {customDropdown(types, "Type Name", type, setType, profile)}
//             <TextField 
//               label="Summary" 
//               fullWidth 
//               sx={{ paddingBottom: 2 }}
//               onChange={(e) => setSummary(e.target.value)}
//               value={summary}
//             >
//               Summary
//             </TextField>
//             {customDropdown(timezones,"Location Timezone",timezone,setTimezone,profile)}
//             <TextField
//               label="Impact Description"
//               fullWidth
//               sx={{ paddingBottom: 2 }}
//               value={description}
//               onChange={(e) => setDescription(e.target.value)}
//             >
//               Description
//             </TextField>
//             <Grid container direction="row">
//               <TextField
//                 sx={{ width: "48%", paddingRight: 2, paddingBottom: 2 }}
//                 type="number"
//                 label="Longitude"
//                 value={locationLongitude}
//                 variant="outlined"
//                 inputProps={{
//                   maxLength: 13,
//                   step: "1",
//                 }}
//                 onChange={(e) => setLocationLongitude(e.target.value)}
//               />
//               <TextField
//                 sx={{ width: "48%", paddingBottom: 2 }}
//                 type="number"
//                 label="Latitude"
//                 value={locationLatitude}
//                 variant="outlined"
//                 inputProps={{
//                   maxLength: 13,
//                   step: "1",
//                 }}
//                 onChange={(e) => setLocationLatitude(e.target.value)}
//               />
//             </Grid>
//             <LocalizationProvider dateAdapter={AdapterDayjs}>
//               <Grid container direction="row" justifyContent="space-between">
//                 <DesktopDatePicker
//                   inputFormat="MM/DD/YYYY"
//                   maxDate={dayjs()}
//                   InputProps={{
//                     classes: {
//                       // input: classes.input,
//                     },
//                   }}
//                   label="Start"
//                   value={selectedStartDate || dayjs()}
//                   onChange={handleStartDateChange}
//                   renderInput={(params) => <TextField required {...params} />}
//                 />
//                 <DesktopDatePicker
//                   inputFormat="MM/DD/YYYY"
//                   minDate={selectedStartDate || dayjs()}
//                   maxDate={dayjs()}
//                   InputProps={{
//                     classes: {
//                       // input: classes.input,
//                     },
//                   }}
//                   label="End"
//                   value={selectedEndDate}
//                   onChange={handleEndDateChange}
//                   renderInput={(params) => <TextField {...params} />}
//                 />
//               </Grid>
//             </LocalizationProvider>
//             <Button
//               type="submit"
//               fullWidth
//               variant="contained"
//               sx={{ mt: 3, mb: 2 }}
//               // onClick={handleLogin}
//             >
//               {sendingData ? (
//                 <CircularProgress size="1.55rem" color="inherit" />
//               ) : (
//                 "Submit"
//               )}
//             </Button>
//           </Box>
//         </Grid>
//         {/* </CardContent> */}
//       </Grid>

//       <Grid item width="50%" sx={{ margin: 5 }}>
//         {/* <Grid item>
//           <Typography component="h1" variant="h5">
//             <br></br>
//           </Typography>
//         </Grid>
//         <br></br>*/}
//         <Grid item> 
//           <Grid container spacing={3} direction="row" justifyItems="center">
//             <Grid item>
//               <Typography component="h1" variant="h5">
//                 Incident History
//               </Typography>
//               <br></br>
//             </Grid>
//           </Grid>
//         </Grid>
//         <Grid item>
//           <Typography variant="subtitle1"></Typography>
//         </Grid>
//         {incidentsTable}
//       </Grid>
//     </Grid>
//   );
// };

// export default IncidentsPage;




