import React, { useEffect, useRef, useState } from 'react';
import Cookies from "js-cookie";
import { Navigate } from 'react-router-dom';
import { useUserStore } from '../stores/userStore';
import { useWebSocketStore } from '../stores/websocketStore';

interface PrivateRouteProps {
  element: JSX.Element;
//   path: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
// const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, path }) => {
    const path = "path";
    console.log("[PrivateRoute] ", path);
    const { isAuthenticated, setAuthenticated } = useUserStore((state) => ({
        isAuthenticated: state.isAuthenticated,
        setAuthenticated: state.setAuthenticated,
    }));

    const { accessTokenConnect, isConnected } = useWebSocketStore((state) => ({
        accessTokenConnect: state.accessTokenConnect,
        isConnected: state.isConnected,
    }));

    const [loading, setLoading] = useState(true);
    const mountCount = useRef(0);

    console.log(`PrivateRoute (${path}) render. isAuthenticated: ${isAuthenticated}, isConnected: ${isConnected}`);

    useEffect(() => {
        mountCount.current += 1;
        console.log(`PrivateRoute mounted. Mount count: ${mountCount.current}`);

        const authenticateUser = async () => {
            const username = Cookies.get('username');
            const accessToken = Cookies.get('accessToken');
            console.log(`PrivateRoute authenticateUser called. Token exists: ${!!accessToken}`);
            console.log("[PrivateRoute-AccessToken]", accessToken);
            if (isAuthenticated && !isConnected) {
                const timeoutInSeconds = 5;
                setTimeout(async ()=>{
                    console.log(`PrivateRoute attempting to connect`);
                    await accessTokenConnect(username, accessToken);
                    console.log(`PrivateRoute connection successful`);
                }, timeoutInSeconds * 1000);

            }
            setLoading(false);

            if (accessToken && !isAuthenticated) {
                setAuthenticated(true);
            }
        };

        authenticateUser();

        return () => {
            console.log(`PrivateRoute unmounted. Mount count: ${mountCount.current}`);
        };
    }, [isAuthenticated, isConnected, accessTokenConnect]);

    if (loading) {
        console.log("[privateRoute][loading]");
        return <div>Loading...</div>;
    }
    console.log(`PrivateRoute (${path}) returning. isAuthenticated: ${isAuthenticated}`);
    return isAuthenticated ? element : <Navigate to="/login" replace />;
};

export default PrivateRoute;