class Socket {
    private socket: WebSocket | null;
    private initial: boolean | null;
    
    constructor() {
      this.socket = null
      this.initial = true
    }
  
    connect(url) {
      if (!this.socket) {
        console.log("BEFORE CONNECT444")
        this.socket = new WebSocket(url)
        console.log("AFTER CONNECT444")
      }
      console.log("AFTER CONNECT 2444", url)
    }
  
    disconnect() {
      if (this.socket) {
        this.socket.close()
        this.socket = null
      }
    }
  
    send(message) {
      if (this.socket) {
        const wrapperMessage = JSON.stringify({
          Is_encrypted: "unencrypted", 
          Data: `${JSON.stringify(message)}`,
          MessageId: `${message.uid}`
        })
        console.log("WRAPPER MESSAGE")
        console.log(`"${JSON.stringify(message)}"`)
        console.log(wrapperMessage)
        this.socket.send(wrapperMessage)
      }
    }
  
    on(eventName, callback) {
      if (this.socket) {
        console.log("EVENTNAME", eventName, callback)
        this.socket.addEventListener(eventName, callback)
      }
    }
  }
  
  export { Socket }