import React from 'react';
import logo from "../../assets/logo_colour.svg";

import './FaqPage.css';

const FaqPage = () => {
    return (
        <div className='faqPage'>
            <img
                className="logo"
                src={logo}
                alt="logo"
                height="100px"
                width="auto"
                onClick={() => {
                    window.location.href = '/';
                }}
            />
            <h1 style={{ fontSize: '1.5rem' }}>Kowroo Beta Testing FAQs</h1>

            <div className='faqs'>

                <div className='faqToc'>
                    <h2>Table of Contents</h2>
                    <ul>
                        <li><a href='#general-info'>General Information</a></li>
                        <li><a href='#installation-access'>Installation & Access</a></li>
                        <li><a href='#using-the-app'>Using the App</a></li>
                        <li><a href='#location-tracking'>Location Tracking</a></li>
                        <li><a href='#emergency-services-integration'>Emergency Services Integration</a></li>
                        <li><a href='#privacy-data'>Privacy & Data</a></li>
                        <li><a href='#troubleshooting'>Troubleshooting</a></li>
                        <li><a href='#updates-feedback'>Updates & Feedback</a></li>
                    </ul>
                </div>

                <div className='faqContents'>

                    <h2 id='general-info'>General Information</h2>

                    <details>
                        <summary>What is a beta test?</summary>
                        <p>A beta test is a pre-release version of our app that allows us to gather feedback from real users like you. This helps us identify bugs, optimize performance, and refine features before the official launch.</p>
                    </details>

                    <details>
                        <summary>Who can participate in the Kowroo beta test?</summary>
                        <p>The beta is currently open to a select group of users who have received an invitation. If you have an invite, you can join the beta by following the instructions provided.</p>
                    </details>

                    <hr />

                    <h2 id='installation-access'>Installation & Access</h2>

                    <details>
                        <summary>How do I download and install the Kowroo beta app?</summary>
                        <p>You can download the Kowroo beta by clicking the link in your invitation email. Follow the prompts to install the app on your iOS or Android device.</p>
                    </details>

                    <details>
                        <summary>Do I need a special code or invitation to join the beta?</summary>
                        <p>Yes, participation in the beta is by invitation only. If you haven&apos;t received an invitation, you won&apos;t be able to join the beta at this time.</p>
                    </details>

                    <hr />

                    <h2 id='using-the-app'>Using the App</h2>

                    <details>
                        <summary>What are the key features in this beta version?</summary>
                        <p>In this beta, we&apos;re introducing several features:</p>
                        <ul>
                            <li>Personalized Risk Assessments: Receive AI-driven, individualized risk ratings based on your personal characteristics and location.</li>
                            <li>SOS/Follow Me Home: Issue a real-time SOS or activate Follow Me Home for added support during emergencies.</li>
                            <li>Enhanced Alert & Incident Notifications: Receive personalized and timely safety and security alerts.</li>
                        </ul>
                    </details>

                    <details>
                        <summary>Are there any known issues or limitations?</summary>
                        <p>Yes, there are some known issues:</p>
                        <ul>
                            <li>Syncing Issues: Some tasks may take longer to sync across devices.</li>
                            <li>Notifications: Delays may occur for some users when receiving safety notifications.</li>
                            <li>Risk Assessments: Certain countries may not have complete assessments yet, which could affect your risk rating.</li>
                            <li>Risk Advice: Advice may not be as personalized as expected in all regions, but improvements are planned for future versions.</li>
                        </ul>
                    </details>

                    <details>
                        <summary>Can I ask for additional countries to be added?</summary>
                        <p>Yes! If you have an upcoming trip, email<a href="mailto:sheelagh@kowroo.com">sheelagh@kowroo.com</a>, and we&apos;ll do our best to onboard your destination before your travels.</p>
                    </details>

                    <details>
                        <summary>How do I report bugs or provide feedback?</summary>
                        <p>You can report bugs or give feedback directly through the app. Tap the &quot;Feedback&quot; option in the Profiles and Settings tab, and submit your comments. Please include a detailed description to help us resolve the issue.</p>
                    </details>

                    <details>
                        <summary>Can I use the app normally during the beta?</summary>
                        <p>While the app is stable, it is still in testing, so we recommend using it alongside your regular security tools, especially for critical tasks.</p>
                    </details>

                    <details>
                        <summary>Will the system be monitored 24/7 during the trials?</summary>
                        <p>Features like the SOS button, alerts, and Follow Me Home will notify the Administrator during activation, but the system may not be monitored 24/7. We recommend using Kowroo alongside other safety measures during the beta.</p>
                    </details>

                    <hr />
                    <h2 id='location-tracking'>Location Tracking</h2>

                    <details>
                        <summary>Does Kowroo track my location?</summary>
                        <p>Yes, Kowroo uses your location data to provide personalised, location-specific risk assessments and safety alerts based on your current environment. This information allows us to give real-time safety advice that is relevant to your specific location. Your location data is securely stored and only used for improving your safety through the app&apos;s features.</p>
                    </details>

                    <details>
                        <summary>How is my location data collected and used?</summary>
                        <p>Kowroo collects your real-time location data to deliver accurate, personalised risk assessments based on where you are. This allows us to notify you about safety concerns or incidents happening nearby and provide relevant security updates. Your location data is anonymised when used for analytical purposes, and it is only stored for as long as necessary to provide these services. We follow strict data protection standards to ensure your privacy.</p>
                    </details>

                    <details>
                        <summary>Can I control the app&apos;s access to my location?</summary>
                        <p>Yes, you have full control over the app&apos;s access to your location. You can enable or disable location tracking through your device settings at any time. However, please note that disabling location services will limit the app&apos;s functionality and its ability to provide personalised risk assessments and real-time safety alerts based on your location. We are working on a new feature that will give you more control over tracking without losing too much functionality.</p>
                    </details>

                    <details>
                        <summary>How long is my location data stored, and who can access it?</summary>
                        <p>Your location data is stored securely for the duration of your use of the app, and it is only kept as long as necessary to provide personalised safety alerts and risk assessments. After this period, it is securely deleted. Kowroo does not share your personal location data with third parties unless required by law. You may also request the deletion of your location data at any time by contacting<a href="mailto:sheelagh@kowroo.com">sheelagh@kowroo.com</a>.</p>
                    </details>

                    <details>
                        <summary>What should I do if I&apos;ve turned off tracking but want to use the app again?</summary>
                        <p>To restore functionality, go to your device&apos;s settings and re-enable location services for Kowroo. Afterward, close and reopen the app to reconnect. If issues persist, contact it_team@kowroo.com for support.</p>
                    </details>

                    <hr />
                    <h2 id='emergency-services-integration'>Emergency Services Integration</h2>

                    <details>
                        <summary>Will Kowroo share my location with emergency services if I trigger an SOS?</summary>
                        <p>
                            Currently, Kowroo does not automatically share your location with emergency services when an SOS is triggered. We are exploring options to integrate with emergency services in future versions to further enhance your safety and a function that will allow you to manually share your location with trusted contacts through the app.
                        </p>
                    </details>

                    <hr />
                    <h2 id='privacy-data'>Privacy & Data</h2>

                    <details>
                        <summary>What data does the app collect during the beta?</summary>
                        <p>The app collects your personal information (name, contact details) when creating your account. It also gathers non-personal data such as usage metrics, crash reports, and feedback to improve the app. All data is handled with the highest standards of security.</p>
                    </details>

                    <details>
                        <summary>What personal data does the app collect?</summary>
                        <p>The app will also collect the personal information you provide when creating your profile, such as your name and contact details. This information is securely stored and used to manage your access to the beta. You may request the deletion of your personal data at any time by contacting
                            <a href="mailto:sheelagh@kowroo.com">
                                sheelagh@kowroo.com
                            </a>.
                        </p>
                    </details>

                    <details>
                        <summary>Is my data safe during the beta?</summary>
                        <p>Yes, your data is encrypted and handled according to industry best practices to ensure its safety.</p>
                    </details>

                    <details>
                        <summary>Will my data be wiped after the beta?</summary>
                        <p>Yes, your personal data will be securely wiped within one month of the beta&apos;s end. If you want your data deleted sooner, contact
                            <a href="mailto:sheelagh@kowroo.com">
                                sheelagh@kowroo.com
                            </a>, and we&apos;ll process your request promptly.
                        </p>
                    </details>

                    <details>
                        <summary>How can I request the deletion of my data?</summary>
                        <p>To request the deletion of your data, contact
                            <a href="mailto:sheelagh@kowroo.com">
                                sheelagh@kowroo.com
                            </a>.
                        </p>
                    </details>

                    <hr />
                    <h2 id='troubleshooting'>Troubleshooting</h2>

                    <details>
                        <summary>What should I do if the app crashes or freezes?</summary>
                        <p>If the app crashes or freezes, try restarting it. If the problem persists, report the issue using the in-app feedback tool or email
                            <a href="mailto:it_team@kowroo.com">
                                it_team@kowroo.com
                            </a>
                            with details and screenshots, if possible.
                        </p>
                    </details>

                    <details>
                        <summary>Why can&apos;t I log in or access certain features?</summary>
                        <p>This may be due to server issues or beta restrictions. Check for updates or contact
                            <a href="mailto:it_team@kowroo.com">
                                it_team@kowroo.com
                            </a>
                            for assistance if the problem continues.
                        </p>
                    </details>

                    <hr />
                    <h2 id='updates-feedback'>Updates & Feedback</h2>

                    <details>
                        <summary>How will I know when updates are available?</summary>
                        <p>You&apos;ll receive email notifications about updates. You can also check for updates manually in your device&apos;s app store.</p>
                    </details>

                    <details>
                        <summary>
                            Can I share the beta app with others?
                        </summary>
                        <p>
                            No, the beta is invite-only. Please do not share the app or your access code with anyone.
                        </p>
                    </details>

                    
                </div>
            </div>
        </div>
    );
};
                

export default FaqPage;