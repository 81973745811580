import React from 'react';
import { Tooltip, Chip, Box, Typography } from '@mui/material';
import  globalConfig from "../config.json"; 

interface UserChipListProps {
  usernames: string[];
}

const UserChipList = ({ usernames }: UserChipListProps) => {
    return (
        <Box sx={{ 
            display: 'flex', 
            flexWrap: 'wrap', 
            justifyContent: 'center', 
            backgroundColor: '#f0f0f0',
            color: 'white',
            cursor: 'pointer',
            // backgroundImage: 'none',
            // border: 0
            padding: '12px', // Add padding for spacing inside the box
            borderRadius: '12px', // Round the corners of the box
            gap: '8px', // Gap between chips
        }}>
            {usernames.map((username, index) => (
                <Chip key={index} label={username} />
            ))}
        </Box>
    );
};

interface CircleWithTooltipProps {
  usernames: string[];
}

const CircleWithTooltip = ({ usernames }: CircleWithTooltipProps) => {
    return (
        <Tooltip
            title={<UserChipList usernames={usernames} />}
            placement="top"
            componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: 'transparent', // Make sure the tooltip background is transparent
                        boxShadow: 'none', // Remove any potential shadow
                        padding: '0',
                        borderRadius: '4px',
                    },
                },
                arrow: {
                    sx: {
                        color: globalConfig['primaryLight'], // Arrow color matching the circle
                    },
                },
            }}
            arrow
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 40,
                    height: 40,
                    borderRadius: '50%',
                    backgroundColor: globalConfig['primaryLight'], // Customize color
                    color: 'white',
                    cursor: 'pointer',
                    backgroundImage: 'none', // Remove any potential transparency
                    opacity: 1, // Ensure full opacity
                }}
            >
                <Typography variant="body1">{usernames.length}</Typography>
            </Box>
        </Tooltip>
    );
};

// Parent component passing the usernames as props
const UsernamesCircleContainer = ({ usernames }: { usernames: string[] }) => {
    return <CircleWithTooltip usernames={usernames} />;
};

export default UsernamesCircleContainer;