import React, { ReactNode, useState } from "react";
import DOMPurify from 'dompurify';
import { FormControl, Box, Typography, Grid, Paper, Autocomplete, TextField, IconButton, SvgIcon } from '@mui/material';


import { useDataStore } from "../stores/dataStore";
import { countryAssessmentCountries } from "../sample_data/constants";
import { useWebSocketStore } from "../stores/websocketStore";
import globalConfig from "../config.json";
import {ReactComponent as GridOne} from "./assets/grid_one.svg";
import {ReactComponent as GridTwo} from "./assets/grid_two.svg";
import {ReactComponent as GridThree} from "./assets/grid_three.svg";
import { CountryAssessmentItem } from "../types/data";

const Assessment: React.FC = () => { 
    const { countryAssessmentData } = useDataStore((state) => ({
        countryAssessmentData: state.countryAssessment,
    }));

    const { fetchCountryAssessment } = useWebSocketStore((state) => ({
        fetchCountryAssessment: state.fetchCountryAssessment,
    }));
    const [selectedCategory, setSelectedCategory] = useState<string[]>([]);

    const handleCategoryChange = (newValue: string[]) => {
        setSelectedCategory(newValue);
    };
    // const uniqueCategories = Array.from(new Set(countryAssessmentData.map(data => data.category_name)));
    // const countries = Array.from(new Set(countryAssessmentData.map(data => data.countryLocation_name)));

    const [selectedCountry, setSelectedCountry] = useState<string>('Ireland');
    const handleCountryChange = (value: string) => {
        if (countryAssessmentCountries.includes(value)){
            fetchCountryAssessment(value as string);
            setSelectedCountry(value as string);
        }
    };

        // role: 'customer',
    // email,
    // first_name: firstName,
    // surname,
    // phone_number: phone
    
    // const countryFilteredData = countryAssessmentData.filter(data => 
    //     selectedCountry ? data.countryLocation_name === selectedCountry : true
    // );
    const countryFilteredData = countryAssessmentData[selectedCountry] || [] as CountryAssessmentItem[];
    const uniqueCategories = Array.from(new Set(countryFilteredData.map(data => data.category_name)));

    const filteredData = countryFilteredData.filter(data => 
        selectedCategory?.length ? selectedCategory?.includes(data?.category_name) : true
    );

    const [activeFilter, setActiveFilter] = useState(3);

    const handleSetActiveFilter = (filter: number) => {
        setActiveFilter(filter);
    };

    const iconButton = (filter: number, icon: ReactNode) => {
        return   <IconButton 
            onClick={() => handleSetActiveFilter(filter)}
                    
            sx={{backgroundColor: filter === activeFilter ? '#0FCD7D' : '#DCE0DA', top: 0, left: 0, right: 0, width: 35, height: 35}}>
            <SvgIcon height={'80%'} sx={{color: filter === activeFilter ?  'white' : null}} >
                {icon}
            </SvgIcon>

        </IconButton>;
    };

    return (
        <>

            <Box pl={6} pt={1} pb={5}>
                <Box display="flex" gap={2} mb={4} >
                    <Grid container gap={1}>
                        <Grid  item xs={3}>
                            <Typography
                                variant="h5"
                                sx={{
                                    fontWeight: 600,
                                    fontFamily: globalConfig["fontFamilies"],
                                    color: globalConfig["primaryMain"],
                                    mb: 1,
                                }}
                            >
                  Country assessment
                            </Typography>
                            <Grid  item container gap={1}>
                                {iconButton(1, <GridOne/>)}

                                {iconButton(2, <GridTwo/>)}
                                {iconButton(3, <GridThree/>)}
                            </Grid>
                        </Grid>
                        {/* <br> </br> */}
                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                                <Autocomplete
                                    size="small"
                                    options={countryAssessmentCountries} // Assuming it's an array of country names
                                    value={selectedCountry}
                                    onChange={(event, newValue) => handleCountryChange(newValue)} // Updates the selected value
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Country"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                                <Autocomplete
                                    size="small"
                                    disableCloseOnSelect
                                    multiple
                                    options={uniqueCategories}
                                    getOptionLabel={(option) => option}
                                    value={selectedCategory}
                                    onChange={(_, newValue) => handleCategoryChange(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            size="small"
                                            {...params}
                                            variant="outlined"
                                            label="Category"
                                            placeholder="Select Categories"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box >

                <Box mt={4}>
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 600,
                            fontFamily: globalConfig["fontFamilies"],
                            color: globalConfig["primaryMain"],
                            mb: 1,
                        }}
                    >
                        {selectedCountry} Information:</Typography>
                    <Grid container gap={2}>
                        {selectedCountry && filteredData.length > 0 ? (
                            filteredData.map((data) => (
                                <Grid  item component={Paper} xs={11.5 / activeFilter} key={data.id}  sx={{}}>
                                    <Grid sx={{p: 1, backgroundColor: '#DCE0DA'}}>
                                        <Typography variant="h6">
                                            {data.category_name}
                                        </Typography>
                                    </Grid>
                                    <Box mt={1} sx={{p: 1.5}}>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.intel) }}
                                        />
                                    </Box>
                                </Grid>
                            ))
                        ) : (
                            <Typography>No assessments available for this category.</Typography>
                        )}
                        <br></br>
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default Assessment;