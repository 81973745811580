import React from 'react';
import { useNavigate } from 'react-router-dom';

import logo from "../../assets/logo_reversed.svg";

/*
src/assets/playstore_download.png
src/assets/appstore_download.png
*/

import playstore from "../../assets/playstore_download.png";
import appstore from "../../assets/appstore_download.png";

const appStoreBetaLink = "https://testflight.apple.com/join/ESpX7jNh"; // TODO: Update this link to release version

const DownLoadPage = () => {
    const navigate = useNavigate();
    return (
        <div
            className='page'
        >
            <img
                className="logo"
                height="100px"
                width={"auto"}
                src={logo}
                alt="logo"
                onClick={() => {
                    navigate("/");
                }}
            />
            <h1>Welcome to Kowroo!</h1>
            <p style={{ textAlign: 'center' }}>
                Please click the link below to download the app.
            </p>
            <div className='download_buttons'>
                <a href="https://play.google.com/store/apps/details?id=com.kowroo.app" target="_blank" rel="noreferrer">
                    <img
                        src={playstore}
                        alt="Play Store"
                        style={{ width: '200px', margin: '10px' }}
                    />
                </a>
                <a href={appStoreBetaLink} target="_blank" rel="noreferrer">
                    <img
                        src={appstore}
                        alt="App Store"
                        style={{ width: '200px', margin: '10px' }}
                    />
                </a>
            </div>

        </div>
    );
};

export default DownLoadPage;