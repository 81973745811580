import React from 'react';
import { Modal, Box, Typography, FormControl, InputLabel, Select, MenuItem, Button, Checkbox, ListItemText } from '@mui/material';

import { PhoneUser } from '../stores/userStore';
import { UserIncident } from '../types/data';
import { useWebSocketStore } from '../stores/websocketStore';


interface ModalWithSelectProps {
    open: boolean;
    handleClose: () => void;
    users: PhoneUser[];
    selectedIDs: number[];
    handleSelectChange: (event: any) => void;
    incident: UserIncident | null;
}

const ModalWithSelect: React.FC<ModalWithSelectProps> = ({
    open,
    handleClose,
    users,
    selectedIDs,
    handleSelectChange,
    incident,
}) => {
    const handleConfirmClick = () => {
        if (incident) {
            selectedIDs.forEach((userId) => {
                sendIncidentNotification(
                    userId, 
                    incident.id,
                    incident.category_name,
                    incident.type_name,
                    incident.summary,
                    incident.started_at_timestamp,
                    incident.ended_at_timestamp,
                    incident.impact_rating,
                    incident.longitude,
                    incident.latitude,
                    incident.description
                );
            });
        }
        handleClose();
    };

    const sendIncidentNotification = useWebSocketStore(state => state.sendIncidentNotification);
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 300,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}
            >
                <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                You are about to push the following incident:
                </Typography>
                <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                    {incident?.description}
                </Typography>
                <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                Please select Users to push it to
                </Typography>
                <FormControl fullWidth>
                    <InputLabel id="multi-select-label">Selected Users</InputLabel>
                    <Select
                        labelId="multi-select-label"
                        id="multi-select"
                        multiple
                        value={selectedIDs}
                        onChange={handleSelectChange}
                        renderValue={(selected) => {
                            const selectedUserNames = users
                                .filter(managedUser => selected.includes(managedUser.id))
                                .map(managedUser => `${managedUser.firstName} ${managedUser.surname}`)
                                .join(', ');
                            return selectedUserNames;
                        }}
                    >
                        {users.map((managedUser) => (
                            <MenuItem key={managedUser.id} value={managedUser.id}>
                                <Checkbox checked={selectedIDs.indexOf(managedUser.id) > -1} />
                                <ListItemText primary={`${managedUser.firstName} ${managedUser.surname}`} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleConfirmClick}
                    sx={{ mt: 2 }}
                >
            Confirm
                </Button>
            </Box>
        </Modal>
    );
};

export default ModalWithSelect;
