
import React, { useMemo } from "react";

import { 
    Button,
    Box, 
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    OutlinedInput
} from "@mui/material";
import { Close } from '@mui/icons-material/';
import {formatDateTimeString} from "../helpers/utils"

const PushIncidentModal = (props:any) => {
    const { 
        incident,
        handleClose, 
        handlePush, 
        selectedUsers,
        handleSelectUser, 
        users
    } = props

    console.log(incident)
    const table = useMemo(() => {
        return (
            <Dialog open={true} maxWidth="sm" fullWidth >
                <DialogTitle>{`${incident.type_name} - ${formatDateTimeString(incident.started_at_timestamp)}`}</DialogTitle>
                <Box position="absolute" top={0} right={0}>
                    <IconButton  onClick={handleClose}>
                    <Close/>
                    </IconButton>
                </Box>
                <DialogContent>
                    <Typography>{incident.summary}</Typography>
                </DialogContent>
                <FormControl sx={{ m: 1, minWidth: 120, maxHeight: 0.5 }}>
                    <InputLabel id="demo-simple-select-helper-label">User</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={selectedUsers}
                        // label="User"
                        input={<OutlinedInput label="Multiple Select" />}
                        onChange={handleSelectUser}
                        multiple
                    >
                    {users.map((user, index) => (
                        <MenuItem key={`${user.firstName}-${user.surname}`} value={user}>{`${user.firstName} ${user.surname}`}</MenuItem>
                    ))}
                    </Select>
                </FormControl>
                <DialogActions>
                    <Button 
                        color="primary" 
                        variant="contained" 
                        onClick={handleClose}
                        sx={{
                            backgroundColor: '#005A31',
                            color: 'white',
                            '&:hover': {
                              backgroundColor: 'green', // Change background color on hover if desired
                            },
                          }}
                    >
                    Cancel
                    </Button>
                    <Button 
                        color="secondary" 
                        variant="contained" 
                        onClick={()=> handlePush(incident)}
                        sx={{
                            backgroundColor: '#005A31',
                            color: 'white',
                            '&:hover': {
                              backgroundColor: 'green', // Change background color on hover if desired
                            },
                          }}
                    >
                    PUSH INCIDENT
                    </Button >
                </DialogActions>
            </Dialog>
            );
    }, [
    selectedUsers
    ]);
    return table
}

export default PushIncidentModal;