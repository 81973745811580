// socketActions.js

// Action types
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const SOCKET_DISCONNECTED = 'SOCKET_DISCONNECTED';
export const SOCKET_RECONNECTING = 'SOCKET_RECONNECTING';
export const SOCKET_ERROR = 'SOCKET_ERROR';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';

// Action creators
export const socketConnected = () => ({
  type: SOCKET_CONNECTED,
});

export const socketDisconnected = () => ({
  type: SOCKET_DISCONNECTED,
});

export const socketReconnecting = () => ({
  type: SOCKET_RECONNECTING,
});

export const socketError = (error="not connected") => ({
  type: SOCKET_ERROR,
  payload: error,
});

export const receiveMessage = (message) => ({
  type: RECEIVE_MESSAGE,
  payload: message,
});
