import React, { useEffect } from 'react';

import logo from "../../assets/logo_reversed.svg";
import { useWebSocketStore } from '../../stores/websocketStore';
import { PartialConnectData, PartialConnectStatus, useUserStore } from '../../stores/userStore';
import { CircularProgress } from '@mui/material';

const ForgotPassword = () => {
    const [email, setEmail] = React.useState('');
    const [errorText, setErrorText] = React.useState('');
    const [successText, setSuccessText] = React.useState('');

    const { partialConnect } = useWebSocketStore();
    const { passwordResetEmailSent, setPasswordResetEmailSent } = useUserStore();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setSuccessText('');
        setErrorText('');
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email === '') {
            setErrorText('Email is required');
            return;
        }
        if (!emailRegex.test(email)) {
            setErrorText('Invalid email format');
            return;
        }
        setErrorText('');

        const partialConnectData: PartialConnectData = {
            email: email,
        };

        setPasswordResetEmailSent(PartialConnectStatus.SENDING);

        partialConnect(partialConnectData);
    };

    useEffect(() => {
        if (passwordResetEmailSent === PartialConnectStatus.SENT) {
            setSuccessText('If the email address you entered is associated with an account, you will receive an email with instructions on how to reset your password!');
        } else if (passwordResetEmailSent === PartialConnectStatus.ERROR) {
            setErrorText('An error occurred. Please try again.');
        }
    }, [passwordResetEmailSent]);

    return (
        <div className='page'>
            <img
                className="logo"
                src={logo}
                alt="logo"
                height="100px"
                width="auto"
                onClick={() => {
                    window.location.href = '/';
                }}
            />
            <h1>Forgot Password?</h1>
            <p style={{
                textAlign: 'center',
            }}>Enter your email address and we&apos;ll send you a link to reset your password.</p>
            <br />

            <form className='form'>
                <label htmlFor="email" className='label'>Email address</label>
                <input
                    type="email"
                    className='input'
                    id="email"
                    placeholder="name@example.com"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                />
                {
                    errorText && <div className='error'>{errorText}</div>
                }
                {
                    successText && <div className='success'>{successText}</div>
                }
                <button type="submit" className="submit"
                    onClick={handleSubmit}
                    disabled={passwordResetEmailSent.toString() === PartialConnectStatus.SENDING.toString()}
                >
                    {
                        passwordResetEmailSent.toString() === PartialConnectStatus.SENDING.toString()
                            ? <CircularProgress size="1.55rem" style={{ color: "white" }} />
                            : 'Send reset link'
                    }
                </button>
            </form>
            <br />
            <br />
        </div>
    );
};

export default ForgotPassword;