import { Alert, Box, Button, CircularProgress, Grid, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { registerAdmin, searchUsers } from "../helpers/api";
import { formatDate, randomStringGen } from "../helpers/utils";

const AdminPage = (props) => {
  console.log("ofeijfoeijfoeijoij")
  // const [loading, setLoading] = useState(true);
  const {loading} =props;
  const [sendingData, setSendingData] = useState(false);
  // const [sendingInviteLink, setSendingInviteLink] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [companyName, setCompanyName] = useState('');
;
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [errorText, setErrorText] = useState('')
  const [errorOpen, setErrorOpen] = useState(false)
  const [rows, setRows] = useState([{companyName: 'No users', email: null, firstName: null, surname: null}]);
  const [searchInput, setSearchInput] = useState('');
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(true);
  const navigate = useNavigate()

  useEffect(() => {
    // eslint-disable-next-line require-jsdoc
    async function fetchData() {
      if (!loading) {
        const admins = await searchUsers("","", "","","");
        if (admins.data.results === undefined) {setRows([]);
        } else {
          setRows(admins.data.results);
        }
      }
    }
    if (reload && !loading) {
      fetchData();
            setReload(false);
    }
      }, [reload, navigate,  loading, searchInput, open]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const randomPassword = randomStringGen(); // SME will reset this via the invite
    setSendingData(true);
    const res = await registerAdmin(
        companyName,
        email,
        randomPassword,
        randomPassword,
        firstName,
        surname,
    );
        try {
      if (res.status === 201 || res.status === 200 || res.status === 204) {
        setSendingData(false);
        setErrorText('');
        setOpen(true);
        setEmail('')
        setCompanyName('')
        setFirstName('')
        setSurname('')
        setReload(true)
      } else if (res.status === 400 && res.data['email']) {
        setSendingData(false)
        setErrorText("Email: " + res.data['email']);
        setErrorOpen(true)
      } else if (res.status === 403 && res.data['detail']) {
        setSendingData(false);
        setErrorText(res.data['detail']);
        setErrorOpen(true)
      } else if (res.status === 404) {
        setSendingData(false);
        setErrorText('Something went wrong');
        setErrorOpen(true)
      } else {
        setSendingData(false);
        setErrorText('Unknown error');
        setErrorOpen(true)
      }
    } catch (err) {
      setSendingData(false);
      setErrorText('Fatal error');
      setErrorOpen(true)
    }
    setSendingData(false);
  }, [email, firstName, surname, companyName]);

  const BasicTable = useMemo(() => {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
            <TableCell>Company</TableCell>
              <TableCell>Email</TableCell>
              <TableCell align="right">First Name</TableCell>
              <TableCell align="right">Surname</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <TableRow
                key={"row-id-"+idx}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.companyName}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.email}
                </TableCell>
                <TableCell align="right">{row.firstName}</TableCell>
                <TableCell align="right">{row.surname}</TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [rows])

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false)
    setErrorText('');
  };

  const handleCloseError = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorOpen(false)
    setErrorText('');
  };

  return (
    <Grid container justifyContent="center" direction="row">
      <Grid item 
        width="30%" 
        justifyContent="center"
        // spacing={0}
        // direction="column"
        // alignItems="center"
        // justifyContent="center"
        alignItems="center" direction="column" sx={{margin: 5}}
      >
        <Snackbar onClose={handleClose} open={open && errorText === '' && !sendingData} autoHideDuration={6000} >
          <Alert severity="success">
            Invite has been successfully sent
          </Alert>
        </Snackbar>
        <Snackbar onClose={handleCloseError} open={errorOpen && errorText !== '' && !sendingData} autoHideDuration={6000} >
          <Alert severity="error">
            {errorText}
          </Alert>
        </Snackbar>
        <Grid item>
          <Typography component="h1" variant="h5">
            Invite company admin
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
          </Typography>
        </Grid>
        <Grid item>
          <Box
            component="form"
            onSubmit={(e) => {handleSubmit(e)}}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              error={errorText !== '' || (submitted && !companyName)}
              value={companyName}
              onChange={(e) => {
                setCompanyName(e.target.value);
              }}
              margin="normal"
              required
              fullWidth
              id="company_name"
              label="Company Name"
              name="companyname"
              // autoComplete="Company name"
              autoFocus
            />
            <TextField
                // type="text"
              error={errorText !== '' || (submitted && !email)}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              margin="normal"
              required
              fullWidth
              id="user_name"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              error={errorText !== '' || (submitted && !firstName)}
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              margin="normal"
              required
              fullWidth
              id="first_name"
              label="First Name"
              name="firstname"
              autoComplete="Firstname"
              autoFocus
            />
            <TextField
              error={errorText !== '' || (submitted && !surname)}
              value={surname}
              onChange={(e) => {
                setSurname(e.target.value);
              }}
              margin="normal"
              required
              fullWidth
              id="last_name"
              label="Last Name"
              name="last name"
              autoComplete="Lastname"
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              // onClick={handleLogin}
            >
              {sendingData ? <CircularProgress size="1.55rem" color="inherit"/> :
              "Invite admin user" }
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid item width="50%" sx={{margin: 5}}>
        {BasicTable}
      </Grid>
    </Grid>
  )
}

const mapStateToProps = ({user}) => ({
    loading: user.loading
  });
export default connect(mapStateToProps)(AdminPage);