
import React, { useMemo } from "react";

import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';
const UserIcon = (props:any) => {
    const { 
        user, 
        openProfile,
        handleClickProfile, 
    } = props
    // console.log("USERICON!!!")
    // console.log(user)
    
    const initials = user.firstName.length > 0 ?  `${user.firstName.charAt(0)}${user.surname.charAt(0)}` : ""

    const Icon = useMemo(() => {
        return (
            <>
                <div className="circular-avatar" onClick={handleClickProfile}> 
                    <Avatar sx={{ bgcolor: deepOrange[500] }}>{initials}</Avatar>
                </div>
            {/* <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorProfile}
                open={openProfile}
                onClose={handleClose}
                PaperProps={{
                elevation: 0,
                sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                    },
                    "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                    },
                },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                {["kowroo_admin", "admin"].includes(user.role) ? null : (
                <MenuItem onClick={handleProfile}>Profile</MenuItem>
                )}
                <MenuItem onClick={handleCloseMaintenance}>
                Maintenance
                </MenuItem>
                <MenuItem onClick={handleCloseRisk}>
                Risk Appetite Selector
                </MenuItem>
                <MenuItem onClick={handleCloseUserSettings}>
                User settings
                </MenuItem>
                <MenuItem onClick={handleCloseSettings}>Settings</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu> */}
            </>
            );
    }, [
        user
    ]);
    return Icon
}

export default UserIcon;