import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  Radio,
  RadioGroup,
  Rating,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ratingQ,
  riskAppetiteSurvey,
  selectQ,
  yesNoQ,
} from "../helpers/constants";
import { createRiskApp, getRiskApp } from "../helpers/api";
import { connect } from "react-redux";
import { setProfile } from "../actions/userActions";
import globalConfig from "../config.json";
import {
  LooksOne,
  LooksTwo,
  Looks3,
  Looks5,
  Looks4,
} from "@mui/icons-material";

const Risk_Appetite = (props) => {
  const [yesNoQuestions, setYesNoQuestions] = useState({});
  const [ratingQuestions, setRatingQuestions] = useState([]);
  const [selectQuestion, setSelectQuestion] = useState([]);
  const { profile, dispatch } = props;
  // const [profileId, setProfileId] = useState(profile.id)
  const [errorText, setErrorText] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [otherSelected, setOtherSelected] = useState(false);
  const [otherInfo, setOtherInfo] = useState("");
  const handleClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setErrorText("");
  };

  const handleCloseError = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
    setErrorText("");
  };

  useEffect(() => {
    const fetchData = async () => {
      const riskApp = await getRiskApp();
      return riskApp;
    };
    fetchData().then((res) => {
      var list_rating = [];
      var select_ques = [];
      for (var ques of res["data"]["results"]) {
        if (ques['question_id']==='11' || ques['question_id']==='12' || ques['question_id']==='17'){
          select_ques.push(ques);
        }
        else {
          list_rating.push(ques);
        }
      }
      setRatingQuestions(list_rating);
      setSelectQuestion(select_ques);
    });
  }, [otherInfo]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setSendingData(true);

      if (ratingQuestions && selectQuestion) {
        const res = await createRiskApp([
          ...ratingQuestions,
          ...selectQuestion,
        ]);
        try {
          if (res.status === 201 || res.status === 200 || res.status === 204) {
            setSendingData(false);
            setErrorText("");
            setOpen(true);
          } else if (res.status === 400 && res.data["email"]) {
            setSendingData(false);
            setErrorText("Email: " + res.data["email"]);
            setErrorOpen(true);
          } else if (res.status === 403 && res.data["detail"]) {
            setSendingData(false);
            setErrorText(res.data["detail"]);
            setErrorOpen(true);
          } else if (res.status === 404) {
            setSendingData(false);
            setErrorText("Something went wrong");
            setErrorOpen(true);
          } else {
            setSendingData(false);
            setErrorText("Unknown error");
            setErrorOpen(true);
          }
        } catch (err) {
          setSendingData(false);
          setErrorText("Fatal error");
          setErrorOpen(true);
        }
      } else {
        <Snackbar
          onClose={handleClose}
          open={open && errorText === "" && !sendingData}
          autoHideDuration={6000}
        >
          <Alert severity="success">User data has been updated</Alert>
        </Snackbar>;
      }

      setSendingData(false);
      // handleCloseConfirmation();
    },
    [errorText, open, ratingQuestions, selectQuestion, sendingData]
  );

  const dispatchProfile = useCallback(() => {
    dispatch(setProfile({}));
  }, [dispatch]);

  const customIcons = {
    1: {
      icon: <LooksOne />,
      label: "Not at all important",
    },
    2: {
      icon: <LooksTwo />,
      label: "",
    },
    3: {
      icon: <Looks3 />,
      label: "",
    },
    4: {
      icon: <Looks4 />,
      label: "",
    },
    5: {
      icon: <Looks5 />,
      label: "Very Important",
    },
  };

  function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }

  const yesNoCheckbox = (text) => {
    return (
      <Checkbox
        // {...label}
        // defaultChecked
        onChange={(e) => {
          const newQuestions = yesNoQuestions;
          newQuestions[text] = e.target.checked;
          setYesNoQuestions(newQuestions);
        }}
        sx={{
          color: globalConfig["primaryMain"],
          "&.Mui-checked": {
            color: globalConfig["primaryMain"],
          },
        }}
      />
    );
  };

  const ratingIcons = (item, text, position) => {
    return (
      <Grid item>
        <Grid container alignItems="center">
          <Typography variant="caption">Not at all important</Typography>
          <Rating
            onChange={(e, newValue) => {
              const quest_id =
                item.section === 1
                  ? position + 1
                  : item.section === 2
                  ? position + 4 + 1
                  : position + 12 + 1;
              const value = {
                question_id: quest_id,
                question_text: text,
                answer_text: newValue,
                section_text: item.question,
              };
              setRatingQuestions([...ratingQuestions, value]);
            }}
            name="customized-icons"
            defaultValue={1}
            precision={5}
            getLabelText={(value) => customIcons[value].label}
            IconContainerComponent={IconContainer}
          />
          <Typography variant="caption">Very important</Typography>
        </Grid>
      </Grid>
    );
  };

  const survey = useMemo(() => {
    return riskAppetiteSurvey.map((item, idx) => {
      return (
        <Grid maxWidth="80%" alignItems="center">
          <List>
            <Typography variant="body1">
              {item.section}. {item.question}
            </Typography>
            <Typography variant="body1" color="primary">
              {" "}
              {item.description}
            </Typography>
            {/* <Grid container> */}
            {item.rating.length === 0 ? (
              <FormControl sx={{ paddingLeft: 10 }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  {item.texts.map((text, i) => {
                    return (
                      <li>
                        <FormControlLabel
                          value={text}
                          control={
                            <Radio
                              onChange={(e) => {
                                if (e.target.value === "Other") {
                                  setOtherSelected(true);
                                } else {
                                  setOtherSelected(false);
                                  const quest_id =
                                    item.section === 3
                                      ? 11
                                      : item.section === 4
                                      ? 12
                                      : 17;
                                  const value = {
                                    question_id: quest_id,
                                    question_text: item.question,
                                    answer_text: e.target.value,
                                    section_text: item.question,
                                  };
                                  setSelectQuestion([...selectQuestion, value]);
                                }
                              }}
                            />
                          }
                          label={text}
                        />
                        {item.section === 4 &&
                        text === "Other" &&
                        otherSelected ? (
                          <TextField
                            value={otherInfo}
                            label="Please enter most appropriate response"
                            fullWidth
                          ></TextField>
                        ) : null}
                      </li>
                    );
                  })}
                </RadioGroup>
              </FormControl>
            ) : (
              <ol>
                {item.texts.map((text, i) => {
                  return (
                    <li>
                      <Grid
                        container
                        alignItems="center"
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Grid item maxWidth={"60%"}>
                          <List sx={{ paddingLeft: 5 }}>
                            <Typography variant="body2">{text}</Typography>
                          </List>
                        </Grid>

                        {item.rating.length === 2
                          ? yesNoCheckbox(text)
                          : ratingIcons(item, text, i)}
                      </Grid>
                    </li>
                  );
                  // </Grid>
                })}
              </ol>
            )}
          </List>
        </Grid>
      );
    });
  }, [ratingIcons, yesNoCheckbox]);

  return (
    <Grid container justifyContent="center" direction="row">
      <Grid
        item
        justifyContent="center"
        // spacing={0}
        // direction="column"
        // alignItems="center"
        // justifyContent="center"
        sx={{ margin: 5 }}
      >
        {/* <CardContent> */}
        <Snackbar
          onClose={handleClose}
          open={open && errorText === "" && !sendingData}
          autoHideDuration={6000}
        >
          <Alert severity="success">
            Risk Appetite info was successfully setup
          </Alert>
        </Snackbar>
        <Snackbar
          onClose={handleCloseError}
          open={errorOpen && errorText !== "" && !sendingData}
          autoHideDuration={6000}
        >
          <Alert severity="error">{errorText}</Alert>
        </Snackbar>

        <Typography component="h1" variant="h5">
          Risk Appetite
        </Typography>
        <br></br>
        <br></br>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="subtitle1" color="primary">
              {
                "Please answer the following questions to submit your Risk Appetite information"
              }
            </Typography>
            <br></br>
          </Grid>
          {survey}
          <Grid item>
            {Object.keys(profile).length > 0 ? (
              <Button
                // disabled={Object.keys(profile).length > 0}
                type="submit"
                onClick={dispatchProfile}
                // fullWidth
                variant="outlined"
                sx={{ mt: 3, mb: 2 }}
                // onClick={handleLogin}
              >
                {sendingData ? (
                  <CircularProgress size="1.55rem" color="inherit" />
                ) : (
                  "Edit"
                )}
              </Button>
            ) : null}
          </Grid>
        </Grid>

        <Box
          component="form"
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          noValidate
          sx={{ mt: 1 }}
        >
          <Grid container style={{ minWidth: 650 }} direction="column">
            {/* <Grid container direction="row"  spacing={2} justifyContent="space-beetween">
                <Grid item style={{width: 350}} >
                  {profile.gender ?
                  customDropdown(genders, "Gender", profile.gender, setGender, profile) :
                  customDropdown(genders, "Gender", gender, setGender, profile)
}

              </Grid>
              <Grid item style={{width: 350}}>
                {profile.age ?
                customDropdown(ages, "Age", profile.age, setAge, profile) :
                customDropdown(ages, "Age", age, setAge, profile)}
              </Grid>
              </Grid>
              <Grid container direction="row" spacing={2} justifyContent="space-beetween">
                <Grid item style={{width: 350}} >
                  {profile.nationality ?
                customDropdown(countries, "Nationality", profile.nationality, setNationality, profile) :
                customDropdown(countries, "Nationality", nationality, setNationality, profile)}

              </Grid>

              <Grid item style={{width: 350}} >
                {profile.ethnicity ?
                customDropdown(ethnicities, "Ethnicity", profile.ethnicity, setEthnicity, profile) :
                customDropdown(ethnicities, "Ethnicity", ethnicity, setEthnicity, profile)}

              </Grid>
              </Grid>
              <Grid container direction="row" spacing={2} justifyContent="space-beetween">


                <Grid item style={{width: 350}} >

{profile.religion ?
                customDropdown(religions, "Religion", profile.religion, setReligion, profile) :
                customDropdown(religions, "Religion", religion, setReligion, profile)}
              </Grid>
                <Grid item style={{width: 350}} >
                  {profile.orientation ?
                customDropdown(orientations, "Sexual orientation", profile.orientation, setOrientation, profile) :
                customDropdown(orientations, "Sexual orientation", orientation, setOrientation, profile) }
              </Grid>
              </Grid>
              <Grid container direction="row" spacing={2} justifyContent="space-beetween">
                <Grid item style={{width: 350}} >
                  {profile.spoken_lang ?
                customDropdown(countries, "Spoken languages", profile.spoken_lang, setSpokenLangs, profile) :
                customDropdown(countries, "Spoken languages", spokenLangs, setSpokenLangs, profile)}

              </Grid>
              <Grid item style={{width: 350}} >
              {profile.medical_cond ?
              customDropdown([], "Medical condition", profile.medical_cond, setMedicalCond, profile) :
              customDropdown([], "Medical condition", medicalCond, setMedicalCond, profile)}
              </Grid>

              </Grid> */}

            <Grid container>
              <Button
                disabled={Object.keys(profile).length > 0}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                // onClick={handleLogin}
              >
                {sendingData ? (
                  <CircularProgress size="1.55rem" color="inherit" />
                ) : (
                  "Submit Risk Appetite"
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
        {/* </Grid> */}
        {/* </CardContent> */}
      </Grid>
      {/* <Grid item width="50%" sx={{margin: 5}}> */}
      {/* </Grid> */}
    </Grid>
  );
};

const mapStateToProps = ({ user }) => ({
  loading: user.loading,
  profile: user.profile,
  profileId: user.profileId,
});
export default connect(mapStateToProps)(Risk_Appetite);
