import React,  { useCallback, useState } from 'react';
import { useUserStore, AdminUser } from '../stores/userStore';
import GenericTable from './GenericTable';
import { SOSHistoryItem } from '../types/data';

import {  MRT_ColumnDef, MRT_PaginationState, MRT_SortingState } from "material-react-table";
import { formatDateTimeToString } from '../lib/utils';
import { Chip } from '@mui/material';

interface SOSHistoryTableProps {
    title: string;
    data: SOSHistoryItem[];
}
const SOSHistoryTable = (props: SOSHistoryTableProps) => {
    
    const { 
        title,
        data,
    } = props;

    const { user } = useUserStore((state) => ({
        user: state.user,
    }));

    const getInitialsFromID = useCallback((id: number)=>{
        const managedUsers = (user as AdminUser).managedUsers;
        const currentUser = managedUsers.filter(managedUser=>managedUser.id===id)[0];
        let initials = "";
        if (currentUser){
            initials = currentUser.firstName.length > 0 ?  `${currentUser.firstName} ${currentUser.surname}` : "";
        }
        
        return initials;
    },[user]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({pageIndex: 0,pageSize: 5});

    const isRefetching = false;
    const isError = false;
    const isLoading = false;
    const columns: MRT_ColumnDef<SOSHistoryItem>[] = [
        {
            accessorKey: "creator_id",
            header: "SOS User",
            size: 50,
            Cell: ({ row }: {row: { original: SOSHistoryItem}}) => (
                <div>
                    {getInitialsFromID(row.original.creator_id)}
                </div>
            ),
        },
        {
            accessorKey: "date_created",
            header: "Date Triggered",
            size: 50,
            Cell: ({ row }: { row: { original: SOSHistoryItem } }) => (
                <div>
                    {formatDateTimeToString(row.original.date_created)}
                </div>
            ),
            filterVariant:"date-range"
        },
        {
            accessorKey: "approved",
            header: "Status",
            size: 50,
            Cell: ({ row }: {row: { original: SOSHistoryItem}}) => (
                <div>
                    {
                        row.original.approved ? (
                            <Chip
                                label="Complete"

                                sx={{
                                    color: "black",
                                    fontWeight: 'bold',
                                }}
                            />
                        ) : (
                            <Chip
                                label="Active"
                                sx={{
                                    color: "white",
                                    background: "red",
                                    fontWeight: 'bold',
                                }}
                            />
                        )}
                </div>
            ),

        },
    ];

    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const sosHistoryTable = (
        <GenericTable
            title={title}
            columns={columns}
            data={data}
            onEditClick={()=>{}}
            handleOpenDeleteModal={()=>{}}
            sorting={sorting}
            pagination={pagination}
            rowCount={data.length}
            isRefetching={isRefetching}
            isError={isError}
            isLoading={isLoading}
            setPagination={setPagination}
            setSorting={setSorting}
            enableRowActions={false}
        />
    ); 

    return sosHistoryTable;
};

export default SOSHistoryTable;