import React, { useEffect, useState } from "react";
import SOSHistoryTable from "../components/sosHistoryTable";
import CheckinHistoryTable from "../components/checkinHistoryTable";
import { useDataStore } from "../stores/dataStore";
import {FollowMeHomeEvent, UserIncident} from "../types/data";
import { Box, Grid } from '@mui/material';
import FollowMeHomeHistoryTable from "../components/FollowMeHomeHistory";
import AlertHistoryTable from "../components/AlertHistoryTable";

const Alert: React.FC = () => { 
    const { checkinHistory, sosHistory, followMeHomeHistory, userIncidents } = useDataStore((state) => ({
        checkinHistory: state.checkinHistory,
        sosHistory: state.sosHistory,
        followMeHomeHistory: state.followMeHomeHistory,
        userIncidents: state.userIncidents
    }));
    const [followMeHomeEvents, setFollowMeHomeEvents] = useState([] as FollowMeHomeEvent[]);

    useEffect(()=>{
        let allFollowMeHomeEvents: FollowMeHomeEvent[] = [];
        Object.values(followMeHomeHistory).forEach((events) => {
            allFollowMeHomeEvents = [...allFollowMeHomeEvents, ...events];
        });
        setFollowMeHomeEvents(allFollowMeHomeEvents);
    },[followMeHomeHistory]);

    const [incidentAlerts, setIncidentAlerts] = useState([] as UserIncident[]);

    useEffect(()=>{
        const allIncidentAlerts = userIncidents.filter(incident=>incident.alerts.length!=0);
        setIncidentAlerts(allIncidentAlerts);
    },[userIncidents]);


    return <>   
        <Box sx={{ width: '100%', padding: 2 }}>
            <Grid container spacing={2} >
                <Grid item xs={12} md={6}  sx={{ pb: 10 }}>        
                    <SOSHistoryTable
                        title={"SOS"}
                        data={sosHistory}
                    />
                </Grid>
                <Grid item xs={12} md={6}  sx={{ pb: 10 }}>
                    <CheckinHistoryTable
                        title={"Checkin"}
                        data={checkinHistory}
                    />
                </Grid>
                <Grid item xs={12} md={6}  sx={{ pb: 10 }}>
                    <FollowMeHomeHistoryTable
                        title={"Follow Me Home History"}
                        data={followMeHomeEvents}
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ pb: 10 }}>
                    <AlertHistoryTable
                        title={"Incident Alerts"}
                        data={incidentAlerts}
                    />
                </Grid>
            </Grid>
        </Box>
    </>;
};


export default Alert;
