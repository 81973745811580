import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SetPassword.css';

import logo from "../../assets/logo_reversed.svg";
import { useWebSocketStore } from '../../stores/websocketStore';
import { PartialConnectData, PartialConnectStatus, useUserStore } from '../../stores/userStore';
import { CircularProgress } from '@mui/material';

const SetPassword = () => {
    const [uid, setUid] = useState('');
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    const [inviteId, setInviteId] = useState('');

    const [password, setPassword] = useState('');
    const [suggestion, setSuggestion] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [successText, setSuccessText] = useState('');

    const [isInvite, setIsInvite] = useState(false);

    const navigate = useNavigate();
    const { partialConnect } = useWebSocketStore();
    const { passwordResetEmailSent } = useUserStore();

    useEffect(() => {
        const url = new URL(window.location.href);
        console.log('[partialConnect]: ', url.pathname.split('/'));
        const isInvite = url.pathname.split('/')[1] === "setpassword";
        setIsInvite(isInvite);
        const uid = url.pathname.split('/')[2];
        const token = url.pathname.split('/')[3];
        const inviteId = url.pathname.split('/')[4];
        const email = url.searchParams.get('email');
        if (!uid && !token && !inviteId && !email) {
            window.location.href = '/';
        }
        if (uid && token && !inviteId) {
            setUid(uid);
            setToken(token);
            setEmail(email);
        } else if (uid && token && inviteId) {
            setUid(uid);
            setEmail(email);
            setToken(token);
            setInviteId(inviteId);
        }
    }, [navigate]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        setErrorText('');

        if (password !== confirmPassword) {
            setErrorText('Passwords do not match');
            return;
        }

        if (password.length < 8) {
            setErrorText('Password should be at least 8 characters long');
            return;
        }

        if (!/[A-Z]/.test(password)) {
            setErrorText('Password should include at least one uppercase letter');
            return;
        }

        if (!/[a-z]/.test(password)) {
            setErrorText('Password should include at least one lowercase letter');
            return;
        }

        if (!/[0-9]/.test(password)) {
            setErrorText('Password should include at least one number');
            return;
        }

        if (!/[!@#$%^&*]/.test(password)) {
            setErrorText('Password should include at least one special character (!@#$%^&*)');
            return;
        }

        try {
            const data: PartialConnectData = {
                email: email,
                token: token,
                uid: uid,
                inviteId: inviteId,
                password: password,
                repeatpassword: confirmPassword
            };
            partialConnect(data);
        } catch (error: unknown) {
            console.error('Error setting password', error);
            setErrorText('An error occurred while setting the password');
        }
    };

    useEffect(() => {
        if (passwordResetEmailSent === PartialConnectStatus.SENT) {
            setSuccessText('Password set successfully. You will be redirected in a few seconds');
            setTimeout(() => {
                navigate('/download');
            }, 5000);
        } else if (passwordResetEmailSent === PartialConnectStatus.ERROR) {
            setErrorText('An error occurred while setting the password. Please try again.');
        }
    }, [passwordResetEmailSent]);


    return (
        <div className='page'>
            <img
                className="logo"
                height="100px"
                width={"auto"}
                src={logo}
                alt="logo"
                onClick={() => {
                    navigate("/");
                }}
            />
            {
                isInvite ?
                    <>
                        <h1>Welcome to Kowroo!</h1>
                        <p>Set a password to access your account</p>
                    </>
                    : <h1>Reset your password</h1>
            }

            <form className='form'>
                <label htmlFor='email' className='label'>Email</label>
                <input type='text' id='email' name='email' value={email} className='input readOnly' readOnly />

                <label htmlFor='password' className='label'>Password</label>
                <input type={showPassword ? 'text' : 'password'} id='password' name='password' className='input' value={password}
                    onChange={(e) => {
                        const password = e.target.value;
                        setPassword(password);

                        const suggestions = [];
                        if (password.length < 8) {
                            suggestions.push('Password should be at least 8 characters long');
                        }
                        if (!/[A-Z]/.test(password)) {
                            suggestions.push('Password should include at least one uppercase letter');
                        }
                        if (!/[a-z]/.test(password)) {
                            suggestions.push('Password should include at least one lowercase letter');
                        }
                        if (!/[0-9]/.test(password)) {
                            suggestions.push('Password should include at least one number');
                        }
                        if (!/[!@#$%^&*]/.test(password)) {
                            suggestions.push('Password should include at least one special character (!@#$%^&*)');
                        }

                        setSuggestion(suggestions.length > 0 ? suggestions.join('. ') : '');
                    }} />

                {
                    suggestion && <div className='suggestion'>{
                        suggestion.split('. ').map((s, i) => <div key={i}> - {s}</div>)
                    }</div>
                }

                <label htmlFor='confirm-password' className='label'>Confirm Password</label>
                <input type={showPassword ? 'text' : 'password'} id='confirm-password' name='confirm-password' className='input' value={confirmPassword}
                    onChange={(e) => {
                        setConfirmPassword(e.target.value);
                    }} />

                <div className='toggle-container'>
                    <label htmlFor='show-password'>Show Password</label>
                    <input type='checkbox' id='show-password' checked={showPassword} onChange={() => setShowPassword(!showPassword)} />
                </div>

                {
                    errorText && <div className='error'>{
                        errorText.split('. ').map((s, i) => <div key={i}> - {s}</div>)
                    }</div>
                }

                {
                    successText && <div className='success'>{successText}</div>
                }

                <button type='submit' className='submit'
                    disabled={password === '' || confirmPassword === '' || suggestion !== '' || passwordResetEmailSent === PartialConnectStatus.SENDING}
                    onClick={handleSubmit}>
                    {
                        passwordResetEmailSent === PartialConnectStatus.SENDING
                            ? <CircularProgress size="1.55rem" style={{ color: "white" }} />
                            : 'Set Password'
                    }
                </button>
            </form>
            <br />
            <br />
        </div>
    );
};

export default SetPassword;