import React, { useEffect, PropsWithChildren } from 'react';
import { useUserStore, AdminUser } from '../stores/userStore';
import { useWebSocketStore } from '../stores/websocketStore';
import { useVersionStore } from '../stores/versionStore';
import { websocketService } from '../services/websocketService';
import { UserIncidentAlert } from '../types/data';
import { toast } from 'sonner';
import { useNavigate } from "react-router-dom";

const WebSocketProvider: React.FC<PropsWithChildren> = ({ children }) => {
    // const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { isAuthenticated, setIsChatMinimized, setSelectedUserId } = useUserStore();
    const { isConnected
        // , disconnect 
    } = useWebSocketStore();
    const handleWebSocketMessage = useWebSocketStore((state) => state.handleWebSocketMessage);

    useEffect(() => {
        const onMessageCallback = (data: any) => {
            const managedUsers = (useUserStore.getState().user as AdminUser).managedUsers;
            // if (!managedUsers){
            //     return;
            // }
            switch (data.messageType) {
            case "User-Start-SOS":{
                const UserID = data.data.UserID;
                const sosUser = managedUsers?.filter(managedUser=>managedUser.id===UserID);
                if (sosUser.length > 0) {
                    // enqueueSnackbar(`SOS Started by ${sosUser[0]?.firstName} ${sosUser[0]?.surname}`,{ 
                    //     variant: 'error',
                    //     autoHideDuration:4000
                    // });
                    toast.error(`SOS Started`, {
                        description: `An SOS was started by ${sosUser[0]?.firstName} ${sosUser[0]?.surname}`,
                        duration: 1000 * 900,
                        dismissible: true,
                        closeButton: true,
                        icon: "🆘",
                        position: "bottom-left",
                        action: {
                            label: "View",
                            onClick: () => {
                                navigate(`/alerts`);
                            },
                        },
                    });
                }
                
                break;
            }
            case "Notification-RiskRating":{
                const UserID = data.data.UserID;
                const oldRiskRating = data.data.oldRiskRating;
                const newRiskRating = data.data.newRiskRating;
                const changeStr = data.data.increaseOrDecrease?.length === 0 ? "change" : data.data.increaseOrDecrease ;

                const riskUser = managedUsers?.filter(managedUser=>managedUser.id===UserID);
                if (riskUser.length > 0) {
                    // enqueueSnackbar(`${riskUser[0]?.firstName} ${riskUser[0]?.surname} risk rating has ${changeStr}d from ${oldRiskRating} to ${newRiskRating}`,{ 
                    //     variant: 'warning',
                    //     autoHideDuration:4000
                    // });

                    toast.warning(`Risk Rating Change`, {
                        description: `${riskUser[0]?.firstName} ${riskUser[0]?.surname} risk rating has ${changeStr}d from ${oldRiskRating} to ${newRiskRating}`,
                        duration: 1000 * 900,
                        dismissible: true,
                        closeButton: true,
                        icon: "🚨",
                        position: "bottom-left",
                        action: {
                            label: "View",
                            onClick: () => {
                                navigate(`/company`);
                            },
                        },
                    });
                }
                break;
            }
            case "User-Start-FMH":{
                const UserID = data.data.UserID;
                const fmhUser = managedUsers?.filter(managedUser=>managedUser.id===UserID);
                if (fmhUser.length > 0) {
                    toast.warning(`Follow Me Home`, {
                        description: `${fmhUser[0]?.firstName} ${fmhUser[0]?.surname} has started a Follow Me Home!`,
                        duration: 1000 * 900,
                        dismissible: true,
                        closeButton: true,
                        icon: "⚠️",
                        position: "bottom-left",
                        action: {
                            label: "View",
                            onClick: () => {
                                navigate(`/alerts`);
                            },
                        },
                    });
                }
                break;
            }
            case "User-Stop-FMH":{
                const UserID = data.data.UserID;
                const fmhUser = managedUsers?.filter(managedUser=>managedUser.id===UserID);
                if (fmhUser.length > 0) {

                    toast.success(`Follow Me Home`, {
                        description: `${fmhUser[0]?.firstName} ${fmhUser[0]?.surname} has stopped a Follow Me Home!`,
                        duration: 1000 * 900,
                        dismissible: true,
                        closeButton: true,
                        icon: "⚠️",
                        position: "bottom-left",
                        action: {
                            label: "View",
                            onClick: () => {
                                navigate(`/alerts`);
                            },
                        },
                    });
                }
                break;
            }
            case "User-Close-Checkin":{
                const UserID = data.data.UserID;
                const checkinUser = managedUsers?.filter(managedUser=>managedUser.id===UserID);
                if (checkinUser.length > 0) {
                    toast.success(`Checkin`, {
                        description: `${checkinUser[0]?.firstName} ${checkinUser[0]?.surname} has responded to a checkin!`,
                        duration: 1000 * 900,
                        dismissible: true,
                        closeButton: true,
                        icon: "✅",
                        position: "bottom-left",
                        action: {
                            label: "View",
                            onClick: () => {
                                navigate(`/alerts`);
                            },
                        },
                    });
                }
                break;
            }
            case "User-Send-Chat":{
                const UserID = data.data.UserID;
                const chatUser = managedUsers?.filter(managedUser=>managedUser.id===UserID);
                if (chatUser.length > 0) {

                    toast.info(`Chat`, {
                        description: `${chatUser[0]?.firstName} ${chatUser[0]?.surname} has sent a chat message!`,
                        duration: 1000 * 900,
                        dismissible: true,
                        closeButton: true,
                        icon: "📩",
                        position: "bottom-left",
                        action: {
                            label: "View",
                            onClick: () => {
                                setIsChatMinimized(false);
                                setSelectedUserId(UserID);
                            },
                        },
                    });
                }
                break;
            }
            case "New-User-Incident":{
                const UserID = data.data.UserID;
                const incidentUser = managedUsers?.filter(managedUser=>managedUser.id===UserID);
                if (incidentUser.length > 0) {

                    toast.warning(`Incident`, {
                        description: `${incidentUser[0]?.firstName} ${incidentUser[0]?.surname} has reported a new incident!`,
                        duration: 1000 * 900,
                        dismissible: true,
                        closeButton: true,
                        icon: "🚨",
                        position: "bottom-left",
                        action: {
                            label: "View",
                            onClick: () => {
                                navigate(`/incidents`);
                            },
                        },
                    });
                }
                break;
            }
            case "Send-IncidentNotification":
                if (data.data?.incident?.alerts?.length > 0) {
                    const latestAlert = data.data.incident?.alerts?.reduce((latest, current) => {
                        return new Date(current.created_at) > new Date(latest.created_at) ? current : latest;
                    }, data.data.incident?.alerts[0]) as UserIncidentAlert;  // Initialize with the first alert object

                    
                    const alertUser = managedUsers?.filter(managedUser => managedUser.id === latestAlert.creator_id);
                    console.log("[alertUser]",alertUser);
                    console.log(`"[alertUser]" : ${alertUser[0]?.firstName} ${alertUser[0]?.surname} has alerted you to an incident`);
                    if (alertUser.length > 0) {
                        toast.warning(`Incident Alert`, {
                            description: `${alertUser[0]?.firstName} ${alertUser[0]?.surname} has alerted you to an incident`,
                            duration: 1000 * 900,
                            dismissible: true,
                            closeButton: true,
                            icon: "🚨",
                            position: "bottom-left",
                            action: {
                                label: "View",
                                onClick: () => {
                                    navigate(`/incidents`);
                                },
                            },
                        });
                    }

                } else {
                    console.log("No alerts available.");
                }
                break;
            case "Get-IntelligenceFusionIncidents":
                if (useVersionStore.getState().local.intelligenceFusion > new Date(data.intelligenceFusionIncidentsVersion)) {

                    toast.info(`Incidents`, {
                        description: `New Incidents received!`,
                        duration: 1000 * 900,
                        dismissible: true,
                        closeButton: true,
                        icon: "📡",
                        position: "bottom-left",
                        action: {
                            label: "Ok",
                            onClick: () => {
                                navigate(`/incidents`);
                            },
                        },
                    });
                }
                break;
            default:
                console.log('[notifications]Unknown message type:', data.messageType);
            }
            console.log("[handleWebSocketMessage]");
            console.log(data);
            handleWebSocketMessage(data); // Forward data to the store
        };
        console.log(`[setOnMessageCallback] (${isAuthenticated}) (${isConnected})`);
        if (isAuthenticated && isConnected) {    
            websocketService.setOnMessageCallback(onMessageCallback);
            return () => {
                console.log("[setOnMessageCallback][disconnect]");
            };
        }

    }, [isAuthenticated, isConnected, handleWebSocketMessage]);

    return <>{children}</>;
};

export default WebSocketProvider;