import { formatDateTimeToString } from '../lib/utils';
import { Asset } from '../types/data';
import GenericTable from './GenericTable';
import React, { useState } from 'react';

import { MRT_SortingState } from "material-react-table";

const MapAssetsTable = (props:any) => {
    const { 
        title,
        data,
        onEditClick,
        handleOpenDeleteModal,
        pagination,
        isRefetching,
        isError,
        isLoading,
        setPagination,
        enableRowActions
    } = props;


    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const columns =  [
        {
            accessorKey: "date_created",
            header: "Date Created",
            size:50,
            Cell: ({ row }: { row: { original: Asset } }) => (
                <div>
                    {formatDateTimeToString(row.original.date_created)}
                </div>
            ),
            filterVariant:"date-range"
        },
        {
            accessorKey: "description",
            header: "Description",
            size:50
        },
        {
            accessorKey: "name",
            header: "Asset Name",
            size:50
        },
        {
            accessorKey: "type_name",
            header: "Type Name",
            size:50
        },
    ];
    const AssetsTable = (
        <GenericTable
            title={title}
            columns={columns}
            data={data}
            onEditClick={onEditClick}
            handleOpenDeleteModal={handleOpenDeleteModal}
            sorting={sorting}
            pagination={pagination}
            rowCount={data.length}
            isRefetching={isRefetching}
            isError={isError}
            isLoading={isLoading}
            setPagination={setPagination}
            setSorting={setSorting}
            enableRowActions={enableRowActions}
        />
    ); 

    return AssetsTable;
};

export default MapAssetsTable;