import React from 'react';
import {  Checkbox, Box } from '@mui/material';

interface CheckboxGroupProps {
  displayIntelligenceFusion: boolean;
  setDisplayIntelligenceFusion: React.Dispatch<React.SetStateAction<boolean>>;
  displayUserLocations: boolean;
  setDisplayUserLocations: React.Dispatch<React.SetStateAction<boolean>>;
  displayUserIncidents: boolean;
  setDisplayUserIncidents: React.Dispatch<React.SetStateAction<boolean>>;
  displayAssets: boolean;
  setDisplayAssets: React.Dispatch<React.SetStateAction<boolean>>;
  displayFollowMeHome: boolean;
  setDisplayFollowMeHome: React.Dispatch<React.SetStateAction<boolean>>;
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
    displayIntelligenceFusion,
    setDisplayIntelligenceFusion,
    displayUserLocations,
    setDisplayUserLocations,
    displayUserIncidents,
    setDisplayUserIncidents,
    displayAssets,
    setDisplayAssets,
    displayFollowMeHome,
    setDisplayFollowMeHome
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        switch (name) {
        case 'intelligenceFusion':
            setDisplayIntelligenceFusion(checked);
            break;
        case 'userLocations':
            setDisplayUserLocations(checked);
            break;
        case 'userIncidents':
            setDisplayUserIncidents(checked);
            break;
        case 'assets':
            setDisplayAssets(checked);
            break;
        case 'followMeHome':
            setDisplayFollowMeHome(checked);
            break;
        default:
            break;
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <div key="Assets" style={{display:"flex", alignItems:"center"}}>
                <Checkbox
                    checked={displayAssets}
                    onChange={handleChange}
                    name="assets"
                />
            Assets 
                <div>

                    <img src="buildings.png" alt="Assets Icon" style={{
                        width: "30px",
                        height: "30px",
                        marginLeft: "4px"
                    }}/>
                </div>
            </div>
            <div key="intelligenceFusion" style={{display:"flex", alignItems:"center"}}>
                <Checkbox
                    checked={displayIntelligenceFusion}
                    onChange={handleChange}
                    name="intelligenceFusion"
                />
                Kowroo Incidents
                <div className="circle" style={{ 
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: 'white',
                    border: '4px solid black',
                    marginLeft: "4px"
                }} ></div>
            </div>
            <div key="userIncidents" style={{display:"flex", alignItems:"center"}}>
                <Checkbox
                    checked={displayUserIncidents}
                    onChange={handleChange}
                    name="userIncidents"
                />
            User Incidents 
                <div className="circle" style={{ 
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: 'white',
                    border: '4px solid red',
                    marginLeft: "4px"
                }} ></div>
            </div>
            <div key="userLocations" style={{display:"flex", alignItems:"center"}}>
                <Checkbox
                    checked={displayUserLocations}
                    onChange={handleChange}
                    name="userLocations"
                />
            User Locations 
                <div className="circle" style={{ 
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: 'white',
                    border: '4px solid blue',
                    marginLeft: "4px"
                }} ></div>
            </div>
            <div key="followMeHome" style={{display:"flex", alignItems:"center"}}>
                <Checkbox
                    checked={displayFollowMeHome}
                    onChange={handleChange}
                    name="followMeHome"
                />
            Follow Me Home Locations
                <div className="circle" style={{ 
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: 'white',
                    border: '4px solid orange',
                    marginLeft: "4px"
                }} ></div>
            </div>
        </Box>
    );
};

export default CheckboxGroup;