export const countryAssessmentCountries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas, The",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo, Democratic Republic of",
    "Congo, The Republic of ",
    "Cook Islands",
    "Costa Rica",
    "Cote d'Ivoire",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czech Republic",
    "Djibouti",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "French Polynesia",
    "Gabon",
    "Gambia, The",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guam",
    "Guatemala",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia, Federated States Of",
    "Moldova",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Municipality of Monaco",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Northern Mariana Islands",
    "North Korea",
    "North Macedonia",
    "Norway",
    "Pakistan",
    "Palau",
    "Palestinian Territories",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn Islands",
    "Poland",
    "Portugal",
    "Qatar",
    "Réunion",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Helena, Ascension and Tristan Da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkiye",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Wallis and Futuna Islands",
    "Yemen",
    "Zambia",
    "Zimbabwe",
];
export const impactRatings = [
    {"Unknown": -1},	
    {"Insignificant": 1},
    {"Limited": 2},	
    {"Moderate": 3},		
    {"Major": 4},
    {"Extreme": 5},
];

export const impactRatingsNumberToValue = {
    "-1": "Unknown",	
    "1": "Insignificant",
    "2": "Limited",	
    "3": "Moderate",		
    "4": "Major",
    "5": "Extreme",
};

export const impactRatingsMap = [
    { label: "Unknown", value: -1 },
    { label: "Insignificant", value: 1 },
    { label: "Limited", value: 2 },
    { label: "Moderate", value: 3 },
    { label: "Major", value: 4 },
    { label: "Extreme", value: 5 },
];
  
export const assetTypes = [
    "Land",
    "Machinery",
    "Buildings",
    "Tools",
    "Equipment",
    "Vehicles",
    "Commodities",
];

export const typeNames =  [
    {"category_name": "Air", "type_name": "Aerial Recon"},
    {"category_name": "Air", "type_name": "Air to Air Attack"},
    {"category_name": "Air", "type_name": "Attack Helicopter"},
    {"category_name": "Air", "type_name": "Aviation Incident"},
    {"category_name": "Air", "type_name": "Bomber"},
    {"category_name": "Air", "type_name": "Drone"},
    {"category_name": "Air", "type_name": "Drone Attack"},
    {"category_name": "Air", "type_name": "Fighter Jet"},
    {"category_name": "Air", "type_name": "Plane Diversion"},
    {"category_name": "Air", "type_name": "Surface to Air Fire"},
    {"category_name": "Air", "type_name": "Surface to Air Missile"},
    {"category_name": "Air", "type_name": "Transport Helicopter"},
    {"category_name": "Air", "type_name": "Transport Plane"},
    {"category_name": "Bombing", "type_name": "Bomb Threat"},
    {"category_name": "Bombing", "type_name": "Drone IED"},
    {"category_name": "Bombing", "type_name": "Explosion"},
    {"category_name": "Bombing", "type_name": "Firecracker/Sound Bomb"},
    {"category_name": "Bombing", "type_name": "IED Detonation"},
    {"category_name": "Bombing", "type_name": "IED Find"},
    {"category_name": "Bombing", "type_name": "IED Hoax"},
    {"category_name": "Bombing", "type_name": "Improvised Incendiary Device"},
    {"category_name": "Bombing", "type_name": "Mine/UXO"},
    {"category_name": "Bombing", "type_name": "Under-Vehicle IED"},
    {"category_name": "Criminality", "type_name": "Abduction"},
    {"category_name": "Criminality", "type_name": "Acid Attack"},
    {"category_name": "Criminality", "type_name": "Affray"},
    {"category_name": "Criminality", "type_name": "Armed Robbery"},
    {"category_name": "Criminality", "type_name": "Arrest"},
    {"category_name": "Criminality", "type_name": "Arson"},
    {"category_name": "Criminality", "type_name": "Assault"},
    {"category_name": "Criminality", "type_name": "ATM Robbery"},
    {"category_name": "Criminality", "type_name": "Attempted Murder"},
    {"category_name": "Criminality", "type_name": "Blasphemy"},
    {"category_name": "Criminality", "type_name": "Bribery"},
    {"category_name": "Criminality", "type_name": "Burglary/Theft"},
    {"category_name": "Criminality", "type_name": "Corruption"},
    {"category_name": "Criminality", "type_name": "Drug Possession"},
    {"category_name": "Criminality", "type_name": "Extortion"},
    {"category_name": "Criminality", "type_name": "False Imprisonment"},
    {"category_name": "Criminality", "type_name": "Forgery"},
    {"category_name": "Criminality", "type_name": "Fraud"},
    {"category_name": "Criminality", "type_name": "Gang Activity"},
    {"category_name": "Criminality", "type_name": "Gang Rape"},
    {"category_name": "Criminality", "type_name": "Harassment"},
    {"category_name": "Criminality", "type_name": "Hate Crime"},
    {"category_name": "Criminality", "type_name": "Kidnap"},
    {"category_name": "Criminality", "type_name": "Manslaughter"},
    {"category_name": "Criminality", "type_name": "Money Laundering"},
    {"category_name": "Criminality", "type_name": "Motor Vehicle Offence"},
    {"category_name": "Criminality", "type_name": "Murder"},
    {"category_name": "Criminality", "type_name": "Prison Break"},
    {"category_name": "Criminality", "type_name": "Property Crime"},
    {"category_name": "Criminality", "type_name": "Prostitution"},
    {"category_name": "Criminality", "type_name": "Rape"},
    {"category_name": "Criminality", "type_name": "Sexual Assault"},
    {"category_name": "Criminality", "type_name": "Slavery"},
    {"category_name": "Criminality", "type_name": "Small Arms Fire (Crime)"},
    {"category_name": "Criminality", "type_name": "Stabbing"},
    {"category_name": "Criminality", "type_name": "Trafficking - Agricultural"},
    {"category_name": "Criminality", "type_name": "Trafficking - Cigarettes"},
    {"category_name": "Criminality", "type_name": "Trafficking - Drugs"},
    {"category_name": "Criminality", "type_name": "Trafficking - Fuel"},
    {"category_name": "Criminality", "type_name": "Trafficking - Human"},
    {"category_name": "Criminality", "type_name": "Trafficking - Other"},
    {"category_name": "Criminality", "type_name": "Trafficking - Weapons"},
    {"category_name": "Criminality", "type_name": "Trafficking - Wildlife"},
    {"category_name": "Criminality", "type_name": "Vandalism"},
    {"category_name": "Criminality", "type_name": "Vigilantism"},
    {"category_name": "Criminality", "type_name": "Weapons Factory"},
    {"category_name": "Direct Weapons", "type_name": "Ambush"},
    {"category_name": "Direct Weapons", "type_name": "Anti-Tank Rocket"},
    {"category_name": "Direct Weapons", "type_name": "Armoured Reconnaissance"},
    {"category_name": "Direct Weapons", "type_name": "Assassination"},
    {"category_name": "Direct Weapons", "type_name": "Heavy Weapons"},
    {"category_name": "Direct Weapons", "type_name": "Infantry"},
    {"category_name": "Direct Weapons", "type_name": "Infantry Fighting Vehicle"},
    {"category_name": "Direct Weapons", "type_name": "Precision Small Arms Fire"},
    {"category_name": "Direct Weapons", "type_name": "Skirmish"},
    {"category_name": "Direct Weapons", "type_name": "Small Arms Fire (Conflict)"},
    {"category_name": "Direct Weapons", "type_name": "Tank"},
    {"category_name": "Direct Weapons", "type_name": "Technical"},
    {"category_name": "Direct Weapons", "type_name": "Weapons Cache"},
    {"category_name": "Grenade", "type_name": "Grenade"},
    {"category_name": "Grenade", "type_name": "Rocket Propelled Grenade"},
    {"category_name": "Hazard", "type_name": "Avalanche"},
    {"category_name": "Hazard", "type_name": "Earthquake"},
    {"category_name": "Hazard", "type_name": "Fire"},
    {"category_name": "Hazard", "type_name": "Flood"},
    {"category_name": "Hazard", "type_name": "Hail"},
    {"category_name": "Hazard", "type_name": "Heat/Drought"},
    {"category_name": "Hazard", "type_name": "Hurricane/Cyclone"},
    {"category_name": "Hazard", "type_name": "Landslide"},
    {"category_name": "Hazard", "type_name": "Major Disease/Illness"},
    {"category_name": "Hazard", "type_name": "Minor Disease/Illness"},
    {"category_name": "Hazard", "type_name": "Sandstorm"},
    {"category_name": "Hazard", "type_name": "Sinkhole"},
    {"category_name": "Hazard", "type_name": "Thunderstorm"},
    {"category_name": "Hazard", "type_name": "Tornado"},
    {"category_name": "Hazard", "type_name": "Tsunami"},
    {"category_name": "Hazard", "type_name": "Volcano"},
    {"category_name": "Hazard", "type_name": "Wildfire"},
    {"category_name": "Indirect Weapons", "type_name": "Artillery Point of Impact"},
    {"category_name": "Indirect Weapons", "type_name": "Artillery Point of Origin"},
    {"category_name": "Indirect Weapons", "type_name": "Ballistic Missile"},
    {"category_name": "Indirect Weapons", "type_name": "Cruise Missile"},
    {"category_name": "Indirect Weapons", "type_name": "IDF Point of Impact"},
    {"category_name": "Indirect Weapons", "type_name": "IDF Point of Origin"},
    {"category_name": "Indirect Weapons", "type_name": "MBRL Point of Impact"},
    {"category_name": "Indirect Weapons", "type_name": "MBRL Point of Origin"},
    {"category_name": "Indirect Weapons", "type_name": "Mortar Point of Impact"},
    {"category_name": "Indirect Weapons", "type_name": "Mortar Point of Origin"},
    {"category_name": "Maritime", "type_name": "Aircraft Carrier"},
    {"category_name": "Maritime", "type_name": "Attempted Boarding"},
    {"category_name": "Maritime", "type_name": "Battleship"},
    {"category_name": "Maritime", "type_name": "Hijacking"},
    {"category_name": "Maritime", "type_name": "Illegal Fishing"},
    {"category_name": "Maritime", "type_name": "Maritime Attack"},
    {"category_name": "Maritime", "type_name": "Maritime Incident"},
    {"category_name": "Maritime", "type_name": "Mine"},
    {"category_name": "Maritime", "type_name": "Submarine"},
    {"category_name": "Maritime", "type_name": "Suspicious Sighting/Incident"},
    {"category_name": "Other", "type_name": "Chemical Weapons"},
    {"category_name": "Other", "type_name": "Cyber Attack"},
    {"category_name": "Other", "type_name": "Espionage"},
    {"category_name": "Other", "type_name": "HAZMAT"},
    {"category_name": "Other", "type_name": "Industrial Incident"},
    {"category_name": "Other", "type_name": "Infrastructure Incident"},
    {"category_name": "Other", "type_name": "Migration"},
    {"category_name": "Other", "type_name": "Military Drills"},
    {"category_name": "Other", "type_name": "Other"},
    {"category_name": "Other", "type_name": "Political"},
    {"category_name": "Other", "type_name": "Refugees/Displaced People"},
    {"category_name": "Other", "type_name": "Security Operation"},
    {"category_name": "Other", "type_name": "Special Forces Operation"},
    {"category_name": "Other", "type_name": "Statement"},
    {"category_name": "Other", "type_name": "Threat/Warning"},
    {"category_name": "Other", "type_name": "Travel Disruption"},
    {"category_name": "Other", "type_name": "Troop Movement"},
    {"category_name": "Protest", "type_name": "Demonstration"},
    {"category_name": "Protest", "type_name": "Planned Demonstration"},
    {"category_name": "Protest", "type_name": "Riot"},
    {"category_name": "Protest", "type_name": "Road Blockade"},
    {"category_name": "Protest", "type_name": "Rock Throwing"},
    {"category_name": "Protest", "type_name": "Strike"},
    {"category_name": "Suicide/Complex", "type_name": "Complex Attack"},
    {"category_name": "Suicide/Complex", "type_name": "Mass Murder"},
    {"category_name": "Suicide/Complex", "type_name": "Suicide Bomber"},
    {"category_name": "Suicide/Complex", "type_name": "Vehicle Attack"},
    {"category_name": "Suicide/Complex", "type_name": "Vehicle Bomb"}
];