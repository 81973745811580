import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Alert, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Snackbar } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { useState } from "react";
import { useCallback } from "react";
import { emailRe, termsAndConditions } from "../helpers/constants";
import { registerCustomer } from "../helpers/api";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import parse from 'html-react-parser';
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
const theme = createTheme();

const RegisterForm = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');

  const [termsClosed, setTermsClosed] = useState(true);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [notifOpen, setNotifOpen] = useState(false)
  const [errorOpen, setErrorOpen] = useState(false)

  const [phoneNumber, setPhoneNumber] = useState('');
  const handleClose = () => {
    setTermsClosed(true);
  };

  const handleCloseNotif = () => {
    setNotifOpen(false)
  }

  const handleCloseError = () => {
    setErrorOpen(false)
  }

  const handleOpen = () => {
    setTermsClosed(false);
  };
  // const passwordStrength = useMemo(() => {
  //   const strengths = ['Very weak', 'Weak', 'Fair', 'Good', 'Strong'];
  //   const str = strengths[zxcvbn(password).score];
  //   let colorClass = '';
  //   if (str === 'Weak' || str === 'Very weak') {
  //     colorClass = `${classes.passwordWeak}`;
  //   } else if (str === 'Fair') {
  //     colorClass = `${classes.passwordFair}`;
  //   } else if (str === 'Good') {
  //     colorClass = `${classes.passwordGood}`;
  //   } else if (str === 'Strong') {
  //     colorClass = `${classes.passwordStrong}`;
  //   }
  //   return <span className={`${classes.passwordStrength} ` + colorClass}>{strengths[zxcvbn(password).score]}</span>;
  // }, [password, classes.passwordFair, classes.passwordWeak, classes.passwordGood, classes.passwordStrong, classes.passwordStrength]);

  const handleChange = (e: string) => {
    // if (matchIsValidTel(e)) {
    setPhoneNumber(e)
    // }
  }

  const navigate = useNavigate();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLoadingButton(true);
    if (emailRe.test(email) && firstName && surname && termsAccepted && repeatPassword === password) {
      // const pw = randomStringGen();
      // TODO fetch company name from right place
      const companyName = "Test"
      const res = await registerCustomer(companyName, email, password, repeatPassword, firstName, surname, phoneNumber);
      setLoadingButton(false);
      try {
        if (res.status === 201 || res.status === 200) {
          navigate('/sign_in');
        } else if (res.status === 400 && res.data['password']) {
          setErrorText(res.data['password'][0]);
          setErrorOpen(true)
        } else if (res.status === 400 && res.data['email']) {
          setErrorText(res.data['email'][0]);
          setErrorOpen(true)
        } else {
          setErrorText('Unknown error.');
          setErrorOpen(true)
        }
      } catch (err) {
        setErrorText('Fatal error.');
        setErrorOpen(true)
        setLoadingButton(false);
      }
    } else if (!firstName || !surname || !email || !phoneNumber) {
      setErrorText('Please fill in all fields.');
      setSubmitted(true);
      setErrorOpen(true)
    } else if (!emailRe.test(email)) {
      setErrorText('Value entered for email is not a valid email address.');
      setSubmitted(true);
      setErrorOpen(true)
    } else if (repeatPassword !== password) {
      setErrorText('Passwords do not match.');
      setSubmitted(true);
      setErrorOpen(true)
    } else if (!termsAccepted) {
        setErrorText('Please accept the terms and conditions.');
        setErrorOpen(true)
    } else if (!matchIsValidTel(phoneNumber)) {
        setErrorText('Please enter a valid phone number');
        setErrorOpen(true)
    } else {
      setErrorText('Invalid registration.');
      setErrorOpen(true)
      setSubmitted(true);
    }
    setLoadingButton(false)
  }, [email, firstName, surname, termsAccepted, repeatPassword, password, phoneNumber, navigate]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowRepeatPassword = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };
  const handleChangeCheckBox = (event) => {
    setTermsAccepted(event.target.checked);
  };

  // const handleSubmit = (event: React.ChangeEvent<any>) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   console.log({
  //     email: data.get("email"),
  //     password: data.get("password"),
  //   });
  // };

  // const handleLogin = () => {
  //   navigate("/");
  // };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
      <Dialog
        // classes={{ paper: classes.dialogPaper }}
        open={!termsClosed}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Grid>
            <Typography variant="h6">{'Terms & Conditions'}</Typography>
          </Grid>
        </DialogTitle>
        <DialogContent
        // className={classes.tcContent}
        >
          <Grid>
            <Grid>
              <Typography
              // className={classes.tcText}
              >{parse(termsAndConditions)}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" id="closeCookieModal" onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar onClose={handleCloseNotif} open={notifOpen && errorText === ''} autoHideDuration={6000} >
        <Alert severity="success">
          Registration has been successful
        </Alert>
      </Snackbar>
      <Snackbar onClose={handleCloseError} open={errorOpen && errorText !== ''} autoHideDuration={6000} >
        <Alert severity="error">
          {errorText}
        </Alert>
      </Snackbar>

      <Grid alignItems="center" direction="row" justifyContent="center" container  component="main" maxWidth="xs">

        <Grid item width="50%"></Grid>
        <Grid item
          width="50%"
          sx={{
            // width: "50%",
            // paddingLeft: 10,
            marginTop: {sx: 5, md: 10, lg: 5, xl: 10},
            // display: "flex",
            // flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // right: 0
          }}
        >
          <Card elevation={0} sx={{ minWidth: 400 }}>
            <CardContent>
              <Typography align="left" component="h1" variant="h5">
                Register
              </Typography>
              <Typography align="left" variant="subtitle1">
                We are happy to see you consider joining us!
              </Typography>
              <Box
                component="form"
                onSubmit={(e) => {handleSubmit(e)}}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                    // type="text"
                    error={errorText !== '' || (submitted && !email)}
                    // value={username}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  margin="dense"
                  required
                  fullWidth
                  id="user_name"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  error={errorText !== '' || (submitted && !firstName)}
                  // value={username}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  margin="dense"
                  required
                  fullWidth
                  id="first_name"
                  label="First Name"
                  name="firstname"
                  autoComplete="Firstname"
                  // autoFocus
                />
                <TextField
                  error={errorText !== '' || (submitted && !surname)}
                  // value={username}
                  onChange={(e) => {
                    setSurname(e.target.value);
                  }}
                  margin="dense"
                  required
                  fullWidth
                  id="last_name"
                  label="Last Name"
                  name="last name"
                  autoComplete="Lastname"
                  // autoFocus
                />
                <Grid>
                <MuiTelInput autoComplete={"off"} error={errorText.includes("phone number")} sx={{pt: 2}} fullWidth forceCallingCode defaultCountry="IE" value={phoneNumber} onChange={handleChange} />
                </Grid>
                <TextField
                error={errorText !== '' || (submitted && !password)}
                // value={username}
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                InputProps={{
                  // classes: { input: classes.input },
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        // className={classes.input}
                        // tabIndex="6"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>,
                }}
                margin="dense"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  // type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <TextField
                error={errorText !== '' || (submitted && !repeatPassword)}
                // value={username}
                onChange={(e) => {
                  setRepeatPassword(e.target.value);
                }}
                margin="dense"
                  required
                  fullWidth
                  name="password"
                  label="Re-type Password"
                  type={showRepeatPassword ? 'text' : 'password'}
                  id="confirm_password"
                  InputProps={{
                    // classes: { input: classes.input },
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          // className={classes.input}
                          // tabIndex="6"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowRepeatPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>,
                  }}
                />
               <Typography style={{maxWidth: 700}} color="primary">
                          I accept <u onClick={handleOpen}>Terms and Conditions</u>:
                          <Checkbox
                            checked={termsAccepted}
                            onChange={handleChangeCheckBox}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                          </Typography>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  // onClick={handleLogin}
                >
                  {loadingButton ? <CircularProgress size="1.55rem" color="inherit"/> :
                  "Register"}
                </Button>
              </Box>
              <Grid container>
                  <Grid item xs>
                    <Link href="/sign_in" variant="body2">
                      Sign In
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="/forgotpassword" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
            </CardContent>
          </Card>
        </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

RegisterForm.propTypes = {
  // history: PropTypes.object.isRequired,
};

RegisterForm.displayName = 'RegisterForm';
export default RegisterForm;

