
import {combineReducers} from 'redux';
import userReducer, {UserState} from './userReducer';
import mapReducer, {MapState} from './mapReducer';
import socketReducer, {SocketState} from './socketReducer';

export interface RootState {
  socket: SocketState;
  user: UserState;
  mapReducer: MapState;
}

const appReducer = combineReducers({
  socket: socketReducer,
  user: userReducer,
  mapReducer: mapReducer,
});

const rootReducer = (state: RootState, action: any) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer;