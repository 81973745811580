
import React, { useMemo } from "react";

import { Box, IconButton, Typography, Grid } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import globalConfig from "../config.json";

import {
    MaterialReactTable
} from "material-react-table";

const GenericTable = (props: any) => {
    const {
        title,
        columns,
        data,
        onEditClick,
        handleOpenDeleteModal,
        sorting,
        pagination,
        rowCount,
        isRefetching,
        isError,
        isLoading,
        setPagination,
        setSorting,
        showColumnFilters,
        enableRowActions,
        columnVisibility
    } = props;


    const table = useMemo(() => {

        const titleElement = () => {
            if (title !== null) {
                return (
                    <Typography sx={{
                        color: globalConfig["primaryMain"],
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                    }} >
                        {title}
                    </Typography>
                );
            } else {
                return <></>;
            }
        };
        const returnTable = (
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    padding: "10px 20px",
                }}
            >
                <Grid>
                    {titleElement()}
                </Grid>
                <Grid>
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        initialState={{ showColumnFilters: showColumnFilters }}
                        enableBottomToolbar
                        // manualFiltering
                        // manualPagination
                        // manualSorting
                        positionPagination="bottom"
                        muiToolbarAlertBannerProps={
                            isError
                                ? {
                                    color: "error",
                                    children: "Error loading data",
                                }
                                : undefined
                        }
                        onPaginationChange={setPagination}
                        onSortingChange={setSorting}
                        rowCount={rowCount}
                        state={{
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching,
                            sorting,
                            columnVisibility
                        }}
                        enableRowActions={enableRowActions}
                        positionActionsColumn="last"
                        renderRowActions={({ row }) => (
                            <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        onEditClick(row.original);
                                    }}
                                >
                                    <EditIcon sx={{ color: 'green' }}/>
                                </IconButton>
                                <IconButton color="error" onClick={() => handleOpenDeleteModal(row.original)}>
                                    <DeleteForeverIcon />
                                </IconButton>
                            </Box>
                        )}
                        muiTableHeadCellProps={{

                            sx: {
                                color: globalConfig["primaryMain"],
                                flex: '0 0 auto',
                            },
                        }}
                        enableTopToolbar={false}
                    />
                </Grid>
            </div>
        );
        return returnTable;
    }, [
        title,
        rowCount,
        columns,
        data,
        onEditClick,
        handleOpenDeleteModal,
        sorting,
        pagination,
        isRefetching,
        isError,
        isLoading,
        setPagination,
        setSorting,
        enableRowActions
    ]);
    return table;
};

export default GenericTable;