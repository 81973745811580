import styled from '@emotion/styled';
import { tooltipClasses, Grid, TooltipProps, Typography, Tooltip } from '@mui/material';

import React from 'react';


const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 1000,
    //   fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const UserTooltip = (props) => {
  const { users, userRole } = props;
  return <HtmlTooltip
    title={
     <Grid  container direction="row">
      {users.map((user, idx) => {
      return <Grid item key={idx}>
        {users.length > 1 ?
        <b>User: {idx+1}</b> : null}
        <br></br>
        <b>Name: </b>
        <Typography variant="body1" color="inherit">{user.firstName} {user.surname}</Typography>
        <b>Company: </b>
        <Typography variant="body1" color="inherit">{user.companyName} </Typography>
        {userRole !== 'customer' ?
        <Grid>
          <b>Role: </b>
          <Typography variant="body1" color="inherit">{user.role} </Typography>

          <b>Email: </b>
        <Typography variant="body1" color="inherit">{user.email} </Typography>
        <Grid container direction="column" right={0}>
          <Grid item>
            <Typography>
          <b>Risk Rating: {user.risk_rating}</b>
          </Typography>
          </Grid>
          <br></br>
          <Grid item>
            <Typography>
          <b>ID: {user.id}</b>
          </Typography>
          </Grid>
          </Grid>
        </Grid>


    : null}
    {userRole === "customer" && user.role === 'admin' ?
        <Grid>
          <b>Role: </b>
          <Typography variant="body1" color="inherit">{user.role} </Typography>

          <b>Email: </b>
        <Typography variant="body1" color="inherit">{user.email} </Typography>
        </Grid>


    : null}
{users.length > 1 ?
"-------------------------------------------------------" : null}
      </Grid>


      })}
      </Grid>
    }>
    {props.children}
  </HtmlTooltip>
}

export default UserTooltip;