import * as React from 'react';
import { useState, useMemo } from "react";
import {
    Box,
    Drawer,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    IconButton,
} from '@mui/material';
import { MRT_PaginationState } from "material-react-table";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { PhoneUser } from "../stores/userStore";
import { UserIncident, Asset, IntelligenceFusionIncident } from "../types/data";

import { FeatureCollection, Point } from 'geojson';
import MapUsersTable from './MapUserTable';
import MapUserIncidentsTable from './MapUserIncidentsTable';
import MapAssetsTable from './MapAssetsTable';
import MapIntelligenceFusionTable from './MapIntelligenceFusionTable';

interface MapTableDrawerProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    selectedTableDataSource: string;
    setSelectedTableDataSource: (dataSource: string) => void;
    filteredIntelligenceFusionData: FeatureCollection<Point, IntelligenceFusionIncident>
    filteredAssetsData: FeatureCollection<Point, Asset>
    filteredUserIncidentsData: FeatureCollection<Point, UserIncident>
    filteredUserLocationsData: FeatureCollection<Point, PhoneUser>
}

function mapFeatureCollectionToUsers(featureCollection: FeatureCollection<Point, PhoneUser>): PhoneUser[] {
    const userLocationRows = featureCollection.features.map((feature) => {
        const { coordinates } = feature.geometry;
        const {
            id,
            email,
            role,
            firstName,
            surname,
            gender,
            isSOS,
            isFMH,
            checkinOpen,
            riskRating,
            connected,
        } = feature.properties; // Now TypeScript knows these properties exist

        return {
            id,
            email,
            role,
            firstName,
            surname,
            gender,
            isSOS,
            isFMH,
            checkinOpen,
            riskRating,
            connected,
            location: {
                latitude: coordinates[1],
                longitude: coordinates[0],
            },
        } as PhoneUser;
    });
    return userLocationRows;
}


function mapFeatureCollectionToUserIncidents(featureCollection: FeatureCollection<Point, UserIncident>): UserIncident[] {
    return featureCollection.features.map((feature) => {
        const { coordinates } = feature.geometry;
        const {
            id,
            category_name,
            type_name,
            summary,
            location_timezone,
            started_at_timestamp,
            ended_at_timestamp,
            impact_rating,
            impact_short_description,
            impact_long_description,
            reported_at,
            reviewed_at,
            geometry_as_text,
            description,
            creator_id,
            alerts
        } = feature.properties; // Now TypeScript knows these properties exist

        return {
            id,
            category_name,
            type_name,
            summary,
            location_timezone,
            started_at_timestamp,
            ended_at_timestamp,
            impact_rating,
            impact_short_description,
            impact_long_description,
            reported_at,
            reviewed_at,
            geometry_as_text,
            description,
            latitude: coordinates[1],
            longitude: coordinates[0],
            creator_id:creator_id,
            alerts: alerts
        };
    });
}


function mapFeatureCollectionToAssets(featureCollection: FeatureCollection<Point, Asset>): Asset[] {
    return featureCollection.features.map((feature) => {
        const { coordinates } = feature.geometry;
        const {
            id,
            name,
            type_name,
            description,
            date_created,
        } = feature.properties;

        return {
            id,
            name,
            type_name,
            description,
            date_created,
            latitude: coordinates[1],
            longitude: coordinates[0],
        };
    });
}

function mapFeatureCollectionToIntelligenceFusionIncidents(featureCollection: FeatureCollection<Point, IntelligenceFusionIncident>): IntelligenceFusionIncident[] {
    return featureCollection.features.map((feature) => {
        const { coordinates } = feature.geometry;

        const {
            category_name,
            description,
            ended_at,
            impact_long_description,
            impact_rating,
            impact_short_description,
            intelligence_fusion_id,
            map_feature_id,
            property_description,
            property_name,
            started_at,
            summary,
            type_name,
        } = feature.properties; // Now TypeScript knows these properties exist

        return {
            category_name,
            description,
            ended_at,
            impact_long_description,
            impact_rating,
            impact_short_description,
            intelligence_fusion_id,
            map_feature_id,
            property_description,
            property_name,
            started_at,
            summary,
            type_name,
            latitude: coordinates[1],
            longitude: coordinates[0],
        };
    });
}

export default function MapTableDrawer({
    open,
    setOpen,
    selectedTableDataSource,
    setSelectedTableDataSource,
    filteredIntelligenceFusionData,
    filteredAssetsData,
    filteredUserIncidentsData,
    filteredUserLocationsData
}: MapTableDrawerProps) {
    const memoizedFilteredUserLocationsData = useMemo(() => {
        return mapFeatureCollectionToUsers(filteredUserLocationsData);
    }, [filteredUserLocationsData]);

    const memoizedFilteredUserIncidentsData = useMemo(() => {
        return mapFeatureCollectionToUserIncidents(filteredUserIncidentsData);
    }, [filteredUserIncidentsData]);

    const memoizedFilteredAssetsData = useMemo(() => {
        return mapFeatureCollectionToAssets(filteredAssetsData);
    }, [filteredAssetsData]);



    const memoizedFilteredIntelligenceFusionData = useMemo(() => {
        return mapFeatureCollectionToIntelligenceFusionIncidents(filteredIntelligenceFusionData);
    }, [filteredIntelligenceFusionData]);

    const [companyPagination, setCompanyPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 5,
    });
    // const [companyIsRefetching, setCompanyIsRefetching] = useState(false);
    // const [companyIsError, setCompanyIsError] = useState(false);
    // const [companyIsLoading, setCompanyIsLoading] = useState(false);

    const companyIsRefetching = false;
    const companyIsError = false;
    const companyIsLoading = false;

    const toggleDrawer = (open: boolean) => () => {
        setOpen(open);
    };

    const handleChangeDataType = (event: SelectChangeEvent<string>) => {
        setSelectedTableDataSource(event.target.value);
    };

    const filteredTable = () => {
        let selectedTable = (<div>
            Filters do not match
        </div>);
        if (selectedTableDataSource === "userLocations") {
            selectedTable = (
                <MapUsersTable
                    title={"Users"}
                    data={memoizedFilteredUserLocationsData}
                    pagination={companyPagination}
                    isRefetching={companyIsRefetching}
                    isError={companyIsError}
                    isLoading={companyIsLoading}
                    setPagination={setCompanyPagination}
                />
            );
        } else if (selectedTableDataSource === "userIncidents") {
            selectedTable = (
                <MapUserIncidentsTable
                    title={"User Incidents"}
                    data={memoizedFilteredUserIncidentsData}
                    pagination={companyPagination}
                    isRefetching={companyIsRefetching}
                    isError={companyIsError}
                    isLoading={companyIsLoading}
                    setPagination={setCompanyPagination}
                />
            );
        } else if (selectedTableDataSource === "assets") {
            selectedTable = (
                <MapAssetsTable
                    title={"User Incidents"}
                    data={memoizedFilteredAssetsData}
                    pagination={companyPagination}
                    isRefetching={companyIsRefetching}
                    isError={companyIsError}
                    isLoading={companyIsLoading}
                    setPagination={setCompanyPagination}
                />
            );
        } else if (selectedTableDataSource === "intelligenceFusion") {
            selectedTable = (
                <MapIntelligenceFusionTable
                    title={"Kowroo Incidents"}
                    data={memoizedFilteredIntelligenceFusionData}
                    pagination={companyPagination}
                    isRefetching={companyIsRefetching}
                    isError={companyIsError}
                    isLoading={companyIsLoading}
                    setPagination={setCompanyPagination}
                />
            );
        }

        return selectedTable;
    };

    return (
        <div>
            {(['left', 'right', 'top', 'bottom'] as const).map((anchor) => (
                <React.Fragment key={anchor}>
                    <Drawer
                        anchor="bottom"
                        open={open}
                        onClose={toggleDrawer(false)}
                        ModalProps={{
                            sx: {
                                '& .MuiBackdrop-root': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.01)', 
                                },
                            },
                        }}
                    >
                        <Box sx={{ width: 'auto', p: 4 }} role="presentation">
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={10}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            Data Type
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedTableDataSource}
                                            label="Data Type"
                                            onChange={handleChangeDataType}
                                        >
                                            <MenuItem value={"intelligenceFusion"}>Kowroo Incidents</MenuItem>
                                            <MenuItem value={"userIncidents"}>User Incidents</MenuItem>
                                            <MenuItem value={"assets"}>Company Assets</MenuItem>
                                            <MenuItem value={"userLocations"}>User Locations</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2} container justifyContent="flex-end">
                                    <IconButton onClick={toggleDrawer(false)}>
                                        <CloseFullscreenIcon sx={{ colorSecondary: "black" }} />
                                    </IconButton>
                                </Grid>
                                <br />
                                {filteredTable()}
                            </Grid>
                        </Box>
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
