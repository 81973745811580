import { Grid, Typography } from "@mui/material";
import React from "react";
// import GaugeChart from 'react-gauge-chart'
const Risk_Rating = () => {
  return <Grid  container direction="row">
    <Grid
      // spacing={0}
      item
      // direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{margin: 5, width: 1000}}
    >
    <Typography component="h1" variant="h5">
      Risk Rating
    </Typography>
      <Grid  justifyContent={"center"} alignItems={"center"} sx={{width: "150vh"}}>
          <Grid item>
          {/* <GaugeChart id="gauge-chart5"
            nrOfLevels={420}
            // width={500}
            // height={1000}
            textColor={"black"}
            arcsLength={[0.3, 0.5, 0.2]}
            colors={['#5BE12C', '#F5CD19', '#EA4228']}
            percent={0.37}
            arcPadding={0.02}
          /> */}
          </Grid>
      </Grid>
    </Grid>
  </Grid>
}

export default Risk_Rating;
