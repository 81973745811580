import React, { useState } from "react";
import Grid from "@mui/material/Grid";

import { countries } from "../helpers/constants";
// import { profile } from "console";
import { customDropdown } from "../components/CustomDropdown";
import { connect } from "react-redux";
import { Typography } from "@mui/material";


const Country_Assessment = (props) => {
  // const {profile} = props;
  const [country, setCountry] = useState({value: '', code: ''});
  return (
    <Grid container direction="column" width={"1000px"}className="country-assessment">
      <h3>
        <i>Country Assessments</i>
      </h3>

      <Grid item spacing={2}>
        <Grid item xs={3}>
          <h3>
            <i>List of countries</i>
          </h3>
          <Grid sx={{ width: "100%"}}>
          {customDropdown(countries, "Country Name", country, setCountry, [])}
          </Grid>
        </Grid>
        <Grid item >
          {/* <Item></Item> */}

        </Grid>

      </Grid>
      <Grid item>
        {country.value ?
      <Grid container direction="row" spacing={2}><Grid item><Typography variant="h5">{country.value} </Typography></Grid><Grid item>  <Typography> {country.code} </Typography></Grid></Grid> : null}

      </Grid>
    </Grid>
  );
}


const mapStateToProps = ({user}) => ({
  loading: user.loading,
  profile: user.profile,
  profileId: user.profileId,
});
export default connect(mapStateToProps)(Country_Assessment);