import React, {useState} from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import {connect} from 'react-redux';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Card, CircularProgress, IconButton, InputAdornment } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  useLocation,
  useParams
} from "react-router-dom";


// import {connect} from 'redux';
import {
  login, accountActivation, getMyUser, searchUsers
} from '../helpers/api';
import { useEffect } from "react";
import { useCallback } from "react";
import {emailRe} from '../helpers/constants';
import {
  setAccessToken,
  setRefreshToken,
  setEmail,
  setStaff,
  setCompanyName,
  set2FAStatus,
  setRole,
  setIsLoggedIn,
  setLoading,
  setId,
} from '../actions/userActions';
import PropTypes from 'prop-types';
import cookie from 'js-cookie';
import { Visibility, VisibilityOff } from "@mui/icons-material";
const theme = createTheme();

const SignIn = (props) => {

  const navigate = useNavigate();

  const { dispatch, loading} = props;
  const [email, setEmailInput] = useState('');
  const [password, setPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [infoText, setInfoText] = useState('');
  const [loadingButton, setLoadingButton] = useState(true);
  const [showPassword, setShowPassword] = useState(false);


  useEffect(() => {
        if (loading) {
    const uid = window.location.href.split('/')[4];
    const tokenEmail = window.location.href.split('/')[5];
    let token;
    if (tokenEmail) {
      token = tokenEmail.split('?')[0];
      setEmailInput(tokenEmail.split('?')[1].split('=')[1]);
    }
    if (uid && token) {
      (async () => {
        const res = await accountActivation(uid, token);
        try {
          if (res.status === 204) {
            setInfoText('Your account has now been verified. You can now log in.');
          } else if (res.status === 403) {
            setErrorText('Account already verified');
          } else {
            setErrorText('Unknown error');
          }
        } catch (err) {
          setErrorText('Fatal error');
        }
      })();
    } else if (props.location && props.location.search) {
      const param = props.location.search.substring(1).split('=');
      if (param[0] === 'reset' && Boolean(param[1].split('&')[0])) {
        setInfoText('Password Successfully Reset');
        setEmailInput(param[2]);
      } else if (param[0] === 'set' && Boolean(param[1].split('&')[0])) {
        setInfoText('Your password has been successfully set up. You can now use your credentials to log in.');
        setEmailInput(param[2]);
      }
    }
  }
  setLoadingButton(false)
  }, [props, loading]);

  const handleSubmit = useCallback(async (e) => {

    e.preventDefault();
    setLoadingButton(true);
    if (emailRe.test(email) && password !== '') {

      const res = await login(email, password);
      try {
        if (res.status === 200) {
          dispatch(setAccessToken(res.data.access_token));
          dispatch(setRefreshToken(res.data.refresh_token));
          cookie.set('refresh_token', res.data.refresh_token);
          cookie.set('access_token', res.data.access_token);
          const res2 = await getMyUser();
          if (res2.status === 200) {
            dispatch(setEmail(res2.data.email));
            dispatch(setLoading(true));
            dispatch(setRole(res2.data.role));
            dispatch(setCompanyName(res2.data.companyName));
            dispatch(setId(res2.data.id));
            dispatch(set2FAStatus(res2.data.is_two_factor_enabled));
            dispatch(setStaff(res2.data.is_staff));
            cookie.set('is_logged_in', "true");
            dispatch(setIsLoggedIn(true));
            console.log("option1 ")
            if (res.data.role === 'admin') {
              const response = await searchUsers("", "", "", "", ""); 
              dispatch({type: "SET_INVITED_USERS", payload: response.data.results})
              dispatch({type: "SET_CHAT_USERS", payload: response.data.results})
              
              navigate('/home');
            } else if (res.data.role === 'kowroo_admin') {
              navigate('/home');
            } else if (res.data.role === 'customer') {
              navigate('/home');
            }
          } else if (res2.status === 403) {
          } else {
            setErrorText('Unknown error');
          }
        } else if (res.status === 400) {
          if (res.data.error_description) {
            setErrorText(res.data.error_description);
          } else if (res.data.error !== 'invalid_grant') {
            setErrorText(res.data.error);
          } else {
            setErrorText('Please verify your email address');
          }
        } else if (res.status === 404) {
          setErrorText('No account exists with these credentials');
        } else {
          setErrorText('Unknown error');
        }
      } catch (err) {
        setErrorText('Fatal error');
      }
    } else {
      setErrorText('Please enter a valid email address and password');
      setSubmitted(true);
    }
    setLoadingButton(false);
  }, [email, password, navigate, dispatch]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid alignItems="center" direction="row" justifyContent="center" container  component="main" maxWidth="xs">

        <Grid item width="50%"></Grid>
        <Grid item
          width="50%"
          sx={{
            // width: "50%",
            // paddingLeft: 10,
            marginTop: {sx: 5, md: 10, lg: 15, xl: 30},
            // display: "flex",
            // flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // right: 0
          }}
        >
          <Card elevation={0} sx={{ minWidth: 400 }}>
            <CardContent>
              <Typography align="left" component="h1" variant="h5">
                Log in
              </Typography>
              <Typography align="left" variant="subtitle1">
                Get started for free
              </Typography>
              <Typography style={{color: "red"}}>
                {errorText}
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  tabIndex={1}
                  type="email"
                  error={errorText !== '' || (submitted && !email)}
                  value={email}
                  onChange={(e) => {
                    setEmailInput(e.target.value);
                  }}
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                error={errorText !== '' || (submitted && !password)}
                // value={email}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                   // InputLabelProps={{
                //   style: { color: globalConfig['textColor'] },
                // }}
                InputProps={{
                  // classes: { input: classes.input },
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        // tabIndex="6"
                        // className={classes.passwordIcon}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>,
                }}
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="current-password"
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  // onClick={handleLogin}
                >
            {loadingButton ? <CircularProgress size="1.55rem" color="inherit"/> :
                  "Sign In"}
                </Button>
                <Grid container>
                  <Grid item>
                    <Link href="/forgotpassword" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

SignIn.propTypes = {
  dispatch: PropTypes.func.isRequired,

};

SignIn.displayName = 'SignIn';

const mapStateToProps = ({user}) => ({
  loading: user.loading,
});

export default connect(mapStateToProps)(SignIn);
