import React, { useState }from 'react';
import GenericTable from './GenericTable';
import { Asset } from '../types/data';

import { MRT_ColumnDef, MRT_SortingState } from "material-react-table";
import { formatDateTimeToString } from '../lib/utils';

interface AssetsTableProps {
    title: string;
    data: Asset[];
    onEditClick: (asset: Asset) => void;
    handleOpenDeleteModal: (asset: Asset) => void;
    pagination: {
        pageIndex: number;
        pageSize: number;
    };
    isRefetching: boolean;
    isError: boolean;
    isLoading: boolean;
    setPagination: (pagination: { pageIndex: number; pageSize: number }) => void;
}

const AssetsTable = (props: AssetsTableProps) => {
    
    const { 
        title,
        data,
        onEditClick,
        handleOpenDeleteModal,
        pagination,
        isRefetching,
        isError,
        isLoading,
        setPagination,
    } = props;
    
    const [sorting, setSorting] = useState<MRT_SortingState>([
        { id: 'date_created', desc: true }
    ]);

    const columns: MRT_ColumnDef<Asset>[] = [
        {
            accessorKey: "date_created",
            header: "Date Created",
            size:50,
            Cell: ({ row }: { row: { original: Asset } }) => (
                <div>
                    {formatDateTimeToString(row.original.date_created)}
                </div>
            ),
            filterVariant:"date-range"
        },
        {
            accessorKey: "description",
            header: "Description",
            size:50
        },
        {
            accessorKey: "name",
            header: "Asset Name",
            size:50
        },
        {
            accessorKey: "type_name",
            header: "Type Name",
            size:50
        },
    ];

    const showColumnFilters = false;
    const assetsTable = (
        <GenericTable
            title={title}
            columns={columns}
            data={data}
            onEditClick={onEditClick}
            handleOpenDeleteModal={handleOpenDeleteModal}
            sorting={sorting}
            pagination={pagination}
            rowCount={data.length}
            isRefetching={isRefetching}
            isError={isError}
            isLoading={isLoading}
            showColumnFilters={showColumnFilters}
            setPagination={setPagination}
            setSorting={setSorting}
            enableRowActions={true}
        />
    ); 

    return assetsTable;
};

export default AssetsTable;