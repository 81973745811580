import React, {useState} from 'react'
import {Grid, Typography, TextField, Autocomplete} from '@mui/material'
const TripApproval = (props) => {
    const {connections} = props;
    const [travelReason, setTravelReason] = useState('')
    return <Grid container direction="column">
        <Grid container direction="row" spacing={2}>
        <Grid item>
            <Typography variant="h6" sx={{minWidth: 200}}>Request for</Typography>
            </Grid>
            <Grid item>
            <Autocomplete
             renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  variant="outlined"
                  label="Choose option"
                //   placeholder="Search or select user email"
                  // onChange={handleUsersChange}
                  // label="Users"
                  fullWidth
                  sx={{ paddingBottom: 2, minWidth: 300 }}
                />)}
                options={['Approval', 'Approval in principle']}
            ></Autocomplete>
            </Grid>
        </Grid>
        <Grid container direction="row" spacing={2}>
        <Grid item>
            <Typography variant="h6" sx={{minWidth: 200}}>Reason for Travel</Typography>
            </Grid>
            <Grid item>

                <TextField
                  required
                //   value={travelReason}
                  onChange={(e) => {setTravelReason(e.target.value)}}
                  multiline={true}
                  variant="outlined"
                  label="Please fill in travel reason info"
                //   placeholder="Search or select user email"
                  // onChange={handleUsersChange}
                  // label="Users"
                  fullWidth
                  sx={{ paddingBottom: 2, minWidth: 300  }}
                />
            </Grid>
        </Grid>
        <Grid container direction="row" spacing={2}>
        <Grid item>
            <Typography variant="h6" sx={{maxWidth: 350}}>Has travel been approved by line  manager
            , and in accordance with privacy</Typography>
            </Grid>
            <Grid item>

        <Autocomplete
             renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  variant="outlined"
                  label="Choose option"
                //   placeholder="Search or select user email"
                  // onChange={handleUsersChange}
                  // label="Users"
                  fullWidth
                  sx={{ paddingBottom: 2, minWidth: 150 }}
                />)}
                options={['Yes', 'No, this is approval in principle', 'No']}
            ></Autocomplete>

            </Grid>
        </Grid>
        <br></br>
        <Typography variant="h5" sx={{width : 200}}>Travel plans</Typography>
        <Grid container direction="row" spacing={2}>


        </Grid>
        {connections.map((departure, idx) => {

            })}
    </Grid>
}

export default TripApproval