
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, IconButton, Typography, Grid } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import MaterialReactTable, {
    MRT_ColumnDef,
    MRT_ColumnFiltersState,
    MRT_PaginationState,
    MRT_SortingState,
} from "material-react-table";

const DisplayTable = (props:any) => {
    const { 
        title,
        columns,
        data,
        onEditClick,
        handleOpenDeleteModal,
        columnFilters,
        globalFilter,
        sorting,
        pagination,
        rowCount,
        isRefetching,
        isError,
        isLoading,
        setColumnFilters,
        setGlobalFilter,
        setPagination,
        setSorting,
        enableRowActions
    } = props

    // console.log("columns", columns)
    // console.log("DISPLAY TABLE!!!!")
    // console.log("data", data)
    // console.log("onEditClick", onEditClick)
    // console.log("handleOpenDeleteModal", handleOpenDeleteModal)
    // console.log("columnFilters", columnFilters)
    // console.log("globalFilter", globalFilter)
    // console.log("sorting", sorting)
    // console.log("pagination", pagination)
    // console.log("rowCount", rowCount)
    // console.log("isRefetching", isRefetching)
    // console.log("isError", isError)
    // console.log("isLoading", isLoading)
    // console.log("setColumnFilters", setColumnFilters)
    // console.log("setGlobalFilter", setGlobalFilter)
    // console.log("setPagination", setPagination)
    // console.log("setSorting", setSorting)

    const titleElement = () => {
        if (title !== null) {
            return (
                <Typography fontSize={24}>
                    {title}
                </Typography>
            )
        } else {
            return <></>
        }
    }

    const table = useMemo(() => {
        const returnTable = (
            <div>
                <Grid>
                    {titleElement()}
                </Grid>
                <Grid>
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        initialState={{ showColumnFilters: false }}
                        enableBottomToolbar
                        // manualFiltering
                        // manualPagination
                        // manualSorting
                        positionPagination="bottom"
                        muiToolbarAlertBannerProps={
                            isError
                            ? {
                                color: "error",
                                children: "Error loading data",
                                }
                            : undefined
                        }
                        onColumnFiltersChange={setColumnFilters}
                        onGlobalFilterChange={setGlobalFilter}
                        onPaginationChange={setPagination}
                        onSortingChange={setSorting}
                        // rowCount={rowCount}
                        state={{
                            columnFilters,
                            globalFilter,
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching,
                            sorting,
                        }}
                        enableRowActions={enableRowActions}
                        positionActionsColumn="last"
                        renderRowActions={({ row, table }) => (
                            <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
                            <IconButton
                                color="primary"
                                onClick={() => {
                                onEditClick(row.original);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton color="error" onClick={() => handleOpenDeleteModal(row)}>
                                <DeleteForeverIcon />
                            </IconButton>
                            </Box>
                        )}
                        muiTableHeadCellProps={{
                        //simple styling with the `sx` prop, works just like a style prop in this example
                        sx: {
                            // fontWeight: 'normal',
                            // fontSize: '14px',
                            backgroundColor: "#005A31",
                            color: "white",
                            flex: '0 0 auto',
                        },
                        }}
                        enableTopToolbar={false}
                    />
                </Grid>
            </div>
            );
        return returnTable
    }, [
        columnFilters,
        columns,
        data,
        globalFilter,
        isError,
        isLoading,
        isRefetching,
        onEditClick,
        pagination,
        rowCount,
        sorting,
    ]);
    return table
}

export default DisplayTable;