import React, { useMemo, useRef, useEffect } from 'react';
import { ChatMessage } from "../types/data";
import globalConfig from "../config.json";

const Chat = ({ messages, chatUserId }: {
    messages: ChatMessage[];
    chatUserId: number | null;
}) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [messages]);

    function parseTime(dateTime: Date): string {
        const now = new Date();
        const difference = now.getTime() - dateTime.getTime();
        const differenceInMinutes = Math.floor(difference / 60000); // convert ms to minutes
    
        if (differenceInMinutes < 1) {
            return 'Just now';
        } else if (differenceInMinutes < 60) {
            return `${differenceInMinutes}m ago`;
        } else if (differenceInMinutes < 1440) { // less than 24 hours
            const differenceInHours = Math.floor(differenceInMinutes / 60);
            return `${differenceInHours}h ago`;
        } else {
            const hours = dateTime.getHours().toString().padStart(2, '0');
            const minutes = dateTime.getMinutes().toString().padStart(2, '0');
            const day = dateTime.getDate().toString().padStart(2, '0');
            const month = (dateTime.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
            const year = dateTime.getFullYear();
            return `${hours}:${minutes} ${day}/${month}/${year}`;
        }
    }

    const trackMessages = useMemo(() => {
        return [...messages].slice().reverse();
    }, [messages]);

    return (
        <div
            ref={containerRef}
            style={{
                padding: '16px', maxWidth: '600px', margin: '0 auto',
                display: 'flex', flexDirection: 'column-reverse',
                height: '100%', overflowY: 'auto',
            }}
        >
            {trackMessages.map((msg) => {
                const isSentByCurrentUser = msg.sender !== chatUserId;
                return (
                    <div
                        key={msg.id}
                        style={{
                            display: 'flex',
                            justifyContent: isSentByCurrentUser ? 'flex-end' : 'flex-start',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: isSentByCurrentUser ? 'flex-end' : 'flex-start',
                                width: '100%',
                            }}
                        >
                            <div
                                style={{
                                    padding: '4px 12px',
                                    borderRadius: '25px',
                                    maxWidth: '75%',
                                    backgroundColor: isSentByCurrentUser ? globalConfig['primaryMain'] : '#F1F0F0',
                                    color: isSentByCurrentUser ? 'white' : 'black',
                                    wordBreak: 'break-word',
                                }}
                            >
                                <div
                                    style={{
                                        fontSize: '14px',
                                        textAlign: isSentByCurrentUser ? 'right' : 'left',
                                    }}
                                >
                                    {msg.message_text}
                                </div>
                            </div>

                            <div
                                style={{
                                    fontSize: '12px',
                                    color: '#8e8e8e',
                                    textAlign: isSentByCurrentUser ? 'right' : 'left',
                                    marginBottom: '4px',
                                }}
                            >
                                {parseTime(new Date(msg.created_at))}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>

    );
};

export default Chat;
