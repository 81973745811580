import cookie from "js-cookie";

const refreshToken = cookie.get("refresh_token") ? cookie.get("refresh_token") : "";
const accessToken = cookie.get("access_token") ? cookie.get("access_token") : "";

export interface UserState {
  access_token: string,
  refresh_token: string,
  firstName: string,
  surname: string,
  currentPage: number,
  is_staff: boolean,
  role: string,
  companyName: string,
  email: string,
  secret: string,
  id: number,
  is_two_factor_enabled: boolean,
  is_logged_in: boolean,
  upload_ops: any[],
  loading: boolean,
  pages: any[],
  routes: any[],
  profile: any,
  newAlerts: any[],
  profileId: number,
  position: any,
}

const state = (
  state: UserState = {
    access_token: accessToken,
    refresh_token: refreshToken,
    currentPage: -1,

    firstName: "",
    surname: "",
    is_staff: false,
    role: "",
    companyName: "",
    email: "",
    secret: "",
    id: null,
    is_two_factor_enabled: false,
    is_logged_in: false,
    upload_ops: [],
    loading: true,
    pages: [],
    routes: [],
    profile: {},
    newAlerts: [],
    profileId: null,
    position: { latitude: null, longitude: null },
  },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case "SET_CURRENT_PAGE":
      return {
        ...state,
        currentPage: action.payload,
      };
    case "SET_PAGES":
      return {
        ...state,
        pages: action.payload,
      };
    case "SET_ROUTES":
      return {
        ...state,
        routes: action.payload,
      };
    case "SET_POSITION":
      return {
        ...state,
        position: action.payload,
      };
    case "SET_PROFILE":
      return {
        ...state,
        profile: action.payload,
      };
    case "SET_PROFILE_ID":
      return {
        ...state,
        profileId: action.payload,
      };
    case "SET_ACCESS_TOKEN":
      return {
        ...state,
        access_token: action.payload,
      };
    case "SET_FIRST_NAME":
      return {
        ...state,
        firstName: action.payload,
      };
    case "SET_SURNAME":
      return {
        ...state,
        surname: action.payload,
      };

    case "DELETE_ACCESS_TOKEN":
      return {
        ...state,
        access_token: "",
      };
    case "SET_REFRESH_TOKEN":
      return {
        ...state,
        refresh_token: action.payload,
      };
    case "DELETE_REFRESH_TOKEN":
      return {
        ...state,
        refresh_token: "",
      };
    case "SET_EMAIL":
      return {
        ...state,
        email: action.payload,
      };
    case "SET_SECRET":
      return {
        ...state,
        secret: action.payload,
      };
    case "DELETE_SECRET":
      return {
        ...state,
        secret: undefined,
      };
    case "DELETE_EMAIL":
      return {
        ...state,
        email: undefined,
      };
    case "SET_STAFF":
      return {
        ...state,
        is_staff: action.payload,
      };
    case "DELETE_STAFF":
      return {
        ...state,
        is_staff: undefined,
      };
    case "SET_ROLE":
      return {
        ...state,
        role: action.payload,
      };
    case "SET_COMPANY_NAME":
      return {
        ...state,
        companyName: action.payload,
      };
    case "DELETE_COMPANY_NAME":
      return {
        ...state,
        companyName: undefined,
      };
    case "SET_ID":
      return {
        ...state,
        id: action.payload,
      };
    case "DELETE_ID":
      return {
        ...state,
        id: undefined,
      };
    case "SET_2FA_STATUS":
      return {
        ...state,
        is_two_factor_enabled: action.payload,
      };
    case "SET_IS_LOGGED_IN":
      return {
        ...state,
        is_logged_in: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SET_NEW_ALERTS":
      return {
        ...state,
        newAlerts: action.payload,
      };
    case "SET_UPLOAD_OPS":
      return {
        ...state,
        upload_ops: action.payload,
      };
    case "SET_FOLLOW_ME_HOME_DATA":
      return{
        ...state,
        follow_me_home_data: action.payload
      }
    default:
      return state;
  }
};

export default state;
