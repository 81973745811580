import React, { useState } from 'react';
import { Chip } from '@mui/material';
import { formatDateTimeToString } from '../lib/utils';
import GenericTable from './GenericTable';
import { IntelligenceFusionIncident } from '../types/data';
import globalConfig from '../config.json';

import {  MRT_ColumnDef, MRT_SortingState } from "material-react-table";

const MapIntelligenceFusionTable = (props:any) => {
    const { 
        title,
        data,
        onEditClick,
        handleOpenDeleteModal,
        pagination,
        isRefetching,
        isError,
        isLoading,
        setPagination,
        enableRowActions
    } = props;

    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const columns: MRT_ColumnDef<IntelligenceFusionIncident>[] = [
        {
            accessorKey: "category_name",
            header: "Category",
            maxSize:2
        },
        {
            accessorKey: "type_name",
            header: "Type",
            maxSize:2
        },
        {
            accessorKey: "summary",
            header: "Summary",
        },
        {
            accessorKey: "started_at",
            header: "Start Date",
            Cell: ({ row }: { row: {original: IntelligenceFusionIncident}}) => {
                return formatDateTimeToString(row.original.started_at);
            }
        },
        {
            accessorKey: "ended_at",
            header: "End Date",
            Cell: ({ row }: { row: {original: IntelligenceFusionIncident}}) => {
                return formatDateTimeToString(row.original.ended_at);
            }
        },
        {
            accessorKey: "impact_rating",
            header: "Impact Rating",
            size: 50,
            Cell: ({ row }: {row: {original: IntelligenceFusionIncident}}) => (
                <div>
                    {
                        row.original.impact_rating === 1 ? (
                            <Chip 
                                label={row.original.impact_short_description}
                                sx={{
                                    fontWeight: 'bold',
                                    color: globalConfig["ratingTextColors"]["1"],
                                    background: globalConfig["ratingBGColors"]["1"],
                                }}
                            />
                        ) : row.original.impact_rating === 2 ? (
                            <Chip 
                                label={row.original.impact_short_description}
                                sx={{
                                    fontWeight: 'bold',
                                    color: globalConfig["ratingTextColors"]["2"],
                                    background: globalConfig["ratingBGColors"]["2"],
                                }}
                            />
                        ) : row.original.impact_rating === 3 ? (
                            <Chip 
                                label={row.original.impact_short_description}
                                sx={{
                                    fontWeight: 'bold',
                                    color: globalConfig["ratingTextColors"]["3"],
                                    background: globalConfig["ratingBGColors"]["3"],
                                }}
                            />
                        ) : row.original.impact_rating === 4 ? (
                            <Chip 
                                label={row.original.impact_short_description}
                                sx={{
                                    fontWeight: 'bold',
                                    color: globalConfig["ratingTextColors"]["4"],
                                    background: globalConfig["ratingBGColors"]["4"],
                                }}
                            />
                        ) : row.original.impact_rating === 5 ? (
                            <Chip 
                                label={row.original.impact_short_description}
                                sx={{
                                    fontWeight: 'bold',
                                    color: globalConfig["ratingTextColors"]["5"],
                                    background: globalConfig["ratingBGColors"]["5"],
                                }}
                            />
                        ) : (
                            <Chip 
                                label={row.original.impact_short_description}
                                sx={{
                                    color:"green",
                                    background:"grey",
                                    fontWeight: 'bold',
                                }}
                            />
                        )
                    }
                </div>
            ),
        },
    ];
    const IntelligenceFusionTable = (
        <GenericTable
            title={title}
            columns={columns}
            data={data}
            onEditClick={onEditClick}
            handleOpenDeleteModal={handleOpenDeleteModal}
            sorting={sorting}
            pagination={pagination}
            rowCount={data.length}
            isRefetching={isRefetching}
            isError={isError}
            isLoading={isLoading}
            setPagination={setPagination}
            setSorting={setSorting}
            enableRowActions={enableRowActions}
        />
    ); 

    return IntelligenceFusionTable;
};

export default MapIntelligenceFusionTable;