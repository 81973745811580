// import * as crypto from 'crypto';
import forge from 'node-forge';

interface EncryptedMessage {
  encryptedAESSecrets: string;
  encryptedDataStr: string;
}

class CryptoService {
    private keyPair: forge.pki.rsa.KeyPair | null = null;
    private serverPublicKey: forge.pki.rsa.PublicKey | null = null;
    private serverPublicKeyPEM: string = "";


    arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        for (let i = 0; i < bytes.byteLength; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    };
  
    base64ToArrayBuffer = (base64: string): ArrayBuffer => {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    };
    encryptAESGCM = async (plaintext: string): Promise<{ ciphertext: string, aesKey: string, IV: string, tag: string }> => {
        const aesKey = crypto.getRandomValues(new Uint8Array(32)); // Generate AES key
        const iv = crypto.getRandomValues(new Uint8Array(12)); // Generate IV
      
        const encoder = new TextEncoder();
        const plaintextBuffer = encoder.encode(plaintext);
      
        const key = await crypto.subtle.importKey(
            'raw',
            aesKey,
            { name: 'AES-GCM' },
            false,
            ['encrypt']
        );
      
        const ciphertextBuffer = await crypto.subtle.encrypt(
            { name: 'AES-GCM', iv },
            key,
            plaintextBuffer
        );
  
        const ciphertext = this.arrayBufferToBase64(ciphertextBuffer);
        const aesKeyBase64 = this.arrayBufferToBase64(aesKey);
        const ivBase64 = this.arrayBufferToBase64(iv);
        return { ciphertext, aesKey: aesKeyBase64, IV: ivBase64, tag: ""};
    };

    encryptAESSecrets = async (publicKeyString: string, aesSecrets: { aesKey: string, IV: string }): Promise<string> => {
        const aesSecretsJSON = JSON.stringify(aesSecrets);
        try {
            const publicKey = forge.pki.publicKeyFromPem(publicKeyString);
            const encryptedAESSecrets = publicKey.encrypt(aesSecretsJSON, 'RSA-OAEP', {
                md: forge.md.sha512.create()
            });
            const encryptedAESSecretsBase64 = forge.util.encode64(encryptedAESSecrets);

            return encryptedAESSecretsBase64;
        } catch (error) {
            console.error("Error encrypting AES secrets:", error);
            return "";
        }
      
    };

    encryptData = async (plaintext: string) =>{
        try {
            const { ciphertext, aesKey, IV } = await this.encryptAESGCM(plaintext);
            const aesSecrets = { aesKey, IV };
            const parsedPublicKey = this.serverPublicKeyPEM;
            const encryptedAESSecretsBase64 = await this.encryptAESSecrets(parsedPublicKey, aesSecrets);
        
            const encryptedData = {
                encryptedAESSecrets:encryptedAESSecretsBase64,
                encryptedDataStr: ciphertext,
                encryptedBy: "phoneUser",
            };
            return encryptedData;
        } catch (error) {
            console.error('Encryption error:', error);
        }
    };

    aesDecrypt = async (ciphertextBase64: string, aesKeyBase64: string, ivBase64: string): Promise<string> => {
        const ciphertext = this.base64ToArrayBuffer(ciphertextBase64);
        const aesKey = this.base64ToArrayBuffer(aesKeyBase64);
        const iv = this.base64ToArrayBuffer(ivBase64);
      
        const key = await crypto.subtle.importKey(
            'raw',
            aesKey,
            { name: 'AES-GCM' },
            false,
            ['decrypt']
        );

        try {
            const decryptedBuffer = await crypto.subtle.decrypt(
                { name: 'AES-GCM', iv },
                key,
                ciphertext
            );

            const decoder = new TextDecoder();
            return decoder.decode(decryptedBuffer);
        } catch (error) {
            console.error('Decryption error:', error);
            throw error;
        }
    };
    decryptData = async(message: EncryptedMessage): Promise<string> => {
        try {
            const encryptedAESSecrets = message.encryptedAESSecrets;
            const encryptedDataStr = message.encryptedDataStr;
            const encryptedData = forge.util.decode64(encryptedAESSecrets);
            const aesSecretsStr = this.keyPair?.privateKey.decrypt(encryptedData, 'RSA-OAEP', {
                md: forge.md.sha512.create()
            });

            if (aesSecretsStr){ 
                const aesSecretsJSON = JSON.parse(aesSecretsStr);
                const IV = aesSecretsJSON.IV;
                const aesKey = aesSecretsJSON.aesKey;
  
                const decryptedText = this.aesDecrypt(encryptedDataStr, aesKey, IV);
                return decryptedText;
            }
        } catch (error) {
            console.error('Encryption error:', error);
        }
        return "";
    };
    generateKeyPair(): void {
        this.keyPair = forge.pki.rsa.generateKeyPair({ bits: 2048, e: 0x10001 });

    }

    setServerPublicKey(publicKeyPem: string): void {
        this.serverPublicKey = forge.pki.publicKeyFromPem(publicKeyPem);
        this.serverPublicKeyPEM = publicKeyPem;
    }

    encrypt(data: string): string {
        if (!this.serverPublicKey) {
            throw new Error('Server public key not set');
        }
        return forge.util.encode64(this.serverPublicKey.encrypt(data, 'RSA-OAEP'));
    }

    decrypt(data: string): string {
        if (!this.keyPair) {
            throw new Error('Key pair not generated');
        }
        return this.keyPair.privateKey.decrypt(forge.util.decode64(data), 'RSA-OAEP');
    }

    getPublicKeyPem(): string {
        if (!this.keyPair) {
            throw new Error('Key pair not generated');
        }
        return forge.pki.publicKeyToPem(this.keyPair.publicKey);
    }


    getServerPublicKey(): forge.pki.rsa.PublicKey | null {
        return this.serverPublicKey;
    }
}

export const cryptoService = new CryptoService();