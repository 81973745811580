
import React, { useMemo } from "react";
import Avatar from '@mui/material/Avatar';
import { useUserStore } from "../stores/userStore";
import { Grid } from "@mui/material";

interface UserIconProps {
    handleClickProfile: (event: React.MouseEvent<HTMLElement>) => void;
}
const UserIcon: React.FC<UserIconProps> = (props) => {
    const { 
        handleClickProfile, 
        isSOS,
        isCheckin,
        color,
        height,
        width
    } = props;
    

    const updatedColor = isCheckin ? 'orange' : isSOS ? 'red' : color
    const { user } = useUserStore((state) => ({
        user: props?.user || state.user,
    }));

    const Icon = useMemo(() => {
        let initials;
        if (user?.firstName){
            initials = user.firstName.length > 0 ?  `${user.firstName?.charAt(0)}${user.surname?.charAt(0)}` : "";
        }
        
        return (
                <Grid className="circular-avatar" onClick={handleClickProfile}>
                    <Avatar sx={{ height: 35, width: 35, bgcolor: updatedColor, animation: isSOS ? 'pulse 1s infinite' : false  }}>{initials}</Avatar>
                </Grid>
        );
    }, [
        handleClickProfile,
        user
    ]);
    return Icon;
};

export default UserIcon;