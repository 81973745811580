import React, { useCallback, useEffect, useState, useMemo } from "react";
import "./topbar.css";
import {
  NotificationsNone,
  Settings,
  MailLock,
  StarBorder,
  ExpandLess,
  ExpandMore,
  LocationOnOutlined,
  Business,
  BusinessCenter,
  Newspaper,
  Summarize,
  FlagCircle,
  NotificationImportant,
  Notifications,
  WarningAmber,
  Assessment
} from "@mui/icons-material";
import GroupIcon from "@mui/icons-material/Group";
import FlightIcon from "@mui/icons-material/Flight";
import Groups2Icon from "@mui/icons-material/Groups2";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ChatIcon from "@mui/icons-material/Chat";
import MenuIcon from "@mui/icons-material/Menu";
import { VariantType, useSnackbar } from "notistack";
import { useNavigate, Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import globalConfig from "../../../config.json";
import { logout } from "../../../helpers/utils";
import {
  Badge,
  CircularProgress,
  Collapse,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Popover,
  Tab,
  Tabs,
  Card, 
  CardContent,
  Button, 
} from "@mui/material";

import UserIcon from "../../UserIcon";
import { useUpdateUser } from "../../../helpers/hooks";
import { connect } from "react-redux";
import {
  setNewAlerts,
  setPages,
  setProfile,
  setProfileId,
  setRoutes,
} from "../../../actions/userActions";
import PropTypes from "prop-types";
import {
  // getNewAlerts,
  getNotifications,
  getUserProfile,
  sendUserLocation,
} from "../../../helpers/api";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers/rootReducer';

const kowrooAdminPages = [
  "Home",
  "Companies",
  "Map",
  "Assessment",
  "News",
  "Report",
  "Risk Appetite",
];

const kowrooAdminRoutes = [
  "/",
  "/companies",
  "/map",
  "/assessment",
  "/news",
  "/reports",
  "/risk_appetite",
];

const adminPages = [
  "Home",
  "Company",
  "Map",
  "Incidents",
  "Assets",
  "Assessment",
  "News",
  "Reports",
  "Risk Appetite",
];
const adminRoutes = [
  "/",
  "/company",
  "/map",
  "/incidents",
  "/assets",
  "/assessment",
  "/news",
  "/reports",
  "/risk_appetite",
];

const customerPages = [
  "Home",
  "Map",
  "Trips",
  "Assessment",
  "News",
  "Reports",
  "Risk Appetite",
];
const customerRoutes = [
  "/",
  "/map",
  "/trips",
  "/assessment",
  "/news",
  "/reports",
  "/risk_appetite",
];

const drawerWidth = 240;

const classes = {
  list: {
    // overflowY: "auto",
    margin: 0,
    padding: 0,
    listStyle: "none",
    height: "100%",
    width: "2000px",
    "::-webkit-scrollbar": {
      width: "0.4em",
    },
    "::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
  },
};


interface NotificationList {
  incidents: any[],
  checkins: any[],
  sos_alerts: any[],
}

const Topbar = (props) => {
  const { loading, role, user, pages, routes, dispatch, newAlerts } = props;
  const [authInitiated, setAuthInitiated] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [iconEl, setIconEl] = useState(null);
  const [anchorMainMenu, setAnchorMainMenu] = useState<null | HTMLElement>(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [currentTab, setCurrentTab] = useState("Home");
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [position, setPosition] = useState({ latitude: null, longitude: null });
  const [locationPath, setLocationPath] = useState(
    window !== undefined ? window.location.pathname : "/"
  );
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMainMenu(event.currentTarget);
  };
  const handleClickProfile = (event: React.MouseEvent<HTMLElement>) => {
    console.log("Clicked profile")
    console.log(event)
    setIconEl(event.currentTarget);
    setOpenProfile(true);
  };
  
  const handleClose = () => {
    setOpenProfile(false);
    setAnchorMainMenu(null);
  };

  const handleProfile = () => {
    navigate("/profile");
    handleClose();
  };

  const handleViewAlerts = () => {
    navigate("/alert");
    handleClose();
  };

  const handleCloseUserSettings = () => {
    navigate("/user_settings");
    handleClose();
  };
  const handleCloseSettings = () => {
    navigate("/settings");
    handleClose();
  };
  const handleCloseMaintenance = () => {
    navigate("/maintenance");
    handleClose();
  };
  const handleCloseRisk = () => {
    navigate("/risk_appetite");
    handleClose();
  };
  const handleLogout = () => {
    handleClose();
    console.log("##### Loging out!!!")
    dispatch({ type: 'socket/disconnect' })
    logout(navigate);
    // navigate("/sign_in");
  };

  const handleClickVariant = (variant: VariantType) => () => {
    setCurrentPage("Alert")
    navigate("/alert");
  };

  const setKowrooAdminPages = useCallback(async () => {
    dispatch(setPages(kowrooAdminPages));
    dispatch(setRoutes(kowrooAdminRoutes));
  }, [dispatch]);

  const setAdminPages = useCallback(() => {
    dispatch(setPages(adminPages));
    dispatch(setRoutes(adminRoutes));
  }, [dispatch]);

  const setCustomerPages = useCallback(() => {
    dispatch(setPages(customerPages));
    dispatch(setRoutes(customerRoutes));
  }, [dispatch]);

  useUpdateUser(authInitiated, setAuthInitiated);

  useEffect(() => {
    if (window && window.location && window.location.pathname) {
      setCurrentPage(
        window.location.pathname.split("/")[1].charAt(0).toUpperCase() +
          window.location.pathname.split("/")[1].slice(1)
      );
    }
  }, []);


  const messages = useSelector((state: RootState) => state.socket.displayNotifications);
  useEffect(()=>{
    console.log("Messages length")
    console.log(messages)
    if (messages.length>0) {
      console.log("INSIDE")
      const latestMessage = messages[messages.length - 1];
      console.log(latestMessage)
      enqueueSnackbar(latestMessage.message, { autoHideDuration:10000, variant: latestMessage.variant, anchorOrigin:{ horizontal: "right", vertical: "bottom" }});
      console.log("COMPLETE DISPLAYED MESSAGE")
    }

  }, [messages])

  useEffect(() => {
    const fetchData = async () => {
      const res = await getUserProfile();
      if (!res.data.detail.length) {
        navigate("/profile");
      } else {
        console.log("SETPROFILE IS ")
        console.log(res)
        dispatch(setProfile(res.data.detail[0]));
        dispatch(setProfileId(res.data.detail[0].id));
      }
    };
    // fetchData();
    if (user.role === "customer") {
      fetchData();
    }
  }, [user.role, dispatch, navigate]);


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    return navigator.geolocation.getCurrentPosition(
      (position) =>
        setPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }),
      (err) => console.log(err)
    );
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const notifications = await getNotifications(); 
      const notificationsData: NotificationList = notifications.data
      // console.log("Notifications are ")
      // console.log(notificationsData)
      var allNotifications = []
      for (const [key, currentArray] of Object.entries(notificationsData)) {
        currentArray.forEach((row: any) => {
          let sortDate = key === "sos_alerts" ? new Date(row.date_created) : new Date(row.created_at)
          const newRow = {
            ...row, 
            sortDate: sortDate.toString(),
            timeSince: timeSince(sortDate),
            notificationType: key,
          }
          allNotifications.push(newRow)
        })
      }
      allNotifications.sort((a, b) => new Date(b.sortDate).getTime() - new Date(a.sortDate).getTime());
      // console.log(allNotifications)
      dispatch(setNewAlerts(allNotifications))
    };
    fetchData()

    if (pages.length > 0 || routes.length > 0) {
      return;
    }
    if (!loading) {
      if (role === "kowroo_admin") {
        setKowrooAdminPages();
      } else if (role === "admin") {
        setAdminPages();
      } else if (role === "customer") {
        setCustomerPages();
      }
    }
    let interval = setInterval(function (that) {
      if (role) {
        fetchData();
      } else {
        clearInterval(interval);
      }
    }, 20000);
  }, [
    role,
    dispatch,
    pages,
    routes,
    loading,
    locationPath,
    setAdminPages,
    setKowrooAdminPages,
    setCustomerPages,
    user.role,
  ]);

  useEffect(() => {
    if (position.latitude && position.longitude) {
      sendUserLocation(position.latitude, position.longitude);
    }
  }, [position.latitude, position.longitude]);

  const drawer = (
    <div>
      {/* <Toolbar /> */}
      <Divider />
      <List>
        {[
          "Map",
          role === "admin"
            ? "Company"
            : role === "kowroo_admin"
            ? "Companies"
            : "Trips",
          "Incidents",
          "Assets",
          "News",
          "Assessment",
          "Risk Appetite",
          "Alert",
          "Queries & Reports",
        ].map((text, index) => {
          return <Grid key={index}>
            <ListItem key={text + index} disablePadding sx={{}}>
              <ListItemButton
                onClick={(e) => {
                  e.stopPropagation();
                  text === "Risk Appetite"
                    ? navigate("/risk_appetite")
                    : text === "Queries & Reports"
                    ? navigate("/reports")
                    : navigate("/" + text);
                }}
              >
		            <ListItemIcon>
                  {index === 0 ? (
                    <LocationOnOutlined />
                  ) : index === 1 ? (
                    <Business></Business>
                  ) : index === 2 ? (
                    <FlagCircle></FlagCircle>
                  ) : index === 3 ? (
                    <BusinessCenter></BusinessCenter>
                  ) : index === 4 ? (
                    <Newspaper></Newspaper>
                  ) : index === 5 ? (
                    <Summarize></Summarize>
                  ) : index === 6 ? (
                    <WarningAmber></WarningAmber>
                  ) : index === 7 ? (
                    <NotificationImportant></NotificationImportant>
                  ) : index === 8 ? (
                    <Assessment></Assessment>
                  ) : (
                    <MailLock></MailLock>
                  )}
                </ListItemIcon>
                <ListItemText primary={text} />
                {open ? (
                  <ExpandLess
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClick();
                    }}
                  />
                ) : (
                  <ExpandMore
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClick();
                    }}
                  />
                )}
              </ListItemButton>
            </ListItem>
            <Collapse in={open && text === "Map"} timeout="auto" unmountOnExit>
              <List disablePadding>
                <ListItemButton sx={{ pl: 5 }}>
                  <ListItemIcon>
                    <StarBorder />
                  </ListItemIcon>
                  <ListItemText primary="Starred" />
                </ListItemButton>
              </List>
            </Collapse>
            <Collapse
              in={open && text === "Company"}
              timeout="auto"
              unmountOnExit
            >
              <List disablePadding>
                <ListItemButton sx={{ pl: 5 }}>
                  <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon>
                  <ListItemText primary="Users" />
                </ListItemButton>
                <ListItemButton component={Link} to="/trips" sx={{ pl: 5 }}>
                  <ListItemIcon>
                    <FlightIcon />
                  </ListItemIcon>
                  <ListItemText primary="Trips" />
                </ListItemButton>
                <ListItemButton component={Link} to="/teams" sx={{ pl: 5 }}>
                  <ListItemIcon>
                    <Groups2Icon />
                  </ListItemIcon>
                  <ListItemText primary="Teams" />
                </ListItemButton>
              </List>
            </Collapse>
          </Grid>
        }
      )}
      </List>
      <Divider />
      <List>
        {[
          "Chat", 
          // "Maintenance", 
          "Settings"
        ].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index === 0 ? (
                  <ChatIcon />
                ) : index === 1 ? (
                //   <Engineering></Engineering>
                // ) : index === 2 ? (
                  <Settings></Settings>
                ) : (
                  <MailLock />
                )}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
        {role==="customer" && <ListItem disablePadding>
          <ListItemButton
            onClick={(e) => {
              e.stopPropagation();
              navigate("/profile");
            }}
          >
            <ListItemIcon>
              <AssignmentIndIcon />
            </ListItemIcon>
            <ListItemText primary={"User Profile"} />
          </ListItemButton>
        </ListItem>}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  const listNotifications = (notificationList) => {
    return (
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        {notificationList.map((item, index) => {
          let currentRoute = "" 
          let displayText = ""
          if (item.notificationType === "incidents") {
            currentRoute = "/incidents"
            var avatar = <Avatar alt="Incident" src="/assets/incident.png" />
            var bgcolor = "powderblue"
            var userName = `${item.creator_firstName} ${item.creator_surname}`
            displayText = item.incident_type_name
          } else if (item.notificationType === "sos_alerts") {
            currentRoute = "/alert"
            var avatar = <Avatar alt="SOS" src="/assets/alert.png" />
            var bgcolor = "lightcoral"
            var userName = `${item.creator_firstName} ${item.creator_surname}`
            // displayText = item.message_text
            displayText = `${userName} has raised an SOS`
          } else if (item.notificationType === "checkins") {
            currentRoute = "/alert"
            var avatar = <Avatar alt="Checkin" src="/assets/map.png" />
            var bgcolor = "lightgreen"
            var userName = `${item.receiver.firstName} ${item.receiver.surname}`
            // displayText = item.text
            displayText = `You sent ${userName} a Checkin Request`
          }
          return (
            <React.Fragment key={index} >
              <ListItem alignItems="center" onClick={()=>{navigate(currentRoute)}} sx={{ bgcolor: bgcolor}}>
                <ListItemAvatar>
                  {avatar}
                </ListItemAvatar>
                <ListItemText 
                  primary={
                    <React.Fragment>
                      {/* <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {userName}
                      </Typography> */}
                      {displayText}
                    </React.Fragment>
                  }
                  secondary={`Created ${item.timeSince} ago`} 
                />
              </ListItem>
              {index < notificationList.length - 1 && <Divider />}
            </React.Fragment>
          )}
        )}
      </List>
    );
  };

  const NotificationsElement =  useMemo(() => {
    const handleDisplayNotifications = (event: React.MouseEvent<HTMLButtonElement>) => {
      setNotificationsAnchorEl(event.currentTarget);
    };

    const handleCloseNotifications = () => {
      setNotificationsAnchorEl(null);
    };
    const open = Boolean(notificationsAnchorEl);
    const id = open ? 'simple-popover' : undefined;
    const NotificationIcon = () => {
      return (
        <>
        {currentPage === "Alert" ? (
          <Notifications sx={{ fill: "blue" }}/>
        ) : (
          <NotificationsNone style={{}} />
        )}
        </>
      )}
    return (
      <Grid className="topbarIconContainer">
        <Badge 
          badgeContent={newAlerts.length} 
          color="error" 
          onClick={handleDisplayNotifications} 
          key="Notifications-Badge"
        >
          <NotificationIcon/>
        </Badge>
          <Popover 
            id={id}
            open={open}
            anchorEl={notificationsAnchorEl}
            onClose={handleCloseNotifications}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
              <Card className="divide-y divide-muted-background">
                <CardContent className="divide-y divide-muted-background">
                  <div style={{ textAlign: 'center' }}>
                    <Typography variant="h5"  fontWeight="bold">
                      User Notifications
                    </Typography>
                      <Button variant="contained" color="primary">Mark all as read</Button>
                  </div>
                </CardContent>
                <CardContent className="divide-y divide-muted-background">
                  {listNotifications(newAlerts)}
                </CardContent>
                <CardContent>
                  <div style={{ textAlign: 'center' }}>
                    <Button variant="contained" onClick={handleViewAlerts}>
                      View All Notifications
                    </Button>
                  </div>
                </CardContent>
              </Card>
          </Popover>
      </Grid>
    )
  }, [newAlerts, notificationsAnchorEl])

  function timeSince(timeObj) {
    const now = new Date();
    const pastDate = new Date(timeObj);

    const timeDiff = now.getTime() - pastDate.getTime();
    const minutesSince = Math.floor(timeDiff / (1000 * 60));
    const hoursSince = Math.floor(timeDiff / (1000 * 60 * 60));
    const daysSince = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const weeksSince = Math.floor(daysSince / 7);
    
    const monthsSince = now.getMonth() - pastDate.getMonth() +
        (12 * (now.getFullYear() - pastDate.getFullYear()));

    if (monthsSince< 12){
      var yearsSince = 0
    } else {
      var yearsSince = now.getFullYear() - pastDate.getFullYear();
    }

    var times = [
      ["year", yearsSince],
      ["month", monthsSince],
      ["week", weeksSince],
      ["day", daysSince],
      ["hour", hoursSince],
      ["minute", minutesSince],
    ]

    for (let index=0; index< times.length; index++){
      if (times[index][1] > 0) {
        if (times[index][1] == 1){
          return `1 ${times[index][0]}`
        } else {
          return `${times[index][1]} ${times[index][0]}s`
        }
      } 
    }
    return `0 minutes`
  }

  

  return (
    <Grid container position="absolute">
      <AppBar
        key="app-bar"
        style={{ background: "white" }}
        position="fixed"
        sx={{
          p: 0,
          right: 0,
          paddingLeft: { xs: "0px", sm: "240px" },
          height: "80px",
          width: { sm: `calc(100%)` },
        }}
      >
        <Container maxWidth="xl">
          <Toolbar
            disableGutters
            sx={{ zIndex: 100, position: "static", backgroundColor: "white" }}
          >
            {/* <NotificationsIcon className={classes.rightSectionItem} /> */}

            {/* </Typography> */}
            <br></br>
            <Box sx={{ flexGrow: 0 }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleClickMenu}
                // style={{color: "white", fill: "white"}}
                sx={{
                  display: { xs: "flex", sm: "none" },
                }}
              >
                <MenuIcon />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorMainMenu}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorMainMenu)}
                onClose={handleClose}
                sx={{
                  display: { xs: "flex", sm: "none" },
                }}
              >
                {pages.map((page, idx) => (
                  <MenuItem
                    key={"page-idx-" + idx}
                    onClick={() => {
                      navigate(routes[idx]);
                    }}
                  >
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Tabs
              value={currentTab}
              onChange={(e, newValue) => {
                setCurrentPage(newValue);
                if (pages.includes(newValue)) {
                  setCurrentTab(newValue);
                }
              }}
              sx={{ flexGrow: 1, display: { md: "flex", xs: "none" } }}
            >
              {pages.map((page, idx) => {
                return <Tab
                  key={page}
                  value={page}
                  label={page}
                  onClick={() => navigate(routes[idx])}
                  sx={{ my: 2, display: "block" }}
                />
              })}
            </Tabs>
            <Box>
              <Grid
                container
                direction="row"
                justifyContent="left"
                spacing={3}
                className="topright"
              >
                <Grid item>
                  <Typography sx={{ color: "green" }} variant="h6">
                    {user.companyName}
                  </Typography>
                </Grid>
                {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                <Grid item>
                  <Typography variant="caption" style={{ color: "black" }}>
                    <b>{role ? role.toUpperCase().replace("_", " ") : null}</b>
                  </Typography>
                </Grid>
                {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                {/* <Grid
                  className="topbarIconContainer"
                  onClick={handleClickVariant("success")}
                >
                  <Badge badgeContent={newAlerts.length} color="error">
                    {currentPage === "Alert" ? (
                      <Notifications sx={{ fill: "blue" }}></Notifications>
                    ) : (
                      <NotificationsNone style={{}} />
                    )}
                  </Badge>
                </Grid> */}
                {NotificationsElement}
                <div
                  className="topbarIconContainer"
                  onClick={() => {
                    setCurrentPage("Chat");
                    navigate("/chat");
                  }}
                >
                  <ChatIcon
                    sx={{ fill: currentPage === "Chat" ? "blue" : "" }}
                  />
                </div>
                <div
                  className="topbarIconContainer"
                  onClick={() => {
                    setCurrentPage("/settings");
                    navigate("/settings");
                  }}
                >
                  <Settings
                    sx={{ fill: currentPage === "/settings" ? "blue" : "" }}
                  />
                </div>
                {loading ? (
                  <CircularProgress
                    size="1.7rem"
                    color="inherit"
                  ></CircularProgress>
                ) : (

                  <UserIcon user={user} handleClickProfile={handleClickProfile}>
                  </UserIcon>
                  // <img
                  //   id="demo-positioned-button"
                  //   // aria-controls={
                  //   //   openProfile ? "demo-positioned-menu" : undefined
                  //   // }
                  //   // aria-haspopup="true"
                  //   // aria-expanded={openProfile ? "true" : undefined}
                  //   onClick={handleClickProfile}
                  //   src={
                  //     user.role === "customer"
                  //       ? "https://images.unsplash.com/photo-1611915387288-fd8d2f5f928b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80"
                  //       : user.role === "admin"
                  //       ? "https://avatarfiles.alphacoders.com/257/thumb-257907.jpg"
                  //       : user.role === "kowroo_admin"
                  //       ? "https://img.lovepik.com/free-png/20211202/lovepik-crystal-lion-head-front-cartoon-avatar-png-image_401269979_wh1200.png"
                  //       : null
                  //   }
                  //   alt=""
                  //   className="topAvatar"
                  // />
                )}
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={iconEl}
                  open={openProfile}
                  onClose={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  {["kowroo_admin", "admin"].includes(user.role) ? null : (
                    <MenuItem onClick={handleProfile}>Profile</MenuItem>
                  )}
                  <MenuItem onClick={handleCloseMaintenance}>
                    Maintenance
                  </MenuItem>
                  <MenuItem onClick={handleCloseRisk}>
                    Risk Appetite Selector
                  </MenuItem>
                  <MenuItem onClick={handleCloseUserSettings}>
                    User settings
                  </MenuItem>
                  <MenuItem onClick={handleCloseSettings}>Settings</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </Grid>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Box
        key="nav-box"
        component="nav"
        // sx={{ width: { sm: drawerWidth }, backgroundColor:"black", display: {xs: "none",  sm: "block"}, flexShrink: { sm: 0 } }}
        // sx={listClass}
        aria-label="mailbox folders"
        sx={classes.list}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

        <Drawer
          // container={container}
          variant="persistent"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          // style={listClass}
        >
          {drawer}
        </Drawer>
        <Drawer
          PaperProps={{
            sx: {
              backgroundColor: globalConfig["primaryMain"],
              color: "white",
            },
          }}
          variant="permanent"
          sx={classes.list}
          open
          // open={false}
        >
          <Grid
            container
            direction="row"
            alignItems={"center"}
            sx={{ p: 3 }}
            justifyContent="center"
          >
            {/* <SupportIcon sx={{ mr: 1 }} />

            <Grid
              onClick={() => {
                navigate("/home");
              }}
            >
              <Typography
                variant="h6"
                noWrap
                sx={{
                  mr: 2,
                  cursor: "pointer",
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  // color: "black",
                  textDecoration: "none",
                }}
              >
                KOWROO
              </Typography>
            </Grid> */}
            {/* <Logo /> */}
            <img 
              className="logo"
              src="logo_white.svg" 
              alt="logo" 
              onClick={() => {
                navigate("/home");
              }}
              // style={{
              //   cursor: 'pointer',
              // }}
            />
          </Grid>
          {drawer}
        </Drawer>
      </Box>
    </Grid>
  );
};

Topbar.propTypes = {
  dispatch: PropTypes.func,
};

const mapStateToProps = ({ user }) => ({
  loading: user.loading,
  role: user.role,
  user,
  pages: user.pages,
  routes: user.routes,
  newAlerts: user.newAlerts,
});
export default connect(mapStateToProps)(Topbar);
