import React, { useState, useContext, useMemo } from 'react';
import './ChatBox.css'; // Your CSS file
import store from "../store"
import {
  Button,
  TextField,
  FormControl,
  MenuItem,
  InputLabel
} from "@mui/material";
// import { WebSocketContext } from '../helpers/WebSocketProvider'; // Import the context and hook

import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers/rootReducer';
// import { RootState } from './store'; // Assuming RootState is the type of your Redux store state


function ChatBox(props) {
  const {
    users,
    // messages,
    // setMessages,
    adminId,
    companyName
  } = props

  const messages = useSelector((state: RootState) => state.socket.chatMessages)
  const [selectedUser, setSelectedUser] = useState({id:"", firstName:"Chat", surname:""});
  const [selectedUserName, setSelectedUserName] = useState("Chat");
  const [selectedUserID, setSelectedUserID] = useState("");

  const [newMessage, setNewMessage] = useState('');
  const [isMinimized, setIsMinimized] = useState(true);

  const handleInputChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleSelectUser = (event) => {
    const userID = event.target.value
    console.log("userID is ",  userID)
    // setSelectedUser(event.target.value);
    const user = users.find(user => user.id === userID);
    setSelectedUser(user);
    setSelectedUserName(`${user.firstName} ${user.surname}`);
    setSelectedUserID(userID);
  };

  const handleSendMessage = () => {
    console.log("all messages", messages)
    let currentDate = new Date()
    if (newMessage.trim() === '') return;
    console.log("AAA AdminID is", adminId)
    const socketMessage = {
      type: 'Standard',
      company: companyName, 
      uid: adminId.toString(), 
      role: 'admin',
      status: 'connected',
      dateTime: currentDate.toString(), 
      // Latitude: 12345,
      // Longitude: 12345,
      // Speed: 12345,
      // Altitude: 12345,
      // Accuracy: 12345,
      // Bearing: 12345,
      message:newMessage,
      userList: `${selectedUser.id}`
    }; 
    console.log("sending mssage from chatbox")
    console.log("chat message sending ", socketMessage)
    console.log("chat messages ", messages)

    store.dispatch({type: "socket/sendchat", message:socketMessage })
    store.dispatch({ type: "ADD_NEW_CHAT_MESSAGE", payload:socketMessage})

    setNewMessage('');
  };

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };



  const ChatBox = useMemo(() => {
    const renderMessages = (messages) => {
      console.log("[renderMessages]")
      console.log(messages)
      
      if (selectedUser.id === ""){
        return <></>
      } else {
        return messages[selectedUser.id].map((message, index) => (
          <div
            key={`${index} ${message.Uid}`}
            className={`message ${message.Uid === adminId.toString()? 'user' : 'other ' + message.Uid}`}
          >
            {message.Message}
          </div>
        ))
      }
    };
    const ChatBoxObj = (
      <div>    
        <div className={`chat-box ${isMinimized ? 'minimized' : ''}`}>
          <div className={`chat-header ${isMinimized ? 'minimized' : ''}`} onClick={toggleMinimize}>
              <span>{selectedUserName}</span>
              <button className="minimize-button">_</button>
          </div>
            {!isMinimized && (
              <>
              <FormControl sx={{ m: 1, minWidth: 120, maxHeight: 0.5 }}>
                <InputLabel id="demo-simple-select-helper-label">User</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={selectedUserID}
                  label="User"
                  onChange={handleSelectUser}
                >
                  {users.map((user, index) => (
                    <MenuItem key={`${user.firstName}-${user.surname}`} value={user.id}>{`${user.firstName} ${user.surname}`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
                <div className="messages">{renderMessages(messages)}</div>
                <div className="chat-input-box">
                  <TextField id="message-input" className="chat-input" label="" value={newMessage} variant="outlined" onChange={handleInputChange}/>
                  <Button type="submit" className = "message-button" onClick={handleSendMessage} variant="contained" style={{backgroundColor:"#005A31"}}>Send</Button>
                </div> 
              </>
            )}
        </div>
      </div>
    )
    return ChatBoxObj
  }, [
    selectedUser,
    isMinimized,
    messages, 
    newMessage
  ]);

  return ChatBox

}


export default ChatBox;