import React, { useCallback, useEffect, useMemo, useState,  useContext } from "react";

import ChatBox from "../components/ChatBox"
import DisplayTable from "../components/DisplayTable";
import Grid from "@mui/material/Grid";
import "mapbox-gl/dist/mapbox-gl.css";
import UserTooltip from "../components/UserTooltip";

import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";

import {
  searchIncidents,
  searchUsers,
  getSOS,
  getCheckins
} from "../helpers/api";
import { connect } from "react-redux";

import UserIcon from "../components/UserIcon";
import { useNavigate } from "react-router-dom";
import { formatDateTimeString } from "../helpers/utils";

const Alert = (props) => {
  const { loading, role, newAlerts, dispatch, adminId, companyName } = props;

  const navigate = useNavigate();
  
  const [SOSRows, setSOSRows] = useState([]);
  const [SOScolumnFilters, SOSsetColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [SOSglobalFilter, SOSsetGlobalFilter] = useState("");
  const [SOSsorting, SOSsetSorting] = useState<MRT_SortingState>([]);
  const [SOSpagination, SOSsetPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  });
  const [editingSOS, setEditingSOS] = useState({ id: null });
  const [SOSisRefetching, SOSsetIsRefetching] = useState(false);
  const [SOSisError, SOSsetIsError] = useState(false);
  const [SOSisLoading, SOSsetIsLoading] = useState(false);
  const [SOSrowCount, SOSsetRowCount] = useState(0);

  const [CheckinRows, setCheckinRows] = useState([]);
  const [CheckincolumnFilters, CheckinsetColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [CheckinglobalFilter, CheckinsetGlobalFilter] = useState("");
  const [Checkinsorting, CheckinsetSorting] = useState<MRT_SortingState>([]);
  const [Checkinpagination, CheckinsetPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  });
  const [editingCheckin, setEditingCheckin] = useState({ id: null });
  const [CheckinisRefetching, CheckinsetIsRefetching] = useState(false);
  const [CheckinisError, CheckinsetIsError] = useState(false);
  const [CheckinisLoading, CheckinsetIsLoading] = useState(false);
  const [CheckinrowCount, CheckinsetRowCount] = useState(0);

  const [rowCount, setRowCount] = useState(0);
  const [userData, setUserData] = useState([]);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [incidents, setIncidents] = useState([]);
  const [customerEmail, setCustomerEmail] = useState('')
  const [ customerOps, setCustomerOps] = useState([])
  const [customers, setCustomers] = useState([])
  const [SOSCount, setSOSCount] = useState(0)

  useEffect(() => {
    const fetchCheckins = async () => {
      const res = await getCheckins();
      console.log("checkin data is ", res.data.results)
      if (res.status === 200) {
        const updatedCheckin = res.data.results.map((checkin) => ({
          ...checkin, 
          status: (checkin.status === "response received" ? "Response Received" :  
            checkin.status === "pending"? "Pending": "") 
        }))

        setCheckinRows(()=> {
          return res.data.results.map((checkin)=>({
            ...checkin,
            displayCreatedAt: formatDateTimeString(checkin.created_at)
          }))
        });
        CheckinsetRowCount(res.data.count)
      }
    }
    fetchCheckins()
  }, [])

  const getCustomers = useCallback(async () => {
    const res = await searchUsers('', '', '', "","");
    if (res.status !== 200 && res.data.results === undefined) {
      setCustomers([]);
      setCustomerOps([]);
    } else {
      let ops = [];
      res.data.results.map((op, i) => {
        if (op.is_active) {
          return ops = [...ops, {email: op.email, surname: op.surname, firstName: op.firstName}];
        }
        return null;
      });
      setCustomers(res.data.results);
      setCustomerOps(ops);
    }
  }, [navigate]);

  const fetchCustomerOps = useCallback(() => {
    // eslint-disable-next-line require-jsdoc
    async function fetchData() {
      if (!loading) {
        const customers = await searchUsers("","", "","","");
        if (!customers.data.results.length) {
            setCustomers([]);
          } else {
            setCustomers(customers.data.results);
          }
        }
    }
    if (!loading) {
      fetchData();
    }

  }, [loading, navigate]);

  useEffect(() => {
    if (!loading) {
      if (customerEmail) {

        const user = customers.filter((item) => {
          return item.email === customerEmail;
        })[0];
        if (!user) {
          return;
        }
        fetchCustomerOps();
      }
    }
  }, [customerEmail, loading, fetchCustomerOps, customers]);

  useEffect(() => {
    // eslint-disable-next-line require-jsdoc
    async function fetchData() {
      if (!loading) {
        const incidents = await searchIncidents("", null, null, navigate, 1, 100);
        console.log(incidents);
        if (!incidents.data.results.length) {

          setIncidents([]);
        } else {

          setIncidents(
            incidents.data.results.map((incident) => {
              return { value: incident.category_name, id: incident.id };
            })
          );
        }
      }
    }
    if (!loading && role) {
      fetchData();
      getCustomers()
    }
  }, [loading, role, navigate, getCustomers]);


  useEffect(() => {
    const fetchSOS = async () => {
      const res = await getSOS(
        "","","",
      //  1, 1000
      );

      if (res.status === 200) {
        setSOSRows(()=> {
          return res.data.results.map((sos)=>({
            ...sos,
            displayCreatedAt: formatDateTimeString(sos.date_created)
          }))
        });
        SOSsetRowCount(res.data.count)
      }
    }
    fetchSOS()
  }, [])
  
  useEffect(() => {
    const fetchData = async () => {
      if (!userData.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {
        const response = await searchUsers("", "", "", "", "");
        console.log("queried user data", response);
        // Check if the response status indicates success
        if (response.status===200) {
          console.log("response ok")
          console.log("queried user data 1", response.data);
          console.log(`adminId is ${adminId}`)
          const invitedUsers = response.data.results.filter(item => item.id !== adminId);
          setUserData(invitedUsers);

          setRowCount(invitedUsers.length)
          setIsLoading(false);
        } else {
          console.error("Failed to fetch user data. Response status:", response.status);
        }
      } catch (error) {
        console.error("Error querying user data:", error);
      }
    }
    fetchData()
  }, []);

  const SOScolumns = useMemo(() => [
    {
      accessorKey: "creator.firstName",
      header: "User",
      Cell: ({ cell, row }) => (
        <>
        <UserTooltip
          // userRole={role}
          users={[row.original.creator]}
          // title="Add"
          placement="top"
          arrow
          describeChild
        >
          <Grid item>
            <UserIcon user={row.original.creator} handleClickProfile={()=>{}}>
            </UserIcon>
          </Grid>
        </UserTooltip>
        </>
      ),
      maxSize:5
    },
    {
      accessorKey: "displayCreatedAt",
      header: "Raised At",
      maxSize:5
    },
  ],[]);

  const SOSonEditClick = (e) => {}
  const SOShandleOpenModal = (e) => {}

  const SOSTable = useMemo(() => {
    const displayTable = (
        <DisplayTable
          title={"SOS History"}
          columns={SOScolumns}
          data={SOSRows}
          onEditClick={SOSonEditClick}
          handleOpenDeleteModal={SOShandleOpenModal}
          columnFilters={SOScolumnFilters}
          globalFilter={SOSglobalFilter}
          sorting={SOSsorting}
          pagination={SOSpagination}
          rowCount={SOSrowCount}
          isRefetching={SOSisRefetching}
          isError={SOSisError}
          isLoading={SOSisLoading}
          setColumnFilters={SOSsetColumnFilters}
          setGlobalFilter={SOSsetGlobalFilter}
          setPagination={SOSsetPagination}
          setSorting={SOSsetSorting}
        />
    ); 
    
    return displayTable 
  }, [
    editingSOS,
    SOSCount,
    SOSpagination,
    SOSonEditClick,
    SOSRows,
  ]);

  const Checkincolumns = useMemo(() => [
    {
      accessorKey: "receiver.firstName",
      header: "User",
      Cell: ({ cell, row }) => {
        return (
        <>
        <UserTooltip
          // userRole={role}
          users={[row.original.receiver]}
          // title="Add"
          placement="top"
          arrow
          describeChild
        >
          <Grid item>
            <UserIcon user={row.original.receiver} handleClickProfile={()=>{}}>
              </UserIcon>
          </Grid>
        </UserTooltip>
        </>
      )},
      maxSize:5
    },
    {
      accessorKey: "displayCreatedAt",
      header: "Raised At",
      maxSize:5
    },
    {
      accessorKey: "status",
      header: "Status",
      maxSize:5
    },
  ], []);

  const CheckinonEditClick = (e) => {}
  const CheckinhandleOpenModal = (e) => {}

  const CheckinTable = useMemo(() => {
    const displayTable = (
        <DisplayTable
          title={"Checkin History"}
          columns={Checkincolumns}
          data={CheckinRows}
          onEditClick={CheckinonEditClick}
          handleOpenDeleteModal={CheckinhandleOpenModal}
          columnFilters={CheckincolumnFilters}
          globalFilter={CheckinglobalFilter}
          sorting={Checkinsorting}
          pagination={Checkinpagination}
          rowCount={CheckinrowCount}
          isRefetching={CheckinisRefetching}
          isError={CheckinisError}
          isLoading={CheckinisLoading}
          setColumnFilters={CheckinsetColumnFilters}
          setGlobalFilter={CheckinsetGlobalFilter}
          setPagination={CheckinsetPagination}
          setSorting={CheckinsetSorting}
        />
    ); 
    
    return displayTable 
  }, [
    editingCheckin,
    CheckinrowCount,
    Checkinpagination,
    CheckinonEditClick,
    role,
    CheckinRows,
  ]);

  return (
    <div className="alert">
      <Grid  sx={{pt: 5}} >
        <Grid container direction="row">
          <Grid item xs={12}>
          {CheckinTable}
          <br></br>          
          </Grid>
          <Grid item xs={12}>
          {SOSTable}
          </Grid>
        </Grid>
      </Grid>
      <ChatBox 
        users={userData}
        // messages={messages}
        // setMessages={setMessages}
        adminId={adminId}
        companyName={companyName}
      />
    </div>
  ); 
};

const mapStateToProps = ({ user, socket }) => ({
  loading: user.loading,
  companyName: user.companyName,
  profile: user.profile,
  role: user.role,
  newAlerts: user.newAlerts,
  adminId: user.id,
  // socket: user.websocket
});

export default connect(mapStateToProps)(Alert);
