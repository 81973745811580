import React, { useState, useRef, useEffect, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';

type MessageType = 'info' | 'success' | 'warning' | 'error';

const getIcon = (type: MessageType) => {
  switch (type) {
    case 'warning':
      return <WarningIcon color={'warning'} sx={{ color: 'orange', fontSize: 24 }} />;
    case 'error':
      return <ErrorIcon color={'error'} sx={{ fontSize: 24, animation: 'pulse 1s infinite' }} />;
    case 'success':
      return <CheckCircleIcon sx={{ fontSize: 24, color: 'green' }} />;
    default:
      return <InfoIcon color={'info'} sx={{ fontSize: 24 }} />;
  }
};

const Marquee: React.FC<{
  message: string;
  source: string;
  type: MessageType;
}> = ({ message, source, type }) => {
  const [isScrolling, setIsScrolling] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [currentX, setCurrentX] = useState(0);
  const textRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging && textRef?.current?.style) {
        const deltaX = e.clientX - startX;
        setCurrentX(deltaX);
        textRef.current.style.transform = `translateX(${deltaX}px)`;
      }
    };

    const handleMouseUp = () => {
      if (textRef?.current) {
        setIsDragging(false);
        textRef.current.style.transition = 'transform 10s linear infinite'; // Resume animation
        textRef.current.style.transform = `translateX(${currentX}px)`; // Set current position
        setTimeout(() => {
          if (textRef?.current) {
          textRef.current.style.transform = 'translateX(-100%)'; // Continue scrolling after delay
          }
        }, 100); // Delay to allow the text to stay in place after dragging
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, startX, currentX]);

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent text selection
    if (textRef.current) {
      setIsDragging(true);
      setStartX(e.clientX - textRef.current.getBoundingClientRect().left);
      textRef.current.style.transition = 'none'; // Disable animation during drag
    }
  };

  const handleClick = () => {
    setIsScrolling(!isScrolling);
    if (textRef?.current?.style) {
      textRef.current.style.animationPlayState = isScrolling ? 'paused' : 'running';
    }
  };

  return (
    <Box
      ref={containerRef}
      onClick={handleClick} // Toggle scrolling on click
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        paddingLeft: '20px',
        background: type === 'info' ? '#e0f7fa' :
                    type === 'success' ? '#d4edda' :
                    type === 'warning' ? '#fff9c4' :
                    type === 'error' ? '#ffebee' :
                    '#e3f2fd',
        borderRadius: '5px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        position: 'relative',
        cursor: 'pointer', // Add cursor pointer for the whole box
      }}
    >
      {getIcon(type)}
      <Typography
        ref={textRef}
        sx={{
          marginLeft: '10px',
          color: 'black',
          display: 'inline-block',
          whiteSpace: 'nowrap',
          animation: 'marquee 10s linear infinite',
          animationPlayState: !(source && message) || !isScrolling ? 'paused' : 'running',
          userSelect: 'none', // Disable text selection
        }}
        onMouseDown={handleMouseDown}
      >
        {source && message ? `[${source}] ${message}` : 'Loading messages...'}
      </Typography>
      <style>{`
        @keyframes marquee {
          from {
            transform: translateX(70%);
          }
          to {
            transform: translateX(-100%);
          }
        }
        @keyframes pulse {
          0% { transform: scale(1); }
          50% { transform: scale(1.2); }
          100% { transform: scale(1); }
        }
      `}</style>
    </Box>
  );
};

export default Marquee;
