import cookie from 'js-cookie';

import store from '../store';
import {logoutToken, searchUsers} from './api';
import {
  deleteAccessToken, deleteCompanyName, deleteEmail, deleteId,
  deleteRefreshToken, deleteStaff, set2FAStatus, setIsLoggedIn, setLoading, setPages, setRole, setRoutes,
} from '../actions/userActions';
import { NavigateFunction } from 'react-router-dom';

export async function loadStoreVariables() {
  const response = await searchUsers("", "", "", "", ""); 
  console.log("search USERS ")
  console.log("[loadStoreVariables-1]")
  console.log(response)
  store.dispatch({type: "SET_INVITED_USERS", payload: response.data.results})
  store.dispatch({type: "SET_CHAT_USERS", payload: response.data.results})
}

export const isLoggedIn = () => {
  console.log("[isLoggedIn]")
  const {user} = store.getState();
  return (user.access_token && user.is_logged_in ? user.access_token && user.is_logged_in : cookie.get('is_logged_in') === "true");
};

export const formatDateTimeString = (dt: string | number | Date) => {
  console.log()
  if (!dt) {
    return 'No date';
  }

  const dtT = new Date(dt);
  const dtGb = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }).format(dtT);
  return dtGb;
};


const USER_LOGOUT = {
  type: "USER_LOGOUT"
}

export const logout = async (navigate: NavigateFunction) => {
  const {user} = store.getState();
  const token = user.access_token || cookie.get('access_token')
  // navigate = useNavigate
  if (!token) {store.dispatch(USER_LOGOUT); navigate("/sign_in")}
  const res = await logoutToken(token);
  console.log(res)
  store.dispatch(USER_LOGOUT)
  store.dispatch(setPages([]))
  store.dispatch(setRoutes([]))
  cookie.remove('refresh_token');
  cookie.remove('access_token');
  cookie.remove('is_logged_in');
  store.dispatch(deleteAccessToken);
  store.dispatch(deleteRefreshToken);
  store.dispatch(deleteEmail);
  store.dispatch(setRole(''));
  store.dispatch(set2FAStatus(false));
  store.dispatch(deleteStaff);
  store.dispatch(deleteCompanyName);
  store.dispatch(deleteId);
  store.dispatch(setIsLoggedIn(false));
  store.dispatch(setLoading(false))
  navigate('/sign_in');

};

export const silentLogout = async () => {
  const {user} = store.getState();
  if (user.access_token) {
    await logoutToken(user.access_token);
  }

  cookie.remove('access_token');
  cookie.remove('refresh_token');
  cookie.remove('is_logged_in');
  console.log("****************** SILENT LOGOUT OCCURRING !!!!!!")
  store.dispatch(deleteAccessToken);
  store.dispatch(deleteRefreshToken);
  store.dispatch(deleteEmail);
  store.dispatch(setRole(''));
  store.dispatch(set2FAStatus(false));
  store.dispatch(deleteStaff);
  store.dispatch(deleteCompanyName);
  store.dispatch(deleteId);
  store.dispatch(setIsLoggedIn(false));
  store.dispatch(setPages([]))
  store.dispatch(setRoutes([]))
  store.dispatch(setLoading(false))
  store.dispatch(USER_LOGOUT)
};

export const formatDate = (dt: Date | null | undefined) => {
  if (dt === null || dt === undefined) {
    return '';
  }
  return dt.toLocaleDateString();
};

export const formatDateCustom = (dt: string | number | Date | null | undefined) => {
  if (dt === null || dt === undefined) {
    return '';
  }
  const dtT = new Date(dt);
  const dtGb = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  }).format(dtT);
  return dtGb;
};

export const randomStringGen = () => {
  // Generates a random string 60 chars long.
  let res = '';
  for (let i = 0; i < 10; i++) {
    res += Math.random().toString(36).substring(7);
  }
  return res;
};


export function getDisplayNotification(eventMessage, userName) {
  let notificationMessage = null
  let variant = null
  switch (eventMessage.Type) {
      case 'SOS':
          // console.log("SOS event message")
          if (eventMessage.Status==="Initial") {
              console.log("SOS INITIAL !!!!!")
              notificationMessage = `${userName} has initiated an SOS.`
              variant = "warning"    
          }
          break        
      case 'FollowMe':
          if (eventMessage.Status==="Initial") {
              notificationMessage = `${userName} has initiated an Follow Me Home.`
              variant = "info"    
          }
          break        
      case 'Standard':
          if (eventMessage.Message!=="") {
              notificationMessage = `Message from ${userName}: ${eventMessage.Message.substring(0,10)}`
              variant = "info"    
          } else if (eventMessage.Status==="[CheckinAcknowledged]") {
              notificationMessage = `${userName} has checked in.`
              variant = "info"  
          }
          break
      case 'RiskRatingChange':
        let increase = eventMessage.increase_decrease;
        let prevRiskRating = eventMessage.prevRiskRating;
        let newRiskRating = eventMessage.newRiskRating;

        notificationMessage = `${userName} Risk Rating has ${increase} from ${prevRiskRating} to ${newRiskRating}.`
        console.log("[notificationMessage]")
        console.log(notificationMessage)
        variant = "info"  
        break
      default:
          break
  }
  if (notificationMessage!==null){
    return {variant:variant, message:notificationMessage}
  } else {
    return null
  }
}
