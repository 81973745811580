import React, { useMemo, useState, useEffect } from "react";

import { 
    Button,
    Box, 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    Checkbox,
    Select,
    MenuItem,
    SelectChangeEvent
} from "@mui/material";
import { UserIncident } from "../types/data";

import globalConfig from '../config.json';
import { AdminUser, PhoneUser, useUserStore } from "../stores/userStore";


interface SubmitIncidentModalProps {
    handleClose: ()=>void 
    handleSubmit: ()=>void
    selectedSubmitIncident: UserIncident
    // const [notifyUsers, setNotifyUsers] = useState(false);
    notifyUsers: boolean
    setNotifyUsers: (value: boolean)=>void
    selectedUsers: string[]
    setSelectedUsers: (users: string[]) => void
}

const SubmitIncidentModal = (props:SubmitIncidentModalProps) => {
    const { 
        handleClose, 
        handleSubmit, 
        selectedSubmitIncident,
        notifyUsers,
        setNotifyUsers,
        selectedUsers,
        setSelectedUsers
    } = props;

    const { user } = useUserStore((state) => ({
        user: state.user,
    }));

    const [currentManagedUsers, setCurrentManagedUsers] = useState([] as PhoneUser[]);
    useEffect(() => {
        if (user.role === "admin") {
            setCurrentManagedUsers((user as AdminUser).managedUsers);
            console.log("Chat managed users: ", currentManagedUsers);
        }
    }, [user]);

    const handleNotifyUsersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNotifyUsers(event.target.checked);
    };

    const handleUserChange = (event: SelectChangeEvent<string[]>) => {
        setSelectedUsers(event.target.value as string[]);
    };
    const table = useMemo(() => {
        return (
            <Dialog open={true} maxWidth="sm" fullWidth >
                <DialogTitle sx={{ bgcolor: globalConfig['primaryMain'], color: 'white' }}>Confirm Submission</DialogTitle>
                <DialogContent>
                    <Typography variant="h6" gutterBottom>
                    You are about to submit the following incident:
                    </Typography>
                    <Box mt={2}>
                        <Typography variant="subtitle1" gutterBottom>
                            <strong>Category:</strong> {selectedSubmitIncident.category_name}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            <strong>Type:</strong> {selectedSubmitIncident.type_name}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            <strong>Summary:</strong> {selectedSubmitIncident.summary}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            <strong>Location Timezone:</strong> {selectedSubmitIncident.location_timezone}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            <strong>Started At:</strong> {selectedSubmitIncident.started_at_timestamp.toLocaleString()}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            <strong>Ended At:</strong> {selectedSubmitIncident.ended_at_timestamp.toLocaleString()}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            <strong>Impact Rating:</strong> {selectedSubmitIncident.impact_rating}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            <strong>Description:</strong> {selectedSubmitIncident.description}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            <strong>Latitude:</strong> {selectedSubmitIncident.latitude}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            <strong>Longitude:</strong> {selectedSubmitIncident.longitude}
                        </Typography>
                    </Box>
                    <Divider />
                    <Box mt={2}>
                        <Typography variant="h6">Would you like to notify any users of this incident?</Typography>
                        <FormControl component="fieldset" sx={{ mt: 2 }}>
                            <FormControlLabel
                                control={<Checkbox checked={notifyUsers} onChange={handleNotifyUsersChange} />}
                                label="Notify users"
                            />
                            {notifyUsers && (
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel id="select-users-label">Select Users</InputLabel>
                                    <Select
                                        labelId="select-users-label"
                                        multiple
                                        value={selectedUsers}
                                        onChange={handleUserChange}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Typography key={value} variant="body2" color="text.primary">
                                                        {value}
                                                    </Typography>
                                                ))}
                                            </Box>
                                        )}
                                        label="Select Users"
                                    >
                                        {currentManagedUsers.map(managedUser=>{
                                            return (
                                                <MenuItem value={managedUser.email} key={managedUser.email}>{managedUser.firstName} {managedUser.surname}</MenuItem>
                                            );})}
                                    </Select>
                                </FormControl>
                            )}
                        </FormControl>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button 
                        sx={{ 
                            backgroundColor: globalConfig['primaryMain'], 
                            '&:hover': { backgroundColor: 'darkgreen' },
                            fontWeight: 'bold'
                        }} 
                        variant="contained" 
                        onClick={handleClose}
                    >
                    Cancel
                    </Button>
                    <Button 
                        sx={{ 
                            backgroundColor: globalConfig['primaryMain'], 
                            '&:hover': { backgroundColor: 'darkgreen' },
                            fontWeight: 'bold'
                        }} 
                        variant="contained" 
                        onClick={()=>{
                            handleSubmit();
                            handleClose();
                        }}
                    >
                    Confirm
                    </Button >
                </DialogActions>
            </Dialog>
        );
    }, [
        selectedSubmitIncident, notifyUsers, selectedUsers
    ]);
    return table;
};

export default SubmitIncidentModal;