import React from 'react';
import GenericTable from './GenericTable';
import { CheckinHistoryItem } from '../types/data';
import {  MRT_ColumnDef, MRT_PaginationState, MRT_SortingState } from "material-react-table";
import { useUserStore, AdminUser } from '../stores/userStore';
import { useCallback, useState } from 'react';
import { formatDateTimeToString } from '../lib/utils';


interface CheckinHistoryTableProps {
    title: string;
    data: CheckinHistoryItem[];
}

const CheckinHistoryTable = (props:CheckinHistoryTableProps) => {
    const { 
        title,
        data,
    } = props;
    const { user } = useUserStore((state) => ({
        user: state.user,
    }));

    const getInitialsFromID = useCallback((id: number)=>{
        const managedUsers = (user as AdminUser).managedUsers;
        const currentUser = managedUsers.filter(managedUser=>managedUser.id===id)[0];
        let initials = "";
        if (currentUser){
            initials = currentUser.firstName.length > 0 ?  `${currentUser.firstName} ${currentUser.surname}` : "";
        }
        
        return initials;
    },[user]);

    const [pagination, setPagination] = useState<MRT_PaginationState>({pageIndex: 0,pageSize: 5});
    const isRefetching = false;
    const isError = false;
    const isLoading = false;
    const columns: MRT_ColumnDef<CheckinHistoryItem>[] = [
        {
            accessorKey: "receiver_id",
            header: "Receiver",
            size: 50,
            Cell: ({ row }:{row: { original: CheckinHistoryItem}}) => (
                <div>
                    {getInitialsFromID(row.original.receiver_id)}
                </div>
            ),
        },
        {
            accessorKey: "created_at",
            header: "Checkin Request Sent",
            size: 50,
            Cell: ({ row }: { row: { original: CheckinHistoryItem } }) => (
                <div>
                    {formatDateTimeToString(row.original.created_at)}
                </div>
            ),
            filterVariant:"date-range"
        },
        {
            accessorKey: "status",
            header: "Status",
            size: 50
        },
    ];

    const [sorting, setSorting] = useState<MRT_SortingState>([
        {id: "created_at", desc:true}
    ]);
    const checkinHistoryTable = (
        <GenericTable
            title={title}
            columns={columns}
            data={data}
            onEditClick={()=>{}}
            handleOpenDeleteModal={()=>{}}
            sorting={sorting}
            pagination={pagination}
            rowCount={data.length}
            isRefetching={isRefetching}
            isError={isError}
            isLoading={isLoading}
            setPagination={setPagination}
            setSorting={setSorting}
            enableRowActions={false}
        />
    ); 

    return checkinHistoryTable;
};

export default CheckinHistoryTable;