import { create } from 'zustand';

export type Version = {
    countryAssessment: Date
    userIncidents: Date
    chat: Date
    assets: Date
    intelligenceFusion: Date
    checkinHistory: Date
    sosHistory: Date
    followMeHome: Date
    followMeHomeHistory: Date
    crisis24: Date
}

export type State = {
    local: Version
}
export type Actions = {
    setCountryAssessment: (newVersion: Date) => void
    getCountryAssessment: () => Date
    setUserIncidents: (newVersion: Date) => void
    getUserIncidents: () => Date
    setChatMessages: (newVersion: Date) => void
    getChatMessages: () => Date
    setAssets: (newVersion: Date) => void
    getAssets: () => Date
    setIntelligenceFusion: (newVersion: Date) => void
    getIntelligenceFusion: () => Date
    setCrisis24: (newVersion: Date) => void
    getCrisis24: () => Date
    setCheckinHistory: (newVersion: Date) => void
    getCheckinHistory: () => Date
    setSOSHistory: (newVersion: Date) => void
    getSOSHistory: () => Date
    setFollowMeHome: (newVersion: Date) => void
    getFollowMeHome: () => Date
    setFollowMeHomeHistory: (newVersion: Date) => void
    getFollowMeHomeHistory: () => Date
}

const initialVersion: Version = {
    countryAssessment: new Date(0),
    userIncidents: new Date(0),
    chat: new Date(0),
    assets: new Date(0),
    intelligenceFusion: new Date(0),
    checkinHistory: new Date(0),
    sosHistory: new Date(0),
    followMeHome: new Date(0),
    followMeHomeHistory: new Date(0),
    crisis24: new Date(0)
};

export const useVersionStore = create<State & Actions>()((set,  get) =>({
    local: initialVersion,    
    setCountryAssessment: (newVersion: Date) => {set(
        state=> ({local: {...state.local,  countryAssessment: newVersion,}})
    );},
    getCountryAssessment: () => get().local.countryAssessment,
    setUserIncidents: (newVersion: Date) => {set(
        state=> ({local: {...state.local,  userIncidents: newVersion,}})
    );},
    getUserIncidents: () => get().local.userIncidents,
    setChatMessages: (newVersion: Date) => {set(
        state=> ({local: {...state.local,  chat: newVersion,}})
    );},
    getChatMessages: () => get().local.chat,
    setAssets: (newVersion: Date) => {set(
        state=> ({local: {...state.local,  assets: newVersion,}})
    );},
    getAssets: () => get().local.assets,
    setIntelligenceFusion: (newVersion: Date) => {set(
        state=> ({local: {...state.local,  intelligenceFusion: newVersion,}})
    );},
    getIntelligenceFusion: () => get().local.intelligenceFusion,
    setCrisis24: (newVersion: Date) => {set(
        state=> ({local: {...state.local,  crisis24: newVersion,}})
    );},
    getCrisis24: () => get().local.crisis24,
    setCheckinHistory: (newVersion: Date) => {set(
        state=> ({local: {...state.local,  checkinHistory: newVersion,}})
    );},
    getCheckinHistory: () => get().local.checkinHistory,
    setSOSHistory: (newVersion: Date) => {set(
        state=> ({local: {...state.local,  sosHistory: newVersion,}})
    );},
    getSOSHistory: () => get().local.sosHistory,
    setFollowMeHome: (newVersion: Date) => {set(
        state=> ({local: {...state.local,  followMeHome: newVersion,}})
    );},
    getFollowMeHome: () => get().local.followMeHome,
    setFollowMeHomeHistory: (newVersion: Date) => {set(
        state=> ({local: {...state.local,  followMeHome: newVersion,}})
    );},
    getFollowMeHomeHistory: () => get().local.followMeHome,
}));
