import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import rootReducer from "./reducers/rootReducer";
import { Socket } from './helpers/Socket'
import { socketMiddleware } from "./helpers/middleware/socket";

const store = configureStore({
  reducer: rootReducer,
  middleware: [
    ...getDefaultMiddleware(),
    socketMiddleware(new Socket()),
  ]
});
export default store;
