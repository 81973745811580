import { SearchRounded, CancelPresentationOutlined } from "@mui/icons-material";

import store from "../store"
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createIncident,
  deleteIncident,
  getIncidentCategories,
  getIncidentTypes,
  searchIncidents,
  createIncidentNotification
} from "../helpers/api";
import { incidents, timezones } from "../helpers/constants";
import { formatDateCustom } from "../helpers/utils";
import { customDropdown } from "../components/CustomDropdown";
// import DateFnsUtils from '@date-io/date-fns';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
// import MapPicker from 'react-google-map-picker'
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { AnimatePresence, motion } from "framer-motion";
import UserTooltip from "../components/UserTooltip";

import DisplayTable from "../components/DisplayTable";
import DeleteModal from "../components/DeleteModal";
import PushIncidentModal from "../components/PushIncidentModal";

import {formatDateTimeString} from "../helpers/utils"

import { RootState } from "../reducers/rootReducer";

import { useSelector } from 'react-redux';
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import UserIcon from "../components/UserIcon";

const IncidentsPage = (props) => {
  const coordinates = props.coordinates;
  // const [type, setType] = useState('')
  const [summary, setSummary] = useState("");
  const [locationTimezone, setLocationTimezone] = useState("");
  const [description, setDescription] = useState("");
  // const [loading, setLoading] = useState(true);
  const { loading, companyName, profile, role } = props;
  const [sendingData, setSendingData] = useState(false);
  // const [sendingInviteLink, setSendingInviteLink] = useState(false);

  const [categoryName, setCategoryName] = useState("");
  const [type, setType] = useState("");

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [pushIncident, setPushIncident] = useState(null) 
  const [errorText, setErrorText] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [oldRows, setOldRows] = useState([
    {
      id: "No incidents",
      creator: {},
      longitude: null,
      latitude: null,
      category_name: "No incidents",
      type_name: null,
      started_at_timestamp: null,
      ended_at_timestamp: null,
      impact_long_description: null,
      impact_rating: null,
      location_timezone: null,
      reported_at: null,
      reviewed_at: null,
      summary: null,
      approved: null,
      geometry_as_text: null,
      impact_short_description: null,
    },
  ]);
  const [searchInput, setSearchInput] = useState("");
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(true);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [locationLongitude, setLocationLongitude] = useState("0.0");
  const [locationLatitude, setLocationLatitude] = useState("0.0");
  const [timezone, setTimezone] = useState("");

  const [editingIncident, setEditingIncident] = useState({ id: null });
  const [deletingIncident, setDeletingIncident] = useState({
    id: null,
    name: null,
    category_name: null,
    type_name: null,
    started_at_timestamp: null,
    summary:null,
    
  });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openIncidentModal, setOpenIncidentModal] = useState(false);

  const [incidentCount, setIncidentCount] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [incidentPage, setIncidentPage] = React.useState(0);
  const [incidentRowsPerPage, setIncidentRowsPerPage] = useState(5);
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const res = await getIncidentCategories();
      if (res.status === 200) {
        setCategories(
          res.data.results.map((category) => {
            return { value: category.category_name };
          })
        );
      }
    };
    if (!loading) {
      fetchData();
    }
  }, [loading]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getIncidentTypes(categoryName);
      if (res.status === 200) {
        setTypes(
          res.data.results.map((type) => {
            return { value: type.type_name };
          })
        );
      }
    };
    if (categories.length > 0 && categoryName !== "") {
      if (!editingIncident.id) {
        setType("");
      }

      fetchData();
    }
  }, [categories.length, categoryName, editingIncident.id]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setSendingData(true);
      const res = await createIncident(
        categoryName,
        type,
        summary,
        locationTimezone,
        selectedStartDate,
        selectedEndDate,
        description,
        locationLongitude,
        locationLatitude,
        editingIncident.id
        // navigate
      );

      try {
        if (res.status === 201 || res.status === 200 || res.status === 204) {
          setSendingData(false);
          setErrorText("");
          setOpen(true);
          setCategoryName("");
          setType("");
          setSummary("");
          setLocationTimezone("");
          setDescription("");
          setLocationLongitude(null);
          setLocationLatitude(null);
          setSelectedStartDate(null);
          setSelectedEndDate(null);
          setReload(true);
          if (editingIncident.id) {
            onCancelClick();
          }
          const incidents = await searchIncidents(
            "",
            null,
            null,
            navigate,
            // incidentPage + 1,
            // incidentRowsPerPage
          );
          console.log("TOTAL ROW COUTN IS ")
          console.log(incidents.data.results.length)
          setRowCount(incidents.data.results.length)
          if (incidents.status === 200 || incidents.data.results.length === 0) {
            setRows([]);
          } else if (incidents.status === 204 || incidents.status === 200) {
            setRows(incidents.data.results);
          }
        } else if (res.status === 400 && res.data["email"]) {
          setSendingData(false);
          setErrorText("Email: " + res.data["email"]);
          setErrorOpen(true);
        } else if (res.status === 403 && res.data["results"]) {
          setSendingData(false);
          setErrorText(res.data["results"]);
          setErrorOpen(true);
        } else if (res.status === 404) {
          setSendingData(false);
          setErrorText("Something went wrong");
          setErrorOpen(true);
        } else {
          setSendingData(false);
          setErrorText("Unknown error");
          setErrorOpen(true);
        }
      } catch (err) {
        setSendingData(false);
        setErrorText("Fatal error");
        setErrorOpen(true);
      }
      setSendingData(false);
      // handleCloseConfirmation();
    },
    [
      categoryName,
      type,
      summary,
      locationTimezone,
      selectedStartDate,
      selectedEndDate,
      description,
      locationLongitude,
      locationLatitude,
      editingIncident.id,
      navigate,
      incidentPage,
      incidentRowsPerPage,
    ]
  );

  const Backdrop = ({ children, onClick }) => {
    return (
      <motion.div
        style={{
          zIndex: 200,
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          background: "#00000056",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={onClick}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {children}
      </motion.div>
    );
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setDeletingIncident({
      id: null,
      name: null,
      category_name: null,
      type_name: null,
      started_at_timestamp: null,
      summary:null,
    });
  };

  const handleOpenDeleteModal = (row) => {
    setOpenDeleteModal(true);
    setDeletingIncident(row.original);
  };

  const handleCloseIncidentModal = () => {
    setOpenIncidentModal(false);
    setPushIncident(null)
    // setDeletingIncident({
    //   id: null,
    //   name: null,
    //   category_name: null,
    //   type_name: null,
    //   started_at_timestamp: null,
    //   summary:null,
    // });
  };

  const handleOpenIncidentModal = (row) => {
    console.log("OPEN MODAL!!!")
    console.log(row.original)
    setOpenIncidentModal(true);
    setPushIncident(row.original)
    // setDeletingIncident(row.original.original);
  };

  const onEditClick = useCallback((row) => {
    console.log("row for submit is ", row)
    setEditingIncident(row);
    setCategoryName(row.category_name);
    setType(row.type_name);
    setSummary(row.summary);
    setLocationTimezone(row.location_timezone);
    setDescription(row.impact_long_description);
    setLocationLongitude(row.longitude);
    setLocationLatitude(row.latitude);
    setSelectedStartDate(row.selected_start_date);
    setSelectedEndDate(row.selected_end_date);
  }, []);

  const onCancelClick = () => {
    setEditingIncident({ id: null });
    setCategoryName("");
    setType("");
    setSummary("");
    setLocationTimezone("");
    setDescription("");
    setLocationLongitude("0.0");
    setLocationLatitude("0.0");
    setSelectedStartDate(null);
    setSelectedEndDate(null);
  };

  const handleDelete = async (id) => {
    console.log("delet id is ", id)
    const res = await deleteIncident(id);
    console.log(res);
    console.log(res.status);
    setReload(true);
    setOpenDeleteModal(false);
    onCancelClick();
  };

  const handlePushIncident = async (incident, selectedUsers) => {

      // console.log(user)
      console.log("PUSHED ")
      console.log(selectedUsers)
      console.log(incident)
      for (const user of selectedUsers){
          const res = await createIncidentNotification(
            incident.id,
            user.id
          );
          console.log("WRITIGN TO DB NOTI")
          console.log(res)

          store.dispatch({
            type: "socket/sendincident", 
            userId:user.id, 
            companyName:user.companyName,
            adminId:adminId,
          })
    }
    setOpenIncidentModal(false);
  }

  const handleChangePage = (event, newPage) => {
    setIncidentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setIncidentRowsPerPage(parseInt(event.target.value, 10));
    setIncidentPage(0);
  };

  const columns = useMemo(() => [
      {
        accessorKey: "creator.firstName",
        header: "User",
        Cell: ({ cell, row }) => (
          <>
          <UserTooltip
            // userRole={role}
            users={[row.original.creator]}
            // title="Add"
            placement="top"
            arrow
            describeChild
          >
            <Grid item>
              <UserIcon user={row.original.creator} handleClickProfile={()=>{}}>
              </UserIcon>
            </Grid>
          </UserTooltip>
          </>
        ),
        size:50
      },
      {
        // accessorKey: "display_ended",
        header: "Notify Users",
        Cell: ({ cell, row }) => (
          <>
          <Button
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: '#005A31',
              color: 'white',
              '&:hover': {
                backgroundColor: 'green', // Change background color on hover if desired
              },
            }}
            onClick={()=>{
              handleOpenIncidentModal(row)
            }}
          >
            Notify
          </Button>
          </>
        ),
        size:50
      },
      {
        accessorKey: "category_name",
        header: "Category",
        // size:50
        maxSize:2
      },
      {
        accessorKey: "type_name",
        header: "Type",
        maxSize:2
      },
      {
        accessorKey: "summary",
        header: "Summary",
      },
      {
        accessorKey: "display_started",
        header: "Start Date",
      },
      {
        accessorKey: "display_ended",
        header: "End Date",
      },

    ],
    []
  );

  const incidentsTable = useMemo(() => {
    const displayTable = (
        <DisplayTable
          columns={columns}
          data={rows}
          onEditClick={onEditClick}
          handleOpenDeleteModal={handleOpenDeleteModal}
          columnFilters={columnFilters}
          globalFilter={globalFilter}
          sorting={sorting}
          pagination={pagination}
          rowCount={rowCount}
          isRefetching={isRefetching}
          isError={isError}
          isLoading={isLoading}
          setColumnFilters={setColumnFilters}
          setGlobalFilter={setGlobalFilter}
          setPagination={setPagination}
          setSorting={setSorting}
          enableRowActions={true}
        />
      
    ); 
    
    return displayTable 
  }, [
    editingIncident,
    // incidentCount,
    rowCount,
    pagination,
    // incidentPage,
    // incidentRowsPerPage,
    onEditClick,
    rows,
  ]);

  useEffect(() => {
    // eslint-disable-next-line require-jsdoc
    async function fetchData() {
      setReload(true);
      if (!loading) {
        const incidents = await searchIncidents(
          searchInput,
          null,
          null,
          navigate,
          // incidentPage + 1,
          // incidentRowsPerPage
        );

        console.log("TOTAL ROW COUTN IS ")
        console.log(incidents.data.results.length)
        setRowCount(incidents.data.results.length)
        if (incidents.status !== 200 || !incidents.data.results.length) {
          console.log("received incidents", incidents)
          setRows([]);
        } else if (incidents.status === 200) {
          setRows(() => {
            return incidents.data.results.map((incident)=>({
            ...incident, 
            display_started: formatDateTimeString(incident.started_at_timestamp),
            display_ended: formatDateTimeString(incident.ended_at_timestamp)
          }))});
          setIncidentCount(incidents.data.count);
        }
      }
    }
    if ((!loading && role) || (role && reload)) {
      fetchData();

      setReload(false);
    }

    if (coordinates) {
      setLocationLongitude(coordinates.lng);
      setLocationLatitude(coordinates.lat);
    }
  }, [
    coordinates,
    incidentPage,
    incidentRowsPerPage,
    loading,
    navigate,
    reload,
    role,
    searchInput,
  ]);

  const handleClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setErrorText("");
  };

  const handleCloseError = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
    setErrorText("");
  };

  const handleEndDateChange = (date) => {
    if (date > new Date()) {
      setSelectedEndDate(null);
    } else {
      setSelectedEndDate(date);
    }
    // setReload(true);
  };

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    // setReload(true);
  };

  const [affectingInc, setAffectingInc] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  
  const invitedUsers = useSelector((state: RootState) => state.socket.invitedUsers);
  const adminId = useSelector((state: RootState) => state.user.id);
  const handleSelectUser = (event) => {
    const user = event.target.value
    console.log("user is ",  user)
    setSelectedUsers(user)
  };

  const handleAffectingIncidents = useCallback(() => {
    const currentState = affectingInc;
    setAffectingInc(!currentState);
    setOldRows(rows);
    if (!currentState) {
      setRows(incidents);
    } else {
      setRows(oldRows);
    }
  }, [oldRows, rows, affectingInc]);

  return (
    <Grid container justifyContent="center" direction="row">
      <Grid
        item
        container
        width="30%"
        justifyContent="center"
        // spacing={0}
        // direction="column"
        // alignItems="center"
        // justifyContent="center"
        alignItems="center"
        direction="column"
        sx={{ margin: 5 }}
      >
        <AnimatePresence
          // Disable any initial animations on children that
          // are present when the component is first rendered
          initial={false}
          // Only render one component at a time.
          // The exiting component will finish its exit
          // animation before entering component is rendered
          exitBeforeEnter={true}
          // Fires when all exiting nodes have completed animating out
          onExitComplete={() => null}
        >
          {openDeleteModal && (
            <DeleteModal
              titleText={"Are you sure you want to Delete the following Incident?"} 
              bodyText={`${deletingIncident.type_name} (${deletingIncident.started_at_timestamp}) ${deletingIncident.summary}`}
              handleClose={handleCloseDeleteModal} 
              handleDelete={(e) => {handleDelete(deletingIncident.id)}} 
            />
          )}
          {openIncidentModal && (
            <PushIncidentModal
              incident={pushIncident}
              handleClose={handleCloseIncidentModal} 
              handlePush={()=>handlePushIncident(pushIncident, selectedUsers)} 
              selectedUsers={selectedUsers}
              handleSelectUser={handleSelectUser}
              users={invitedUsers}
            />
          )}
        </AnimatePresence>
        <Snackbar
          onClose={handleClose}
          open={open && errorText === "" && !sendingData}
          autoHideDuration={6000}
        >
          <Alert severity="success">
            Incident has been successfully created
          </Alert>
        </Snackbar>
        <Snackbar
          onClose={handleCloseError}
          open={errorOpen && errorText !== "" && !sendingData}
          autoHideDuration={6000}
        >
          <Alert severity="error">{errorText}</Alert>
        </Snackbar>
        {/* <Grid container spacing={3}>
          <Grid item>
            <Typography 
              component="h1" 
              variant="h5"
            >
              My Incidents
            </Typography>
          </Grid>
        </Grid> */}
        {/* <br></br> */}
        <Grid item>
          <Typography component="h1" variant="h5">
            {editingIncident.id
              ? "Edit an existing Incident"
              : "Create a new Incident"}
          </Typography>
          <Typography component="h1" variant="h5">
            {editingIncident.id ? (
              <Chip
                color="warning"
                label="Editing an Incident"
                variant="outlined"
                onDelete={() => {
                  onCancelClick();
                }}
              />
            ) : null}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1"></Typography>
        </Grid>
        <Grid item>
          <Box
            component="form"
            onSubmit={(e) => {
              handleSubmit(e);
              setSearchInput("");
            }}
            noValidate
            sx={{ mt: 1 }}
          >
            {customDropdown(categories,"Category Name",categoryName,setCategoryName, profile)}
            {customDropdown(types, "Type Name", type, setType, profile)}
            <TextField 
              label="Summary" 
              fullWidth 
              sx={{ paddingBottom: 2 }}
              onChange={(e) => setSummary(e.target.value)}
              value={summary}
            >
              Summary
            </TextField>
            {customDropdown(timezones,"Location Timezone",timezone,setTimezone,profile)}
            <TextField
              label="Impact Description"
              fullWidth
              sx={{ paddingBottom: 2 }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            >
              Description
            </TextField>
            <Grid container direction="row">
              <TextField
                sx={{ width: "48%", paddingRight: 2, paddingBottom: 2 }}
                type="number"
                label="Longitude"
                value={locationLongitude}
                variant="outlined"
                inputProps={{
                  maxLength: 13,
                  step: "1",
                }}
                onChange={(e) => setLocationLongitude(e.target.value)}
              />
              <TextField
                sx={{ width: "48%", paddingBottom: 2 }}
                type="number"
                label="Latitude"
                value={locationLatitude}
                variant="outlined"
                inputProps={{
                  maxLength: 13,
                  step: "1",
                }}
                onChange={(e) => setLocationLatitude(e.target.value)}
              />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container direction="row" justifyContent="space-between">
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  maxDate={new Date()}
                  InputProps={{
                    classes: {
                      // input: classes.input,
                    },
                  }}
                  label="Start"
                  value={selectedStartDate}
                  onChange={handleStartDateChange}
                  renderInput={(params) => <TextField required {...params} />}
                />
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  minDate={selectedStartDate || new Date()}
                  maxDate={new Date()}
                  InputProps={{
                    classes: {
                      // input: classes.input,
                    },
                  }}
                  label="End"
                  value={selectedEndDate}
                  onChange={handleEndDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </LocalizationProvider>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              // onClick={handleLogin}
            >
              {sendingData ? (
                <CircularProgress size="1.55rem" color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </Grid>
        {/* </CardContent> */}
      </Grid>

      <Grid item width="50%" sx={{ margin: 5 }}>
        {/* <Grid item>
          <Typography component="h1" variant="h5">
            <br></br>
          </Typography>
        </Grid>
        <br></br>*/}
        <Grid item> 
          <Grid container spacing={3} direction="row" justifyItems="center">
            <Grid item>
              <Typography component="h1" variant="h5">
                Incident History
              </Typography>
              <br></br>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1"></Typography>
        </Grid>
        {incidentsTable}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ user }) => ({
  loading: user.loading,
  companyName: user.companyName,
  profile: user.profile,
  role: user.role,
});
export default connect(mapStateToProps)(IncidentsPage);
