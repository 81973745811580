import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./topbar.css";
import {
    Settings,
    MailLock,
    Home,
    LocationOnOutlined,
    Business,
    BusinessCenter,
    Newspaper,
    Summarize,
    FlagCircle,
    NotificationImportant,
    WarningAmber,
    Assessment,
    InboxOutlined,
    MailOutline
} from "@mui/icons-material";
import ChatIcon from "@mui/icons-material/Chat";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import globalConfig from "../../../config.json";

import UserIcon from "../../UserIcon";
import {
    Button,
    Chip,
    CircularProgress,
    Divider,
    Drawer,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    SvgIcon,
    Tooltip,
    useMediaQuery,
    useTheme
} from "@mui/material";

import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import logo from "../../../assets/logo_reversed.svg";
import { AdminUser, useUserStore } from "../../../stores/userStore";
import Chatbox from "../../Chatbox";
import { Anchor } from "mapbox-gl";

import {ReactComponent as CheckIn} from '../../../assets/check_in_icon.svg';
// import {ReactComponent as Alerts} from '../../../assets/alert_icon.svg'
import {ReactComponent as FollowMeHome} from '../../../assets/follow_me_home.svg';
import {ReactComponent as SOS} from '../../../assets/sos_icon.svg';
import {ReactComponent as Alert} from '../../../assets/alert_icon.svg';
import { useDataStore } from "../../../stores/dataStore";
import Marquee from "./Marquee";
import UserTooltip from "../../UserTooltip";
import { useWebSocketStore } from "../../../stores/websocketStore";
import stringToColour from "../../stringToColour";
import SidebarDrawer from "./SidebarDrawer";

const classes = {
    list: {
    // overflowY: "auto",
        margin: 0,
        padding: 0,
        listStyle: "none",
        height: "100%",
        width: "2000px",
        "::-webkit-scrollbar": {
            width: "0.4em",
        },
        "::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.1)",
            outline: "1px solid slategrey",
        },
        "& .MuiDrawer-paper": { boxSizing: "border-box", width: {xs: '40vw', sm : '30vw', md: '25vw', lg: '18vw'} },
    },
};

// create a map of numbers and their corresponding icons
const icons: { [key: number]: JSX.Element } = {
    0: <Home />,
    1: <LocationOnOutlined />,
    2: <Business />,
    3: <FlagCircle />,
    4: <BusinessCenter />,
    5: <Newspaper />,
    6: <Summarize />,
    7: <WarningAmber />,
    8: <NotificationImportant />,
    9: <Assessment />,
    10: <MailLock />,
};


const Topbar: React.FC = () => {
    const { companyName, role, isMinimized, setIsMinimized, user } = useUserStore((state) => ({
        companyName: state.user.companyName,
        role: state.user.role,

        isMinimized: state.isChatMinimized,
        setIsMinimized: state.setIsChatMinimized,
        user: state.user,

    }));

    const { checkinHistory, sosHistory, followMeHomeLocations, userIncidents } = useDataStore((state) => ({
        checkinHistory: state.checkinHistory,
        sosHistory: state.sosHistory,
        followMeHomeLocations: state.followMeHomeLocations,
        userIncidents: state.userIncidents,
    }));


    const allAlerts = useMemo(() => userIncidents.flatMap(incident => incident.alerts || []), [userIncidents]);

    const renderAlertListItem = (alert, index) => {
        const date = alert?.created_at ? new Date(alert?.created_at) : null;
        const time = date ? `${date.toLocaleDateString()} ${date.toLocaleTimeString()}` : 'Unknown time';
        const incident = userIncidents.find(incident => incident.id === alert.incident_id);
        const category = incident?.category_name || 'Unknown';
        const categoryColor = stringToColour(category);
        // const address = incidentAddressMap?.get(`${incident?.latitude?.toFixed(6)}+${incident?.longitude?.toFixed(6)}`) || 'Fetching address...';
        return (
            <ListItem key={index} disablePadding>
                <ListItemButton>
                    <ListItemIcon>
                        <Tooltip title={incident ? (
                            <div>
                                <strong>{incident.summary}</strong><br/>
                                {incident.description}<br/>
                                <em>Reported At: {new Date(incident.reported_at).toLocaleDateString()}</em>
                            </div>
                        ) : 'No incident information'}>
                            <Grid container alignItems={'center'} spacing={3}  >

                                <Grid item xs={6}>
                                    <Typography color={globalConfig['primaryMain']} variant="body1" > {incident?.type_name || 'Unknown type'}</Typography>
                                </Grid>


                                <Grid item xs={5}>
                                    <Chip
                                        size={'small'}
                                        label={category}
                                        sx={{ backgroundColor: categoryColor, opacity: 0.8, color: 'black', color: '#fff', marginRight: '8px' }}
                                    />
                                </Grid>
                            </Grid>
                            {/* <a href={`#incident-${alert.id}`} style={{ textDecoration: 'none' }}> */}


                            <Grid sx={{width: 300}}>
                                <ListItemText
                                    sx={{
                                        maxWidth: '300px',
                                        fontSize: '12px',
                                        color: 'black',
                                        minWidth: '300px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: 'vertical',
                                    }}
                                    primary={incident?.description}
                                    secondary={time}

                                />
                            </Grid>

                            {/* </a> */}
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText
                        // primary={`Location: ${address}`}
                        secondary={`Latitude:  ${incident?.latitude.toFixed(6)}, Longitude: ${incident?.longitude.toFixed(6)}`}
                    />
                </ListItemButton>
            </ListItem>
        );
    };

    const listAlerts = (alerts) => (
        <Box sx={{ width: '500px' }} onClick={toggleDrawer('Alerts', false)} onKeyDown={toggleDrawer('Alerts', false)}>
            <List>
                {alerts?.map(renderAlertListItem)}
                {!alerts.length && 'No data available'}
            </List>
            <Divider />
        </Box>
    );


    const managedUsers = (user as AdminUser).managedUsers;
    const loading = false;

    const [openProfile, setOpenProfile] = useState(false);
    const iconEl = null;

    const [mobileOpen, setMobileOpen] = useState(false);

    const navigate = useNavigate();


    const handleClose = () => {
        setOpenProfile(false);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    const handleProfile = () => {
        navigate("/profile");
        handleClose();
    };

    const handleCloseUserSettings = () => {
        navigate("/user_settings");
        handleClose();
    };
    const handleCloseSettings = () => {
        navigate("/settings");
        handleClose();
    };
    const handleCloseMaintenance = () => {
        navigate("/maintenance");
        handleClose();
    };
    const handleCloseRisk = () => {
        navigate("/risk_appetite");
        handleClose();
    };
    const handleClickProfile = (event: React.MouseEvent<HTMLElement>) => {
        setOpenProfile(true);
    };

    const { logout } = useWebSocketStore();

    const handleLogout = () => {
        handleClose();
        console.log("##### Loging out!!!");
        logout();
        navigate("/login");
    };

    const pages = useMemo(() => role ? [
        "Home",
        "Map",
        "Company",
        "Incidents",
        "Assets",
        // "News",
        "Countries",
        // "Risk Appetite",
        "Alerts",
        "Queries & Reports",
    ] : [], [role]);


    const [state, setState] = React.useState({
        'Check In': false,
        'Follow': false,
        'SOS': false,
        'Alerts': false,
    });

    const bannerIcons = {
        'Check In': CheckIn,
        'Follow': FollowMeHome,
        'SOS': SOS,
        'Alerts': Alert,
    };

    const items = useMemo(() => {
        return {
            'Check In': checkinHistory,
            'Follow': followMeHomeLocations,
            'SOS': sosHistory,
            'Alerts': allAlerts,
        };
    }, [checkinHistory, followMeHomeLocations, sosHistory]);


    const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setState({ ...state, [anchor]: open });

        };



    const getUserForCheckin = (object) =>
        managedUsers?.find(user => Number(user?.id) === Number(object?.receiver_id));

    const getUserForSOS = (object) =>
        managedUsers?.find(user => Number(user?.id) === Number(object?.creator_id));

    const formatTime = (date) => date ? `${date.toLocaleDateString()} ${date.toLocaleTimeString()}` : 'Unknown time';

    const renderUserIcon = (user, isSOSActive, isCheckinActive, source) => (
        <UserIcon
            color={source === 'Follow' ? '#0288d1' : globalConfig['primaryLight']}
            isSOSActive={isSOSActive}
            isCheckinActive={isCheckinActive}
            user={user}
            handleClickProfile={() => {}}
        />
    );

    const renderCheckinListItem = (object, index) => {
        const date = object?.created_at ? new Date(object?.created_at) : null;
        const time = formatTime(date);
        const currUser = getUserForCheckin(object);

        return (
            <ListItem key={index} disablePadding>
                <ListItemButton>
                    <ListItemIcon>
                        <UserTooltip users={[currUser]} placement="top" arrow describeChild>
                            <Grid item>
                                {renderUserIcon(currUser, false, object?.status === 'pending', 'Check In')}
                            </Grid>
                        </UserTooltip>
                    </ListItemIcon>
                    <ListItemText primary={`${currUser?.firstName} ${currUser?.surname}`} secondary={time} />
                    <ListItemText primary={`Check-in status ${object?.status}`} />
                </ListItemButton>
            </ListItem>
        );
    };

    const renderSOSListItem = (object, index) => {
        const date = object?.date_created ? new Date(object?.date_created) : null;
        const time = formatTime(date);
        const currUser = getUserForSOS(object);

        return (
            <ListItem key={index} disablePadding>
                <ListItemButton>
                    <ListItemIcon>
                        <UserTooltip users={[currUser]} placement="top" arrow describeChild>
                            <Grid item>
                                {renderUserIcon(currUser, !object?.approved, false, 'SOS')}
                            </Grid>
                        </UserTooltip>
                    </ListItemIcon>
                    <ListItemText primary={`${currUser?.firstName} ${currUser?.surname}`} secondary={time} />
                    <ListItemText primary={object?.approved ? 'SOS approved' : 'SOS status pending'} />
                </ListItemButton>
            </ListItem>
        );
    };

    const listCheckin = (data) => (
        <Box sx={{ width: '500px' }} onClick={toggleDrawer('Check In', false)} onKeyDown={toggleDrawer('Check In', false)}>
            <List>
                {data?.map(renderCheckinListItem)}
                {!data.length && 'No data available'}
            </List>
            <Divider />
        </Box>
    );

    const listSOS = (data) => (
        <Box sx={{ width: '500px' }} onClick={toggleDrawer('SOS', false)} onKeyDown={toggleDrawer('SOS', false)}>
            <List>
                {data?.map(renderSOSListItem)}
                {!data.length && 'No data available'}
            </List>
            <Divider />
        </Box>
    );



    const [currentMessage, setCurrentMessage] = useState<string>("");
    const [currentSource, setCurrentSource] = useState<string>("");
    const [messageType, setMessageType] = useState<string>("");

    useEffect(() => {
        const checkForNewMessages = () => {
            // Extract and assign priorities to SOS and Check In items
            const checkInItems = items["Check In"]?.map(item => ({
                ...item,
                date: new Date(item.created_at),
                priority: 1 // Normal priority for Check In items
            })) || [];

            const sosItems = items["SOS"]?.map(item => ({
                ...item,
                date: new Date(item.date_created),
                priority: item.approved === false ? 2 : 1 // Higher priority for SOS with approved=false
            })) || [];

            const followMeHome = items["Follow"]?.map(item => ({
                ...item,
                date: new Date(item.createdAt),
                priority: 1 // Higher priority for SOS with approved=false
            })) || [];

            // Combine all items
            const allItems = [...checkInItems, ...sosItems, ...followMeHome];

            // Sort by priority first, then by date in descending order
            allItems.sort((a, b) => {
                if (b.priority !== a.priority) {
                    return b.priority - a.priority; // Higher priority comes first
                }
                return b.date - a.date; // Sort by date in descending order
            });

            // Get the most recent item
            const newItem = allItems[0];

            if (!newItem) {
                setCurrentSource('');
                setCurrentMessage('');
                setMessageType(''); // Clear the message type
                return;
            }

            // Set the current source based on item type
            const source = newItem?.status ? 'Check In' : newItem?.date_created !== undefined ? 'SOS' : 'Follow';
            setCurrentSource(source);

            // Format the date and message
            const dateTime = `[${newItem.date.toLocaleDateString()} ${newItem?.date.toLocaleTimeString()}]`;
            const currUser = managedUsers?.find(user => Number(user.id) === Number(newItem?.userID || newItem?.creator_id ||  newItem?.receiver_id));
            const message = `${dateTime} (${currUser?.firstName || ''} ${currUser?.surname || ''}) ` +
            `${source === 'SOS' ?
                (newItem?.approved
                    ? 'SOS approved'
                    : 'SOS status pending') : ''}
            ${source ==='Check In' ?
        `Check-in status ${newItem?.status}` : ''}
            ${ source === 'Follow' ?
        `Latitude: ${newItem?.latitude?.toFixed(6)}, Longitude: ${newItem?.longitude?.toFixed(6)} Location: ${addressMap.get(`${newItem?.latitude?.toFixed(6)}+${newItem?.longitude?.toFixed(6)}`)}`
        : ''}`;
            setCurrentMessage(message);
            // Determine and set the message type based on severity
            let messageType = '';
            if (source === 'SOS') {
                messageType = newItem?.approved ? 'success' : 'error';
            } else if (source === 'Check In') {
                messageType = newItem?.status === 'pending' ? 'warning' : 'success';
            } else if (source === 'Follow') {
                messageType = 'info'; // Example color for Follow
            }
            setMessageType(messageType);
        };

        checkForNewMessages();
    }, [items, managedUsers]);


    const useFollowMeHome = (followMeHomeLocations) => {
        const [addressMap, setAddressMap] = useState(new Map());
        const [fetchQueue, setFetchQueue] = useState([]);

        const fetchAddress = useCallback(async (latitude, longitude) => {
            const key = `${latitude?.toFixed(6)}+${longitude?.toFixed(6)}`;
            if (!addressMap.has(key)) {
                try {
                    const response = await fetch(
                        `https://geocode.maps.co/reverse?lat=${latitude}&lon=${longitude}&api_key=66e434c2e74ca466901506ylzc43c64`,
                        { method: 'GET' }
                    );
                    const data = await response.json();
                    const address = data?.display_name || 'Address not found';
                    setAddressMap(prevMap => new Map(prevMap).set(key, address));
                } catch (error) {
                    console.error('Failed to fetch address:', error);
                }
            }
        }, [addressMap]);

        useEffect(() => {
            if (fetchQueue.length > 0) {
                const [nextLatitude, nextLongitude] = fetchQueue[0];
                setTimeout(
                    () => fetchAddress(nextLatitude, nextLongitude), 1000);
                setFetchQueue(queue => queue.slice(1)); // Remove the processed item
            }
        }, [fetchQueue, fetchAddress]);

        useEffect(() => {
            const newFetchQueue = [];
            followMeHomeLocations?.forEach(object => {
                const key = `${object?.latitude?.toFixed(6)}+${object?.longitude?.toFixed(6)}`;
                if (!addressMap.has(key)) {
                    newFetchQueue.push([object?.latitude, object?.longitude]);
                }
            });
            setFetchQueue(newFetchQueue);
        }, [followMeHomeLocations, addressMap]);

        return { addressMap };
    };

    const { addressMap } = useFollowMeHome(followMeHomeLocations);

    const listFollowMeHome = (data) => (
        <Box
            sx={{ width: '500px' }}
            onClick={toggleDrawer('Follow', false)}
            onKeyDown={toggleDrawer('Follow', false)}
        >
            <List>
                {data?.map((object, index) => {
                    const date = object?.createdAt ? new Date(object?.createdAt) : new Date();
                    const time = date ? `${date.toLocaleDateString()} ${date.toLocaleTimeString()}` : 'Unknown time';
                    const key = `${object?.latitude?.toFixed(6)}+${object?.longitude?.toFixed(6)}`;
                    const address = addressMap.get(key) || 'Fetching address...';
                    const currUser = managedUsers?.find(user => Number(user.id) === Number(object?.userID));

                    return (
                        <ListItem key={index} disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <UserTooltip users={[currUser]} placement="top" arrow describeChild>
                                        <Grid item>
                                            {renderUserIcon(currUser, false, false, 'Follow')}
                                        </Grid>
                                    </UserTooltip>
                                </ListItemIcon>
                                <ListItemText
                                    primary={`${currUser?.firstName || 'Unknown'} ${currUser?.surname || ''}`}
                                    secondary={time}
                                />
                                <ListItemText
                                    primary={`Location: ${address}`}
                                    secondary={`Latitude: ${object?.latitude.toFixed(6)}, Longitude: ${object?.longitude.toFixed(6)}`}
                                />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
                {!data.length && <ListItem><ListItemText primary="No data available" /></ListItem>}
            </List>
            <Divider />
        </Box>
    );

    const list = (anchor: Anchor, data) => {
        switch (anchor) {
        case 'Check In':
            return listCheckin(data); // Render the Check-In list

        case 'SOS':
            return listSOS(data); // Render the SOS list

        case 'Follow':
            return listFollowMeHome(data); // Placeholder for Follow

        case 'Alerts':
            return listAlerts(data);
        default:
            return (
                <Box sx={{ width: '500px' }}>
                    <Typography>No data available for {anchor}</Typography>
                </Box>
            );
        }
    };


    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // Check if the screen size is 'sm' or 'xs'
    const userBanner = useMemo(() => {


        return [
            <Grid key="marquee" item xs={3} sm={4} md={4.5}>
                <Marquee message={currentMessage} source={currentSource} type={messageType} />
            </Grid>,
            <Grid container key="panel"  xs={3} md={5.1} column={2}>
                {(['Check In', 'Follow', 'SOS', 'Alerts'] as const).map((anchor) => {
                    const itemsForAnchor = items?.[anchor] || [];
                    const isSOSActive = anchor === 'SOS' && itemsForAnchor?.some(item => item.approved === false);
                    const isCheckInActive = anchor === 'Check In' && itemsForAnchor?.some(item => item.status === 'pending');
                    const isFollowMeHomeActive = anchor === 'Follow' && itemsForAnchor?.length > 0;

                    // Determine if any type of activity is active
                    let bgColor = globalConfig['primaryLight']; // Default color
                    if (isSOSActive) {
                        bgColor = globalConfig['errorMain'];
                    } else if (isCheckInActive) {
                        bgColor = '#ffb74d'; // Assuming you have a warning color defined
                    } else if (isFollowMeHomeActive) {
                        bgColor = '#0288d1';
                    }

                    return (
                        <Grid item key={`grid-${anchor}`}>
                            <React.Fragment key={anchor}>
                                <Tooltip title={isSmallScreen ? anchor : ''} placement="top">
                                    <span>
                                        <Button
                                            sx={{ color: globalConfig['primaryMain'] }}
                                            startIcon={<SvgIcon sx={{ height: 15, width: 15 }} component={bannerIcons[anchor]} />}
                                            endIcon={
                                                <Grid
                                                    sx={{
                                                        animation: isSOSActive ? 'pulse 1s infinite' : null,
                                                        pr: 0.5,
                                                        pl: 0.5,
                                                        borderRadius: '5px',
                                                        backgroundColor: bgColor
                                                    }}
                                                >
                                                    <Typography fontWeight={700} color="white" fontSize={12}>
                                                        {itemsForAnchor.length}
                                                    </Typography>
                                                </Grid>
                                            }
                                            onClick={toggleDrawer(anchor, true)}
                                        >
                                            {!isSmallScreen && (
                                                <Typography variant="body2">
                                                    {anchor}
                                                </Typography>
                                            )}
                                        </Button>
                                    </span>
                                </Tooltip>
                                <Drawer
                                    sx={{ top: 200, pt: 100 }}
                                    anchor="right"
                                    open={state[anchor]}
                                    onClose={toggleDrawer(anchor, false)}
                                >
                                    {list(anchor, itemsForAnchor)}
                                </Drawer>
                            </React.Fragment>
                        </Grid>
                    );
                })}
            </Grid>
        ];
    }, [currentMessage, currentSource, bannerIcons, items, globalConfig, state, toggleDrawer, list]);

    const { isConnected } = useWebSocketStore((state) => ({
        isConnected: state.isConnected,
    }));
    return (
        <Grid container position="absolute">
            <AppBar
                key="app-bar"
                style={{ background: "white" }}
                position="fixed"
                sx={{
                    p: 0,
                    left: 0,
                    height: "80px",
                    width: { sm: `calc(100%)`,  },
                    boxShadow: "none",
                    borderBottom: "1px solid #e0e0e0",
                    backgroundColor: "#194B4B",
                    color: '#DCE0DA'
                }}
            >

                <Toolbar
                    disableGutters
                    sx={{ zIndex: 100, position: "static", top: 0, backgroundColor: "white" }}
                >


                    <Grid
                        container
                        sx={{position: 'absolute',   paddingLeft:  {xs: '42vw', sm : '33vw', md: '26vw', lg: '19vw', } ,pt:  '10px', pr:'20px'}}
                        justifyContent="space-between"
                        alignItems="center"
                    >

                        {userBanner}
                        <Chip
                            size="small"
                            sx={{
                                background: isConnected ? globalConfig['primaryMain'] : "red",
                                width: 20,       // Set the width
                                height: 20,      // Set the height to the same value as width
                                borderRadius: '50%',  // Make the chip circular
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        />
                        <Grid item  >
                            <Grid item container alignContent={'center'} justifyContent={"center"}>
                                {/* <Box> */}

                                {!isSmallScreen ?
                                    <Grid
                                        onClick={() => {
                                        // setCurrentPage("Home");
                                            navigate("/");
                                        }}
                                        alignContent={'center'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        sx={{
                                            cursor: "pointer",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",


                                        }}

                                    >

                                        <Typography sx={{ fontSize: {sm: 12, md: 16, lg: 20, }, color: globalConfig['primaryMain'], fontFamily: "Poppins", fontWeight: "bold" }} variant="body1">
                                            {companyName}
                                        </Typography>
                                        <Typography sx={{pr: 2}} sx={{ color: globalConfig['primaryMain'], fontFamily: "Poppins" }} variant="caption">
                                            {role ? role.toUpperCase().replace("_", " ") : null}
                                        </Typography>
                                    </Grid> : null}
                                        
                                {loading ? (
                                    <CircularProgress
                                        size="1.7rem"
                                        color="inherit"
                                    ></CircularProgress>
                                ) : (

                                    <Grid item sx={{
                                        pl: 2,
                                        cursor: "pointer",
                                    }}>
                                        <UserIcon
                                            color={globalConfig['primaryLight']}

                                            // user={{ firstName: "S", surname: "B" }}
                                            handleClickProfile={handleClickProfile}/>

                                    </Grid>

                                )}

                                <Menu
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={iconEl}
                                    open={openProfile}
                                    onClose={handleClose}
                                    slotProps={{
                                        paper: {
                                            elevation: 0,
                                            sx: {
                                                top: 0,
                                                overflow: "visible",
                                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                mt: 1.5,
                                                "& .MuiAvatar-root": {
                                                    width: 32,
                                                    height: 32,
                                                    ml: -0.5,
                                                    mr: 1,
                                                },
                                                "&:before": {
                                                    content: '""',
                                                    display: "block",
                                                    position: "absolute",
                                                    top: 0,
                                                    right: 14,
                                                    width: 10,
                                                    height: 10,
                                                    bgcolor: "background.paper",
                                                    transform: "translateY(-50%) rotate(45deg)",
                                                    zIndex: 0,
                                                },
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                                >
                                    {["kowroo_admin", "admin"].includes(role) ? null : (
                                        <MenuItem onClick={handleProfile}>Profile</MenuItem>
                                    )}
                                    <MenuItem disabled onClick={handleCloseMaintenance}>
                                        Maintenance
                                    </MenuItem>
                                    <MenuItem disabled onClick={handleCloseRisk}>
                                        Risk Appetite Selector
                                    </MenuItem>
                                    <MenuItem disabled onClick={handleCloseUserSettings}>
                                        User settings
                                    </MenuItem>
                                    <MenuItem disabled onClick={handleCloseSettings}>Settings</MenuItem>
                                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                </Menu>
                                {/* </Box> */}
                            </Grid>
                        </Grid>
                    </Grid>

                </Toolbar>
                {/* </Container> */}
            </AppBar>

            <Box
                key="nav-box"
                component="nav"
                aria-label="mailbox folders"
                sx={classes.list}
            >

                <Drawer
                    variant="persistent"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    <SidebarDrawer
                        isMinimized={isMinimized}
                        setIsMinimized={setIsMinimized}
                    />
                </Drawer>
                <Drawer
                    PaperProps={{
                        sx: {
                            // backgroundColor: globalConfig["primaryMain"],
                            // color: "white",
                        },
                    }}
                    variant="permanent"
                    sx={classes.list}
                    open
                >


                    <Grid
                        sx={{ height: "80px", backgroundColor: globalConfig["primaryMain"] }}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <img
                            className="logo"
                            height="80px"
                            style={{padding: 15}}
                            // height={'auto'}
                            width={"auto"}
                            src={logo}
                            alt="logo"
                            onClick={() => {
                                navigate("/");
                            }}
                        />
                    </Grid>
                    <SidebarDrawer
                        isMinimized={isMinimized}
                        setIsMinimized={setIsMinimized}
                    />
                </Drawer>
            </Box>
            <Chatbox sx={{position: 'absolute' , bottom: 0, right: 0, zIndex: 101}} />
        </Grid>
    );

};

export default Topbar;