import React, {
  useState, useCallback, memo, useEffect, useMemo,
} from 'react';
import {
  Grid, TextField, Typography, Button, CircularProgress, Checkbox,
  IconButton, InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions,
} from '@mui/material';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
import zxcvbn from 'zxcvbn';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// import styles from '../../assets/styles/Main.module.scss';
import { resetPassword } from '../helpers/api';
// import LeftLanding from '../../optimizesme_components/LeftLanding/LeftLanding';
import globalConfig from '../config.json';
import parse from 'html-react-parser';
import { silentLogout } from '../helpers/utils';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, createTheme,ThemeProvider, CssBaseline } from '@mui/material';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { termsAndConditions } from '../helpers/constants';

const PasswordReset = memo((props) => {
  // const { history } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [loading, setLoading] = useState(false);
  const [uid, setUid] = useState('');
  const [token, setToken] = useState('');
  const [invitedUserId, setInvitedUserId] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [termsClosed, setTermsClosed] = useState(true);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const isSetPasswordPage = window.location.pathname.split('/')[1] === 'setpassword';
  const theme = createTheme();

  const navigate = useNavigate()

  useEffect(() => {
    setUid(window.location.href.split('/')[4]);
    const urlToken = window.location.href.split('/')[5];
    const urlInvitedUserId = window.location.href.split('/')[6];
    if (!urlToken && !urlInvitedUserId) {
      navigate("/")
    }
    if (urlToken && !urlInvitedUserId) {
      const email = urlToken.split('?')[1].split('=')[1]
      const token = urlToken.split('?')[0]
      if (!email && !token) {
        navigate("/")
      }
      setEmail(email);
      setToken(token);
    } else if (urlToken && urlInvitedUserId) {
      const email = urlInvitedUserId.split('?')[1].split('=')[1]
      if (!email || !urlToken) {
        navigate("/")
      }
      setEmail(email);
      setToken(urlToken);
      setInvitedUserId(Number(urlInvitedUserId.split('?')[0]));
    }
  }, [isSetPasswordPage, navigate]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (password && repeatPassword === password) {
      if (!termsAccepted && isSetPasswordPage) {
        setErrorText('Please accept the terms and conditions.');
        return;
      }
      setLoading(true);
      const res = await resetPassword(uid, token, password, repeatPassword, invitedUserId);
      setLoading(false);
      try {
        if (res.status === 204) {
          if (isSetPasswordPage) {
            await silentLogout();
            navigate('/login?set=true&email=' + email);
          } else {
            await silentLogout();
            navigate('/login?reset=true&email=' + email);
          }
        } else if (res.status === 400 && res.data['new_password']) {
          setErrorText(res.data['new_password'][0]);
        } else if (res.status === 400 && (res.data['uid'] || res.data['token'])) {
          setErrorText('Then link you have used is either expired or malformed.');
        } else if (res.status === 403 && res.data['err_message']) {
          setErrorText(res.data['err_message']);
        } else {
          setErrorText('Unknown error');
        }
      } catch (err) {
        setErrorText('Fatal error');
      }
    } else {
      setErrorText('Please enter matching passwords');
    }
    setSubmitted(true);
  }, [uid, token, password, repeatPassword, invitedUserId, email, termsAccepted, isSetPasswordPage, navigate]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowRepeatPassword = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const handleClose = () => {
    setTermsClosed(true);
  };

  const handleOpen = () => {
    setTermsClosed(false);
  };
  const handleChangeCheckBox = (event) => {
    setTermsAccepted(event.target.checked);
  };


  const passwordStrength = useMemo(() => {
    const strengths = ['Very weak', 'Weak', 'Fair', 'Good', 'Strong'];
    const str = strengths[zxcvbn(password).score];
    let color = '';
    if (str === 'Weak' || str === 'Very weak') {
      color = `red`;
    } else if (str === 'Fair') {
      color = `orange`;
    } else if (str === 'Good') {
      color = `lightgreen`;
    } else if (str === 'Strong') {
      color = `green`;
    }
    return <span style={{color: color}}>{strengths[zxcvbn(password).score]}</span>;
  }, [password]);

  return (
    <ThemeProvider theme={theme}>
<Container component="main" maxWidth="xs">
      <CssBaseline />

      <Dialog
        open={!termsClosed}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Grid>
            <Typography variant="h6">{'Terms & Conditions'}</Typography>
          </Grid>
        </DialogTitle>
        <DialogContent
         >
          <Grid>
            <Grid>
              <Typography
              >{parse(termsAndConditions)}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" id="closeCookieModal" onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* <LeftLanding /> */}
      {/* <Grid   item xs={12} sm={12} md={6}> */}
        <TransitionGroup>
          <CSSTransition
            timeout={300}
            in={true}
            classNames="fade"
            appear
          >
                    <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
            <Card sx={{minWidth: 500}}      >

<CardContent>
              {/* <Grid item> */}
              <Typography align="left" component="h1" variant="h5">

              {window.location.pathname.split('/')[1] === 'setpassword' ? 'Set Password' : 'Reset Password'}
              </Typography>
              <Typography align="left" variant="subtitle1">
              {window.location.pathname.split('/')[1] === 'setpassword' ? 'Fill in password to access the system' : 'Type in new password to reset'}
              </Typography>
{/* </Grid> */}



                <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                    {/* <Grid item container alignItems="center" direction="column" spacing={3}> */}

                        <Typography color="error">
                          {errorText}
                        </Typography>

                        <TextField
                          tabIndex={1}
                          type="email"
                          error={errorText !== '' || (submitted && !email)}
                          value={email}

                          // margin="normal"
                          required
                          margin="normal"
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          autoComplete="email"
                          autoFocus
                          variant="outlined"
                          disabled
                          // label="Email"
                          // id="emailField"
                          // value={email}
                          InputLabelProps={{
                            style: { color: globalConfig['notAvailableText'] },
                          }}
                          InputProps={{

                          }}
                        />

                        <TextField
                        // margin="normal"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          label="Password"
                          id="passwordField"
                          tabIndex={1}
                          autoComplete="on"
                          error={!!errorText || (submitted && !password)}
                          type={showPassword ? 'text' : 'password'}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          helperText={password ? passwordStrength : ''}
                          InputLabelProps={{
                            style: { color: globalConfig['textColor'] },
                          }}
                          InputProps={{
                            endAdornment:
                              <InputAdornment position="end">
                                <IconButton
                                  tabIndex={4}
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>,
                          }}
                        />


                        <TextField
                        fullWidth
                        margin="normal"
                          label="Repeat Password"
                          variant="outlined"
                          id="repeatField"
                          tabIndex={2}
                          autoComplete="on"
                          error={!!errorText || (submitted && !repeatPassword)}
                          type={showRepeatPassword ? 'text' : 'password'}
                          onChange={(e) => {
                            setRepeatPassword(e.target.value);
                          }}
                          InputLabelProps={{
                            style: { color: globalConfig['textColor'] },
                          }}
                          InputProps={{
                            endAdornment:
                              <InputAdornment position="end">
                                <IconButton
                                  tabIndex={5}
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowRepeatPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>,
                          }}
                        />
                      {isSetPasswordPage ?
                        <Typography style={{maxWidth: 700}} color="primary">
                         I accept <u onClick={handleOpen}>Terms and Conditions</u>:
                          <Checkbox
                            checked={termsAccepted}
                            onChange={handleChangeCheckBox}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                          </Typography>
                          : null}


                        {
                          loading ?
                            <CircularProgress /> :
                            <Button
                              type="submit"
                              fullWidth
                              variant="contained"
                              sx={{ mt: 3, mb: 2 }}
                            // onClick={handleLogin}
                          >
                            Submit
                          </Button>
                        }

                    {/* </Grid> */}
                  </Box>
                {/* </Grid> */}
                </CardContent>
            </Card>
            </Box>
          </CSSTransition>
        </TransitionGroup>
      {/* </Grid> */}
    {/* </Grid > */}
    </Container>
    </ThemeProvider>

  );
});



PasswordReset.displayName = 'PasswordReset';
export default PasswordReset;

