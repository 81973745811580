import store from "../store";

type dispatchType = typeof store.dispatch;

export const setDataType = (dataType: string) => (dispatch: dispatchType) => {
  dispatch({
    type: "SET_DATATYPE",
    payload: dataType,
  });
};

export const setRows = (rows: [{}]) => (dispatch: dispatchType) => {
  dispatch({
    type: "SET_ROWS",
    payload: rows,
  });
};

export const setIntelligenceFusionData =
  (intelligenceFusionData: {}) => (dispatch: dispatchType) => {
    dispatch({
      type: "SET_INTEL",
      payload: intelligenceFusionData,
    });
  };

export const setIncidentCount =
  (incidentCount: number) => (dispatch: dispatchType) => {
    dispatch({
      type: "SET_INCIDENTCOUNT",
      payload: incidentCount,
    });
  };

export const setIncidentPage = (incidentPage: number) => (dispatch: dispatchType) =>{
  dispatch({
    type: "SET_INCIDENT_PAGE",
    payload: incidentPage,
  })
}

export const setIncidentRowsPerPage = (incidentRowsPerPage: number) => (dispatch: dispatchType) =>{
  dispatch({
    type: "SET_INCIDENT_ROWS_PAGE",
    payload: incidentRowsPerPage,
  })
}

export const setAssets = (assets:[]) => (dispatch: dispatchType) =>{
  dispatch({
    type:"SET_ASSETS",
    payload: assets,
  })
}

export const setIncidents = (incident:[]) => (dispatch: dispatchType) => {
  dispatch({
    type:"SET_INCIDENT",
    payload:incident,
  })
}

export const setFilteredIntelligenceFusionData =
  (filtered_intelligenceFusionData: {}) => (dispatch: dispatchType) => {
    dispatch({
      type: "SET_FILTER_INTEL",
      payload: filtered_intelligenceFusionData,
    });
  };

  export const setFilteredRows = (filtered_rows: [{}]) => (dispatch: dispatchType) => {
    dispatch({
      type: "SET_FILTER_ROWS",
      payload: filtered_rows,
    });
  };

  export const setFilteredIncidentCount =
  (filtered_incidentCount: number) => (dispatch: dispatchType) => {
    dispatch({
      type: "SET_FILTER_INCI_COUNT",
      payload: filtered_incidentCount,
    });
  };

  export const setDATA_READY_INTEL =
  (data_ready_intel: boolean) => (dispatch: dispatchType) => {
    dispatch({
      type: "SET_LOADING_DATA_INTEL",
      payload: data_ready_intel,
    });
  };

  export const setIntelStartDate = (intel_StartDate: string) => (dispatch: dispatchType) => {
    dispatch({
      type:"SET_INTEL_START_DATE",
      payload: intel_StartDate,
    })
  }

  export const setIntelEndDate = (intel_EndDate: string) => (dispatch: dispatchType) => {
    dispatch({
      type:"SET_INTEL_END_DATE",
      payload: intel_EndDate,
    })
  }

  export const setSelectedStartDate = (selectedStartDate: string) => (dispatch: dispatchType) => {
    dispatch({
      type:"SET_SELECTED_START_DATE",
      payload: selectedStartDate,
    })
  }


  export const setSelectedEndDate = (selectedEndDate: string) => (dispatch: dispatchType) => {
    dispatch({
      type:"SET_SELECTED_END_DATE",
      payload: selectedEndDate,
    })
  }

  export const setUserLocations = (userLocations: []) => (dispatch: dispatchType) => {
    dispatch({
      type: "SET_USER_LOCATIONS",
      payload: userLocations
    })
  }