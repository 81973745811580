import cookie from 'js-cookie';
import {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  setStaff, set2FAStatus, setAccessToken, setCompanyName, setEmail, setId,
  setRefreshToken,
  setSecret,
  setRole,
  setLoading,
  setFirstName,
  setSurname
} from '../actions/userActions';
import store from '../store';
import {getMyUser, refreshToken, getUserAndInvitedUsers, getChatMessages} from './api';
import {logout} from './utils';
import { useCallback, useRef } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const useUpdateUser = (authInitiated, setAuthInitiated) => {
  // This hook ensures the user's data is loaded into the Redux
  // store after a refresh, and also handles role routing for
  // private routes.
  const {user} = store.getState();
  const navigate = useNavigate()

  useEffect(() => {
    const authenticated = async (refresh) => {
      const res = await getUserAndInvitedUsers()
        if (res.status === 200) {
          store.dispatch(setEmail(res.data.email));
          store.dispatch(setSecret(res.data.userSecret));
          store.dispatch(set2FAStatus(res.data.is_two_factor_enabled));
          store.dispatch(setStaff(res.data.is_staff));
          store.dispatch(setId(res.data.id));
          store.dispatch(setRole(res.data.role));
          store.dispatch(setCompanyName(res.data.companyName));
          store.dispatch(setFirstName(res.data.firstName));
          store.dispatch(setSurname(res.data.surname));
          console.log("INITIIATING SOCKET")
          const payload = {
            companyName: res.data.companyName,
            adminId: res.data.id,
            invitedUsers: res.invitedUsers
          }
          console.log(payload)
          store.dispatch({ 
            type:"socket/connect", 
            payload:payload
          }) 
          console.log(payload)
          console.log("INITIATED SOCKET")

          const chatResult = await getChatMessages()
          if (chatResult.status===200) {
            console.log("[CHAT MESSAGES]")
            console.log(chatResult)
            chatResult.data.forEach(user=>{
              let user_id = user.user_id
              let messages = user.messages
              messages.forEach(message=>{

                let sender_id = message.sender
                let chatUser = user_id
                let messageText = message.message_text
                let messageCreatedTime  = message.created_at
                let newMessage = {
                  sender_id:sender_id,
                  chatUser:chatUser,
                  messageText:messageText,
                  messageCreatedTime:messageCreatedTime,
                  messageType: "GET-Request"
                }
                console.log("[PRINT MESSAGE]")
                console.log(newMessage)
                store.dispatch({ type: "ADD_NEW_CHAT_MESSAGE", payload:newMessage})
              })
            })

          }
          if (res.data.role === user.role) {          

          } else if (user.role) {
            if (res.data.role === 'customer') {
              navigate('/home')
            } else if (res.data.role === 'admin') {
              navigate('/home')
            } else if (res.data.role ==='kowroo_admin') {
              navigate('/home')
            } else {
              logout(navigate);
            }
           }
        } else {
          if (refresh) {
            let res = await refreshToken(refresh)
            if (res.status === 200) {
                store.dispatch(setAccessToken(res.data.access_token));
                store.dispatch(setRefreshToken(res.data.refresh_token));
                cookie.set('refresh_token', res.data.refresh_token);
                cookie.set('access_token', res.data.access_token);
                res = await getMyUser()
                if (res.status === 200) {
                  store.dispatch(setEmail(res.data.email));
                  store.dispatch(setSecret(res.data.userSecret));
                  store.dispatch(set2FAStatus(res.data.is_two_factor_enabled));
                  store.dispatch(setRole(res.data.role));
                  store.dispatch(setStaff(res.data.is_staff));
                  store.dispatch(setId(res.data.id));
                  store.dispatch(setCompanyName(res.data.companyName));
                  store.dispatch(setFirstName(res.data.firstName));
                  store.dispatch(setSurname(res.data.surname));

                  if (res.data.role === user.role) {} else if (user.role) {
                    if (res.data.role === 'customer') {
                      navigate('/home')
                    } else if (res.data.role === 'admin') {
                      navigate('/home')
                    } else if (res.data.role ==='kowroo_admin') {
                      navigate('/home')
                    } else {
                      logout(navigate);
                    }
                  }
                } else {
                  logout(navigate);
                }
              } else {
                logout(navigate);
              }
          } else {
            logout(navigate);
          }
        }
    }

    const refresh = (
      user.refresh_token ? user.refresh_token : cookie.get('refresh_token')
    );

    const refreshing = async (refresh) => {
      let res = await refreshToken(refresh)
      if (res.status === 200) {
        cookie.set('refresh_token', res.data.refresh_token);
        cookie.set('access_token', res.data.access_token);
        res = await getMyUser()
        if (res.status === 200) {
          store.dispatch(setEmail(res.data.email));
          store.dispatch(setSecret(res.data.userSecret));
          store.dispatch(setRole(res.data.role));
          store.dispatch(set2FAStatus(res.data.is_two_factor_enabled));
          store.dispatch(setStaff(res.data.is_staff));
          store.dispatch(setId(res.data.id));
          store.dispatch(setCompanyName(res.data.companyName));
          if (res.data.role === user.role) {} else {
            if (user.role) {
              if (res.data.role === 'customer') {
                navigate('/home')
              } else if (res.data.role === 'admin') {
                navigate('/home')
              } else if (res.data.role ==='kowroo_admin') {
                navigate('/home')
              } else {
                logout(navigate);
              }
            }
          }
        } else {
          logout(navigate);
        }
      } else {
        // ;
        logout(navigate);
      }
    }

    if (user.loading && !authInitiated) {
      setLoading(true);
      setAuthInitiated(true);
      if (user.access_token) {
        authenticated(refresh)
      } else if (refresh && !user.access_token) {
        refreshing(refresh);
      } else {
        logout(navigate);
      }
      setAuthInitiated(false)
      store.dispatch(setLoading(false))
    }
  }, [user.loading, user.access_token, user.refresh_token, user.role, user.id, user.email, user.is_staff, user.is_two_factor_enabled, user.companyName, user.secret, authInitiated, navigate, setAuthInitiated]);
};


const useLongPress = (
    longPressTriggered: boolean,
    setLongPressTriggered: Function,
    onLongPress: Function,
    onClick: Function,
    { shouldPreventDefault = true, delay = 3000 } = {}
    ) => {
       const timeout = useRef();
    const target = useRef();

    const start = useCallback(
        (event: { target: { addEventListener: (arg0: string, arg1: (event: any) => void, arg2: { passive: boolean; }) => void; }; }, timeout: { current: NodeJS.Timeout; }, target: { current: any; }) => {
            if (shouldPreventDefault && event.target) {
                    event.target.addEventListener("touchend", preventDefault, {
                    passive: false
                });
                target.current = event.target;
            }
            
            timeout.current = setTimeout(() => {
                onLongPress(event);
                setLongPressTriggered(true);
            }, delay);
        },
        [onLongPress, delay, shouldPreventDefault, setLongPressTriggered ]
    );

    const clear = useCallback(
        (event: any, timeout: { current: NodeJS.Timeout | undefined; }, target: { current: { removeEventListener: (arg0: string, arg1: (event: any) => void) => void; }; }, shouldTriggerClick = true, ) => {
            timeout.current && clearTimeout(timeout.current);
            shouldTriggerClick && !longPressTriggered && onClick();
            setLongPressTriggered(false);
            if (shouldPreventDefault && target.current) {
                target.current.removeEventListener("touchend", preventDefault);
            }
        },
        [shouldPreventDefault, onClick, longPressTriggered, setLongPressTriggered]
    );

    return {
        onMouseDown: (e: any) => start(e, timeout, target),
        onTouchStart: (e: any) => start(e, timeout, target),
        onMouseUp: (e: any) => clear(e, timeout, target),
        onMouseLeave: (e: any) => clear(e, timeout, target, false),
        onTouchEnd: (e: any) => clear(e, timeout, target)
    };
};

const isTouchEvent = (event: any) => {
return "touches" in event;
};

const preventDefault = (event: { touches: string | any[]; preventDefault: () => void; }) => {
  if (!isTouchEvent(event)) return;

  if (event.touches.length < 2 && event.preventDefault) {
      event.preventDefault();
  }
};

export default useLongPress;