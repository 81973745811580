import React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  CardActionArea,
  createTheme,
  LinearProgress,
} from "@mui/material";
import alert from "./assets/alert.png";
import country_assessment from "./assets/country_assessment.png";
import incident from "./assets/incident.png";
import maintenance from "./assets/maintenance.png";
import map from "./assets/map.png";
import company from "./assets/company.png";
import asset from "./assets/asset.png";

import news from "./assets/news.png";
import report from "./assets/report.png";
import risk_selector from "./assets/risk_selector.png";
import user_settings from "./assets/user_settings.png";
import settings from "./assets/settings.png";
import chat from "./assets/chat.png";
import { Link as RouterLink } from "react-router-dom";

import Alert from "./Alert";
import Chat from "./Chat";
import Incidents from "./IncidentsPage";
import Maintenance from "./Maintenance";
import Map from "./MapDisplay";
import News from "./News";
import Report from "./Report";
import Risk_Appetite from "./Risk_Appetite";
import Settings from "./Settings";
import User_Settings from "./User_Settings";
import Country_Assessment from "./Country_Assessment";
import { ThemeProvider } from "@emotion/react";
import { connect } from "react-redux";
import AdminPage from "./AdminPage";
import AssetsPage from "./AssetsPage";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Dashboard = (props) => {
  const { loading, role } = props;
  const images = [
      role !== "customer"
      ? {
          ima: report,
          title: "Query/Report",
          path: "/reports",
          component: Report,
        }
      : { ima: null },
    { ima: map, title: "Map", path: "/map", component: Map },
    {
      ima: alert,
      title: "Alerts and Chat",
      path: "/alert",
      component: Alert,
    },
    role === "kowroo_admin"
      ? {
          ima: company,
          title: "Companies",
          path: "/companies",
          component: AdminPage,
        }
      : { ima: null },
    role === "admin"
      ? {
          ima: company,
          title: "Company",
          path: "/company",
          component: AdminPage,
        }
      : { ima: null },
    {
      ima: incident,
      title: "Incidents",
      path: "/incidents",
      component: Incidents,
    },
    {
      ima: asset,
      title: "Assets",
      path: "/assets",
      component: AssetsPage,
    },
    // {
    //   ima: country_assessment,
    //   title: "Country Assessments",
    //   path: "/assessment",
    //   component: Country_Assessment,
    // },
    role === "admin"
      ? {
          ima: risk_selector,
          title: "Risk Appetite Selector",
          path: "/risk_appetite",
          component: Risk_Appetite,
        }
      : { ima: null },
    { ima: news, title: "News", path: "/news", component: News },
    // { ima: chat, title: "Chat Functions", path: "/chat", component: Chat },

    // {
    //   ima: maintenance,
    //   title: "Maintenance",
    //   path: "/maintenance",
    //   component: Maintenance,
    // },
    role === "customer"
      ? {
          ima: user_settings,
          title: "User Settings",
          path: "/user_settings",
          component: User_Settings,
        }
      : { ima: null },
    {
      ima: settings,
      title: "Settings",
      path: "/settings",
      component: Settings,
    },
  ];

  const theme = createTheme();

  theme.typography.overline = {
    fontSize: "1.0rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.0rem",
    },
    [theme.breakpoints.up("xs")]: {
      fontSize: "0.9rem",
    },
  };

  return (
    <div className="home">
      {loading ? (
        <Grid container sx={{mt: 5,position: "fixed"}}>
          <LinearProgress sx={{}} color="inherit" />
        </Grid>
      ) : (
        <Grid sx={{ flexGrow: 1 }}>
          <Grid
            // justifyContent="center"
            justifyItems={"center"}
            // minHeight={"90vh"}
            container
            style={{
              paddingTop: 20,
              paddingLeft: 100,
              paddingRight: 70,
              paddingBottom: 50,
            }}
            spacing={{ xs: 4, md: 6, sm: 10 }}
            columns={{ xs: 4, sm: 8, md: 10 }}
          >
            {images.map((pic, index) =>
              pic.ima !== null ? (
                <Grid
                  justifyItems="center"
                  justifyContent="center"
                  item
                  xs={6}
                  sm={3}
                  md={3}
                  key={index}
                >
                  <Card
                    onMouseEnter={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    sx={{
                      ":hover": {
                        backgroundColor: "black",
                        boxShadow: 10,
                        transform: "scale(1.05)",
                        transition: "backgroundColor, transform 0.2s", // theme.shadows[20]
                      },
                      transition: "backgroundColor, transform 0.2s",
                      backgroundColor: "#555555",
                      maxWidth: 1500,
                      maxHeight: 1000,
                      margin: "0 auto",
                    }}
                    variant="outlined"
                  >
                    <ThemeProvider theme={theme}>
                      <Typography
                        // xs={1}
                        fontSize={10}
                        gutterBottom
                        variant="overline"
                        component="div"
                        color="white"
                        className="title"
                      >
                        {pic.title}
                      </Typography>
                    </ThemeProvider>
                    <CardActionArea
                      style={{
                        backgroundPosition: "center",
                        backgroundColor: "lightgrey",
                        backgroundRepeat: "repeat",
                        // height: "50%",
                        // width: "100%",
                        // maxWidth: "200px",
                      }}
                      component={RouterLink}
                      to={pic.path}
                    >
                      <Grid
                        justifyContent={"center"}
                        justifyItems={"center"}
                        container
                      >
                        <CardMedia
                          style={{
                            opacity: 0.7,
                            objectFit: "cover",
                            padding: 10,
                            width: "50%",
                          }}
                          component="img"
                          alt="green iguana"
                          // height={120}
                          image={pic.ima}
                        />
                      </Grid>
                    </CardActionArea>
                  </Card>
                </Grid>
              ) : null
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const mapStateToProps = ({ user }) => ({
  loading: user.loading,
  companyName: user.companyName,
  role: user.role,
  email: user.email
});

export default connect(mapStateToProps)(Dashboard);
