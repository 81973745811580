

import { Grid } from "@mui/material";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import React, { useCallback, useEffect } from "react";
import { useWebSocketStore } from "../stores/websocketStore";
import { useDataStore } from "../stores/dataStore";

const Report = () => {

    const { getDashboardEmbed} = useWebSocketStore((state) => ({
        getDashboardEmbed: state.getDashboardEmbed,
    }));

    const { dashboardEmbed } = useDataStore((state) => ({
        dashboardEmbed: state.dashboardEmbed,
    }));

    const fetchDashboardEmbed = useCallback(() => {
        // Silly function to imitate the asynchronous response to pass
        // to fetchGuestToken attribute of embedDashboard function
        // perhaps better to make getDashboardEmbed to return a promise
        // and resolve it with the return of token value
        return new Promise((resolve) => {
            setTimeout(() => {
              resolve(dashboardEmbed);
            }, 3000);
          });

    }, [dashboardEmbed]);

    const [tokenSet, setTokenSet] = React.useState(false);

    const handleGetDashboardEmbed = async () => {
         getDashboardEmbed();
    }

    useEffect(() => {
        if (!tokenSet) {
            handleGetDashboardEmbed()
            setTokenSet(true);
        }
    }, [tokenSet]);


    useEffect(() => {

        const fetchData =  () => {
            if (!dashboardEmbed) {
                return;

            }

            const checkMountPoint = () => {
                const mountPoint = window.document.getElementById("superset-container");
                if (mountPoint && dashboardEmbed) {
                    embedDashboard({
                        id: "6ad7dddb-4c39-4466-970d-3cae76433324", // Superset dashboard ID
                        supersetDomain: "https://analytics.kowroo.com",
                        mountPoint: mountPoint, // HTML element in which iframe will render
                        fetchGuestToken: fetchDashboardEmbed,
                        dashboardUiConfig: {
                        // hideTitle: true,
                        // filters: {
                        //     expanded: true,
                        // },
                        },
                        debug: true,
                    });
            
                    const supersetIframe = mountPoint.getElementsByTagName("iframe")[0];
                    if (supersetIframe) {
                        supersetIframe.style.height = "100%";
                        supersetIframe.style.width = "100%";
                    }
                } else {
                    setTimeout(checkMountPoint, 100); // Retry after 100ms
                }
            };
            checkMountPoint(); // Initial check
        };
        if (dashboardEmbed) {
            fetchData();
        }
    }, [dashboardEmbed, tokenSet]);

    return  (
        <>
            <Grid sx={{
                left: 0,
                height : "90vh",
                width : "100%"
            }} id='superset-container'></Grid>
        </>
        
    );
};

export default Report;
