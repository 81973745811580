import { create } from 'zustand';
import {
    UserIncident,
    // CountryAssessment,
    IntelligenceFusionIncidentFeature,
    ChatChannel,
    Asset,
    CheckinHistoryItem,
    SOSHistoryItem,
    CountryAssessmentMap,
    FollowMeHomeLocation,
    FollowMeHomeHistory,
    Crisis24Item,
    State
} from "../types/data";

export type Actions = {
    // setCountryAssessment: (newCountryAssessmentList: CountryAssessment[]) => void
    setCountryAssessment: (newCountryAssessmentList: CountryAssessmentMap) => void
    setUserIncidents: (newUserIncidents: UserIncident[]) => void
    setAssets: (assets: Asset[]) => void
    setIntelligenceFusion: (intelligenceFusion: IntelligenceFusionIncidentFeature[]) => void
    setCrisis24: (crisis24: Crisis24Item[]) => void
    updateChat: (chatChannels: ChatChannel[]) => void
    setSOSHistory: (sosHistory: SOSHistoryItem[]) => void
    setCheckinHistory: (checkinHistory: CheckinHistoryItem[]) => void
    setFollowMeHomeLocations: (followMeHomeLocations: FollowMeHomeLocation[]) => void
    setFollowMeHomeHistory: (followMeHomeHistory: FollowMeHomeHistory) => void
    setDashboardEmbed: (dashboardEmbed: string) => void
}

const initialCountryAssessment: CountryAssessmentMap = {};
const initialUserIncidents: UserIncident[] = [];
const initialChatChannels: ChatChannel[] = [];
const initialAssets: Asset[] = [];
const initialSOSHistory: SOSHistoryItem[] = [];
const initialCheckinHistory: CheckinHistoryItem[] = [];
const initialIntelligenceFusion: IntelligenceFusionIncidentFeature[] = [];
const initialFollowMeHome: FollowMeHomeLocation[] = [];
const initialFollowMeHomeHistory: FollowMeHomeHistory = {};
const initialCrisis24: Crisis24Item[] = [];
const dashboardEmbed: string =  "";

export const useDataStore = create<State & Actions>()(set =>({
    countryAssessment: initialCountryAssessment,
    setCountryAssessment: (newCountryAssessmentList: CountryAssessmentMap) => {set({ countryAssessment:  newCountryAssessmentList });},
    userIncidents: initialUserIncidents,
    setUserIncidents: (newUserIncidents: UserIncident[]) => {set({ userIncidents:  newUserIncidents });},
    chatChannels: initialChatChannels,
    updateChat: (chatChannels) => {set({chatChannels: chatChannels});},
    assets: initialAssets,
    setAssets: (assets: Asset[]) => {set({assets: assets});},
    intelligenceFusion: initialIntelligenceFusion,
    setIntelligenceFusion: (intelligenceFusion: IntelligenceFusionIncidentFeature[]) => {set({intelligenceFusion: intelligenceFusion});},
    crisis24: initialCrisis24,
    setCrisis24: (crisis24: Crisis24Item[]) => {set({crisis24: crisis24});},
    checkinHistory: initialCheckinHistory,
    setCheckinHistory: (checkinHistory: CheckinHistoryItem[]) => {set({checkinHistory: checkinHistory});},
    sosHistory: initialSOSHistory,
    setSOSHistory: (sosHistory: SOSHistoryItem[]) => {set({sosHistory: sosHistory});},
    followMeHomeLocations: initialFollowMeHome,
    setFollowMeHomeLocations: (followMeHomeLocations: FollowMeHomeLocation[]) => {set({followMeHomeLocations: followMeHomeLocations});},
    followMeHomeHistory: initialFollowMeHomeHistory,
    setFollowMeHomeHistory: (followMeHomeHistory: FollowMeHomeHistory) => {set({followMeHomeHistory: followMeHomeHistory});},
    dashboardEmbed: dashboardEmbed,
    setDashboardEmbed: (dashboardEmbed: string) => {set({dashboardEmbed: dashboardEmbed });},
}));
