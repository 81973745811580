import { SOCKET_CONNECTED, SOCKET_DISCONNECTED } from '../actions/socketActions';
import { getDisplayNotification } from '../helpers/utils';

export interface SocketState {
    isSocketConnected: boolean,
    invitedUsers:any[],
    displayNotifications:any[],
    chatMessages : any,
    riskRatings: any,
    // sosUsers: [],
    userLocations: {
        features:any,
        crs:number, 
        type:string
    },
    followMeLocations: {
        features:any,
        crs:number, 
        type:string
    },
    // SOSLocations: {
    //     features:any,
    //     crs:number, 
    //     type:string
    // }
}

const state = (
    state:SocketState = {
        isSocketConnected:false,
        displayNotifications:[],
        invitedUsers:[],
        chatMessages : {},
        riskRatings: {},
        // sosUsers: [],
        userLocations: {
            features:[],
            crs:null, 
            type:"FeatureCollection"
        },
        followMeLocations: {
            features:[],
            crs:null, 
            type:"FeatureCollection"
        },
        // SOSLocations: {
        //     features:[],
        //     crs:null, 
        //     type:"FeatureCollection"
        // }
    },
    action: { type: any; payload: any }
  )=> {
    switch (action.type) {
        case SOCKET_CONNECTED:
            return {
                ...state,
                isSocketConnected: true,
            };
        case SOCKET_DISCONNECTED:
            return {
                ...state,
                isSocketConnected: false,
            };
        
        case "SET_DISPLAY_NOTIFICATIONS":
            return {
                ...state, 
                displayNotifications: [
                    ...state.displayNotifications, 
                    action.payload
                ]
            };
            
        case "SET_INVITED_USERS":
            const invitedUsers = action.payload.map(user =>({
                ...user, 
                initials: `${user.firstName.charAt(0)}${user.surname.charAt(0)}`
            }))
            return {
                ...state, 
                invitedUsers: invitedUsers
            };
        case "SET_CHAT_USERS":
            const messages = Object.assign({}, ...action.payload.map(dict => ({ [dict.id]: [] })));
            console.log("#### SET_CHAT_USERS")
            console.log(messages)
            return {
                ...state, 
                chatMessages: messages
            };
        case "ADD_NEW_CHAT_MESSAGE":
            console.log("#### CHAT MESSAGE ADDING")
            const socketMessage = action.payload
            let chatUser = null
            let newAddMessage = ""
            let sender = ""
            let messageCreatedTime = new Date 
            // let newAddUid = ""
            console.log(action.payload)
            if (socketMessage.hasOwnProperty("userList")){
                // sending message from webapp
                newAddMessage = socketMessage.message
                chatUser = socketMessage.userList
                sender = socketMessage.uid
                messageCreatedTime = new Date(socketMessage.dateTime)
            } else if (socketMessage.messageType==="GET-Request"){
                chatUser = socketMessage.chatUser
                newAddMessage = socketMessage.messageText
                sender = socketMessage.sender_id 
                messageCreatedTime = new Date(socketMessage.messageCreatedTime)
            } else {
                // receiving message via socket
                chatUser = socketMessage.Uid
                newAddMessage = socketMessage.Message
                sender = socketMessage.Uid
                messageCreatedTime = new Date(socketMessage.DateTime)
            }
            const messageList = state.chatMessages[chatUser] ? [...state.chatMessages[chatUser]] : [];
            messageList.sort((a, b) => {
                const datetimeA = new Date(a.messageCreatedTime);
                const datetimeB = new Date(b.messageCreatedTime);
                return datetimeA.getTime() - datetimeB.getTime(); // Ascending order

            });
            const newMessage = { Message: newAddMessage, Uid: sender,  messageCreatedTime: messageCreatedTime.toLocaleString()}
            console.log("newMessage")
            console.log(newMessage)
            messageList.push(newMessage);
            console.log("PUSHED MESSAGE")
            console.log(state.chatMessages)
            return {
                ...state, 
                chatMessages: {
                    ...state.chatMessages,
                    [chatUser]: messageList 
                }
            };
        
        case "UPDATE_USER_LOCATION":
            console.log("[UPDATE_USER_LOCATION]")
            const locationMessage = action.payload
            const currentUser = state.invitedUsers.find((user) => user.id === parseInt(locationMessage.Uid));
            let currentUserName = `${currentUser.firstName} ${currentUser.surname}`
            let userLocationExists = false;
            state.userLocations.features.forEach(feature=>{
                if (String(feature.properties.user_id) === String(locationMessage.Uid)){
                    userLocationExists = true;
                }
            })

            let featureCollection = {
                features:[],
                crs:null, 
                type:"FeatureCollection"
            }
            // state.userLocations
            let updatedFeatures;

            if (!userLocationExists) {
                const propertiesObj = {
                    initials:currentUser.initials,
                    latitude:locationMessage.Latitude,
                    longitude:locationMessage.Longitude,
                    risk_rating:locationMessage.RiskRating,
                    status:locationMessage.Type,
                    time_entered_location:locationMessage.DateTime,
                    user_id:parseInt(locationMessage.Uid),
                    user_name:currentUserName,
                }
                const featureObj = {
                    geometry: {
                        type: "Point",
                        coordinates: [locationMessage.Longitude, locationMessage.Latitude, ]
                    },
                    type:"Feature",
                    properties: propertiesObj
        
                }
                updatedFeatures = [...state.userLocations.features, featureObj]
                featureCollection.features = updatedFeatures
                // .push(featureObj);
            } else {
                const newRiskRating = locationMessage.RiskRating === "" ? currentUser.risk_rating : locationMessage.RiskRating
                const propertiesObj = {
                    initials:currentUser.initials,
                    latitude:locationMessage.Latitude,
                    longitude:locationMessage.Longitude,
                    risk_rating:newRiskRating,
                    status:locationMessage.Type,
                    time_entered_location:locationMessage.DateTime,
                    user_id:parseInt(locationMessage.Uid),
                    user_name:currentUserName,
                }
                const featureObj = {
                    geometry: {
                        type: "Point",
                        coordinates: [locationMessage.Longitude, locationMessage.Latitude, ]
                    },
                    type:"Feature",
                    properties: propertiesObj
                }
                state.userLocations.features.forEach((feature)=>{
                    if (feature.properties.user_id === propertiesObj.user_id){
                        featureCollection.features.push(featureObj)
                    } else{
                        featureCollection.features.push(feature)
                    }
                })
                // featureCollection.features.map((feature) => 
                //     feature.properties.user_id === propertiesObj.user_id ? featureObj : feature
                // );
            }
            return {
                ...state, 
                userLocations: featureCollection
            }  
              
        case "UPDATE_RISK_RATINGS":
            const RiskUser = state.invitedUsers.find((user) => user.id === parseInt(action.payload.Uid));
            let RiskUserName = `${RiskUser.firstName} ${RiskUser.surname}`
            const newRiskRating = action.payload.RiskRating
            const uid = action.payload.Uid

            const prevRiskRatings = state.riskRatings
            let updatedRiskRatings = state.riskRatings
            const userName = RiskUserName
            const riskMappings = {
                "Low Risk": 0,
                "Medium Risk": 1,
                "High Risk": 2,
            }

            let NewDisplayNotifications = state.displayNotifications
            if (uid in prevRiskRatings) {
                // check if risk has changed and if so craete notification
                const prevRiskRating = prevRiskRatings[uid]
                let riskMessage = {
                    Type:"RiskRatingChange",
                    userName: RiskUserName,
                    prevRiskRating:prevRiskRating, 
                    newRiskRating:newRiskRating, 
                }

                let notificationMessage = null;
                if (riskMappings[prevRiskRating] > riskMappings[newRiskRating]) {
                    console.log("[riskMessage-decreased]")
                    riskMessage["increase_decrease"] = "decreased"
                    notificationMessage = getDisplayNotification(riskMessage, RiskUserName)
                } else if (riskMappings[prevRiskRating] < riskMappings[newRiskRating]) {
                    console.log("[riskMessage-increased]")
                    riskMessage["increase_decrease"] = "increased"
                    notificationMessage = getDisplayNotification(riskMessage, RiskUserName)
                }
                if (notificationMessage!==null) {
                    NewDisplayNotifications = [
                        ...state.displayNotifications, 
                        notificationMessage
                    ]
                } else {
                    NewDisplayNotifications = state.displayNotifications     
                }
            } 
            updatedRiskRatings = {
                ...prevRiskRatings, // Copy existing fields
                [uid]: newRiskRating, // Add the new field
            };
            return {
                ...state, 
                riskRatings: updatedRiskRatings,
                displayNotifications: NewDisplayNotifications

            }    
        case "UPDATE_FOLLOW_ME_HOME":
            const followMeMessage = action.payload
            const followMeUser = state.invitedUsers.find((user) => user.id === parseInt(followMeMessage.Uid));

            let FollowMeInitials = `${followMeUser.firstName.charAt(0)}${followMeUser.surname.charAt(0)}`
            let FollowMeUserName = `${followMeUser.firstName} ${followMeUser.surname}`

            let followMeFeatureCollection = {
                features:[],
                crs:null, 
                type:"FeatureCollection"
            }
            
            let updatedFollowMeFeatures;
            console.log(`[UPDATE_FOLLOW_ME_HOME] USER`)
            console.log(FollowMeUserName)

            if (typeof followMeUser === 'undefined') {
                console.log(`[UPDATE_FOLLOW_ME_HOME] undefined`)
                const propertiesObj = {
                    initials:FollowMeInitials,
                    latitude:followMeMessage.Latitude,
                    longitude:followMeMessage.Longitude,
                    risk_rating:followMeMessage.RiskRating,
                    status:followMeMessage.Type,
                    time_entered_location:followMeMessage.DateTime,
                    user_id:parseInt(followMeMessage.Uid),
                    user_name:FollowMeUserName,
                }
                const featureObj = {
                    geometry: {
                        type: "Point",
                        coordinates: [followMeMessage.Longitude, followMeMessage.Latitude, ]
                    },
                    type:"Feature",
                    properties: propertiesObj
                }
                updatedFollowMeFeatures = [...state.followMeLocations.features, featureObj]
                followMeFeatureCollection.features = updatedFollowMeFeatures
            } else {
                const newRiskRating = followMeMessage.RiskRating === "" ? followMeUser.properties.risk_rating : followMeMessage.RiskRating

                const propertiesObj = {
                    initials:FollowMeInitials,
                    latitude:followMeMessage.Latitude,
                    longitude:followMeMessage.Longitude,
                    risk_rating:newRiskRating,
                    status:followMeMessage.Type,
                    time_entered_location:followMeMessage.DateTime,
                    user_id:parseInt(followMeMessage.Uid),
                    user_name:FollowMeUserName,
                }
                const featureObj = {
                    geometry: {
                        type: "Point",
                        coordinates: [followMeMessage.Longitude, followMeMessage.Latitude, ]
                    },
                    type:"Feature",
                    properties: propertiesObj
                }
                
                updatedFollowMeFeatures = [...state.followMeLocations.features, featureObj]
                followMeFeatureCollection.features = updatedFollowMeFeatures
            }
            return {
                ...state, 
                followMeLocations: followMeFeatureCollection
            }  
        case "UPDATE_SOS":
            const SOSMessage = action.payload

            const SOSUser = state.invitedUsers.find((user) => user.id === parseInt(SOSMessage.Uid));

            let initials = `${SOSUser.firstName.charAt(0)}${SOSUser.surname.charAt(0)}`
            let user_name = `${SOSUser.firstName} ${SOSUser.surname}`

            let SOSFeatureCollection = {
                features:[],
                crs:null, 
                type:"FeatureCollection"
            }
            let updatedSOSFeatures;

            if (typeof SOSUser === 'undefined') {
                const propertiesObj = {
                    initials: initials,
                    latitude:SOSMessage.Latitude,
                    longitude:SOSMessage.Longitude,
                    risk_rating:SOSMessage.RiskRating,
                    status:SOSMessage.Type,
                    time_entered_location:SOSMessage.DateTime,
                    user_id:parseInt(SOSMessage.Uid),
                    user_name:user_name,
                }
                const featureObj = {
                    geometry: {
                        type: "Point",
                        coordinates: [SOSMessage.Longitude, SOSMessage.Latitude]
                    },
                    type:"Feature",
                    properties: propertiesObj
                }
                updatedSOSFeatures = [...state.userLocations.features, featureObj]

                SOSFeatureCollection.features = updatedSOSFeatures
                // .push(featureObj);
            } else {
                const newRiskRating = SOSMessage.RiskRating === "" ? SOSUser.properties.risk_rating : SOSMessage.RiskRating
                const propertiesObj = {
                    initials:initials,
                    latitude:SOSMessage.Latitude,
                    longitude:SOSMessage.Longitude,
                    risk_rating:newRiskRating,
                    status:SOSMessage.Type,
                    time_entered_location:SOSMessage.DateTime,
                    user_id:parseInt(SOSMessage.Uid),
                    user_name:user_name,
                }
                const featureObj = {
                    geometry: {
                        type: "Point",
                        coordinates: [SOSMessage.Longitude, SOSMessage.Latitude, ]
                    },
                    type:"Feature",
                    properties: propertiesObj
                }

                state.userLocations.features.forEach((feature)=>{
                    if (feature.properties.user_id === propertiesObj.user_id){
                        SOSFeatureCollection.features.push(featureObj)
                    } else{
                        SOSFeatureCollection.features.push(feature)
                    }
                })
            }
            return {
                ...state, 
                userLocations: SOSFeatureCollection
            }  
        default:
            return state;
  }
};

export default state;