import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, Grid } from '@mui/material';
import Home from '@mui/icons-material/Home';
import LocationOnOutlined from '@mui/icons-material/LocationOnOutlined';
import Business from '@mui/icons-material/Business';
import FlagCircle from '@mui/icons-material/FlagCircle';
import BusinessCenter from '@mui/icons-material/BusinessCenter';
import Newspaper from '@mui/icons-material/Newspaper';
import Summarize from '@mui/icons-material/Summarize';
import WarningAmber from '@mui/icons-material/WarningAmber';
import NotificationImportant from '@mui/icons-material/NotificationImportant';
import Assessment from '@mui/icons-material/Assessment';
import MailLock from '@mui/icons-material/MailLock';
import ChatIcon from '@mui/icons-material/Chat';
import Settings from '@mui/icons-material/Settings';
import globalConfig from '../../../config.json';

// Define colors
const greyIconColor = '#595959';
const whiteIconColor = globalConfig['primaryMain'];
const primaryMainColor = globalConfig['primaryMain'];
const selectedColor = `#DCE0DA`; // 70% opacity for light color

const icons: { [key: number]: JSX.Element } = {
    0: <Home sx={{ color: greyIconColor, fontSize: '1.7rem' }} />,
    1: <LocationOnOutlined sx={{ color: greyIconColor, fontSize: '1.7rem' }} />,
    2: <Business sx={{ color: greyIconColor, fontSize: '1.7rem' }} />,
    3: <FlagCircle sx={{ color: greyIconColor, fontSize: '1.7rem' }} />,
    4: <BusinessCenter sx={{ color: greyIconColor, fontSize: '1.7rem' }} />,
    5: <Newspaper sx={{ color: greyIconColor, fontSize: '1.7rem' }} />,
    6: <Summarize sx={{ color: greyIconColor, fontSize: '1.7rem' }} />,
    7: <WarningAmber sx={{ color: greyIconColor, fontSize: '1.7rem' }} />,
    8: <NotificationImportant sx={{ color: greyIconColor, fontSize: '1.7rem' }} />,
    9: <Assessment sx={{ color: greyIconColor, fontSize: '1.7rem' }} />,
    10: <MailLock sx={{ color: greyIconColor, fontSize: '1.7rem' }} />,
};

// Type definitions for the menu items
interface MenuItem {
    label: string;
    icon: JSX.Element;
    navigateTo?: string;
    action?: () => void;
    disabled?: boolean;
}

const SidebarMenuItem: React.FC<{
    label: string;
    icon: JSX.Element;
    isSelected: boolean;
    isChat: boolean;
    isDisabled: boolean;
    onClick: () => void;
}> = ({ label, icon, isSelected, isChat, isDisabled, onClick }) => (
    <Grid item sx={{ width: '100%' }}>
        <ListItem disablePadding sx={{
            cursor: isDisabled ? 'not-allowed' : undefined,
            padding: '0px 8px 0px 8px' }}>
            <ListItemButton
                onClick={isDisabled ? undefined : onClick}
                sx={{
                    borderRadius: '8px',
                    backgroundColor: isChat
                        ? `${primaryMainColor}20 !important`
                        : isSelected
                            ? `${selectedColor} !important`
                            : 'transparent',
                    color: isChat
                        ? whiteIconColor
                        : isSelected
                            ? whiteIconColor
                            : greyIconColor,
                    padding: '8px',
                    pointerEvents: isDisabled ? 'none' : 'auto',
                    opacity: isDisabled ? 0.5 : 1,
                    '&:hover': {
                        backgroundColor: isSelected
                            ? `${selectedColor} !important`
                            : `${greyIconColor}20 !important`,
                        transition: 'background-color 0.3s ease',
                    },
                }}
            >

                <ListItemIcon
                    sx={{
                        color: isChat ? whiteIconColor : (isSelected ? whiteIconColor : greyIconColor),
                        fontSize: '1.7rem', // Increase icon size
                    }}
                >
                    {React.cloneElement(icon, {
                        sx: {
                            color: isChat ? whiteIconColor : (isSelected ? whiteIconColor : greyIconColor),
                            fontSize: '1.7rem', // Increase icon size
                        }
                    })}
                </ListItemIcon>
                <ListItemText
                    primary={label}
                    sx={{
                        transition: 'opacity 0.3s ease',
                        opacity: isChat ? 0.7 : 1,
                        color: isChat ? whiteIconColor : (isSelected ? whiteIconColor : greyIconColor),
                    }}
                />
            </ListItemButton>
        </ListItem>
    </Grid>
);

const SidebarMenuList: React.FC<{
    menuItems: MenuItem[];
    selectedItem: string;
    highlightedChat: boolean;
    onItemSelect: (label: string, navigateTo?: string, action?: () => void) => void;
}> = ({ menuItems, selectedItem, highlightedChat, onItemSelect }) => (
    <List>
        <Grid container spacing={0.4}>
            {menuItems.map((item, index) => (
                <SidebarMenuItem
                    key={item.label + index}
                    label={item.label}
                    icon={item.icon}
                    isSelected={selectedItem === item.label}
                    isChat={item.label === "Chat" && highlightedChat}
                    isDisabled={item.disabled || false}
                    onClick={() => onItemSelect(item.label, item.navigateTo, item.action)}
                />
            ))}
        </Grid>
    </List>
);

const SidebarDrawer: React.FC<{
    isMinimized: boolean;
    setIsMinimized: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isMinimized, setIsMinimized }) => {
    const [highlightedChat, setHighlightedChat] = useState<boolean>(false); // Track if Chat is highlighted
    const navigate = useNavigate();
    const location = useLocation();

    // Define the sidebar menu items based on pages and icons
    const sidebarMenuItems: MenuItem[] = useMemo(
        () => [
            { label: "Home", icon: icons[0], navigateTo: "/" },
            { label: "Map", icon: icons[1], navigateTo: "/map" },
            { label: "Company", icon: icons[2], navigateTo: "/company" },
            { label: "Incidents", icon: icons[3], navigateTo: "/incidents" },
            { label: "Assets", icon: icons[4], navigateTo: "/assets" },
            { label: "Countries", icon: icons[5], navigateTo: "/assessment" },
            { label: "Alerts", icon: icons[6], navigateTo: "/alerts" },
            { label: "Queries & Reports", icon: icons[7], navigateTo: "/reports" },
            // { label: "Risk Appetite", icon: icons[8], navigateTo: "/risk_appetite" },
        ],
        []
    );

    const sidebarAdditionalItems: MenuItem[] = useMemo(
        () => [
            { label: "Chat", icon: <ChatIcon />, action: () => setIsMinimized(!isMinimized) }, // Toggle highlight
            { label: "Settings", icon: <Settings />, disabled: true },
        ],
        [isMinimized, setIsMinimized]
    );

    // Determine the selected menu item based on the current route
    const selectedMenuItem = useMemo(() => {
        const currentPath = location.pathname;
        const item = sidebarMenuItems.find(item => item.navigateTo === currentPath);
        return item ? item.label : 'Home'; // Default to 'Home' if no match
    }, [location.pathname, sidebarMenuItems]);

    // Handle the selection of a menu item and navigation
    const handleMenuItemSelect = (label: string, navigateTo?: string, action?: () => void) => {
        if (label === "Chat") {
            setHighlightedChat(prev => !prev); // Toggle chat highlight
            action();
        } else {
            if (navigateTo) {
                navigate(navigateTo); // Navigate to the selected path
            }
        }
    };

    useEffect(() => {
        setHighlightedChat(!isMinimized);
    }, [isMinimized]);

    return (
        <Grid>
            <Divider />
            <SidebarMenuList
                menuItems={sidebarMenuItems}
                selectedItem={selectedMenuItem}
                highlightedChat={highlightedChat}
                onItemSelect={handleMenuItemSelect}
            />
            <Divider />
            <SidebarMenuList
                menuItems={sidebarAdditionalItems}
                selectedItem={selectedMenuItem}
                highlightedChat={highlightedChat}
                onItemSelect={handleMenuItemSelect}
            />
        </Grid>
    );
};

export default SidebarDrawer;
