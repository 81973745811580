import { SearchRounded, DeleteForever, CancelPresentationOutlined, Edit } from "@mui/icons-material";
import { Alert, Autocomplete, Box, Button, Chip, CircularProgress, Grid, IconButton, InputAdornment, Paper,
  Snackbar, Table,TablePagination, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createTeam, deleteTeam, searchTeams, searchUsers } from "../helpers/api";
import { AnimatePresence, motion } from "framer-motion";
import UserTooltip from "../components/UserTooltip";
import GroupsIcon from '@mui/icons-material/Groups';


const TeamsPage = (props) => {
  // const [loading, setLoading] = useState(true);
  const {loading, companyName, role} =props;
  const [checkedUsers, setCheckedUsers] = useState([])

  const [sendingData, setSendingData] = useState(false);
  // const [sendingInviteLink, setSendingInviteLink] = useState(false);
  const [customerOps, setCustomerOps] = useState([])
  const [customers, setCustomers] = useState([])
  const [customerEmail, setCustomerEmail] = useState('')
  const [name, setName] = useState('')
  const [submitted, setSubmitted] = useState(false);
  const [errorText, setErrorText] = useState('')
  const [errorOpen, setErrorOpen] = useState(false)
  const [rows, setRows] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(true);
  const [description, setDescription] = useState('')


  const [editingTeam, setEditingTeam] = useState({id: null, users: []})
  const [deletingTeam, setDeletingTeam] = useState({id: null, users: [], name: null})
  const [openModal, setOpenModal] = useState(false);


  const [teamCount, setTeamCount] = useState(0)
  
  const [teamPage, setTeamPage] = React.useState(0)
  const [teamRowsPerPage, setTeamRowsPerPage] = useState(5);


  const Backdrop = ({children, onClick}) => {
    return ( <motion.div
      style={{
      zIndex: 200,
      position: "absolute",
      top:0,
      left:0,
      height: "100%",
      width: "100%",
      background: "#00000056",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      }}
      onClick={onClick}
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
    >{children}</motion.div>)
  }



  const handleCloseModal = () => {
    setOpenModal(false);
    setDeletingTeam({id: null, name: null, users: []});
  }

  const handleOpenModal = (row) => {
    setOpenModal(true);
    setDeletingTeam(row);
  }

  const onEditClick = (row) => {
    setEditingTeam(row);
    setErrorText('');
    setName(row.name)
    setDescription(row.description)
    setCheckedUsers(row.users.map(user => user.email))
    setReload(true)
  }

  const onCancelClick = () => {
    setEditingTeam({id: null, users: []});
    setErrorText('');
    setName('')
    setDescription('')
    setCheckedUsers([])
    setReload(true)
  }

  const handleDelete = async (id) => {
    const res = await deleteTeam(id);


    setReload(true)
    setOpenModal(false)
    onCancelClick()

  }


  const Modal = ({handleClose, text})=> {
    return <Backdrop onClick={handleClose}>

      <Grid
      spacing={2}
      container
      justifyContent={"space-around"}
      alignItems={"center"}
        style={{
        width: "clamp(50%, 700px, 90%)",
        height: "min(50%, 700px, 90%)",
        margin: "auto",
        padding: "0 2rem",
        top:0,
        bottom: 0,
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",
        background: "#ffffffe0",
      }}
        component={motion.div}
        onClick={(e) => {e.stopPropagation()}}
      >
        <Grid item>
          {/* <Grid container> */}
        <Typography color="inherit" variant="h5">{text}</Typography>

        {/* </Grid> */}

        </Grid>
        <Grid item>
          Name—{deletingTeam.name}
          </Grid>
          <Grid container spacing={1}>
            <Grid item>
           Users —
           </Grid>
           {deletingTeam.users.map((user) => {
            return <Grid item>
            <Typography>
              {user.firstName}
            </Typography>
            <Typography>
              {user.surname}
            </Typography>
            <Typography>
              {user.email}
            </Typography>
            </Grid>
           })}
           </Grid>
        <Grid item>
          <Grid container spacing={3}>

            <Grid item>
      <Button sx={{color: "black"}} variant="outlined" onClick={handleClose}>Close</Button>
      </Grid>
      <Grid item>
      <Button sx={{color: "black"}} variant="outlined" onClick={(e) => {handleDelete(deletingTeam.id)}}>Delete</Button>
      </Grid>
      </Grid>
      </Grid>
      </Grid>
    </Backdrop>
  }

  const handleChangePage = (event, newPage) => {
    setTeamPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    setTeamRowsPerPage(parseInt(event.target.value, 10));
    setTeamPage(0);
  };





  const navigate = useNavigate()
  // const handleCloseConfirmation = () => {
  //   setOpenConfirmation(false);
  // };

  const fetchCustomerOps = useCallback(() => {
    // eslint-disable-next-line require-jsdoc
    async function fetchData() {
      if (!loading) {
        const customers = await searchUsers("","", "","","");

        if (!customers.data.results.length) {

          setCustomers([]);
        } else {

          setCustomers(customers.data.results);

        }
      }
    }
    if (reload && !loading) {
      fetchData();

      setReload(false);
    }

  }, [loading, navigate, reload]);

  useEffect(() => {
    if (!loading) {
      if (customerEmail) {

        const user = customers.filter((item) => {
          return item.email === customerEmail;
        })[0];
        if (!user) {
          return;
        }
        // const userId = user.id;

        fetchCustomerOps();
      }
    }
  }, [customerEmail, customers, loading, fetchCustomerOps]);



  const getCustomers = useCallback(async () => {
    const res = await searchUsers('', '', '', "","");
    if (res.status !== 200 && res.data.results === undefined) {
      setCustomers([]);
      setCustomerOps([]);
    } else {
      let ops = [];
      res.data.results.map((op, i) => {
        if (op.is_active) {
          return ops = [...ops, {email: op.email, surname: op.surname, firstName: op.firstName}];
        }
        return null;
      });


      setCustomers(res.data.results);
      setCustomerOps(ops);
    }
  }, [navigate]);



  useEffect(() => {
    // eslint-disable-next-line require-jsdoc
    async function fetchData() {
      if (!loading) {
        const teams = await searchTeams(
            searchInput,
            "", "",
            navigate);

        if (!teams.data.results.length) {

          setRows([]);
        } else {

          var unique = teams.data.results.filter(function(item, pos){
            return teams.data.results.indexOf(item) === pos;
          });
          ;
          setRows([...new Set(teams.data.results.map(item => item))])
          // setRows(unique.map((team_id) => teams.data.results[team_id]));

        }
      }
    }
    if (reload || !loading) {
      fetchData();
      getCustomers()

      setReload(false);
    }

  }, [reload, navigate,  loading, searchInput, open, getCustomers]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setSendingData(true);
    const filteredIds = customers.filter((user) => checkedUsers.includes(user.email)).map((u) => u.id)

    const res = await createTeam(
        name,
        description,
        filteredIds,
        editingTeam.id
        // navigate
    );

    try {
      if (res.status === 201 || res.status === 200 || res.status === 204) {
        setSendingData(false);
        setErrorText('');
        setOpen(true);
        setName('')
        setName('')
        setDescription('')
        setCheckedUsers([])

        setDescription('')
        setReload(true)
        if (editingTeam.id) {
          onCancelClick()
        }
      } else if (res.status === 400 && res.data['email']) {
        setSendingData(false)
        setErrorText("Email: " + res.data['email']);
        setErrorOpen(true)
      } else if (res.status === 403 && res.data['results']) {
        setSendingData(false);
        setErrorText(res.data['results']);
        setErrorOpen(true)
      } else if (res.status === 404) {
        setSendingData(false);
        setErrorText('Something went wrong');
        setErrorOpen(true)
      } else {
        setSendingData(false);
        setErrorText(res.data['results']);
        setErrorOpen(true)
      }
    } catch (err) {
      setSendingData(false);
      setErrorText('Fatal error');
      setErrorOpen(true)
    }
    setSendingData(false);
    // handleCloseConfirmation();
  }, [customers, name, description, editingTeam, checkedUsers]);


  // const rows = [

  //   // createData('Ice cream sandwich', 237, 9.0),
  //   // createData('Eclair', 262, 16.0),
  //   // createData('Cupcake', 305, 3.7),
  //   // createData('Gingerbread', 356, 16.0),
  // ];

  const BasicTable = useMemo(() => {
    return (
      <Grid>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell >No. in Team</TableCell>
              <TableCell align="left">Description</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <TableRow
                key={"row-id-"+row.id}
                sx={{ height: '50px', '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>

                <TableCell align="left">
                <UserTooltip userRole={role} users={row.users}
                // title="Add"
                placement="top"
                arrow
                describeChild>
                  <Grid sx={{ cursor: "pointer"}} spacing={2} container alignItems="center"direction="row">

                  <Grid item>

                  <Typography sx={{textDecoration: "underline", color: "blue", }}>{row.users.length}</Typography>

                  </Grid>

                  <Grid item>
                  <GroupsIcon></GroupsIcon>
                  </Grid>
                  </Grid>
                  </UserTooltip>
                  </TableCell>

                <TableCell align="left">{row.description}</TableCell>
                <TableCell>

                  {rows.length > 0 ? <IconButton onClick={() => {return editingTeam.id === null ? onEditClick(row) : onCancelClick() }}>
                  {Object.values(editingTeam).length > 0 && editingTeam.id === row.id ? <CancelPresentationOutlined color="warning"></CancelPresentationOutlined> : <Edit></Edit> }
                    </IconButton>
                    : null}
                  </TableCell>
                <TableCell><IconButton onClick={() => {handleOpenModal(row)}}><DeleteForever></DeleteForever></IconButton></TableCell>


              {/* {row.users.map((user) => {
                user =user instanceof Array ? user : [user]
                return <TableRow
              key={"rowrow-id-"+user.email}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } } }

              >{user.surname}</TableRow>})} */}
              </TableRow>

            ))}
          </TableBody>
        </Table>
      </TableContainer>
                        <TablePagination
                        component="div"
                        count={teamCount}
                        page={teamPage}
                        onPageChange={handleChangePage}
                        rowsPerPage={teamRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />

                      </Grid>
    );
  }, [editingTeam, rows, teamCount, teamPage, teamRowsPerPage])

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false)
    setErrorText('');
  };

  const handleCloseError = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorOpen(false)
    setErrorText('');
  };

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handleDescChange = (e) => {
    setDescription(e.target.value);
    // setReload(true);
  };

  return <Grid container justifyContent="center" direction="row">

    <Grid item width="30%" justifyContent="center"
  // spacing={0}
  // direction="column"
  // alignItems="center"
  // justifyContent="center"
  alignItems="center" direction="column" sx={{margin: 5}}>
       <AnimatePresence
    // Disable any initial animations on children that
    // are present when the component is first rendered
    initial={false}
    // Only render one component at a time.
    // The exiting component will finish its exit
    // animation before entering component is rendered
    exitBeforeEnter={true}
    // Fires when all exiting nodes have completed animating out
    onExitComplete={() => null}
>
    {openModal && <Modal handleClose={handleCloseModal} text={"Are you sure you want to delete a Team?"}></Modal>}
</AnimatePresence>
            {/* <CardContent> */}
            <Snackbar onClose={handleClose} open={open && errorText === '' && !sendingData} autoHideDuration={6000} >
                  <Alert severity="success">
                    Team has been successfully created
                  </Alert>
                  </Snackbar>
                  <Snackbar onClose={handleCloseError} open={errorOpen && errorText !== '' && !sendingData} autoHideDuration={6000} >
                  <Alert severity="error">
                    {errorText}
                  </Alert>
                  </Snackbar>
                  <Grid container spacing={3}>
                  <Grid item>
              {/* <Typography  color="primary"  component="h1" variant="h3">
                {loading ? <CircularProgress  size="1.55rem" color="inherit"></CircularProgress> :
                companyName}
              </Typography> */}
              </Grid>
                  <Grid item>



            {/* <Typography component="h1" variant="h5">
                Company Teams
              </Typography> */}
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={3}>

            <Grid item>
              <Typography  color="primary"  component="h1" variant="h5">
                {loading ? <CircularProgress  size="1.55rem" color="inherit"></CircularProgress> :
                companyName}
              </Typography>
              <br></br>
              </Grid>

              <Grid item>



<Typography component="h1" variant="h6">
    Create Teams
  </Typography>
  </Grid>
  </Grid>
            <Grid item>
              <Typography component="h1" variant="h6">
              {editingTeam.id ? "Edit an existing Team" : "Create a new Team"}

</Typography>
<Typography component="h1" variant="h6">
{editingTeam.id ? <Chip color="warning" label="Editing an Team" variant="outlined" onDelete={() => {onCancelClick()}} /> : null}
          </Typography>
              </Grid>
              <Grid item>
              <Typography variant="subtitle1">
              </Typography>
              </Grid>
              <Grid item>
              <Box

                component="form"
                onSubmit={(e) => {handleSubmit(e)}}
                noValidate
                sx={{ mt: 1 }}
              >

                {/* <TextField
                  error={errorText !== '' || (submitted && !firstName)}
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  margin="normal"
                  required
                  fullWidth
                  id="first_name"
                  label="City"
                  name="firstname"
                  autoComplete="Firstname"
                  // autoFocus
                /> */}
                  {/* <Grid item style={{width: 350}} > */}

              {/* {customDropdown(countries, "Country", country, setCountry, profile)} */}
              {/* {customDropdown(teamTypes, "Type", type, setType, profile)} */}
              <TextField value={name} required error={submitted && !name} onChange={handleNameChange} label="Name" fullWidth sx={{ paddingBottom: 2 }}>Name</TextField>
              <TextField  value={description} onChange={handleDescChange} label="Description" fullWidth sx={{ paddingBottom: 2 }}>Description</TextField>
              <Autocomplete
        multiple
        onChange={(e, v) => {

          setCheckedUsers(v);

        }}
        value={checkedUsers}
        id="tags-filled"
        options={customerOps.map((option) => option.email)}
        // defaultValue={[customerO[13].title]}
        freeSolo
        inputValue={customerEmail}
        onInputChange={(e, v) => {
          setCustomerEmail(v);
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField
            required
            {...params}
            variant="outlined"
            label="Users"
            placeholder="Search or select user email"
            // onChange={handleUsersChange}
            // label="Users"
            fullWidth
            sx={{ paddingBottom: 2 }}
          />
        )}
      />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  // onClick={handleLogin}
                >
                  {sendingData ? <CircularProgress size="1.55rem" color="inherit"/> :
                  "Submit" }
                </Button>
              </Box>
              </Grid>
            {/* </CardContent> */}
          </Grid>
          <Grid item width="50%" sx={{margin: 5}}>
          <Grid item>



<Typography component="h1" variant="h5">
    <br></br>
  </Typography>
  </Grid>
{/* </Grid> */}
<br></br>
<Grid item>
  <Typography component="h1" variant="h6">
    Teams
  </Typography>
  <br></br>
  </Grid>
  <Grid item>
  <Typography variant="subtitle1">
  </Typography>
  <Grid item>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRounded />
                </InputAdornment>
              ),
            }}
            sx={{ minWidth: 400, mb: 1 }}
            fullWidth
            value={searchInput}
            onChange={(e) => {setSearchInput(e.target.value)}}
            label="Search Teams"
          ></TextField>
        </Grid>
  </Grid>
  {BasicTable}

</Grid>
          </Grid>

}


const mapStateToProps = ({user}) => ({
    loading: user.loading,
    companyName: user.companyName,
    profile: user.profile,
    role: user.role
  });
export default connect(mapStateToProps)(TeamsPage);