import { create } from 'zustand';
import Cookies from 'js-cookie';
import { websocketService } from '../services/websocketService';
// import { PhoneUser, useUserStore } from './userStore';
import { PartialConnectData, PartialConnectStatus, PhoneUser, useUserStore } from './userStore';
import { useVersionStore } from './versionStore';
import { useDataStore } from './dataStore';
import {
    GenericMessage,
    UserIncident,
    Asset,
    ChatChannel,
    ChatMessage,
    IntelligenceFusionIncidentFeature,
    CheckinHistoryItem,
    SOSHistoryItem,
    FollowMeHomeLocation,
    FollowMeHomeHistory,
    CountryAssessmentMap,
    Crisis24Item
} from '../types/data';

interface WebSocketStore {
  isConnected: boolean;
  messages: string[];
  notifications: string[];
  userStatus: string;
  setIsConnected: (isConnected: boolean) => void;
  connect: (username: string, password: string) => Promise<void>;
  accessTokenConnect: (username: string, accessToken: string) => Promise<void>;
  partialConnect: (partialConnectData: PartialConnectData) => Promise<void>;
  disconnect: () => void;
  logout: () => void;
  processHeartbeat: (message: any) => void;
  processUpdateAuthToken: (message: any) => void;
  processGetUserIncident: (message: {userIncidentsVersion:Date, userIncidentsData: UserIncident[]}) => void;
  processGetCountryAssessment: (message: any) => void;
  getDashboardEmbed: () => void;
  processGetDashboardEmbed: (message: any) => void;
  processGetAssets: (message: any) => void;
  processGetChat: (message: any) => void;
  processGetIntelligenceFusion: (message: any) => void;
  processGetCrisis24: (message: any) => void;
  processGetProfile: (message: any) => void;
  processGetCheckinHistory: (message: any) => void;
  processGetSOSHistory: (message: any) => void;
  processGetFollowMeHome: (message: any) => void;
  processGetFollowMeHomeHistory: (message: any) => void;
  fetchCountryAssessment: (country: string) => void;
  addNotification: (notification: string) => void;
  setUserStatus: (status: string) => void;
  handleWebSocketMessage: (message: GenericMessage) => void;
  sendUserIncident: (
    category_name: string,
    type_name: string,
    summary: string,
    started_at_timestamp: Date,
    ended_at_timestamp: Date,
    impact_rating: number,
    longitude: number,
    latitude: number,
    description: string,
  ) => void
  editUserIncident: (
    id:string,
    category_name: string,
    type_name: string,
    summary: string,
    started_at_timestamp: Date,
    ended_at_timestamp: Date,
    impact_rating: number,
    longitude: number,
    latitude: number,
    description: string,
  ) => void
  sendAsset: (name: string, type_name: string, description: string, longitude: number, latitude: number) => void
  editAsset: (id: string, name: string, type_name: string, description: string, longitude: number, latitude: number) => void
  sendOpenCheckin: (phoneUserID: number, adminUserId: number) => void
  sendUserInvite: (
    email: string,
    first_name: string,
    surname: string,
    phone_number: string,
  ) => void
  sendIncidentNotification: (
        receiver: number,
        id: string,
        category_name: string,
        type_name: string,
        summary: string,
        started_at_timestamp: Date,
        ended_at_timestamp: Date,
        impact_rating: number,
        longitude: number,
        latitude: number,
        description: string
    ) => void
    createIncidentWithNotification: (
        receiver: number,
        category_name: string,
        type_name: string,
        summary: string,
        started_at_timestamp: Date,
        ended_at_timestamp: Date,
        impact_rating: number,
        longitude: number,
        latitude: number,
        description: string
    ) => void
    sendStopSOS: (phoneUserID: number, adminUserId: number) => void
    sendChatMessage: (chatMessage: ChatMessage) => void
    deleteUserIncident: (incidentID: string) => void
    deleteAsset: (assetID: string) => void
    editUser: (id: number, email: string, firstName: string, surname: string, phoneNumber: string) => void
}

type ChatData = {
    user_id: number;
    messages: ChatMessage[];
};

type ParsedData = {
    chatData: ChatData[];
};
const parseChatData = (data: ParsedData): ChatChannel[] => {
    return data.chatData.map(chat => ({
        UserID: chat.user_id,
        Messages: chat.messages
    }));
};

export const useWebSocketStore = create<WebSocketStore>((set, get) => {
    return {
        isConnected: false,
        messages: [],
        notifications: [],
        userStatus: 'offline',
        setIsConnected: (isConnected: boolean) =>{
            set({ isConnected: isConnected });
        },
        connect: async (username: string, password: string) => {
            if (!get().isConnected) {
                // console.log("[connect][setUsername]", username);
                Cookies.set('username', username, { expires: 7 });
                set({ isConnected: true });

                try {
                    await websocketService.connect(username, password);
                    console.log("[isConnected][connect]");
                } catch (error){ 
                    console.error("[Password-Connect] connection threw an error: ", error);
                }
            }
        },
        accessTokenConnect: async (username: string, accessToken: string) => {
            if (!get().isConnected) {
                // useWebSocketStore.getState().setIsConnected(true);
                // console.log("[accessTokenConnect][setUsername]", username);
                Cookies.set('username', username, { expires: 7 });
                set({ isConnected: true });
                try {
                    await websocketService.accessTokenConnect(username, accessToken);
                    console.log("[isConnected][accessTokenConnect]");
                } catch (error){ 
                    console.error("[accessTokenConnect] connection threw an error: ", error);
                }
            }
        },
        partialConnect: async (data: PartialConnectData) => {
            console.log("[partialConnect]: start");
            const userStore = useUserStore.getState();
            userStore.setPasswordResetEmailSent(PartialConnectStatus.SENDING);

            const sentPasswordResetEmail = await websocketService.partialConnect(data);
            console.log("[partialConnect]: end -> sentPasswordResetEmail: ", sentPasswordResetEmail.toString());
            userStore.setPasswordResetEmailSent(sentPasswordResetEmail);
        },
        disconnect: () => {
            websocketService.disconnect();
            console.log("[isConnected][disconnect]");
        },
        logout: () => {
            // disconnects then removes accesstoken
            console.log("[isConnected][disconnect]");
            console.log("[logout][removeAccessToken]");
            Cookies.remove('accessToken');
            useUserStore.getState().setAuthenticated(false);
            websocketService.disconnect();
        },
        processHeartbeat: (data) => {
            console.log("[processHeartbeat]");
            console.log(data);
            if (useUserStore.getState().getRole() === "admin") {
                const currentManagedUsers = data.heartbeatUsers.map((managedUser:any) => {
                    return {
                        id: managedUser.userID,
                        connected: managedUser.status.connected,
                        phoneNumber: managedUser.phoneNumber,
                        isSOS: managedUser.status.sosActive,
                        checkinOpen: managedUser.status.checkinOpen,
                        riskRating: managedUser.status.riskRating,
                        isFMH: managedUser.status.followMeHomeActive,
                        location: managedUser.status.location,
                        email: managedUser.email,
                        is_active: managedUser.status.is_active,
                        firstName: managedUser.firstName,
                        surname: managedUser.surname,
                    } as PhoneUser;
                });
                useUserStore.getState().setManagedUsers(currentManagedUsers);
            } else {
                // Todo handle phone users here
                console.log("Phone User Heartbeat response");
            }
            console.log("useVersionStore.getState().getCountryAssessment()", useVersionStore.getState().getCountryAssessment());
            const serverCountryAssessmentVersion = new Date(data.countryAssessmentVersion);
            if (serverCountryAssessmentVersion > useVersionStore.getState().getCountryAssessment()) {
                const message = {
                    MessageType: "Get-CountryAssessment",
                    Data: {}
                };
                websocketService.send(message);
            }
            const serverUserIncidentsVersion = new Date(data.userIncidentsVersion);
            if (serverUserIncidentsVersion > useVersionStore.getState().getUserIncidents()) {
                // TODO Refactor send logic
                const message = {
                    MessageType: "Get-UserIncidents",
                    Data: {}
                };
                websocketService.send(message);
            }
            const serverChatVersion = new Date(data.chatVersion);
            if (serverChatVersion > useVersionStore.getState().getChatMessages()) {
                // TODO Refactor send logic
                const message = {
                    MessageType: "Get-Chat",
                    Data: {}
                };
                websocketService.send(message);
            }
            const serverAssetsVersion = new Date(data.assetsVersion);
            if (serverAssetsVersion > useVersionStore.getState().getAssets()) {
                // TODO Refactor send logic
                const message = {
                    MessageType: "Get-Assets",
                    Data: {}
                };
                websocketService.send(message);
            }
            const serverFollowMeHomeHistoryVersion = new Date(data.followMeHomeHistoryVersion);
            if (serverFollowMeHomeHistoryVersion > useVersionStore.getState().getFollowMeHomeHistory()) {
                // TODO Refactor send logic
                const message = {
                    MessageType: "Get-FollowMeHomeHistory",
                    Data: {}
                };
                websocketService.send(message);
            }
            const serverFollowMeHomeVersion = new Date(data.followMeHomeVersion);
            if (serverFollowMeHomeVersion > useVersionStore.getState().getFollowMeHome()) {
                // TODO Refactor send logic
                const message = {
                    MessageType: "Get-FollowMeHome",
                    Data: {}
                };
                websocketService.send(message);
            }
            const serverIntelligenceFusionVersion = new Date(data.intelligenceFusionVersion);
            if (serverIntelligenceFusionVersion > useVersionStore.getState().getIntelligenceFusion()) {
                // TODO Refactor send logic
                const message = {
                    MessageType: "Get-IntelligenceFusionIncidents",
                    Data: {}
                };
                websocketService.send(message);
            }
            const serverCrisis24Version = new Date(data.crisis24Version);
            if (serverCrisis24Version > useVersionStore.getState().getCrisis24()) {
                // TODO Refactor send logic
                const message = {
                    MessageType: "Get-Crisis24",
                    Data: {}
                };
                websocketService.send(message);
            }
            const serverSOSHistoryVersion = new Date(data.sosHistoryVersion);
            if (serverSOSHistoryVersion > useVersionStore.getState().getSOSHistory()) {
                // TODO Refactor send logic
                const message = {
                    MessageType: "Get-SOSHistory",
                    Data: {}
                };
                websocketService.send(message);
            }
            const serverCheckinHistoryVersion = new Date(data.checkinHistoryVersion);
            if (serverCheckinHistoryVersion > useVersionStore.getState().getCheckinHistory()) {
                // TODO Refactor send logic
                const message = {
                    MessageType: "Get-CheckinHistory",
                    Data: {}
                };
                websocketService.send(message);
            }
        },
        sendOpenCheckin: (phoneUserID: number, adminUserId: number) => {
            const message = {
                messageType: 'Open-Checkin',
                data: {
                    receiver: phoneUserID,
                    sender: adminUserId,
                    created_at: new Date()
                }
            };
            websocketService.send(message);
        },
        sendUserIncident: (
            category_name: string,
            type_name: string,
            summary: string,
            started_at_timestamp: Date,
            ended_at_timestamp: Date,
            impact_rating: number,
            longitude: number,
            latitude: number,
            description: string,
        ) => {
            const message = {
                messageType: 'Submit-UserIncident',
                data: {
                    category_name: category_name,
                    type_name: type_name,
                    summary: summary,
                    location_timezone: "",
                    started_at_timestamp: new Date(started_at_timestamp),
                    ended_at_timestamp: new Date(ended_at_timestamp),
                    impact_rating: impact_rating.toString(),
                    reported_at: new Date(),
                    longitude: longitude,
                    latitude: latitude,
                    description: description,
                }
            };
            websocketService.send(message);
        },
        editUserIncident: (
            id: string,
            category_name: string,
            type_name: string,
            summary: string,
            started_at_timestamp: Date,
            ended_at_timestamp: Date,
            impact_rating: number,
            longitude: number,
            latitude: number,
            description: string
        ) => {
            const message = {
                messageType: 'Edit-UserIncident',
                data: {
                    id: id,
                    category_name: category_name,
                    type_name: type_name,
                    summary: summary,
                    location_timezone: "",
                    started_at_timestamp: new Date(started_at_timestamp),
                    ended_at_timestamp: new Date(ended_at_timestamp),
                    impact_rating: impact_rating.toString(),
                    reported_at: new Date(),
                    longitude: longitude,
                    latitude: latitude,
                    description: description,
                }
            };
            websocketService.send(message);
        },
        sendAsset: (name: string, type_name: string, description: string, longitude: number, latitude: number) => {
            const message = {
                messageType: 'Submit-Asset',
                data: {
                    name: name,
                    type_name: type_name,
                    description: description,
                    date_created: new Date(),
                    longitude: longitude,
                    latitude: latitude,
                }
            };
            websocketService.send(message);
        },
        editAsset: (id: string, name: string, type_name: string, description: string, longitude: number, latitude: number) => {
            const message = {
                messageType: 'Edit-Asset',
                data: {
                    id: id,
                    name: name,
                    type_name: type_name,
                    description: description,
                    longitude: longitude,
                    latitude: latitude,
                }
            };
            websocketService.send(message);
        },
        sendUserInvite: (
            email: string,
            first_name: string,
            surname: string,
            phone_number: string,
        ) => {
            const randomStringGen = () => {
                // Generates a random string 60 chars long.
                let res = '';
                for (let i = 0; i < 10; i++) {
                    res += Math.random().toString(36).substring(7);
                }
                return res;
            };

            const randomPassword = randomStringGen();
            const message = {
                messageType: 'Submit-UserInvite',
                data: {
                    email: email,
                    firstName: first_name,
                    surname: surname,
                    phoneNumber: phone_number,
                    companyName: useUserStore.getState().user.companyName,
                    password: randomPassword,
                    rePassword: randomPassword,

                }
            };
            websocketService.send(message);
        },
        sendIncidentNotification: (
            receiver: number,
            id: string,
            category_name: string,
            type_name: string,
            summary: string,
            started_at_timestamp: Date,
            ended_at_timestamp: Date,
            impact_rating: number,
            longitude: number,
            latitude: number,
            description: string
        ) => {
            console.log("[Send-IncidentNotification]");
            console.log({
                receiver: receiver,
                incident_id:id,
                category_name: category_name,
                type_name: type_name,
                summary: summary,
                location_timezone: "",
                started_at_timestamp: new Date(started_at_timestamp),
                ended_at_timestamp: new Date(ended_at_timestamp),
                impact_rating: impact_rating.toString(),
                reported_at: new Date(),
                longitude: longitude,
                latitude: latitude,
                description: description,
            });
            const message = {
                messageType: 'Send-IncidentNotification',
                data: {
                    receiver: receiver,
                    incident: {
                        id: id,
                        category_name: category_name,
                        type_name: type_name,
                        summary: summary,
                        location_timezone: "",
                        started_at_timestamp: new Date(started_at_timestamp),
                        ended_at_timestamp: new Date(ended_at_timestamp),
                        impact_rating: impact_rating.toString(),
                        reported_at: new Date(),
                        longitude: longitude,
                        latitude: latitude,
                        description: description,
                    }
                }
            };
            websocketService.send(message);
        },
        createIncidentWithNotification: (
            receiver: number,
            category_name: string,
            type_name: string,
            summary: string,
            started_at_timestamp: Date,
            ended_at_timestamp: Date,
            impact_rating: number,
            longitude: number,
            latitude: number,
            description: string
        ) => {
            const message = {
                messageType: 'Create-IncidentWithNotification',
                data: {
                    receiver: receiver,
                    incident: {
                        category_name: category_name,
                        type_name: type_name,
                        summary: summary,
                        location_timezone: "",
                        started_at_timestamp: new Date(started_at_timestamp),
                        ended_at_timestamp: new Date(ended_at_timestamp),
                        impact_rating: impact_rating.toString(),
                        reported_at: new Date(),
                        longitude: longitude,
                        latitude: latitude,
                        description: description,
                    }
                }
            };
            websocketService.send(message);
        },
        sendStopSOS: (phoneUserID: number, adminUserId: number) => {
            const message = {
                messageType: 'Stop-SOS',
                data: {
                    receiver: phoneUserID,
                    sender: adminUserId,
                }
            };
            websocketService.send(message);
        },
        sendChatMessage: (chatMessage: ChatMessage) => {
            const message = {
                messageType: 'Update-Chat',
                data: {
                    message_text: chatMessage.message_text,
                    receiver: chatMessage.receiver,
                }
            };
            websocketService.send(message);
        },
        deleteUserIncident: (incidentID: string) => {
            const message = {
                messageType: 'Delete-UserIncident',
                data: {
                    id: incidentID,
                }
            };
            websocketService.send(message);
        },
        deleteAsset: (assetID: string) => {
            const message = {
                messageType: 'Delete-Asset',
                data: {
                    id: assetID,
                }
            };
            websocketService.send(message);
        },
        editUser: (id: number, email: string, firstName: string, surname: string, phoneNumber: string) => {
            const message = {
                messageType: 'Edit-User',
                data: {
                    id: id,
                    email: email,
                    first_name: firstName,
                    surname: surname,
                    phone_number: phoneNumber
                }
            };
            websocketService.send(message);
        },
        fetchCountryAssessment: (country: string) => {
            // const countryExists = useDataStore.getState().countryAssessment.some(data => data.category_name === country);
            const countryExists = country in useDataStore.getState().countryAssessment;

            if (!countryExists) {
                const message = {
                    messageType: 'Update-Country-Assessment',
                    data: {
                        message: country
                    }
                };
                websocketService.send(message);
            }
        },
        processGetUserIncident: (userIncidentMessage) => {
            const serverUserIncidentsVersion = new Date(userIncidentMessage.userIncidentsVersion);
            let UserIncidentsData: UserIncident[] = userIncidentMessage.userIncidentsData as UserIncident[];
            if (!UserIncidentsData) {
                UserIncidentsData = [] as UserIncident[];
            }
            useVersionStore.getState().setUserIncidents(serverUserIncidentsVersion);
            useDataStore.getState().setUserIncidents(UserIncidentsData);
        },
        processUpdateAuthToken: (authTokenMessage) => {
            const accessToken = authTokenMessage.accessToken;
            Cookies.set("accessToken", accessToken, {expires: 7});
        },
        processGetIntelligenceFusion: (intelligenceFusionMessage) => {
            const serverIntelligenceFusionIncidentsVersion = new Date(intelligenceFusionMessage.intelligenceFusionVersion);
            let IntelligenceFusionIncidentsData: IntelligenceFusionIncidentFeature[] = intelligenceFusionMessage.intelligenceFusionIncidentsData as IntelligenceFusionIncidentFeature[];
            if (!IntelligenceFusionIncidentsData) {
                IntelligenceFusionIncidentsData = [] as IntelligenceFusionIncidentFeature[];
            }
            useVersionStore.getState().setIntelligenceFusion(serverIntelligenceFusionIncidentsVersion);
            useDataStore.getState().setIntelligenceFusion(IntelligenceFusionIncidentsData);
        },
        processGetCrisis24: (crisis24Message) => {
            const serverCrisis24IncidentsVersion = new Date(crisis24Message.crisis24Version);
            let Crisis24Data: Crisis24Item[] = crisis24Message.crisis24Data as Crisis24Item[];
            if (!Crisis24Data) {
                Crisis24Data = [] as Crisis24Item[];
            }
            useVersionStore.getState().setCrisis24(serverCrisis24IncidentsVersion);
            useDataStore.getState().setCrisis24(Crisis24Data);
        },
        processGetCountryAssessment: (CountryAssessmentMessage) => {
            const serverCountryAssessmentsVersion = new Date(CountryAssessmentMessage.countryAssessmentVersion);
            let CountryAssessmentsData: CountryAssessmentMap = CountryAssessmentMessage.countryAssessmentData as CountryAssessmentMap;
            if (!CountryAssessmentsData) {
                CountryAssessmentsData = {} as CountryAssessmentMap;
            }
            useVersionStore.getState().setCountryAssessment(serverCountryAssessmentsVersion);
            useDataStore.getState().setCountryAssessment(CountryAssessmentsData);
        },
        processGetAssets: (AssetMessage) => {
            const serverAssetsVersion = new Date(AssetMessage.assetsVersion);
            let AssetsData: Asset[] = AssetMessage.assetsData as Asset[];
            if (!AssetsData) {
                AssetsData = [] as Asset[];
            }
            useVersionStore.getState().setAssets(serverAssetsVersion);
            useDataStore.getState().setAssets(AssetsData);

        },
        processGetFollowMeHomeHistory: (FollowMeHomeHistoryMessage) => {
            const serverFollowMeHomeHistoryVersion = new Date(FollowMeHomeHistoryMessage.followMeHomeHistoryVersion);
            let followMeHomeHistory: FollowMeHomeHistory = FollowMeHomeHistoryMessage.followMeHomeHistoryData as FollowMeHomeHistory;
            if (!followMeHomeHistory) {
                followMeHomeHistory = {} as FollowMeHomeHistory;
            }
            useVersionStore.getState().setFollowMeHomeHistory(serverFollowMeHomeHistoryVersion);
            useDataStore.getState().setFollowMeHomeHistory(followMeHomeHistory);
    
        },
        processGetCheckinHistory: (CheckinHistoryMessage) => {
            const serverCheckinHistoryVersion = new Date(CheckinHistoryMessage.checkinHistoryVersion);
            let CheckinHistoryData: CheckinHistoryItem[] = CheckinHistoryMessage.checkinHistoryData as CheckinHistoryItem[];
            if (!CheckinHistoryData) {
                CheckinHistoryData = [] as CheckinHistoryItem[];
            }
            useVersionStore.getState().setCheckinHistory(serverCheckinHistoryVersion);
            useDataStore.getState().setCheckinHistory(CheckinHistoryData);

        },
        processGetSOSHistory: (SOSHistoryMessage) => {
            const serverSOSHistoryVersion = new Date(SOSHistoryMessage.sosHistoryVersion);
            let SOSHistoryData: SOSHistoryItem[] = SOSHistoryMessage.sosHistoryData as SOSHistoryItem[];
            if (!SOSHistoryData) {
                SOSHistoryData = [] as SOSHistoryItem[];
            }
            useVersionStore.getState().setSOSHistory(serverSOSHistoryVersion);
            useDataStore.getState().setSOSHistory(SOSHistoryData);

        },
        processGetChat: (ChatMessage) => {
            const serverChatsVersion = new Date(ChatMessage.chatVersion);
            let chatChannels: ChatChannel[] = parseChatData(ChatMessage);
            if (!chatChannels) {
                chatChannels = [] as ChatChannel[];
            }
            useVersionStore.getState().setChatMessages(serverChatsVersion);
            useDataStore.getState().updateChat(chatChannels);
        },
        processGetFollowMeHome: (FollowMeHomeMessage) => {
            const serverFollowMeHomeVersion = new Date(FollowMeHomeMessage.followMeHomeVersion);
            let followMeHomeLocations: FollowMeHomeLocation[] = FollowMeHomeMessage.followMeHomeData as FollowMeHomeLocation[];
            if (!followMeHomeLocations) {
                followMeHomeLocations = [] as FollowMeHomeLocation[];
            }
            useVersionStore.getState().setFollowMeHome(serverFollowMeHomeVersion);
            useDataStore.getState().setFollowMeHomeLocations(followMeHomeLocations);
        },
        processGetProfile: (profileMessage) => {
            useUserStore.getState().initializeUser(
                profileMessage.profile.id,
                profileMessage.role,
                profileMessage.profile.gender,
                profileMessage.profile.email,
                profileMessage.profile.firstName,
                profileMessage.profile.surname,
                profileMessage.profile.companyName
            );
        },
        processGetDashboardEmbed: (dashboardEmbedMessage) => {
            useDataStore.getState().setDashboardEmbed(dashboardEmbedMessage.token);
        },
        addNotification: (notification) => set((state) => ({ notifications: [...state.notifications, notification] })),
        setUserStatus: (status) => set({ userStatus: status }),
        handleWebSocketMessage: (message: GenericMessage) => {
            switch (message.messageType) {
            case "Heartbeat Response": 
                console.log("message.data");
                console.log(message.data);
                get().processHeartbeat(message.data);
                break;
            case "Update-AuthToken":
                console.log("[Update-AuthToken]");
                get().processUpdateAuthToken(message.data);
                break;
            case "Get Profile": 
                get().processGetProfile(message.data);
                break;
            case "Get-UserIncidents": 
                get().processGetUserIncident(message.data as {userIncidentsVersion:Date, userIncidentsData: UserIncident[]});
                break;
            case "Get-CountryAssessment": 
                get().processGetCountryAssessment(message.data);
                break;
            case "Get-Assets": 
                get().processGetAssets(message.data);
                break;
            case "Get-Chat": 
                get().processGetChat(message.data);        
                break;
            case "Get-FollowMeHome": 
                get().processGetFollowMeHome(message.data);        
                break;
            case "Get-FollowMeHomeHistory": 
                get().processGetFollowMeHomeHistory(message.data);        
                break;
            case "Get-SOSHistory": 
                get().processGetSOSHistory(message.data);        
                break;
            case "Get-CheckinHistory": 
                get().processGetCheckinHistory(message.data);        
                break;
            case "Get-DashboardEmbed":
                get().processGetDashboardEmbed(message.data);
                break;
            case "Get-IntelligenceFusionIncidents": 
                get().processGetIntelligenceFusion(message.data);
                break;
            case "Get-Crisis24": 
                get().processGetCrisis24(message.data);
                break;
            default:
                console.log('Unknown message type:', message.messageType);
            }
        },
        getDashboardEmbed: () => {
            const message = {
                messageType: 'Get-DashboardEmbed',
                data: {
                }
            };
            websocketService.send(message);
        },
    };
});