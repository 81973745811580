import React, { useState, useEffect } from 'react';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Card, CircularProgress, IconButton, InputAdornment } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import left from './assets/left.png';

import { useNavigate } from 'react-router-dom';
import { useUserStore } from '../stores/userStore';
import { useWebSocketStore } from '../stores/websocketStore';
import './Login.css';
import logo from "../assets/logo_reversed.svg";

import globalConfig from "../config.json";
import TypingEffect from '../components/TypingEffect';
const theme = createTheme();



const Login: React.FC = () => {
    const { isAuthenticated, error } = useUserStore();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { connect } = useWebSocketStore();

    const [errorText, setErrorText] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            setLoadingButton(true);

            connect(username, password);


        } catch (err) {
            console.error('Connection error. Please try again.');
        }
    };
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (e: any) => {
        e.preventDefault();
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Grid alignItems="center" direction="row" height="100%" justifyContent="center" container component="main" maxWidth="xs">

                {/* <Box
      sx={{
        position: "fixed",
          left: 0,
          top: 0, height: "100%", zIndex: -1 }}component={"img"} src={left}></Box> */}
                <Grid item xs={6} sx={{
                    height: '100vh', left: 0,
                    top: 0, p: 10, backgroundColor: globalConfig["primaryMain"]
                }}>
                    <Grid
                        sx={{ height: "200px", }}
                        container
                        direction="row"
                        // justifyContent="center"
                        alignItems="center"
                    >
                        <img
                            className="logo"
                            height="100px"
                            width={"auto"}
                            src={logo}
                            alt="logo"
                            onClick={() => {
                                navigate("/");
                            }}
                        />

                    </Grid>
                    <Grid item container spacing={5}
                        alignItems="center" >
                        <Grid item sx={{ minHeight: 50 }}>
                            <Grid item sx={{ minHeight: 50 }}>
                                <TypingEffect />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" sx={{ color: "white", fontFamily: globalConfig["fontFamilies"], }}>
                                - Sheelagh Brady, CEO & Co-Founder at <b>KOWROO</b>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item
                    xs={6}
                    sx={{
                        // marginTop: {sx: 5, md: 10, lg: 15, xl: 30},
                        padding: 10,
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Card elevation={0} sx={{ minWidth: 400 }}>
                        <CardContent>
                            <Typography align="left" component="h1" variant="h5">
                                Log in
                            </Typography>
                            <Typography align="left" variant="subtitle1">
                                Get started for free
                            </Typography>
                            <Typography style={{ color: "red" }}>
                                {errorText}
                            </Typography>
                            <Box
                                component="form"
                                onSubmit={handleSubmit}
                                noValidate
                                sx={{ mt: 1 }}
                            >
                                <TextField
                                    tabIndex={1}
                                    type="email"
                                    error={errorText !== '' || (submitted && !username)}
                                    value={username}
                                    onChange={(e) => {
                                        setUsername(e.target.value);
                                    }}
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                />
                                <TextField
                                    error={errorText !== '' || (submitted && !password)}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>,
                                    }}
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    autoComplete="current-password"
                                />

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    {loadingButton ? <CircularProgress size="1.55rem" color="inherit" /> :
                                        "Sign In"}
                                </Button>
                                <Grid container>
                                    <Grid item>
                                        <Link href="/forgotpassword" variant="body2">
                                            Forgot password?
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default Login;
