import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
    CardActionArea,
    createTheme,
    LinearProgress,
} from "@mui/material";
import alert from "./assets/alert.png";
// import country_assessment from "./assets/country_assessment.png";
import incident from "./assets/incident.png";
// import maintenance from "./assets/maintenance.png";
import map from "./assets/map.png";
import company from "./assets/company.png";
import asset from "./assets/asset.png";

// import news from "./assets/news.png";
import report from "./assets/report.png";
import country_assessment from "./assets/country_assessment.png";
import user_settings from "./assets/user_settings.png";
import settings from "./assets/settings.png";
import chat from "./assets/chat.png";
import { Link as RouterLink } from "react-router-dom";

import Alert from "./Alert";
// import Chat from "./Chat";
import Incidents from "./IncidentsPage";
// import Maintenance from "./Maintenance";
import Map from "./MapDisplay";
// import News from "./News";
import Report from "./Report";
// import RiskAppetite from "./RiskAppetite";
import Settings from "./Settings";
import User_Settings from "./User_Settings";
// import Country_Assessment from "./Country_Assessment";
import { ThemeProvider } from "@emotion/react";
// import { connect } from "react-redux";
import AdminPage from "./AdminPage";
import AssetsPage from "./AssetsPage";
import { useUserStore } from "../stores/userStore";
import { toast } from "sonner";

const Dashboard: React.FC = () => {
    const [role, setRole] = useState("admin");
    const [loading, setLoading] = useState(false);
    const images = [
        {
            ima: chat,
            title: "Chat",
            path: "/chat",
            component: Alert,
        },
        { ima: map, title: "Map", path: "/map", component: Map },
        {
            ima: alert,
            title: "Alerts",
            path: "/alerts",
            component: Alert,
        },

        {
            ima: company,
            title: "Company",
            path: "/company",
            component: AdminPage,
        },
        {
            ima: incident,
            title: "Incidents",
            path: "/incidents",
            component: Incidents,
        },
        {
            ima: asset,
            title: "Assets",
            path: "/assets",
            component: AssetsPage,
        },
        // {
        //   ima: country_assessment,
        //   title: "Country Assessments",
        //   path: "/assessment",
        //   component: Country_Assessment,
        // },
        // role === "admin"
        //   ? {
        //       ima: risk_selector,
        //       title: "Risk Appetite Selector",
        //       path: "/risk_appetite",
        //       component: RiskAppetite,
        //     }
        //   : { ima: null },
        { ima: country_assessment, title: "Countries", path: "/assessment" },
        // { ima: chat, title: "Chat Functions", path: "/chat", component: Chat },

        // {
        //   ima: maintenance,
        //   title: "Maintenance",
        //   path: "/maintenance",
        //   component: Maintenance,
        // },
        {
            ima: report,
            title: "Query/Report",
            path: "/reports",
            component: Report,
        },
        {
            ima: settings,
            title: "Settings",
            path: "/settings",
            component: Settings,
            disabled: true,
        },
    ];

    const theme = createTheme();

    theme.typography.overline = {
        fontSize: "1.0rem",
        "@media (min-width:600px)": {
            fontSize: "1.5rem",
        },
        [theme.breakpoints.up("md")]: {
            fontSize: "1.2rem",
        },
        [theme.breakpoints.up("sm")]: {
            fontSize: "1.0rem",
        },
        [theme.breakpoints.up("xs")]: {
            fontSize: "0.9rem",
        },
    };

    const { isChatMinimized, setIsChatMinimized } = useUserStore((state) => ({
        isChatMinimized: state.isChatMinimized,
        setIsChatMinimized: state.setIsChatMinimized,
    }));


    return (
        <Grid className="home" container sx={{ pb: '80px' }}>
            {loading ? (
                <Grid container sx={{ position: "fixed" }}>
                    <LinearProgress sx={{}} color="inherit" />
                </Grid>
            ) : (
                <Grid sx={{ mt: 1 }}>
                    <Grid
                        justifyItems={"center"}
                        container
                        style={{
                            // paddingTop: 20,
                            paddingLeft: 100,
                            paddingRight: 70,
                            // paddingBottom: 50,
                        }}
                        spacing={{ xs: 4, md: 6, sm: 10 }}
                        columns={{ xs: 4, sm: 4, md: 10 }}
                    >
                        {images.map((pic, index) =>
                            pic.ima !== null ? (
                                <Grid
                                    justifyItems="center"
                                    justifyContent="center"
                                    item
                                    xs={6}
                                    sm={3}
                                    md={3}
                                    key={index}
                                >
                                    <Card
                                        onMouseEnter={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                        sx={{
                                            ":hover": !pic?.disabled ? {
                                                backgroundColor: "black",
                                                color: "white",
                                                boxShadow: 10,

                                                transform: "scale(1.05)",
                                                transition: "backgroundColor, transform 0.2s", // theme.shadows[20]
                                            } : {},



                                            " .MuiCardActionArea-focusHighlight": {
                                                background: pic?.disabled ? 'transparent' : null,
                                            },



                                            opacity: pic?.disabled ? 0.5 : 1,
                                            transition: "backgroundColor, transform 0.2s",
                                            backgroundColor: "#DCE0DA",
                                            maxWidth: 1500,
                                            maxHeight: 1000,
                                            margin: "0 auto",
                                        }}
                                        variant="outlined"
                                    >
                                        <ThemeProvider theme={theme}>
                                            <Typography
                                                // xs={1}
                                                sx={{ pl: 1 }}
                                                fontSize={10}
                                                gutterBottom
                                                variant="overline"
                                                component="div"
                                                color="main"
                                                className="title"
                                            >
                                                {pic.title}
                                            </Typography>
                                        </ThemeProvider>
                                        <CardActionArea
                                            sx={{ cursor: pic?.disabled ? 'not-allowed' : 'pointer', }}
                                            style={{
                                                backgroundPosition: "center",
                                                backgroundColor: "white",
                                                backgroundRepeat: "repeat",
                                            }}
                                            component={pic.title === 'Chat' ? Grid : RouterLink}
                                            to={!pic?.disabled ? pic?.path : null}
                                            onClick={() => {
                                                return pic.title === 'Chat' ? setIsChatMinimized(!isChatMinimized) : null;
                                            }}
                                        >
                                            <Grid
                                                justifyContent={"center"}
                                                justifyItems={"center"}
                                                container
                                            >
                                                <CardMedia
                                                    color="white"
                                                    style={{
                                                        opacity: 0.7,
                                                        margin: 0,
                                                        backgroundBlendMode: "overlay",
                                                        objectFit: "cover",
                                                        padding: 24,
                                                        height: "50%",
                                                        width: "50%",
                                                    }}
                                                    component="img"
                                                    alt="green iguana"
                                                    image={pic.ima}
                                                />
                                            </Grid>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ) : null
                        )}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};



export default Dashboard;
