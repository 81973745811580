import Cookies from 'js-cookie';
import { cryptoService } from './cryptoService';
import { useWebSocketStore } from '../stores/websocketStore';
import { PartialConnectData, PartialConnectStatus, useUserStore } from '../stores/userStore'; // Import the user store

class WebSocketService {
    private socket: WebSocket | null = null;
    private url: string;
    private isAuthenticated: boolean = false;
    private heartbeatIntervalId: NodeJS.Timeout | null = null;
    private onMessageCallback: ((data: any) => void) | null = null;

    private readTimeout: NodeJS.Timeout = null;
    private readTimeoutInterval: number = 15000; 
    
    constructor(url: string) {
        this.url = url;
    }



    // Reset the read timeout each time a message is received
    resetReadTimeout() {
        if (this.readTimeout) {
            clearTimeout(this.readTimeout);
        }

        this.readTimeout = setTimeout(() => {
            console.warn('Read timeout: No messages received from server for 10 seconds');
            this.disconnect();  // Disconnect if no messages are received in the given time
        }, this.readTimeoutInterval);
    }

    // Clear the timeout when the connection is closed
    clearReadTimeout() {
        if (this.readTimeout) {
            clearTimeout(this.readTimeout);
            this.readTimeout = null;
        }
    }

    async connect(username: string, password: string): Promise<boolean> {
        console.log("[async connect]");
        if (this.isConnected()){
            this.disconnect();
        }
        return new Promise((resolve, reject) => {
            cryptoService.generateKeyPair();
            this.socket = new WebSocket(this.url);

            this.socket.onopen = () => {
                this.resetReadTimeout();
                const publicKeyPEM = cryptoService.getPublicKeyPem();
                const handshakeData = JSON.stringify({
                    messageType: 'Handshake',
                    data: {
                        userPublicKey: publicKeyPEM,
                    }
                });
                if (this.socket) {
                    this.socket.send(handshakeData);
                }
            };

            this.socket.onmessage = async (event) => {
                this.resetReadTimeout();
                const message = JSON.parse(event.data);
                const data = message.data;
                const messageType = message.messageType;
                if (messageType === 'Handshake') {
                    console.log("[Handshake]");
                    console.log(event.data);
                    cryptoService.setServerPublicKey(data.serverPublicKey);
                    await this.sendPasswordCredentials(username, password);
                } else if ("encryptedDataStr" in message) {
                    const decryptedData = await cryptoService.decryptData(message);
                    const parsedData = JSON.parse(decryptedData);
                    const data = parsedData.data;
                    const messageType = parsedData.messageType;
                    console.log("completing on message");
                    console.log(parsedData);
                    if (messageType === 'Auth Response') {
                        if (data.status ==="success") {
                            // console.log("[connect][setAccessToken]", data.token);
                            Cookies.set('accessToken', data.token, { expires: 7 });
                            this.isAuthenticated = true;
                            console.log("[setAuthenticated][connect][data.status ===success]");
                            useUserStore.getState().setAuthenticated(true);
                            // useWebSocketStore.getState().setIsConnected(true);
                            this.startHeartbeat();  // Start heartbeat after successful authentication
                            resolve(this.isAuthenticated);
                        } else {
                            this.isAuthenticated = false;
                            console.log("[setAuthenticated][connect][data.status !==success]");
                            console.log("[connect][removeAccessToken]");
                            Cookies.remove("accessToken");
                            useUserStore.getState().setAuthenticated(false);
                            useWebSocketStore.getState().disconnect();
                            // useWebSocketStore.getState().setIsConnected(false);
                            
                            this.stopHeartbeat();  // stop heartbeat after unsuccessful authentication
                            resolve(this.isAuthenticated);
                        }
                    } else {
                        if (this.onMessageCallback) {
                            console.log("setting password onmessagecallback");
                            this.onMessageCallback(parsedData);
                        }
                    }
                }
            };

            this.socket.onerror = (error) => {
                console.error('WebSocket Password error:', error);
                this.disconnect();
                console.error('[this.disconnect();]');
                reject(error);
                console.error('[reject(error);]');
            };

            this.socket.onclose = () => {
                console.log('WebSocket disconnected6');
                this.isAuthenticated = false;
                console.log('[clearReadTimeout]');
                this.clearReadTimeout();
                console.log("[setAuthenticated][connect][onclose]");
                useWebSocketStore.getState().setIsConnected(false); 
                console.log("[useWebSocketStore.getState().setIsConnected(false); ]");
                this.stopHeartbeat();  // Stop heartbeat when disconnected
                console.log("[this.stopHeartbeat();]");
                this.socket = null;
                console.log("[this.socket]");
            };
        });
    }

    async accessTokenConnect(username: string, accessToken: string): Promise<boolean> {
        console.log("[async accessTokenConnect]");
        if (this.isConnected()){
            this.disconnect();
        }
        return new Promise((resolve, reject) => {
            cryptoService.generateKeyPair();
            this.socket = new WebSocket(this.url);

            this.socket.onopen = () => {
                this.resetReadTimeout();
                const publicKeyPEM = cryptoService.getPublicKeyPem();
                const handshakeData = JSON.stringify({
                    messageType: 'Handshake',
                    data: {
                        userPublicKey: publicKeyPEM,      
                    }
                });
                if (this.socket) { 
                    this.socket.send(handshakeData);
                }
            };

            this.socket.onmessage = async (event) => {
                this.resetReadTimeout();
                const message = JSON.parse(event.data);
                const data = message.data;
                const messageType = message.messageType;
                if (messageType === 'Handshake') {
                    console.log("[Handshake]");        
                    console.log(event.data);
                    cryptoService.setServerPublicKey(data.serverPublicKey);
                    await this.sendAccessTokenCredentials(username, accessToken);
                } else if ("encryptedDataStr" in message) {
                    const decryptedData = await cryptoService.decryptData(message);
                    const parsedData = JSON.parse(decryptedData);
                    const data = parsedData.data;
                    const messageType = parsedData.messageType;
                    console.log("completing on message");
                    console.log(parsedData);
                    if (messageType === 'Auth Response') {
                        if (data.status ==="success") {
                            console.log("[accessTokenConnect][setAccessToken]", data.token);
                            Cookies.set('accessToken', data.token, { expires: 7 });
                            this.isAuthenticated = true;

                            console.log("[setAuthenticated][accessTokenConnect][data.status ===success]");
                            useUserStore.getState().setAuthenticated(true);
                            // useWebSocketStore.getState().setIsConnected(true);
                            this.startHeartbeat();  // Start heartbeat after successful authentication
                            resolve(this.isAuthenticated);
                        } else {
                            this.isAuthenticated = false;

                            console.log("[setAuthenticated][accessTokenConnect][data.status !==success]");
                            console.log("[accessTokenConnect][removeAccessToken]");
                            Cookies.remove("accessToken");
                            useUserStore.getState().setAuthenticated(false);
                            useWebSocketStore.getState().disconnect();
                            this.stopHeartbeat();  // stop heartbeat after unsuccessful authentication
                            resolve(this.isAuthenticated);
                        }
                    } else {
                        if (this.onMessageCallback) {
                            console.log("setting accesstoken onmessagecallback");
                            this.onMessageCallback(parsedData); 
                        } 
                    }
                }
            };

            this.socket.onerror = (error) => {
                console.error('WebSocket Access Token error:', error);
                this.disconnect();
                reject(error);
            };

            this.socket.onclose = () => {
                this.clearReadTimeout();
                console.log('WebSocket disconnected3');
                this.isAuthenticated = false;
                console.log("[setAuthenticated][accessTokenConnect][onclose]");
                useWebSocketStore.getState().setIsConnected(false); 
                this.stopHeartbeat();  // Stop heartbeat when disconnected
            };
        });
    }

    // private sendPasswordCredentials(username: string, password: string): void {
    async partialConnect(partialConnectData: PartialConnectData): Promise<PartialConnectStatus> {
        return new Promise((resolve, reject) => {
            cryptoService.generateKeyPair();
            this.socket = new WebSocket(this.url);

            this.socket.onopen = () => {
                const publicKeyPEM = cryptoService.getPublicKeyPem();
                const handshakeData = JSON.stringify({
                    messageType: 'Handshake',
                    data: {
                        userPublicKey: publicKeyPEM,
                    }
                });
                console.log("[partialConnect] Handshake data -> ", handshakeData);
                if (this.socket) {
                    this.socket.send(handshakeData);
                }
            };

            this.socket.onmessage = async (event) => {
                const message = JSON.parse(event.data);
                const data = message.data;
                const messageType = message.messageType;
                if (messageType === 'Handshake') {
                    // console.log("[partialConnect]: event.data -> ", event.data);
                    cryptoService.setServerPublicKey(data.serverPublicKey);
                    if (partialConnectData.email && !partialConnectData.token) {
                        await this.sendResetPassword(partialConnectData.email);
                    } else {
                        await this.sendSetPassword(partialConnectData);
                    }
                } else if ("encryptedDataStr" in message) {
                    const decryptedData = await cryptoService.decryptData(message);
                    const parsedData = JSON.parse(decryptedData);
                    const data = parsedData.data;
                    // console.log("[partialConnect]: decryptedData -> ", decryptedData);
                    const messageType = parsedData.messageType;
                    console.log(parsedData);
                    if (messageType === 'ResetPassword') {
                        const status = data.status;
                        if (status === "success") {
                            resolve(PartialConnectStatus.SENT);
                        } else {
                            resolve(PartialConnectStatus.ERROR);
                        }
                        this.socket.close();
                    } else if (messageType === 'SetPassword') {
                        const status = data.status;
                        if (status === "success") {
                            resolve(PartialConnectStatus.SENT);
                        } else {
                            resolve(PartialConnectStatus.ERROR);
                        }
                        this.socket.close();
                    }
                }
            };

            this.socket.onerror = (error) => {
                console.error('WebSocket error:', error);
                reject(PartialConnectStatus.ERROR);
            };

            this.socket.onclose = () => {
                console.log('WebSocket disconnected');
                
            };
        });
    }

    private async sendResetPassword(email: string): Promise<void> {
        /*
         {
            messageType: "ResetPassword",
            data: {
                email: email,
            }
        };
        */

        const resetPasswordData = {
            messageType: "ResetPassword",
            data: {
                email: email,
            }
        };

        console.log("resetPasswordData sending...");

        this.send(resetPasswordData);
    }

    private async sendSetPassword(partialConnectData: PartialConnectData): Promise<void> {
        /*
          {
            messageType: "SetPassword",
            data: {
                email: email
                token: token
                uid: uid
                password: password
                repeatpassword: repeatpassword
                inviteid: inviteid
            }
        };
        */

        const setPasswordData = {
            messageType: "SetPassword",
            data: {
                email: partialConnectData.email,
                token: partialConnectData.token,
                uid: partialConnectData.uid,
                password: partialConnectData.password,
                repeatpassword: partialConnectData.repeatpassword,
                inviteId: partialConnectData.inviteId
            }
        };

        console.log("setPasswordData sending...");

        this.send(setPasswordData);
    }

    private sendPasswordCredentials(username: string, password: string): void {
        const authData = {
            messageType: "Auth",
            data: {
                username: username,
                password: password,
                grantType:"password",
                status: "success",
            }
        };

        console.log("authData sending");
        console.log(authData);

        this.send(authData);
    }

    private sendAccessTokenCredentials(username: string, accessToken: string): void {
        const authData = {
            messageType: "Auth",
            data: {
                username: username,
                accessToken: accessToken,
                grantType:"accessToken",
                status: "success",
            }
        };

        console.log("authData sending");
        console.log(authData);

        this.send(authData);
    }

    private startHeartbeat(): void {
        if (this.heartbeatIntervalId) {
            // Clear existing interval to prevent multiple intervals
            clearInterval(this.heartbeatIntervalId);
        }
        this.heartbeatIntervalId = setInterval(() => {
            if (this.isConnected()) {
                const heartbeatMessage = {
                    messageType: "Heartbeat",
                    data: {
                        FollowMeHomeActive: false,
                        SOSActive: false,
                        Location: {
                            longitude: -6,
                            latitude: 53,
                        }
                    }
                };
                // console.log("before heartbeat");
                this.send(heartbeatMessage).catch(error => console.error('Error sending heartbeat:', error));
                // console.log("after heartbeat");
            }
        }, 10000); // Send heartbeat every 10 seconds
    }

    private stopHeartbeat(): void {
        if (this.heartbeatIntervalId) {
            // console.log("before clear interval");
            clearInterval(this.heartbeatIntervalId);
            // console.log("after clear interval");
            this.heartbeatIntervalId = null;
        }
    }

    disconnect(): void {
        if (this.socket) {
            this.isAuthenticated = false;
            console.log("[disconnecting websocket]", this.socket);
            this.socket.close();
        }
    }

    async send(data: any): Promise<void> {
        console.log("[async send]", data);
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            try {
                const encryptedData = await cryptoService.encryptData(JSON.stringify(data));
                this.socket.send(JSON.stringify(encryptedData));
            } catch (error) {
                console.error('Error encrypting data:', error);
            }
        } else {
            console.error('Cannot send message: WebSocket is not open');
        }
    }

    setOnMessageCallback(callback: (data: any) => void) {
        this.onMessageCallback = callback;
    }

    isConnected(): boolean {
        // TODO should we align this with the store value? 
        return this.socket !== null && this.socket.readyState === WebSocket.OPEN && this.isAuthenticated;
    }
}

let HUB_URL = process.env.REACT_APP_HUB_URL;
if (!HUB_URL) {
    HUB_URL = "ws://localhost:8080/ws";
}
export const websocketService = new WebSocketService(HUB_URL);