import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {Provider} from 'react-redux';
import reportWebVitals from "./reportWebVitals";
import { SnackbarProvider } from "notistack";
import store from './store';
import PathnameApp from "./App";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
    <Provider store={store}>
    <SnackbarProvider >
      <PathnameApp/>
    </SnackbarProvider>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
