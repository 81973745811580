export const formatDateTimeToString = (dt: Date) => {
    if (!dt) {
        return 'No date';
    }
  
    const dtT = new Date(dt);
    const dtGb = new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    }).format(dtT);
    return dtGb;
};

export const formatDateTimeString = (dt: string) => {
    if (!dt) {
        return 'No date';
    }

    const dtT = new Date(dt);
    const dtGb = new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    }).format(dtT);
    return dtGb;
};