import store from "../../store"
import {Socket} from "../Socket.tsx"
import {
    socketConnected,
    socketError,
    socketDisconnected,
    socketReconnecting,
}  from "../../actions/socketActions"
import {formatDateTimeString, getDisplayNotification} from "../utils"

function getUserName(id, invitedUsers) {
    console.log("GET USERNAME")
    console.log(id)
    console.log(invitedUsers)
    const user = invitedUsers.find(user => user.id.toString()===id.toString())
    const userName = `${user.firstName} ${user.surname}`
    return userName
}


export const socketMiddleware = (socket) => (params) => (next) => (action) => {
    const { dispatch, getState } = params
    const { type } = action

    const websocketUrl = 'wss://websocket.kowroo.com:8080/ws'

    let companyName = ""
    let adminId = ""
    let invitedUsers = ""
    
    switch (type) {
        case 'socket/connect':
            companyName = action.payload.companyName
            adminId = action.payload.adminId
            invitedUsers = action.payload.invitedUsers 
            
            console.log("BEFORE CONNECT111")

            socket.connect(websocketUrl)
            console.log("AFTER CONNECT111")
            socket.on("open", (event) => {
                console.log("onOPEN1")
                console.log(event)
                console.log("FETCHED1")
                store.dispatch(socketConnected());
                let currentDate = new Date()
                console.log(currentDate)
                const jsonObj = {
                    type: 'Standard',
                    status: 'Initial',
                    company: companyName, 
                    uid: adminId.toString(), 
                    role: 'admin',
                    dateTime: formatDateTimeString(currentDate), 
                    // latitude: 12345,
                    // longitude: 12345,
                    // speed: 12345,
                    // altitude: 12345,
                    // accuracy: 12345,
                    // bearing: 12345,
                    message:'',
                    // userList: getPhoneUsers(currentUser)
                    userList: ''
                };
                console.log("initial connection info ")

                console.log(jsonObj)
                socket.send(jsonObj);
                console.log("initial message sent")
                console.log("initial connected")  
            })
            
            socket.on('error', (event) => {
                console.log("ERROR EVENT")
                console.log(event)
                const error = "Could not connect Websocket"
                store.dispatch(socketError(error));
            })
            
            socket.on('message', (event) => {
                const eventMessage = event.data
                if (eventMessage.charAt(0) === "{") {
                    console.log("RAW MESSAGE: ")
                    const message = JSON.parse(event.data);
                    console.log(message)
                    let notificationMessage = getDisplayNotification(message, getUserName(message.Uid, invitedUsers))
                    if (notificationMessage!==null) {
                        console.log("SETTING DISPLAY_NOTIFICATIONS")
                        store.dispatch({
                            type: 'SET_DISPLAY_NOTIFICATIONS',
                            payload: notificationMessage
                        })
                        console.log("SETTING DISPLAY_NOTIFICATIONS2")
                    }
                    store.dispatch({ type: "UPDATE_RISK_RATINGS", payload:message})
                    switch (message.Type) {
                        case "Standard":
                            if (message.Message!=""){
                                console.log("ADDING NEW CHAT MESSAGE")
                                store.dispatch({ type: "ADD_NEW_CHAT_MESSAGE", payload:message})
                            } 
                            store.dispatch({ type: "UPDATE_USER_LOCATION", payload:message})
                            break;
                        case 'FollowMe':
                            store.dispatch({ type: "UPDATE_FOLLOW_ME_HOME", payload:message})
                            break;
                        case 'SOS':
                            store.dispatch({ type: "UPDATE_SOS", payload:message})
                            break;
                        default:
                            // Ignore unknown message types
                            break;
                    }

                }
            })

            // console.log("AFTER SOCKET MESSAGE")
            socket.on('close', (event) => {
                // store.dispatch(socketDisconnected());
                console.log("!!! Test websocket failed")
                console.log(event)
                setTimeout(() => {
                    console.log("RECONECTING")
                    store.dispatch(socketReconnecting());
                    socket = new Socket();
                    socket.initial = false
                    socket.connect(websocketUrl)

                    socket.on('open', (event) => {
                        console.log("RECONNECT MESSAGE")
                        store.dispatch(socketConnected());

                        let currentDate = new Date()
                        console.log(currentDate)
                        const jsonObj = {
                            type: 'Standard',
                            status: 'Initial',
                            company: companyName, 
                            uid: adminId.toString(), 
                            role: 'admin',
                            dateTime: formatDateTimeString(currentDate), 
                            // latitude: 12345,
                            // longitude: 12345,
                            // speed: 12345,
                            // altitude: 12345,
                            // accuracy: 12345,
                            // bearing: 12345,
                            message:'',
                            // userList: getPhoneUsers(currentUser)
                            userList: ''
                        };
                        console.log("initial connection info 3")
                        console.log(JSON.stringify(jsonObj))
                        socket.send(jsonObj);
                        console.log("REconnection info mesage sent")
                    })
                }, 3000); // Retry connection after 3 seconds
                console.log("AFTER RECONNECT")
            })
            console.log("FINAL BEFORE BREAK")
            break
        case "socket/initial":
            companyName = action.payload.companyName
            adminId = action.payload.adminId
            invitedUsers = action.payload.invitedUsers 
            console.log("INITIAL!!!")
            console.log("INITIAL2!!!!")
            store.dispatch(socketConnected());

            const newDate = new Date();
            console.log(newDate)

            const jsonObj = {
                type: 'Standard',
                status: 'Initial',
                company: companyName, 
                uid: adminId.toString(), 
                role: 'admin',
                dateTime: formatDateTimeString(newDate), 
                // latitude: 12345,
                // longitude: 12345,
                // speed: 12345,
                // altitude: 12345,
                // accuracy: 12345,
                // bearing: 12345,
                message:'',
                // userList: getPhoneUsers(currentUser)
                userList: ''
            };
            console.log("initial connection info ")
            console.log(jsonObj)
            socket.send(jsonObj);
            console.log("INITIAL MESSAGE SENT")
        case "socket/sendchat":
            const chatMessage = action.message 
            console.log("dispacth message sending")
            console.log(chatMessage)

            socket.send(chatMessage)
            // store.dispatch()
            console.log("message sent ")
            break
        
        case "socket/sendincident":
            const userId = action.userId 
            companyName = action.companyName 
            adminId = action.adminId 
            let currentDate = new Date()
            const incidentNotification = {
                type: 'Standard',
                company: companyName, 
                uid: adminId.toString(), 
                role: 'admin',
                status: 'connected',
                dateTime: currentDate.toString(), 
                // Latitude: 12345,
                // Longitude: 12345,
                // Speed: 12345,
                // Altitude: 12345,
                // Accuracy: 12345,
                // Bearing: 12345,
                message:"",
                userList: `${userId}`,
                notifications:"[Incident]"
              }; 
            console.log("dispacth message sending")
            console.log(incidentNotification)

            socket.send(incidentNotification)
            // store.dispatch()
            console.log("message sent")
            break
        case "socket/cancelsos":
            console.log("[socket/cancelsos]")
            console.log(action)

            let cancelSOSUserId = action.userId 
            let cancelSOSCompanyName = action.companyName 
            let cancelSOSAdminId = action.adminId 
            let cancelSOSCurrentDate = new Date()
            const CancelSOSNotification = {
                type: 'SOS',
                company: cancelSOSCompanyName, 
                uid: cancelSOSAdminId.toString(), 
                role: 'admin',
                status: '[CancelSOS]',
                dateTime: cancelSOSCurrentDate.toString(), 
                // Latitude: 12345,
                // Longitude: 12345,
                // Speed: 12345,
                // Altitude: 12345,
                // Accuracy: 12345,
                // Bearing: 12345,
                message:"",
                userList: `${cancelSOSUserId}`,
                // notifications:"[Incident]"
                }; 
            console.log("dispacth message sending")
            console.log(CancelSOSNotification)

            socket.send(CancelSOSNotification)
            // store.dispatch()
            console.log("message sent")
            break

        case "socket/sendcheckin":
            const checkinMessage = action.message 
            console.log("dispacth checkin sending")
            console.log(checkinMessage)
            socket.send(checkinMessage)
            break
        
        case 'socket/disconnect':
            console.log("!!!!!! socket disconnecting ")
            socket.disconnect()
            break

        default:
            break
    }
  
    return next(action)
  }