import { SearchRounded, CancelPresentationOutlined } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { Close } from '@mui/icons-material/';
// import { Modal, Button as bootstrapButton } from "react-bootstrap";
import DisplayTable from "../components/DisplayTable";
import DeleteModal from "../components/DeleteModal";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { registerCustomer, searchUsers, editCustomer } from "../helpers/api";
import { randomStringGen } from "../helpers/utils";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useWindowDimensions } from "../helpers/hooks";
import FilterListIcon from "@mui/icons-material/FilterList";
import { AnimatePresence, motion } from "framer-motion";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import { MenuItem } from "@mui/material";
type UserApiResponse = {
  data: { results: Array<User> };
};


type User = {
  id: string;
  addressLineOne: string;
  addressLineTwo: string;
  banned: false;
  companyName: string;
  companyNumber: string;
  country: string;
  deleted: string;
  email: string;
  is_active: boolean;
  landline: string;
  numberOfEmployees: string;
  phoneNumber: string;
  position: string;
  postCode: string;
  risk_rating: string;
  role: string;
  stateOrCounty: string;
  title: string;
  firstName: string;
  surname: string;
};

const CompanyPage = (props: { loading: any; companyName: any }) => {
  // const [loading, setLoading] = useState(true);
  const { loading, companyName } = props;
  const [sendingData, setSendingData] = useState(false);
  // const [sendingInviteLink, setSendingInviteLink] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  // const [companyName, setCompanyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [rows, setRows] = useState([
    {
      email: "No users",
      phoneNumber: null,
      firstName: null,
      surname: null,
      is_active: null,
    },
  ]);
  const [searchInput, setSearchInput] = useState("");
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(true);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [editingUser, setEditingUser] = useState({ id: null, users: [] });
  const [deletingUser, setDeletingUser] = useState({
    id: null,
    email: null,
    phoneNumber: null,
    firstName: null,
    surname: null,
  });
  //data and fetching state
  const [userData, setUserData] = useState<User[]>([]);
  const [filters, setFilters] = useState({
    firstName: "",
    surname: "",
    phoneNumber: "",
    risk_rating: "",
    status: "",
  });
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    //if you want to avoid useEffect, look at the React Query example instead
    const fetchData = async () => {
      if (!userData.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {
        const response = await searchUsers("", "", "", "", "");
        console.log("queried user data", response);
        // Check if the response status indicates success
        if (response.status===200) {
          console.log("response ok")
          console.log("queried user data 1", response.data);
          setUserData(response.data.results);
          setRowCount(response.data.results.length)
          setIsLoading(false);
        } else {
          console.error("Failed to fetch user data. Response status:", response.status);
        }
      } catch (error) {
        console.error("Error querying user data:", error);
      }
    }
    fetchData()
  }, []);


  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        accessorKey: "firstName",
        header: "First Name",
        size:50
      },
      {
        accessorKey: "surname",
        header: "Surname",
        size:50
      },
      {
        accessorKey: "phoneNumber",
        header: "Phone Number",
        size:50
      },
      {
        accessorKey: "risk_rating",
        header: "Risk Rating",
        Cell: ({ cell, row }) => (
          <div>
            {
            row.original.risk_rating === "Low Risk" ? (
              <Typography color="green">{"Low Risk"}</Typography>
            ) : row.original.risk_rating === "Medium Risk" ? (
              <Typography color="orange">{"Medium Risk"}</Typography>
            ) : row.original.risk_rating === "High Risk" ? (
              <Typography color="red">{"High Risk"}</Typography>
            ) : (
              <Typography color="blue">{"Not available"}</Typography>
            )}
          </div>
        ),
        size:20
      },
      // {
      //   accessorKey: "is_active",
      //   header: "Status",
      //   Cell: ({ cell, row }) => (
      //     <div>
      //       {row.original.is_active ? (
      //         <Typography color="green">{"Active"}</Typography>
      //       ) : (
      //         <Typography color="red">{"Invited"}</Typography>
      //       )}
      //     </div>
      //   ),
      // },
    ],
    []
  );

  const Backdrop = ({ children, onClick }) => {
    return (
      <motion.div
        style={{
          zIndex: 200,
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          background: "#00000056",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={onClick}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {children}
      </motion.div>
    );
  };

  const handleSubmit = useCallback(
    async (e: { preventDefault: () => void }) => {
      e.preventDefault();
      const randomPassword = randomStringGen();
      setSendingData(true);
      if (!email) {
        setSendingData(false);
        setErrorText("Please enter an email");
        setErrorOpen(true);
        return;
      }
      if (!firstName) {
        setSendingData(false);
        setErrorText("Please enter a first name");
        setErrorOpen(true);
        return;
      }
      if (!surname) {
        setSendingData(false);
        setErrorText("Please enter a surname");
        setErrorOpen(true);
        return;
      }
      if (!matchIsValidTel(phoneNumber)) {
        setSendingData(false);
        setErrorText("Please enter a valid phone number");
        setErrorOpen(true);
        return;
      }
      const phone = phoneNumber.replace(/\s/g, "");
      let res = null;
      if (editingUser.id) {
        res = await editCustomer(
          // companyName,
          email,
          firstName,
          surname,
          phone
        );
        onCancelClick();
      } else {
        res = await registerCustomer(
          companyName,
          email,
          randomPassword,
          randomPassword,
          firstName,
          surname,
          phone
        );
      }
      try {
        if (res.status === 201 || res.status === 200 || res.status === 204) {
          setSendingData(false);
          setErrorText("");
          setOpen(true);
          setEmail("");
          setFirstName("");
          setSurname("");
          setPhoneNumber("");
          setReload(true);        
          const users = await searchUsers("", "", "", "", "");
          setUserData(users.data.results)
          setRowCount(users.data.results.length)
        } else if (res.status === 400 && res.data["email"]) {
          setSendingData(false);
          setErrorText("Email: " + res.data["email"]);
          setErrorOpen(true);
        } else if (res.status === 403 && res.data["detail"]) {
          setSendingData(false);
          setErrorText(res.data["detail"]);
          setErrorOpen(true);
        } else if (res.status === 404) {
          setSendingData(false);
          setErrorText("Something went wrong");
          setErrorOpen(true);
        } else {
          setSendingData(false);
          setErrorText("Unknown error");
          setErrorOpen(true);
        }
      } catch (err) {
        setSendingData(false);
        setErrorText("Fatal error");
        setErrorOpen(true);
      }
      setSendingData(false);
      // handleCloseConfirmation();
    },
    [email, firstName, surname, phoneNumber, editingUser.id]
  );


  const handleChange = (e: string) => {
    // if (matchIsValidTel(e)) {
    setPhoneNumber(e);
    // }
  };

  const onEditClick = useCallback((row) => {
    setEditingUser(row);
    setEmail(row.email);
    setFirstName(row.firstName);
    setSurname(row.surname);
    setPhoneNumber(row.phoneNumber);
  }, []);

  const handleClose = (e: any, reason: string) => {
    if (reason === "clickaway") {return;}
    setOpen(false);
    setErrorText("");
  };

  const handleCloseError = (e: any, reason: string) => {
    if (reason === "clickaway") {return;}
    setErrorOpen(false);
    setErrorText("");
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = (row) => {
    setOpenModal(true);
    console.log("handle open amodel",row.original)
    setDeletingUser(row.original);
  };

  const onCancelClick = () => {
    setEditingUser({ id: null, users: [] });
    setEmail("");
    setFirstName("");
    setSurname("");
    setPhoneNumber("");
    setReload(true);
  };

  const handleDelete = useCallback(
    async (id) => {
      setReload(true);
      if (id === editingUser.id) {
        // Cancel editing the trip if it has been successfully deleted
        onCancelClick();
      }
      setOpenModal(false);
      const users = await searchUsers("", "", "", "", "");
      setUserData(users.data.results)
      setRowCount(users.data.results.length)

    },
    [editingUser.id]
  );

  const usersTable = useMemo(() => {
    console.log("user data is ", userData)
    const displayTable = (
      <DisplayTable
        columns={columns}
        data={userData}
        onEditClick={onEditClick}
        handleOpenModal={handleOpenModal}
        columnFilters={columnFilters}
        globalFilter={globalFilter}
        sorting={sorting}
        pagination={pagination}
        rowCount={rowCount}
        isRefetching={isRefetching}
        isError={isError}
        isLoading={isLoading}
        setColumnFilters={setColumnFilters}
        setGlobalFilter={setGlobalFilter}
        setPagination={setPagination}
        setSorting={setSorting}
        enableRowActions={true}
      />
    )
    return (
      displayTable
    );
  }, [
    columnFilters,
    columns,
    userData,
    globalFilter,
    isError,
    isLoading,
    isRefetching,
    onEditClick,
    pagination,
    rowCount,
    sorting,
  ]);

  

  return (
    <Grid container direction="row">
      <Grid
        // item
        container
        width="30%"
        justifyContent="center"
        spacing={0}
        // direction="column"
        // alignItems="center"
        // justifyContent="center"
        // alignItems="center"
        direction="column"
        sx={{ margin: 5 }}
      >
        <AnimatePresence
          // Disable any initial animations on children that
          // are present when the component is first rendered
          initial={false}
          // Only render one component at a time.
          // The exiting component will finish its exit
          // animation before entering component is rendered
          exitBeforeEnter={true}
          // Fires when all exiting nodes have completed animating out
          onExitComplete={() => null}
        >
          {openModal && (
            <DeleteModal
              titleText={"Are you sure you want to Delete the following User?"} 
              bodyText={`${deletingUser.firstName} ${deletingUser.surname} (${deletingUser.email})`}
              handleClose={handleCloseModal} 
              handleDelete={handleDelete} 
            ></DeleteModal>
          )}
        </AnimatePresence>
        {/* <CardContent> */}
        <Snackbar
          onClose={handleClose}
          open={
            open && errorText === "" && !sendingData && editingUser.id !== null
          }
          autoHideDuration={6000}
        >
          <Alert severity="success">Invite has been successfully sent</Alert>
        </Snackbar>
        <Snackbar
          onClose={handleClose}
          open={
            open && errorText === "" && !sendingData && editingUser.id === null
          }
          autoHideDuration={6000}
        >
          <Alert severity="success">User data has been updated</Alert>
        </Snackbar>
        <Snackbar
          onClose={handleCloseError}
          open={errorOpen && errorText !== "" && !sendingData}
          autoHideDuration={6000}
        >
          <Alert severity="error">{errorText}</Alert>
        </Snackbar>
        <Grid container spacing={3}>
          <Grid item>
            <Typography color="primary" component="h1" variant="h5">
              {loading ? (
                <CircularProgress
                  size="1.55rem"
                  color="inherit"
                ></CircularProgress>
              ) : (
                companyName
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Typography component="h1" variant="h6">
              {/* Company profile */}
              {/* <IconButton size="large" sx={{fontSize: 30, height: 50, width: 50}} ><EditIcon width={50} height={50} sx={{width:30, height: 30}}></EditIcon></IconButton> */}
            </Typography>
          </Grid>
        </Grid>
        <br></br>

        <Grid item id="invite_user" sx={{}}>
          <Typography component="h1" variant="h6">
            {editingUser.id
              ? "Edit an existing employee"
              : "Add Employee Account"}
          </Typography>
          <Typography component="h1" variant="h6">
            {editingUser.id ? (
              <Chip
                color="warning"
                label="Editing an employee"
                variant="outlined"
                onDelete={() => {
                  onCancelClick();
                }}
              />
            ) : null}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1"></Typography>
        </Grid>
        <Grid item>
          <Box
            component="form"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              // type="text"
              error={errorText.includes("email") || (submitted && !email)}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              margin="normal"
              required
              fullWidth
              id="user_name"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              error={
                errorText.includes("first name") || (submitted && !firstName)
              }
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              margin="normal"
              required
              fullWidth
              id="first_name"
              label="First Name"
              name="firstname"
              autoComplete="Firstname"
              // autoFocus
            />

            <TextField
              error={errorText.includes("surname") || (submitted && !surname)}
              value={surname}
              onChange={(e) => {
                setSurname(e.target.value);
              }}
              margin="normal"
              required
              fullWidth
              id="last_name"
              label="Last Name"
              name="last name"
              autoComplete="Lastname"
              // autoFocus
            />
            <br></br>
            <Grid>
              <MuiTelInput
                autoComplete={"off"}
                error={errorText.includes("phone number")}
                sx={{ pt: 2 }}
                fullWidth
                forceCallingCode
                defaultCountry="IE"
                value={phoneNumber}
                onChange={handleChange}
              />
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              // onClick={handleLogin}
            >
              {sendingData ? (
                <CircularProgress size="1.55rem" color="inherit" />
              ) : editingUser.id === null ? (
                "Invite customer user"
              ) : (
                "Update customer user"
              )}
            </Button>
          </Box>
        </Grid>
        {/* </CardContent> */}
      </Grid>
      <Grid item width="50%" sx={{ margin: 5 }}>
        <Grid item>
          <Typography component="h1" variant="h5">
            <br></br>
          </Typography>
        </Grid>
        {/* </Grid> */}
        <br></br>
        <Grid item>
          <Typography component="h1" variant="h6">
            Company users
          </Typography>
          <br></br>
        </Grid>
        {usersTable}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ user }) => ({
  loading: user.loading,
  companyName: user.companyName,
});
export default connect(mapStateToProps)(CompanyPage);
