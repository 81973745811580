import React from 'react';
import { Drawer } from '@mui/material';
import IncidentsPage from '../pages/IncidentsPage';


interface CreateIncidentDrawerProps {
    drawerOpen: boolean;
    setDrawerOpen: (open: boolean) => void;

    initialLatitude: number;
    initialLongitude: number;
}

const CreateIncidentDrawer: React.FC<CreateIncidentDrawerProps> = ({ initialLatitude, initialLongitude,  drawerOpen, setDrawerOpen }) => {
  return (
    <>
      <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      PaperProps={{
        sx: {
          width: '70%',
          maxWidth: '70%', // Ensures the drawer doesn't exceed 60% of the viewport width
        },
      }}
      >
        <div style={{ padding: '20px' }}>
            <IncidentsPage
            initialLatitude={initialLatitude}
            initialLongitude={initialLongitude}
            />
        </div>
      </Drawer>
    </>
  );
};

export default CreateIncidentDrawer;
