import { FormControlLabel, Checkbox } from "@mui/material"
import { useMemo } from "react"


const Label = (props: any) => {
  const { 
    filter, 
    handleDataSource, 
    sourceInfo,
    enabledSources
  } = props
  const label = useMemo(() => {
    const checkItem = enabledSources[sourceInfo.source]
    return <FormControlLabel
      control={
        <Checkbox 
          checked={checkItem}
          onChange={(e) => {return handleDataSource(e, filter)}} 
        />
      }
      label={filter}
    />
  }, [
    enabledSources
  ])

  return label
}

export default Label;