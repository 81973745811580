import SignIn from "./components/SignIn";
import RegisterForm from "./components/RegisterForm";

import Topbar from "./components/menu/topbar/Topbar";
import MainHome from "./pages/Dashboard";
import AdminPage from "./pages/AdminPage";
import Alert from "./pages/Alert";
import Chat from "./pages/Chat";
import Countriesinterest from "./pages/Countries_Interest";
import IncidentsPage from "./pages/IncidentsPage";
import Maintenance from "./pages/Maintenance";
import MapDisplay from "./pages/MapDisplay";
import News from "./pages/News";
import Report from "./pages/Report";
import RiskAppetite from "./pages/Risk_Appetite";
import Settings from "./pages/Settings";
import Privacy from "./pages/Privacy";
import UserSettings from "./pages/User_Settings";
import CountryAssessment from "./pages/Country_Assessment";

// import Route from 'next/Route'
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { createContext, useContext, useEffect, useState } from "react";
import { isLoggedIn, loadStoreVariables } from "./helpers/utils";
import PropTypes from "prop-types";
import PasswordReset from "./pages/PasswordReset";
import SendPasswordReset from "./pages/SendPasswordReset";
import Profile from "./pages/Profile";
import TripsPage from "./pages/TripsPage";
import TeamsPage from "./pages/TeamsPage";
import AssetsPage from "./pages/AssetsPage";
import RiskRating from "./pages/Risk_Rating";
import "mapbox-gl/dist/mapbox-gl.css";
// import { useDispatch } from 'react-redux';
import store from "./store"
import { RootState } from "./reducers/rootReducer";
import left  from './pages/assets/left.png'
import { Box, Grid } from "@mui/material";
import MainCompanyPage from "./pages/MainCompanyPage";

const ProtectedRoute = ({
  redirectPath = "/sign_in",
  children,
  currentPath = "",
  restricted = false,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const loggedIn = isLoggedIn();
  // const user = useSelector((state: RootState) => state.user)
  
  useEffect(() => {
    console.log("ProtectedRoute0")
    if (!loggedIn && ["/", ""].includes(location.pathname)) {
      // Basic not logged in redirect
      navigate("/sign_in");
      // window.location.reload();
      console.log("ProtectedRoute1")
    } else if (loggedIn && ["/", ""].includes(location.pathname)) {
      // Basic logged in redirect
      navigate("/home");
      // window.location.reload();
      console.log("ProtectedRoute2")
    }
    if (!loggedIn && !restricted) {
      // Restrict not logged in users to sign in pages
      navigate(redirectPath, { replace: true });
      console.log("ProtectedRoute3")
    }
    if (loggedIn && restricted) {
      // Restrict logged in users to private pages to avoid issues with signing in multiple users
      navigate(redirectPath, { replace: true });
      console.log("ProtectedRoute4")
      // window.location.reload();
    }
  }, [location.pathname, loggedIn, navigate, redirectPath, restricted]);
  return children ? (
      children
  ) : (
      <Outlet />
  );
};

// const WebsocketContextLayout = () => {
//   return (
//     <WebSocketProvider>
//       <Outlet />
//     </WebSocketProvider>
//   );
// };

ProtectedRoute.propTypes = {
  children: PropTypes.any,
  restricted: PropTypes.bool,
};


function App(props) {
  const loggedIn = isLoggedIn();
  if (loggedIn){

    loadStoreVariables()
  }
  let storeObj = store
  useEffect(()=>{
    return () => {
      console.log("############## disconnecteing")
      store.dispatch({ type: 'socket/disconnect' });
    }
  }, [store.dispatch])
  return (
    <BrowserRouter>
      <Grid className="App">
        {/* Do not display Topbar on following routes and their extensions */}
        <Grid container>
        <Box display="flex" justifyContent="center" className="container"  sx={{ p: 0, right: 0, paddingLeft: {xs: "0px", sm: "240px"}, width: { sm: `calc(100% - 240px)` } }}>
        {/* <Box display="flex" justifyContent="center" className="container"  sx={{ p: 0, right: 0, paddingLeft: {xs: "0px", sm: "240px"}, width: {  } }}> */}
        
          {/* <Sidebar /> */}
          <Routes>
            {/* Public Routes */}
            <Route
              path="/privacy"
              element={
                <ProtectedRoute currentPath="/privacy" restricted={true}>
                  {/* <Topbar /> */}
                  {/* <MainHome /> */}
                  <Privacy />
                </ProtectedRoute>
              }
            />
            <Route
              path="/forgotpassword"
              element={
                <ProtectedRoute restricted={true} redirectPath={"/home"}>
                  <Box sx={{position: "fixed",  left: 0, top: 0, height: "100%", zIndex: -5 }} component={"img"} src={left}></Box>
                  <SendPasswordReset />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="/"
              element={
                <ProtectedRoute
                  restricted={true}
                  redirectPath={"/home"}
                >
                </ProtectedRoute>
              }
            />
            <Route
            path="/sign_in"
            element={
                <ProtectedRoute restricted={true} redirectPath={"/home"}>
                  <Box sx={{position: "fixed",  left: 0, top: 0, height: "100%", zIndex: -1 }}component={"img"} src={left}></Box>
                  <SignIn />
                </ProtectedRoute>
              }
            />
            <Route path="/minimap"
              element={
                <ProtectedRoute restricted={true} currentPath="/minimap">
                  {/* <Minimap currentPage = "/minimap" /> */}
                </ProtectedRoute>
              }
            />
            <Route
              path="/register_kowroo_u2"
              element={
                <ProtectedRoute restricted={true} redirectPath={"/home"}>
                  <Box sx={{position: "fixed",  left: 0, top: 0, height: "100%", zIndex: -5 }}component={"img"} src={left}></Box>
                  <RegisterForm />
                </ProtectedRoute>
              }
            />
            {/* Public routes only if page params provided, see useEffect of the PasswordReset page*/}
            <Route
              path="/setpassword/*"
              element={
                <ProtectedRoute restricted={true} redirectPath={"/home"}>
                  <Box sx={{position: "fixed",  left: 0, top: 0, height: "100%", zIndex: -5 }}component={"img"} src={left}></Box>
                  <PasswordReset />
                </ProtectedRoute>
              }
            />
            <Route
              path="/passwordreset/*"
              element={
                <ProtectedRoute restricted={true} redirectPath={"/home"}>
                  <Box sx={{position: "fixed", left: 0, top: 0, height: "100%", zIndex: -5 }}component={"img"} src={left}></Box>
                  <PasswordReset />
                </ProtectedRoute>
              }
            />
            {/* Private Routes */}
            {/* <Route element={<WebsocketContextLayout/>}> */}
            <Route
              path="/home"
              element={
                <ProtectedRoute currentPath="/home">
                  <Topbar />
                  <MainHome />
                </ProtectedRoute>
              }
            />
            <Route
              path="/companies"
              element={
                <ProtectedRoute currentPath="/companies">
                  <Topbar />
                  {/* <Minimap currentPage = "/companies" /> */}
                  <AdminPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="incidents"
              element={
                <ProtectedRoute currentPath="/incidents">
                  <Topbar />
                  {/* <Minimap currentPage="/incidents"/> */}
                  <IncidentsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/assets"
              element={
                <ProtectedRoute currentPath="/assets">
                  <Topbar />
                  {/* <Minimap currentPage="/assets"/> */}
                  <AssetsPage />
                </ProtectedRoute>
              }
            />
            <Route path="/map"
              element={
                <ProtectedRoute currentPath="/map">
                  <Topbar />
                  <MapDisplay />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teams"
              element={
                <ProtectedRoute currentPath="/teams">
                  <Topbar />
                  {/* <Minimap currentPage="/teams"/> */}
                  <TeamsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/assessment"
              element={
                <ProtectedRoute currentPath="/assessment">
                  <Topbar />
                  {/* <Minimap currentPage="/assessment"/> */}
                  <CountryAssessment />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/risk_appetite"}
              element={
                <ProtectedRoute currentPath="/risk_appetite">
                  <Topbar />
                  <RiskAppetite />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/risk_rating"}
              element={
                <ProtectedRoute currentPath="/risk_rating">
                  <Topbar />
                  {/* <Minimap currentPage="/risk_rating"/> */}
                  <RiskRating />
                </ProtectedRoute>
              }
            />
            <Route
              path={"/company"}
              element={
                <ProtectedRoute currentPath="/company">
                  <Topbar />
                  <MainCompanyPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/trips"
              element={
                <ProtectedRoute currentPath="/trips">
                  <Topbar />
                  {/* <Minimap currentPage="/trips"/> */}
                  <TripsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/countries_interest"
              element={
                <ProtectedRoute currentPath="/countries_interest">
                  <Topbar />
                  {/* <Minimap currentPage="/countries_interest"/> */}
                  <Countriesinterest />
                </ProtectedRoute>
              }
            />
            <Route
              path="/alert"
              element={
                <ProtectedRoute currentPath="/alert">
                  <Topbar />
                  {/* <Minimap currentPage="/alert"/> */}
                  <Alert />
                </ProtectedRoute>
              }
            />
            <Route
              path="/news"
              element={
                <ProtectedRoute currentPath="/news">
                  <Topbar />
                  <News />
                </ProtectedRoute>
              }
            />
            <Route
              path="/chat"
              element={
                <ProtectedRoute currentPath="/chat">
                  <Topbar />
                  <Chat />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reports"
              element={
                <ProtectedRoute currentPath="/reports">
                  <Topbar />
                  <Report currentPage='reports' />
                </ProtectedRoute>
              }
            />
            {/* <Route
              path="/maintenance"
              element={
                <ProtectedRoute currentPath="/maintenance">
                  <Topbar />
                  <Maintenance />
                </ProtectedRoute>
              } 
            /> */}
            <Route
              path="/user_settings"
              element={
              <ProtectedRoute currentPath="/user_settings">
                <Topbar />
                <UserSettings />
              </ProtectedRoute>
            } />
            <Route
              path="/settings"
              element={
              <ProtectedRoute currentPath="/settings">
                <Topbar />
                <Settings />
              </ProtectedRoute>
            } />
            <Route
              path="/profile"
              element={
              <ProtectedRoute currentPath="/profile">
                <Topbar />
                <Profile />
              </ProtectedRoute>
            } />
            {/* Redirect to main page if nonexistent page is accessed */}
            <Route path="*" element={<Navigate to="/"/>} />

            {/* </Route> */}
        
          </Routes>
        </Box>
        </Grid>
      </Grid>
    </BrowserRouter>
  );
}

function withPathname(WrappedComponent) {
  return function (props) {
    const [pathname, setPathname] = useState(window.location.pathname);

    useEffect(() => {
      setPathname(window.location.pathname);
    }, []);

    return <WrappedComponent pathname={pathname} {...props} />;
  };
}

const PathnameApp = withPathname(App)

export default PathnameApp;
