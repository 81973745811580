
import React, { useMemo } from "react";

import { 
    Button,
    Box, 
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
} from "@mui/material";
import { Close } from '@mui/icons-material/';
import {formatDateTimeString} from "../helpers/utils"

const CancelSOSModal = (props:any) => {
    const { 
        handleClose, 
        handlePush, 
        selectedUser
    } = props

    console.log("[CancelSOSModal] User")
    console.log(selectedUser)
    let cancelMessage = {
        Type: "SOS",
        Status: "[CancelSOS]",
        UserList: selectedUser.user_id
    }
    const table = useMemo(() => {
        return (
            <Dialog open={true} maxWidth="sm" fullWidth >
                <DialogTitle>Do you want to cancel the SOS made by the following User?</DialogTitle>
                <Box position="absolute" top={0} right={0}>
                    <IconButton  onClick={handleClose}>
                    <Close/>
                    </IconButton>
                </Box>
                <DialogContent>
                    <Typography>{selectedUser.user_name}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary" 
                        variant="contained" 
                        onClick={handleClose}
                        sx={{
                            backgroundColor: '#005A31',
                            color: 'white',
                            '&:hover': {
                              backgroundColor: 'green', // Change background color on hover if desired
                            },
                          }}
                    >
                    Cancel
                    </Button>
                    <Button 
                        color="secondary" 
                        variant="contained" 
                        onClick={()=> handlePush(cancelMessage)}
                        sx={{
                            backgroundColor: '#005A31',
                            color: 'white',
                            '&:hover': {
                              backgroundColor: 'green', // Change background color on hover if desired
                            },
                          }}
                    >
                    Cancel SOS
                    </Button >
                </DialogActions>
            </Dialog>
            );
    }, [
    selectedUser
    ]);
    return table
}

export default CancelSOSModal;