import React from 'react';
import './404.css';
import logo from "../../assets/logo_reversed.svg";

function NotFoundPage() {
    return (
        <div className='page'
            style={{
                height: '100vh',
            }}
        >
            <div className='page-content'>
                <img
                    className="logo"
                    src={logo}
                    alt="logo"
                    height="100px"
                    width="auto"
                    onClick={() => {
                        window.location.href = '/';
                    }}
                />
                <h1
                    style={{
                        textAlign: 'start',
                    }}
                >404</h1>
                <h2>Page Not Found</h2>
                <p>Sorry, the page you are looking for does not exist.</p>
                <p>Click <a href="/"
                    style={{
                        color: 'lightblue',
                    }}
                >here</a> to go back to the homepage.</p>
            </div>

        </div>
    );
}

export default NotFoundPage;