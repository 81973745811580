export interface MapState {
  data_ready_intel: boolean,
}

const state = (
  state = {
    data_ready_intel: false,
  },
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case "SET_DATATYPE":
      return {
        ...state,
        dataType: action.payload,
      };
    case "SET_ROWS":
      return {
        ...state,
        rows: action.payload,
      };
    case "SET_INTEL":
      return {
        ...state,
        intelligenceFusionData: action.payload,
      };
    case "SET_INCIDENTCOUNT":
      return {
        ...state,
        incidentCount: action.payload,
      };
    case "SET_INCIDENT_PAGE":
      return {
        ...state,
        incidentPage: action.payload,
      };
    case "SET_INCIDENT_ROWS_PAGE":
      return {
        ...state,
        incidentRowsPerPage: action.payload,
      };
    case "SET_ASSETS":
      return {
        ...state,
        assets: action.payload,
      };
    case "SET_INCIDENT":
      return {
        ...state,
        incident: action.payload,
      };
    case "SET_FILTER_INTEL":
      return {
        ...state,
        filtered_intelligenceFusionData: action.payload,
      };
    case "SET_FILTER_ROWS":
      return {
        ...state,
        filtered_rows: action.payload,
      };
    case "SET_FILTER_INCI_COUNT":
      return {
        ...state,
        filtered_incidentCount: action.payload,
      };
    case "SET_LOADING_DATA_INTEL":
      return {
        ...state,
        data_ready_intel: action.payload,
      };
    case "SET_INTEL_START_DATE":
      return {
        ...state,
        intel_StartDate: action.payload,
      };
    case "SET_INTEL_END_DATE":
      return {
        ...state,
        intel_EndDate: action.payload,
      };
    case "SET_SELECTED_START_DATE":
      return {
        ...state,
        selectedStartDate: action.payload,
      };
    case "SET_SELECTED_END_DATE":
      return {
        ...state,
        selectedEndDate: action.payload,
      };
    case "SET_USER_LOCATIONS":
      return{
        ...state,
        userLocations: action.payload
      };
    default:
      return state;
  }
};

export default state;
