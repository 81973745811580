import React, { useState, useEffect } from "react";
import "./TypingEffect.css"; // For blinking cursor style
import { Typography } from "@mui/material";
import globalConfig from "../config.json";

const TypingEffect: React.FC = () => {
    const texts = ["Powering resilience.", "Building a safer community.", ""];
    const [currentText, setCurrentText] = useState("");
    const [index, setIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [loopNum, setLoopNum] = useState(0);
    const [typingSpeed, setTypingSpeed] = useState(150);

    useEffect(() => {
        const handleType = () => {
            const fullText = texts[loopNum % texts.length];

            if (isDeleting) {
                setCurrentText(fullText.substring(0, currentText.length - 1));
            } else {
                setCurrentText(fullText.substring(0, currentText.length + 1));
            }

            if (!isDeleting && currentText === fullText) {
                setTimeout(() => setIsDeleting(true), 3500);
                setTypingSpeed(50);
            } else if (isDeleting && currentText === "") {
                setIsDeleting(false);
                setLoopNum(loopNum + 1);
                setIndex(0);
                setTypingSpeed(150);
            } else {
                setIndex(index + 1);
            }
        };

        const timer = setTimeout(handleType, typingSpeed);

        return () => clearTimeout(timer);
    }, [currentText, isDeleting, typingSpeed, loopNum, texts, index]);

    return (
        <div >
            <Typography  sx={{ fontSize: 50,color: "white", fontFamily: globalConfig["fontFamilies"], }}>
                <span>{currentText}</span>
                <span className="blinking-cursor">|</span>
            </Typography>
      
 
        </div>
    );
};

export default TypingEffect;
