import React, { useEffect, useMemo, useState } from "react";
import {
    MRT_ColumnDef,
    MRT_ColumnFiltersState,
    MRT_PaginationState,
    MRT_SortingState,
} from "material-react-table";
import {
    Box,
    Button,
    Chip,
    Container,
    createTheme,
    TextField,
    ThemeProvider,
    Typography,
    Grid
} from "@mui/material";


import { MuiTelInput, matchIsValidTel } from "mui-tel-input";

import GenericTable from "../components/GenericTable";
import { useUserStore, AdminUser, PhoneUser } from "../stores/userStore";

import globalConfig from "../config.json";
import { useWebSocketStore } from "../stores/websocketStore";

const Company: React.FC = () => {
    const { user } = useUserStore((state) => ({
        user: state.user,
    }));

    const [companyRows, setCompanyRows] = useState([] as PhoneUser[]);
    useEffect(() => {
        if (user.role === "admin") {
            setCompanyRows((user as AdminUser).managedUsers);
            setCompanyRowCount((user as AdminUser).managedUsers.length);
        }
    }, [user]);
    const [companyColumnFilters, setCompanyColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    const [companyGlobalFilter, setCompanyGlobalFilter] = useState("");
    const [companySorting, setCompanySorting] = useState<MRT_SortingState>([]);
    const [companyPagination, setCompanyPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 5,
    });
    // const [editedUser, setEditedUser] = useState(null as PhoneUser);
    // const [companyIsRefetching, setCompanyIsRefetching] = useState(false);
    // const [companyIsError, setCompanyIsError] = useState(false);
    // const [companyIsLoading, setCompanyIsLoading] = useState(false);

    const companyIsRefetching = false;
    const companyIsError = false;
    const companyIsLoading = false;
    const [companyRowCount, setCompanyRowCount] = useState(0);

    const [editMode, setEditMode] = useState(false);
    const [formData, setFormData] = useState({
        id: "",
        firstName: '',
        surname: '',
        email: '',
        phoneNumber: '',
    });
    const UserTable = useMemo(() => {
        const editUserHandler = (managedUser: PhoneUser) => {
            console.log(managedUser);
            setFormData({
                id: managedUser.id.toString(),
                firstName: managedUser.firstName,
                surname: managedUser.surname,
                email: managedUser.email,
                phoneNumber: managedUser.phoneNumber,
            } as FormData);
            setEditMode(true);
        };
        const handleDeleteUserModal = (row: { row: { original: PhoneUser } }) => {
            console.log(row);
        };

        const companyColumns: MRT_ColumnDef<PhoneUser>[] = [
            {
                accessorKey: "username",
                header: "Name",
                size: 60,
                Cell: ({ row }: { row: { original: PhoneUser } }) => (
                    <div>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontFamily: globalConfig["fontFamilies"],
                            }}
                        >
                            {row.original.firstName + " " + row.original.surname}
                        </Typography>
                    </div>
                ),
            },
            {
                accessorKey: "phoneNumber",
                header: "Telephone",
                size: 60,
                Cell: ({ row }: { row: { original: PhoneUser } }) => (
                    <div>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontFamily: globalConfig["fontFamilies"],
                            }}
                        >
                            {row.original.phoneNumber}
                        </Typography>
                    </div>
                ),
            },
            {
                accessorKey: "connected",
                header: "Connected",
                size: 50,
                Cell: ({ row }: { row: { original: PhoneUser } }) => (
                    <div>
                        {
                            row.original.connected ? (
                                <Chip
                                    label="Connected"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: globalConfig["ratingTextColors"]["1"],
                                        backgroundColor: globalConfig["ratingBGColors"]["low"],
                                    }}
                                />
                            ) : (
                                <Chip
                                    label="Not Connected"
                                    sx={{
                                        color: "black",
                                        fontWeight: 'bold',
                                    }}
                                />
                            )}
                    </div>
                ),
            },
            {
                accessorKey: "isSOS",
                header: "SOS",
                size: 50,
                Cell: ({ row }: { row: { original: PhoneUser } }) => (
                    <div>
                        {
                            row.original.isSOS ? (
                                <Chip
                                    label="SOS"
                                    sx={{
                                        color: "white",
                                        background: "red",
                                        fontWeight: 'bold',
                                    }}
                                />
                            ) : (
                                <Chip
                                    label="Not Active"
                                    sx={{
                                        color: "black",
                                        fontWeight: 'bold',
                                    }}
                                />
                            )}
                    </div>
                ),
            },
            {
                accessorKey: "isFMH",
                header: "Follow Me Home",
                size: 50,
                Cell: ({ row }: { row: { original: PhoneUser } }) => (
                    <div>
                        {
                            row.original.isFMH ? (
                                <Chip
                                    label="Follow me Home"
                                    sx={{
                                        color: "white",
                                        background: "red",
                                        fontWeight: 'bold',
                                    }}
                                />
                            ) : (
                                <Chip
                                    label="Not Active"
                                    sx={{
                                        color: "black",
                                        fontWeight: 'bold',
                                    }}
                                />
                            )}
                    </div>
                ),
            },
            {
                accessorKey: "checkinOpen",
                header: "Checkin Open",
                size: 50,
                Cell: ({ row }: { row: { original: PhoneUser } }) => (
                    <div>
                        {
                            row.original.checkinOpen ? (
                                <Chip
                                    label="Checkin Pending"
                                    sx={{
                                        color: "white",
                                        background: "red",
                                        fontWeight: 'bold',
                                    }}
                                />
                            ) : (
                                <Chip
                                    label="Not Pending"
                                    sx={{
                                        color: "black",
                                        fontWeight: 'bold',
                                    }}
                                />
                            )}
                    </div>
                ),
            },
            {
                accessorKey: "riskRating",
                header: "Risk Rating",
                size: 50,
                Cell: ({ row }: { row: { original: PhoneUser } }) => (
                    <div>
                        {
                            row.original.is_active === true ? // user has submitted profile info
                                row.original.riskRating === "" ? (
                                    <Chip
                                        label="Not Assessed"
                                        sx={{
                                            fontWeight: 'bold',
                                            color: globalConfig["notAvailableText"],
                                            backgroundColor: globalConfig["disabledButton"],
                                        }}
                                    />
                                ) : row.original.riskRating === "Low Risk" ? (
                                    <Chip
                                        label={row.original.riskRating}
                                        sx={{
                                            fontWeight: 'bold',
                                            color: globalConfig["ratingTextColors"]["1"],
                                            backgroundColor: globalConfig["ratingBGColors"]["low"],
                                        }}
                                    />
                                ) : row.original.riskRating === "Medium Risk" ? (
                                    <Chip
                                        label={row.original.riskRating}
                                        sx={{
                                            fontWeight: 'bold',
                                            color: globalConfig["ratingTextColors"]["3"],
                                            backgroundColor: globalConfig["ratingBGColors"]["3"],
                                        }}
                                    />
                                ) : row.original.riskRating === "High Risk" ? (
                                    <Chip
                                        label={row.original.riskRating}
                                        sx={{
                                            fontWeight: 'bold',
                                            color: globalConfig["ratingTextColors"]["5"],
                                            backgroundColor: globalConfig["ratingBGColors"]["5"],
                                        }}
                                    />
                                ) : (
                                    <Chip
                                        label={row.original.riskRating}
                                        sx={{
                                            fontWeight: 'bold',
                                            color: globalConfig["ratingTextColors"]["5"],
                                            backgroundColor: globalConfig["ratingBGColors"]["5"],
                                        }}
                                    />
                                ) : (
                                    <Chip
                                        label="Not Active"
                                        sx={{
                                            fontWeight: 'bold',
                                            color: globalConfig["notAvailableText"],
                                            backgroundColor: globalConfig["disabledButton"],
                                        }}
                                    />
                                )

                        }
                    </div>
                ),
            },
        ];
        const userTable = (
            <GenericTable
                title={"Users"}
                columns={companyColumns}
                data={companyRows}
                onEditClick={editUserHandler}
                handleOpenDeleteModal={handleDeleteUserModal}
                sorting={companySorting}
                pagination={companyPagination}
                rowCount={companyRowCount}
                isRefetching={companyIsRefetching}
                isError={companyIsError}
                isLoading={companyIsLoading}
                setPagination={setCompanyPagination}
                setSorting={setCompanySorting}
                enableRowActions={true}
            />
        );

        return userTable;
    }, [
        companyRows,
        companyColumnFilters,
        companyGlobalFilter,
        companySorting,
        companyPagination,
        companyRowCount,
        companyIsRefetching,
        companyIsError,
        companyIsLoading,
        setCompanyColumnFilters,
        setCompanyGlobalFilter,
        setCompanyPagination,
        setCompanySorting,
    ]);

    return (
        <div
            style={{
                display: "flex",
                // flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
            }}
        >
            <CreateUserForm
                formData={formData}
                setFormData={setFormData}
                setEditMode={setEditMode}
                editMode={editMode}
            />
            <br />
            {UserTable}
        </div>
    );

};

export default Company;

const theme = createTheme({
    palette: {
        primary: {
            main: globalConfig["primaryMain"],
            contrastText: globalConfig["background"],
        },
        secondary: {
            main: globalConfig["secondaryMain"],
        },
    },
    typography: {
        fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
    },
});


interface FormData {
    id: string;
    email: string;
    firstName: string;
    surname: string;
    phoneNumber: string;
}

type SetFormData = (value: Partial<FormData> | ((prevState: FormData) => Partial<FormData>)) => void;
interface SubmitUserFormProps {
    formData: FormData;
    setFormData: SetFormData;
    setEditMode: (editing: boolean) => void;
    editMode: boolean;
}

function CreateUserForm({
    formData,
    setFormData,
    setEditMode,
    editMode
}: SubmitUserFormProps) {

    const { sendUserInvite, editUser } = useWebSocketStore((state) => ({
        sendUserInvite: state.sendUserInvite,
        editUser: state.editUser,

    }));
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleChangePhoneNumber = (phoneNumber: string) => {
        // const { name, value } = e.target;
        setFormData({
            ...formData,
            phoneNumber: phoneNumber,
        });
    };
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const validateForm = (): boolean => {
        let isValid = true;
        const newErrors: { [key: string]: string } = {};
        for (const [key, value] of Object.entries(formData)) {
            if (key === "id") {
                console.log("id not required");
            } else if (key === "phoneNumber") {
                if (!matchIsValidTel(value)) {
                    newErrors[key] = `Not a valid phone number`;
                    isValid = false;
                }
            } else if (value === '') {
                newErrors[key] = `${key} is required.`;
                isValid = false;
            }
        }
        setErrors(newErrors);
        return isValid;
    };
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (validateForm()) {
            if (formData.id?.length > 0) {
                editUser(
                    parseInt(formData.id),
                    formData.email,
                    formData.firstName,
                    formData.surname,
                    formData.phoneNumber,
                );
            } else {
                sendUserInvite(
                    formData.email,
                    formData.firstName,
                    formData.surname,
                    formData.phoneNumber,
                );
            }
            setFormData({
                id: "",
                email: "",
                firstName: "",
                surname: "",
                phoneNumber: "",
            });
        }
        e.preventDefault();
        setEditMode(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <div
                style={{
                    padding: "1rem",
                    width: "100%",
                    maxWidth: "500px",
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 'bold',
                            fontFamily: globalConfig["fontFamilies"],
                            color: globalConfig["primaryMain"],
                            mb: 1,
                        }}
                    >
                        Invite
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        {editMode ? (
                            <Chip
                                label="Editing User"
                                color="primary"
                                clickable
                                onClick={() => {
                                    setFormData({
                                        id: "",
                                        email: "",
                                        firstName: "",
                                        surname: "",
                                        phoneNumber: "",
                                    });
                                    setEditMode(false);
                                }}
                                sx={{ mb: 2 }}
                            />
                        ) : (
                            <Chip
                                label="Creating User"
                                color="default"
                                sx={{ mb: 2 }}
                            />
                        )}
                    </Box>
                </Box>
                <Container
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        p: 0,
                        m: 0,
                    }}
                    style={{
                        padding: "0",
                        width: "100%",
                    }}
                >
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            p: 3,
                            borderRadius: 1,
                            boxShadow: 2,
                            width: '100%',
                        }}
                    >
                        <TextField
                            name="firstName"
                            label="First Name"
                            variant="outlined"
                            value={formData.firstName}
                            onChange={handleChange}
                            fullWidth
                            error={!!errors.firstName}
                            helperText={errors.firstName}
                        />
                        <TextField
                            name="surname"
                            label="Surname"
                            variant="outlined"
                            value={formData.surname}
                            onChange={handleChange}
                            fullWidth
                            error={!!errors.surname}
                            helperText={errors.surname}
                        />
                        <TextField
                            name="email"
                            label="Email"
                            variant="outlined"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            fullWidth
                            error={!!errors.email}
                            helperText={errors.email}
                            disabled={editMode === true}
                        />
                        <Grid>
                            <MuiTelInput
                                name="phoneNumber"
                                label="Phone Number"
                                variant="outlined"
                                type="tel"
                                value={formData.phoneNumber}
                                onChange={handleChangePhoneNumber}
                                fullWidth={true}
                                error={!!errors.phoneNumber}
                                helperText={errors.phoneNumber}
                                forceCallingCode={true}
                                defaultCountry="IE"
                            />
                        </Grid>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 2, fontWeight: 'bold' }}
                            onClick={handleSubmit}
                        >
                            {formData.id?.length === 0 ? "Invite User" : "Edit User"}
                        </Button>
                    </Box>
                </Container>
            </div>
        </ThemeProvider>
    );
}
