import React, { useEffect } from "react";
import { Grid } from '@mui/material'
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { connect } from "react-redux";

import { fetchGuestTokenFromBackend } from "../helpers/api";

const Report = (props) => {
  const {loading, currentPage} = props;

  useEffect(() => {
    const fetchData = async () => {
      const token = fetchGuestTokenFromBackend().then(function(result){
        return result.data.token;
      });

      embedDashboard({
        id: "6ad7dddb-4c39-4466-970d-3cae76433324",  // given by the Superset embedding UI
        supersetDomain: "https://analytics.kowroo.com",
        mountPoint: window.document.getElementById("superset-container"), // html element in which iframe render
        fetchGuestToken: () => token,
        dashboardUiConfig: { // dashboard UI config: hideTitle, hideTab, hideChartControls, filters.visible, filters.expanded (optional)
          // hideTitle: true,
          // filters: {
          //     expanded: true,"1869e9ec-576e-4479-97a6-2db297cc3e4su
          // },
        },
        debug:true
      });
    }
    if (!loading){
      fetchData();
      const supersetIframe = window.document.getElementById("superset-container").getElementsByTagName("iframe")[0]
      supersetIframe.style.height = "100%";
      supersetIframe.style.width = "100%";
    }
  }, [loading]);
  return currentPage === 'reports' ? <Grid sx={{
      left: 0,
      height : "90vh",
      width : "100%"
  }} id='superset-container'></Grid> : null
}

const mapStateToProps = ({user}) => ({
  loading: user.loading,
});
export default connect(mapStateToProps)(Report);
