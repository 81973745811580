import React from 'react';
import { Drawer } from '@mui/material';
import AssetsPage from '../pages/AssetsPage';


interface CreateAssetDrawerProps {
    drawerOpen: boolean;
    setDrawerOpen: (open: boolean) => void;
    initialLatitude: number
    initialLongitude: number
}

const CreateAssetDrawer: React.FC<CreateAssetDrawerProps> = ({ initialLatitude, initialLongitude, drawerOpen, setDrawerOpen }) => {
  return (
    <>
      <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      PaperProps={{
        sx: {
          width: '60%',
          maxWidth: '60%', // Ensures the drawer doesn't exceed 60% of the viewport width
        },
      }}
      >
        <div style={{ padding: '20px' }}>
            <AssetsPage
              initialLatitude={initialLatitude}
              initialLongitude={initialLongitude}
            />
        </div>
      </Drawer>
    </>
  );
};

export default CreateAssetDrawer;
