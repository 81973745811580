import { Grid, ButtonGroup, Button, Typography } from "@mui/material"
import { useState } from "react";
import CompanyPage from "./CompanyPage";
import CompanyProfile from "./CompanyProfile";
import TeamsPage from "./TeamsPage";
import TripsPage from "./TripsPage";
import Risk_Appetite from "./Risk_Appetite";


const MainCompanyPage = () => {

    const [subPage, setSubPage] = useState(2);

    return (
        <Grid container spacing={0}  direction="column">
                      <Grid item sx={{ pt: 5}}>
            <Typography variant={"h6"} sx={{pl: 5}}>
        Company Administration
            </Typography>

            </Grid>
            <Grid container justifyContent={"space-between"} width={"100%"}>

            <ButtonGroup sx={{pl:  '5vh', pt: '5vh'}} variant="outlined" aria-label="outlined primary button group">
            {/* <Button variant={subPage === 1 ? "contained" : "outlined"} onClick={() => {setSubPage(1)}}>Profile</Button> */}
            <Button variant={subPage === 2 ? "contained" : "outlined"} onClick={() => {setSubPage(2)}}>Users</Button>
            <Button variant={subPage === 3 ? "contained" : "outlined"} onClick={() => {setSubPage(3)}}>Trips</Button>
            <Button variant={subPage === 4 ? "contained" : "outlined"} onClick={() => {setSubPage(4)}}>Teams</Button>

            </ButtonGroup>

            <ButtonGroup sx={{pr: '10vh', pt: '5vh'}} variant="outlined" aria-label="outlined primary button group">
            {/* <Button variant={subPage === 1 ? "contained" : "outlined"} onClick={() => {setSubPage(1)}}>Profile</Button> */}
            <Button variant={subPage === 5 ? "contained" : "outlined"} onClick={() => {setSubPage(5)}}>Risk Appetite</Button>
            {/* <Button variant={subPage === 6 ? "contained" : "outlined"} onClick={() => {setSubPage(6)}}>Risk Rating</Button> */}


            </ButtonGroup>
            </Grid>
            <Grid item>
                {subPage === 2 ?
                <CompanyPage></CompanyPage> :
                subPage === 3 ?
            <TripsPage></TripsPage> :
            subPage === 4 ?
        <TeamsPage></TeamsPage> :
        subPage === 5 ? <Risk_Appetite></Risk_Appetite> :
       <CompanyProfile/>  }

            </Grid>
        </Grid>
    )
}

export default MainCompanyPage;