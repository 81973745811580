// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blinking-cursor {
    font-weight: 100;
    font-size: 52px;
    color: white;
    animation: blink 0.7s infinite;
  }
  
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/TypingEffect.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,YAAY;IAGZ,8BAA8B;EAChC;;EAEA;IACE;MACE,UAAU;IACZ;IACA;MACE,UAAU;IACZ;IACA;MACE,UAAU;IACZ;EACF","sourcesContent":[".blinking-cursor {\n    font-weight: 100;\n    font-size: 52px;\n    color: white;\n    -webkit-animation: blink 0.7s infinite;\n    -moz-animation: blink 0.7s infinite;\n    animation: blink 0.7s infinite;\n  }\n  \n  @keyframes blink {\n    0% {\n      opacity: 1;\n    }\n    50% {\n      opacity: 0;\n    }\n    100% {\n      opacity: 1;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
