import { RemoveCircle, SearchRounded, CancelPresentationOutlined,} from "@mui/icons-material";
import { Alert, Box, Button, CircularProgress,
  Grid, IconButton, InputAdornment, Paper, Snackbar, Table, TableBody, TableCell,
  TableContainer, TableHead, TablePagination,
   TableRow, TextField, Typography, Chip,
   Dialog, DialogContent, DialogTitle, DialogActions } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { approveTrip, createTrip, deleteTrip, searchTrips } from "../helpers/api";
import { countries, tripTypes } from "../helpers/constants";
import { formatDate} from "../helpers/utils";
import { customDropdown } from "../components/CustomDropdown";
// import DateFnsUtils from '@date-io/date-fns';
import globalConfig from '../config.json';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircle from "@mui/icons-material/AddCircle";
import { AnimatePresence, motion } from "framer-motion";
import TripApproval from "../components/TripApproval";



const TripsPage = (props) => {
  const [destNum, setDestNum] = useState(1);
  // const [loading, setLoading] = useState(true);
  const {loading, companyName, profile, role, id} =props;
  const [sendingData, setSendingData] = useState(false);
  const [destinations, setDestinations] = useState([])
  const [country, setCountry] = useState('');
  const [country2, setCountry2] = useState('');
  const [country3, setCountry3] = useState('');
  const [type, setType] = useState('');
  const [text, setText] = useState('')
  
  const today = new Date();
  const [errorText, setErrorText] = useState('')
  const [errorOpen, setErrorOpen] = useState(false)
  const [rows, setRows] = useState([{connections: [],creator: {id: null}, id: 'No trips', pending: null, country: null, type: null, start_date: null, end_date: null}]);
  const [searchInput, setSearchInput] = useState('');
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(true);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [start2, setStart2] = useState(null);
  const [end2, setEnd2] = useState(null);
  const [start3, setStart3] = useState(null);
  const [end3, setEnd3] = useState(null);

  const [editingTrip, setEditingTrip] = useState({id: null, connections: []})
  const [deletingTrip, setDeletingTrip] = useState({id: null, type: null, country: null})
  const [openModal, setOpenModal] = useState(false);


  const [tripCount, setTripCount] = useState(0)
  
  const [tripPage, setTripPage] = React.useState(0)
  const [tripRowsPerPage, setTripRowsPerPage] = useState(5);
  const [openApproval, setOpenApproval] = useState(false)

  const Backdrop = ({children, onClick}) => {
    return ( <motion.div
      style={{
      zIndex: 200,
      position: "absolute",
      top:0,
      left:0,
      height: "100%",
      width: "100%",
      background: "#00000056",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      }}
      onClick={onClick}
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
    >{children}</motion.div>)
  }



  const handleCloseModal = () => {
    setOpenModal(false);
    setDeletingTrip({id: null, type: null, country: null});
  }

  const handleOpenModal = (row) => {
    setOpenModal(true);
    setDeletingTrip(row);
  }

  const addDestination = useCallback((country, start_date, end_date) => {
    // if (destNum > 1) {
      setDestNum(destNum+1)
    destinations.push({country: country, start_date: start_date, end_date: end_date})
    setDestinations(destinations);
    // }
  }, [destNum, destinations])


  const removeDestination = useCallback((i) => {

    destinations.splice(i, 1)
    setDestinations(destinations)
    setDestNum(destinations.length - 1)
    if (i === 0) {
      setStart2(null)
      setEnd2(null)
      setCountry2('')
    } else if (i === 1) {
      setStart3(null)
      setEnd3(null)
      setCountry3('')
    }

  }, [destinations])

  const onEditClick = useCallback((row) => {
    // const connections = row.connections
    row.connections = row.connections.reverse()
    setEditingTrip(row);
    setType(row.type);
    setText(row.text);
    setDestNum(1)
        if (row.connections.length) {
    for (let i = 0; i < row.connections.length; i++) {
      const country = row.connections[i].country
      const start_date = new Date(row.connections[i].start_date)
      const end_date = new Date(row.connections[i].end_date)
      if (i === 0) {
        setCountry(country)
        setSelectedStartDate(start_date)
        setSelectedEndDate(end_date)
      } else if (i === 1) {
        setCountry2(country)
        setStart2(start_date)
        setEnd2(end_date)
        addDestination(country, start_date, end_date)
      } else if (i === 2) {
        setCountry3(country)
        setStart3(start_date)
        setEnd3(end_date)
        addDestination(country, start_date, end_date)
      }

    }
  } else {
    setCountry(row.country)
    setSelectedStartDate(new Date(row.start_date))
    setSelectedEndDate(new Date(row.end_date))

  }

  }, [addDestination])

  const onCancelClick = () => {
    setEditingTrip({id: null, connections: []});

    setCountry('')
    setCountry2('')
    setCountry3('')
    setText('')
    setType('')
    setSelectedStartDate(null)
    setSelectedEndDate(null)
    setStart2(null)
    setStart3(null)
    setEnd2(null)
    setEnd3(null)
    setReload(true)
    setSearchInput("")
    setDestinations([])
    setDestNum(1)
  }

  const handleDelete = useCallback(async (id) => {
    const res = await deleteTrip(id);
    if ([200, 201, 204].includes(res.status)) {
      setReload(true)
      if (id === editingTrip.id) {
        // Cancel editing the trip if it has been successfully deleted
          onCancelClick()
        }
    }
    setOpenModal(false)
  }, [editingTrip.id])


  const Modal = ({handleClose, text})=> {
    return <Backdrop onClick={handleClose}>

      <Grid
      spacing={2}
      container
      justifyContent={"space-around"}
      alignItems={"center"}
        style={{
        width: "clamp(50%, 700px, 90%)",
        height: "min(50%, 700px, 90%)",
        margin: "auto",
        padding: "0 2rem",
        top:0,
        bottom: 0,
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",
        background: "#ffffffe0",
      }}
        component={motion.div}
        onClick={(e) => {e.stopPropagation()}}
      >
        <Grid item>
          {/* <Grid container> */}
        <Typography color="inherit" variant="h5">{text}</Typography>

        {/* </Grid> */}

        </Grid>
        <Grid item>
          Country—{deletingTrip.country}
          </Grid>
          <Grid item>
           Type—{deletingTrip.type}
           </Grid>
        <Grid item>
          <Grid container spacing={3}>

            <Grid item>
      <Button sx={{color: "black"}} variant="outlined" onClick={handleClose}>Close</Button>
      </Grid>
      <Grid item>
      <Button sx={{color: "black"}} variant="outlined" onClick={(e) => {handleDelete(deletingTrip.id)}}>Delete</Button>
      </Grid>
      </Grid>
      </Grid>
      </Grid>
    </Backdrop>
  }

  const handleChangePage = (event, newPage) => {
    setTripPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    setTripRowsPerPage(parseInt(event.target.value, 10));
    setTripPage(0);
  };



  const navigate = useNavigate()

  const handleSubmit = useCallback(async (e) => {


    e.preventDefault();
    setSendingData(true);
    const res = await createTrip(
        country,
        type,
        text,
        selectedStartDate,
        selectedEndDate,
        country2,
        start2,
        end2,
        country3,
        start3,
        end3,
        editingTrip.id
    );
    try {
      if (res.status === 201 || res.status === 200 || res.status === 204) {
        setSendingData(false);
        setErrorText('');
        setOpen(true);
        setCountry('')
        setCountry2('')
        setCountry3('')
        setText('')
        setType('')
        setSelectedStartDate(null)
        setSelectedEndDate(null)
        setStart2(null)
        setStart3(null)
        setEnd2(null)
        setEnd3(null)
        setReload(true)
        if (editingTrip.id) {
          onCancelClick()
        }
        const trips = await searchTrips(
          "",
          null, null, tripPage + 1, tripRowsPerPage);
        if (!trips.data.results.length) {
          setRows([{connections: [], creator: {id: null}, id: 'No trips', pending: null, country: null, type: null, start_date: null, end_date: null}]);
        } else {
          setRows(trips.data.results);
        }
      } else if (res.status === 400 && res.data['email']) {
        setSendingData(false)
        setErrorText("Email: " + res.data['email']);
        setErrorOpen(true)
      } else if (res.status === 403 && res.data['results']) {
        setSendingData(false);
        setErrorText(res.data['results']);
        setErrorOpen(true)
      } else if (res.status === 404) {
        setSendingData(false);
        setErrorText('Something went wrong');
        setErrorOpen(true)
      } else {
        setSendingData(false);
        setErrorText('Unknown error');
        setErrorOpen(true)
      }
    } catch (err) {
      setSendingData(false);
      setErrorText('Fatal error');
      setErrorOpen(true)
    }
    setSendingData(false);
  }, [country, type, text, selectedStartDate, selectedEndDate, country2, start2, end2, country3, start3, end3, editingTrip, tripPage, tripRowsPerPage]);


  const handleApprove = async (tripID) => {
    const res = await approveTrip(tripID);
    if (res.status === 204) {
      setReload(true)
    }
  }

  const BasicTable = useMemo(() => {
    return (
      <Grid>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Country</TableCell>
              <TableCell>Type</TableCell>

              <TableCell align="right">Start Date</TableCell>
              <TableCell align="right">End Date</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right"></TableCell>


            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <TableRow
                key={"row-id-"+idx}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                {row.country}
                </TableCell>
                <TableCell align="right">{row.type}</TableCell>
                <TableCell align="right">{row.start_date ? formatDate(new Date(row.start_date)) : ''}</TableCell>
                <TableCell align="right">{row.end_date ? formatDate(new Date(row.end_date)) : ''}</TableCell>
                <TableCell sx={{color: globalConfig["primaryMain"]}} align="right">{row.pending ? "Pending" : id === row.creator.id && role === "admin" ? "Personal" : (rows.length>0 && rows[0].start_date === null) ? "" : "Approved"}</TableCell>
                <TableCell>
                {role === "admin" && row.pending && id !== row.creator.id ?
                  <Grid container spacing={1}>
                    <Grid item>
                  <Button size="small" onClick={() => {handleApprove(row.id)}} variant="contained">Approve</Button>
                  </Grid>
                  {/* <Grid item>
                  <Button size="small" variant="outlined">Reject</Button>
                  </Grid> */}

                  </Grid>
                  : null}
                </TableCell>

                <TableCell>

{rows.length > 0 ? <IconButton onClick={() => {return editingTrip.id === null ? onEditClick(row) : onCancelClick() }}>
{editingTrip.connections.length > 0 && editingTrip.id === row.id ? <CancelPresentationOutlined color="warning"></CancelPresentationOutlined> : <EditIcon></EditIcon> }
  </IconButton>
  : null}
</TableCell>
<TableCell><IconButton onClick={() => {handleOpenModal(row)}}><DeleteForeverIcon></DeleteForeverIcon></IconButton></TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
                        <TablePagination
                        component="div"
                        count={tripCount}
                        page={tripPage}
                        onPageChange={handleChangePage}
                        rowsPerPage={tripRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                      </Grid>
    );
  }, [rows, tripCount, tripPage, tripRowsPerPage, id, role, editingTrip, onEditClick])

  useEffect(() => {
    // eslint-disable-next-line require-jsdoc
    async function fetchData() {
      if (!loading) {
        const trips = await searchTrips(
            searchInput,
            null, null, tripPage +1, tripRowsPerPage);
        if (!trips.data.results.length) {
          setRows([{connections: [], id: 'No trips', creator: {id: null}, pending: null, country: null, type: null, start_date: null, end_date: null}]);
        } else {
          setRows(trips.data.results);
          setTripCount(trips.data.count)
        }
      }
    }
    if (!loading || reload) {
      fetchData();
      setReload(false);
    }
  }, [loading, navigate, reload, searchInput, tripPage, tripRowsPerPage]);

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false)
    setErrorText('');
  };

  const handleCloseError = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorOpen(false)
    setErrorText('');
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    // setReload(true);
  };

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    // setReload(true);
  };

  const handleEnd2 = (date) => {
    setEnd2(date);
    // setReload(true);
  };

  const handleStart2 = (date) => {
    setStart2(date);
    // setReload(true);
  };
  const handleEnd3 = (date) => {
    setEnd3(date);
    // setReload(true);
  };

  const handleStart3 = (date) => {
    setStart3(date);
    // setReload(true);
  };


  const destinationsComp = useMemo(() => {
    const minDate = (i) => {
      return i === 0 ? selectedEndDate || selectedStartDate : i ===1 ? end2 || start2 || selectedEndDate || selectedStartDate : today
    }

    const minEndDate = (i) => {
      return i === 0? start2 || selectedEndDate || selectedStartDate : i === 1 ? start3 || end2 || start2 || selectedEndDate || selectedStartDate : today
    }

    const datePickerConn1 = () => {
      return <Grid key={"destination-2-picker"}>
      <br></br>
      <Typography variant="body1">Destination 2</Typography>
      {customDropdown(countries, "Country", country2,   setCountry2 , profile)}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container direction="row" justifyContent="space-between">
    <DesktopDatePicker
        minDate={minDate(0)}
        // label="Date desktop"
        inputFormat="MM/DD/YYYY"
        key="start2-date-picker"
        label="Start"
        value={start2}
        onChange={ handleStart2 }
        renderInput={(params) => <TextField required {...params} />}
      />
       <DesktopDatePicker
        // label="Date desktop"
        minDate={minEndDate(0)}
        inputFormat="MM/DD/YYYY"
        key="end2-date-picker"
        label="End"
        value={end2}
        onChange={ handleEnd2 }
        renderInput={(params) => <TextField required {...params} />}
      />
      </Grid>
      </LocalizationProvider>
      {<Button sx={{right: 0, color: "darkred"}} onClick={() => {removeDestination(0)}} startIcon={<RemoveCircle></RemoveCircle>}>REMOVE DESTINATION</Button>}
      </Grid>
    }


    const datePickerConn2 = () => {
    return <Grid key="destination-3-picker">
    <br></br>
    <Typography variant="body1">Destination 3</Typography>
    {customDropdown(countries, "Country",  country3,  setCountry3, profile)}
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Grid container direction="row" justifyContent="space-between">
  <DesktopDatePicker
      minDate={minDate(1)}
      // label="Date desktop"
      inputFormat="MM/DD/YYYY"
      key="start3-date-picker"
      label="Start"
      value={ start3}
      onChange={handleStart3}
      renderInput={(params) => <TextField required {...params} />}
    />
     <DesktopDatePicker
      // label="Date desktop"
      minDate={minEndDate(1)}
      inputFormat="MM/DD/YYYY"

      key="end3-date-picker"
      label="End"
      value={ end3}
      onChange={ handleEnd3}
      renderInput={(params) => <TextField required {...params} />}
    />
    </Grid>
    </LocalizationProvider>
    {<Button sx={{right: 0, color: "darkred"}} onClick={() => {removeDestination(1)}} startIcon={<RemoveCircle></RemoveCircle>}>REMOVE DESTINATION</Button>}
    </Grid>
    }

    return <Grid>

{destinations.map((destination, idx) => {
  return idx === 0 ? datePickerConn1() : idx === 1 ? datePickerConn2() : null
})}
             </Grid>

  }, [destinations, selectedEndDate, selectedStartDate, end2, start2, today, start3, country2, profile, removeDestination, country3, end3])

  return <Grid container justifyContent="center" direction="row">

    <Grid item width="30%" justifyContent="center"
  // spacing={0}
  // direction="column"
  // alignItems="center"
  // justifyContent="center"
  alignItems="center" direction="column" sx={{margin: 5}}>
    <AnimatePresence
    // Disable any initial animations on children that
    // are present when the component is first rendered
    initial={false}
    // Only render one component at a time.
    // The exiting component will finish its exit
    // animation before entering component is rendered
    exitBeforeEnter={true}
    // Fires when all exiting nodes have completed animating out
    onExitComplete={() => null}
>
    {openModal && <Modal handleClose={handleCloseModal} text={"Are you sure you want to delete a Trip?"}></Modal>}
</AnimatePresence>
            {/* <CardContent> */}
            <Snackbar onClose={handleClose} open={open && errorText === '' && !sendingData} autoHideDuration={6000} >
                  <Alert severity="success">
                    Trip has been successfully created
                  </Alert>
                  </Snackbar>
                  <Snackbar onClose={handleCloseError} open={errorOpen && errorText !== '' && !sendingData} autoHideDuration={6000} >
                  <Alert severity="error">
                    {errorText}
                  </Alert>
                  </Snackbar>
                  <Grid container spacing={3}>
                  <Grid item>
              <Typography  color="primary"  component="h1" variant="h5">
                {loading ? <CircularProgress  size="1.55rem" color="inherit"></CircularProgress> :
                companyName}
              </Typography>
              </Grid>
                  <Grid item>



            <Typography component="h1" variant="h6">
                Company Trips
              </Typography>
              </Grid>
            </Grid>
            <br></br>
            <Grid item>
              <Typography component="h1" variant="h6">
              {editingTrip.id ? "Edit an existing Trip" : "Create a new Trip"}

</Typography>
<Typography component="h1" variant="h6">
{editingTrip.id ? <Chip color="warning" label="Editing a Trip" variant="outlined" onDelete={() => {onCancelClick()}} /> : null}

              </Typography>
              <Typography variant="body1">Destination {destinations.length ? 1 : ""}</Typography>
              </Grid>
              <Grid item>
              <Typography variant="subtitle1">
              </Typography>
              </Grid>
              <Grid item>
              <Box

                component="form"
                onSubmit={(e) => {handleSubmit(e)}}
                noValidate
                sx={{ mt: 1 }}
              >

                {/* <TextField
                  error={errorText !== '' || (submitted && !firstName)}
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  margin="normal"
                  required
                  fullWidth
                  id="first_name"
                  label="City"
                  name="firstname"
                  autoComplete="Firstname"
                  // autoFocus
                /> */}
                  {/* <Grid item style={{width: 350}} > */}

              {customDropdown(countries, "Country", country, setCountry, profile)}
              {customDropdown(tripTypes, "Type", type, setType, profile)}
              {/* <TextField  label="Description" fullWidth sx={{ paddingBottom: 2 }}>Description</TextField> */}
              {/* </Grid> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* <Stack spacing={3}> */}
      <Grid container direction="row" justifyContent="space-between">
                    {/* <Grid item> */}

        <DesktopDatePicker
          minDate={today}
          // label="Date desktop"
          inputFormat="MM/DD/YYYY"
          InputProps={{
            classes: {
              // input: classes.input,
            },
          }}
          label="Start"
          value={selectedStartDate}
          onChange={handleStartDateChange}
          // KeyboardButtonProps={{
          //   'aria-label': 'change date',
          // }}
          renderInput={(params) => <TextField required {...params} />}
        />
         <DesktopDatePicker
          // label="Date desktop"
          minDate={selectedStartDate || today}
          inputFormat="MM/DD/YYYY"
          InputProps={{
            classes: {
              // input: classes.input,
            },
          }}
          label="End"
          value={selectedEndDate}
          onChange={handleEndDateChange}
          // KeyboardButtonProps={{
          //   'aria-label': 'change date',
          // }}
          renderInput={(params) => <TextField required {...params} />}
        />
        {/* </Grid> */}
        </Grid>
      {/* </Stack> */}
    </LocalizationProvider>

                  <Grid>
                  {destinationsComp}
                  </Grid>
              {destNum < 3 ? <Button onClick={() => {addDestination(null, null, null)}} startIcon={<AddCircle></AddCircle>}>ADD A DESTINATION</Button> : null}
<Grid item>
                <Button disabled={!(country && type && selectedStartDate && selectedEndDate)}fullWidth variant="outlined" onClick={() => {setOpenApproval(!openApproval)}}>TRIP APPROVAL FORM</Button>
                </Grid>
                <Grid item>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  // onClick={handleLogin}
                >
                  {sendingData ? <CircularProgress size="1.55rem" color="inherit"/> :
                  "Submit" }
                </Button>
                </Grid>
              </Box>
              </Grid>
            {/* </CardContent> */}
          </Grid>
          <Grid item width="50%" sx={{margin: 5}}>
          <Grid item>



<Typography component="h1" variant="h5">
    <br></br>
  </Typography>
  </Grid>
{/* </Grid> */}
<br></br>
<Grid item>
  <Typography component="h1" variant="h6">
    Trip History
  </Typography>
  <br></br>
  </Grid>
  <Grid item>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRounded />
                </InputAdornment>
              ),
            }}
            sx={{ minWidth: 400, mb: 1 }}
            fullWidth
            value={searchInput}
            onChange={(e) => {setSearchInput(e.target.value)}}
            label="Search Trips"
          ></TextField>
        </Grid>
  {/* </Grid> */}
  <Grid item>
  <Typography variant="subtitle1">
  </Typography>
  </Grid>
  {BasicTable}

</Grid>
<Dialog
        // classes={{ paper: classes.dialogPaper }}
        open={openApproval}
        onClose={() => {setOpenApproval(!openApproval)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Grid>
            <Typography variant="h5">{'Travel Approval Form'}</Typography>
          </Grid>
        </DialogTitle>
        <DialogContent
        // className={classes.tcContent}
        >
          <TripApproval connections={[
            country || selectedStartDate || selectedEndDate ?
            {setCountry: setCountry, country: country, start: selectedStartDate, end: selectedEndDate} : null,
            country2 || start2 || end2 ?
            {setCountry: setCountry2, country: country2, start: start2, end: end2} : null,
            country3 || start3 || end3 ?
            {setCountry: setCountry3, country: country3, start: start3, end: end3} : null
            ].filter((object) => object !== null)}></TripApproval>
        </DialogContent>
        <DialogActions>

        <Button variant="contained" id="submitTripApproval" onClick={() => {setOpenApproval(false)}} color="primary">
            SUBMIT
          </Button>
          <Button variant="outlined" id="closeTripApproval" onClick={() => {setOpenApproval(false)}} color="primary">
            Close
          </Button>

        </DialogActions>
      </Dialog>
          </Grid>

}


const mapStateToProps = ({user}) => ({
    loading: user.loading,
    role: user.role,
    id: user.id,
    companyName: user.companyName,
    profile: user.profile
  });
export default connect(mapStateToProps)(TripsPage);