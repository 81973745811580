import React, { useEffect, useMemo, useState } from "react";
import { useDataStore } from "../stores/dataStore";
import { useWebSocketStore } from "../stores/websocketStore";
import { ChatMessage } from "../types/data";
import {
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    TextField,
    Button,
    IconButton,
    Grid,
    Chip,
} from "@mui/material";
import { Minimize, ExpandMore } from "@mui/icons-material";
import { AdminUser, PhoneUser, useUserStore } from "../stores/userStore";

import Chat from "./Chat";

const Chatbox = (props) => {
    const { chatData } = useDataStore((state) => ({
        chatData: state.chatChannels,
    }));
    const { sendChatMessage } = useWebSocketStore((state) => ({
        sendChatMessage: state.sendChatMessage,
    }));

    const { user, isMinimized, setIsMinimized, selectedUserId, setSelectedUserId } = useUserStore((state) => ({
        user: state.user,
        isMinimized: state.isChatMinimized,
        setIsMinimized: state.setIsChatMinimized,
        selectedUserId: state.selectedUserId,
        setSelectedUserId: state.setSelectedUserId,
    }));

    const [currentManagedUsers, setCurrentManagedUsers] = useState([] as PhoneUser[]);
    useEffect(() => {
        if (user.role === "admin") {

            setCurrentManagedUsers((user as AdminUser).managedUsers);
            console.log("Chat managed users: ", currentManagedUsers);
        }
    }, [user]);

    // const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [newMessage, setNewMessage] = useState('');
    const [userIds, setUserIds] = useState<number[]>([]);

    useEffect(() => {
        setUserIds(currentManagedUsers.map(managedUser => managedUser.id));
    }, [currentManagedUsers]);

    useEffect(() => {
        if (selectedUserId !== null) {
            const userChatData = chatData.find(managedUser => managedUser.UserID === selectedUserId);
            setMessages(userChatData ? userChatData.Messages : []);
        }
    }, [selectedUserId, chatData]);

    const handleUserChange = (event: any) => {
        const userId = event.target.value;
        setSelectedUserId(userId);

        const userChatData = chatData.find(managedUser => managedUser.UserID === userId);
        setMessages(userChatData ? userChatData.Messages : []);
    };

    const handleSendMessage = () => {
        if (selectedUserId && newMessage.trim()) {
            sendChatMessage({
                message_text: newMessage,
                receiver: selectedUserId
            } as ChatMessage);
            setNewMessage('');
        }
    };

    const userForm = useMemo(() => {

        return <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel id="user-select-label"></InputLabel>
            <Select
                labelId="user-select-label"
                value={selectedUserId || ''}
                onChange={handleUserChange}
                displayEmpty
            >
                <MenuItem value="" disabled>Select user</MenuItem>
                {userIds?.map(id => {
                    const user = currentManagedUsers?.find(user => user?.id === id);
                    return <MenuItem key={id} value={id}>
                        <Grid container justifyContent={'space-between'}>

                            <Grid item xs={6}>
                                <Typography variant="inherit">
                                    {`${user?.firstName ?? ''} ${user?.surname ?? ''} ${(!user?.surname && !user?.firstName) ? id : ''}`}

                                </Typography>

                            </Grid>
                            <Grid item xs={6}>
                                <Chip
                                    size="small"
                                    label={user?.email}/>
                            </Grid>
                        </Grid>

                    </MenuItem>;

                })}
            </Select>
        </FormControl>;
    }, [user, userIds, currentManagedUsers, selectedUserId]);

    return (
        <Grid
            // used to pass sx props from parent component
            {...props}
        >
            <Grid
                item
                sx={{
                    width: isMinimized ? '200px' : '100%',
                    maxWidth: '600px',
                    margin: 0,
                    padding: 2,
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                    backgroundColor: 'white',
                    border: '1px solid #ddd',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                    transition: 'width 0.3s',
                }}
            >

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>Chat</Typography>
                    <IconButton onClick={() => setIsMinimized(!isMinimized)}>
                        {isMinimized ? <ExpandMore /> : <Minimize />}
                    </IconButton>
                </Box>
                {!isMinimized && (
                    <>
                        {userForm}
                        <Box sx={{ height: 400, border: '1px solid #ddd', padding: 2, overflowY: 'scroll', marginBottom: 2 }}>
                            {messages.length === 0 && <Typography>No messages to display</Typography>}
                            <Chat key={selectedUserId} messages={messages} chatUserId={selectedUserId} />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                placeholder="Type a message..."
                            />
                            <Button variant="contained" color="primary" onClick={handleSendMessage} sx={{ marginLeft: 1 }}>
                                Send
                            </Button>
                        </Box>
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default Chatbox;
