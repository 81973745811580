import store from "../store";

type dispatchType = typeof store.dispatch

export const setCurrentPage = (currentPage: number) => (dispatch: dispatchType) => {
  dispatch({
    type: 'SET_CURRENT_PAGE',
    payload: currentPage,
  });
};

export const setPages = (pages: string[]) => (dispatch: dispatchType) => {
  dispatch({
    type: 'SET_PAGES',
    payload: pages,
  });
};

export const setRoutes = (routes: string[]) => (dispatch: dispatchType) => {
  dispatch({
    type: 'SET_ROUTES',
    payload: routes,
  });
};

export const setProfile = (profile: {}) => (dispatch: dispatchType) => {
  dispatch({
    type: 'SET_PROFILE',
    payload: profile,
  });
};

export const setPosition = (position: string) => (dispatch: dispatchType) => {
  dispatch({
    type: 'SET_POSITION',
    payload: position,
  });
};

export const setProfileId = (profileId: string) => (dispatch: dispatchType) => {
  dispatch({
    type: 'SET_PROFILE_ID',
    payload: profileId,
  });
};

export const setAccessToken = (accessToken: string) => (dispatch: dispatchType) => {
  dispatch({
    type: 'SET_ACCESS_TOKEN',
    payload: accessToken,
  });
};

export const setFirstName = (firstName: string) => (dispatch: dispatchType) => {
  dispatch({
    type: 'SET_FIRST_NAME',
    payload: firstName,
  });
};
export const setSurname = (surname: string) => (dispatch: dispatchType) => {
  dispatch({
    type: 'SET_SURNAME',
    payload: surname,
  });
};

export const deleteAccessToken = (dispatch: dispatchType) => {
  dispatch({
    type: 'DELETE_ACCESS_TOKEN',
  });
};

export const setRefreshToken = (refreshToken: string) => (dispatch: dispatchType) => {
  dispatch({
    type: 'SET_REFRESH_TOKEN',
    payload: refreshToken,
  });
};

export const deleteRefreshToken = (dispatch: dispatchType) => {
  dispatch({
    type: 'DELETE_REFRESH_TOKEN',
  });
};

export const setEmail = (email: string) => (dispatch: dispatchType) => {
  dispatch({
    type: 'SET_EMAIL',
    payload: email,
  });
};

export const deleteEmail = (dispatch: dispatchType) => {
  dispatch({
    type: 'DELETE_EMAIL',
  });
};

export const setStaff = (isStaff: boolean) => (dispatch: dispatchType) => {
  dispatch({
    type: 'SET_STAFF',
    payload: {is_staff: isStaff},
  });
};

export const deleteStaff = (dispatch: dispatchType) => {
  dispatch({
    type: 'DELETE_STAFF',
  });
};

export const setRole = (role: string) => (dispatch: dispatchType) => {
  dispatch({
    type: 'SET_ROLE',
    payload: role,
  });
};

export const setCompanyName = (companyName: string) => (dispatch: dispatchType) => {
  dispatch({
    type: 'SET_COMPANY_NAME',
    payload: companyName,
  });
};

export const deleteCompanyName = (dispatch: dispatchType) => {
  dispatch({
    type: 'DELETE_COMPANY_NAME',
  });
};

export const setId = (id: string) => (dispatch: dispatchType) => {
  dispatch({
    type: 'SET_ID',
    payload: id,
  });
};

export const deleteId = (dispatch: dispatchType) => {
  dispatch({
    type: 'DELETE_ID',
  });
};

export const set2FAStatus = (status: boolean) => (dispatch: dispatchType) => {
  dispatch({
    type: 'SET_2FA_STATUS',
    payload: status,
  });
};

export const setIsLoggedIn = (loggedIn: boolean) => (dispatch: dispatchType) => {
  dispatch({
    type: 'SET_IS_LOGGED_IN',
    payload: loggedIn,
  });
};

export const setLoading = (loading: boolean) => (dispatch: dispatchType) => {
  dispatch({
    type: 'SET_LOADING',
    payload: loading,
  });
};

export const setNewAlerts = (newAlerts: any[]) => (dispatch: dispatchType) => {
  dispatch({
    type: 'SET_NEW_ALERTS',
    payload: newAlerts,
  });
};

export const setUploadOps = (ops: []) => (dispatch: dispatchType) => {
  dispatch({
    type: 'SET_UPLOAD_OPS',
    payload: ops,
  });
};

export const setSecret = (secret: string) => (dispatch: dispatchType) => {
  dispatch({
    type: 'SET_SECRET',
    payload: secret,
  });
};

export const deleteSecret = (dispatch: dispatchType) => {
  dispatch({
    type: 'DELETE_SECRET',
  });
};

export const setFollowMeHomeData = (FollowMeHomeData) => (dispatch: dispatchType) => {
  dispatch({
    type: 'SET_FOLLOW_ME_HOME_DATA',
    payload: FollowMeHomeData
  });
};