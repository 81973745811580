import React, { useState } from 'react';
import { MRT_ColumnDef, MRT_SortingState, MRT_PaginationState } from "material-react-table";

import { formatDateTimeToString } from '../lib/utils';
import GenericTable from './GenericTable';
import { Crisis24Item } from '../types/data';
import { Button, Chip } from '@mui/material';
import globalConfig from '../config.json';
import DOMPurify from 'dompurify';

interface Crisis24TableProps {
    title: string;
    data: Crisis24Item[];
    handleStageIncident: (crisis24Item: Crisis24Item) => void;
}
const Crisis24Table = (props: Crisis24TableProps) => {
    const {
        title,
        data,
        handleStageIncident
    } = props;

    const [pagination, setPagination] = useState<MRT_PaginationState>({ pageIndex: 0, pageSize: 5 });
    const isRefetching = false;
    const isError = false;
    const isLoading = false;

    const showColumnFilters = false;
    const columns: MRT_ColumnDef<Crisis24Item>[] = [
        {
            accessorKey: "name",
            header: "Description",
        },
        {
            accessorKey: "advice",
            header: "Summary",
            Cell: ({ row }: { row: { original: Crisis24Item } }) => {
                const sanitizedHTML = DOMPurify.sanitize(row.original.advice);

                return (
                    <div
                        dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
                    />
                );
            },
        },
        {
            accessorKey: "location",
            header: "Location",
            maxSize: 3,
            Cell: ({ row }: { row: { original: Crisis24Item } }) => {
                const locationSet = new Set(
                    row.original.locations.flatMap(l => [l.location_name, l.location_countryName])
                );

                const pointRadiusSet = new Set(
                    row.original.point_radius.map(pr => pr.centre_description).filter(Boolean)
                );

                const uniqueCombinedDescriptions = Array.from(
                    new Set([...pointRadiusSet, ...locationSet])
                ).join(', ');

                return uniqueCombinedDescriptions;
            },
            sortingFn: (rowA, rowB) => {
                const locationA = rowA.original.point_radius[0]?.centre_description || "";
                const locationB = rowB.original.point_radius[0]?.centre_description || "";

                return locationA.localeCompare(locationB);
            }
        },
        {
            accessorKey: "category_name",
            header: "Categories",
            maxSize: 7,
            Cell: ({ row }: { row: { original: Crisis24Item } }) => {
                return row.original.categories.map(row => row.category_name).join(', ');
            },
            sortingFn: (rowA, rowB) => {
                const locationA = rowA.original.categories[0]?.category_name || "";
                const locationB = rowB.original.categories[0]?.category_name || "";

                return locationA.localeCompare(locationB);
            }
        },
        {
            accessorKey: "beginDate",
            header: "Dates",
            maxSize: 7,
            Cell: ({ row }: { row: { original: Crisis24Item } }) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>Start: {formatDateTimeToString(row.original.beginDate)}</span>
                        <span>End: {formatDateTimeToString(row.original.endDate)}</span>
                    </div>
                );
            },
        },
        {
            accessorKey: "impact",
            header: "Impact",
            maxSize: 3,
            sortingFn: (rowA, rowB) => {
                const locationA = rowA.original.impact[0]?.impact_key;
                const locationB = rowB.original.impact[0]?.impact_key;

                return locationA - locationB;
            },
            Cell: ({ row }: { row: { original: Crisis24Item } }) => {
                const impact = row.original.impact[0];
                if (!impact) {
                    return (
                        <Chip
                            label="Not Assessed"
                            sx={{
                                fontWeight: 'bold',
                                color: "black",
                                background: "grey"
                            }}
                        />
                    );
                }
                return impact.impact_value === "Low" ? (
                    <Chip
                        label={impact.impact_value}
                        sx={{
                            fontWeight: 'bold',
                            color: globalConfig["ratingTextColors"]["1"],
                            background: globalConfig["ratingBGColors"]["1"],
                        }}
                    />
                ) : impact.impact_value === "Moderate" ? (
                    <Chip
                        label={impact.impact_value}
                        sx={{
                            fontWeight: 'bold',
                            color: globalConfig["ratingTextColors"]["3"],
                            background: globalConfig["ratingBGColors"]["3"],
                        }}
                    />
                ) : impact.impact_value === "High" ? (
                    <Chip
                        label={impact.impact_value}
                        sx={{
                            fontWeight: 'bold',
                            color: globalConfig["ratingTextColors"]["5"],
                            background: globalConfig["ratingBGColors"]["5"],
                        }}
                    />
                ) : (
                    <Chip
                        label={impact.impact_value}
                        sx={{
                            color: "green",
                            background: "grey",
                            fontWeight: 'bold',
                        }}
                    />
                );
            }
        },
        {
            accessorKey: "incident",
            header: "Send Incident",
            maxSize: 7,
            Cell: ({ row }: { row: { original: Crisis24Item } }) => {
                const incident = row.original;
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleStageIncident(incident)}
                        sx={{
                            bgcolor: "#194B4B",
                            fontWeight: 'bold',
                        }}
                    >
                        Notify Users
                    </Button>
                );
            },
        },
    ];

    // const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [sorting, setSorting] = useState<MRT_SortingState>([
        { id: 'beginDate', desc: true }
    ]);

    const Crisis24Table = (
        <GenericTable
            title={title}
            columns={columns}
            data={data}
            onEditClick={() => { }}
            handleOpenDeleteModal={() => { }}
            sorting={sorting}
            pagination={pagination}
            rowCount={data.length}
            isRefetching={isRefetching}
            isError={isError}
            isLoading={isLoading}
            showColumnFilters={showColumnFilters}
            setPagination={setPagination}
            setSorting={setSorting}
            enableRowActions={false}
        />
    );

    return Crisis24Table;
};

export default Crisis24Table;