import { create } from 'zustand';

export enum PartialConnectStatus {
    SENT,
    NOT_SENT,
    SENDING,
    ERROR
};


export interface PartialConnectData {
    email?: string;
    token?: string;
    uid?: string;
    inviteId?: string;
    password?: string;
    repeatpassword?: string;
};


export type Role = 'admin' | 'customer' | "kowroo_admin"

export type PhoneUser = {
    id: number
    email: string
    phoneNumber: string
    firstName: string
    surname: string
    companyName: string
    role: Role
    gender: string
    isSOS: boolean
    isFMH: boolean
    checkinOpen: boolean
    riskRating: string
    connected: boolean
    is_active: boolean
    location: {
      longitude: number
      latitude: number
    }
}

export type AdminUser = {
    id: number
    firstName: string
    surname: string
    companyName: string
    email: string
    role: Role
    gender: string
    managedUsers: PhoneUser[]
    riskRating: string
}

export type User = PhoneUser | AdminUser;

export type State = {
    user: User
    isAuthenticated: boolean
    isChatMinimized: boolean,
    selectedUserId: number | null,
    error: string | null;
    passwordResetEmailSent: PartialConnectStatus;
}

export type Actions = {
  setAuthenticated: (isAuthenticated: boolean) => void
  initializeUser: (id: number, role: Role, gender: string, email: string, firstName: string, surname: string,  companyName: string) => void
  setManagedUsers: (data: PhoneUser[]) => void
  // setRole: (role: Role) => void
  // setRiskRating: (riskRating: string) => void
  // setEmail: (email: string) => void
  // setGender: (gender: string) => void
  // setID: (id: number) => void
  // getID: () => number
  getRole: () => string
  setIsChatMinimized: (isChatMinimized: boolean) => void
//   const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  setSelectedUserId: (selectedUserId: number | null) => void
  setPasswordResetEmailSent: (passwordResetEmailSent: PartialConnectStatus) => void
}



const initialUser: User = {
    id: -1,
    phoneNumber: "",
    firstName: "",
    surname: "",
    email: '',
    companyName: '',
    role: 'customer',
    gender: '',
    isSOS: false,
    isFMH: false,
    checkinOpen: false,
    riskRating: "Low Risk",
    connected: false,
    is_active: false,
    location: {
        longitude: 0,
        latitude:0
    }
};

export const useUserStore = create<State & Actions>()((set,  get) =>({
    user: initialUser,
    isAuthenticated: false,
    isChatMinimized: true,
    selectedUserId: null,
    error: null,
    passwordResetEmailSent: PartialConnectStatus.NOT_SENT,
    setAuthenticated: (isAuthenticated: boolean)=>{
        set({ isAuthenticated: isAuthenticated});
    },
    setPasswordResetEmailSent: (passwordResetEmailSent: PartialConnectStatus) => {
        set({ passwordResetEmailSent: passwordResetEmailSent });
    },
    setIsChatMinimized: (isChatMinimized: boolean) => {
        set({ isChatMinimized: isChatMinimized });
    },
    setSelectedUserId: (selectedUserId: number | null) => {
        set({ selectedUserId: selectedUserId });
    },
    initializeUser: (id: number, role: Role, gender: string, email: string, firstName: string, surname: string, companyName: string) => {
        const baseUser = {
            id,
            role,
            gender,
            email, 
            firstName, 
            surname, 
            companyName
        };
        if (role === 'customer') {
            set({
                user: {
                    ...baseUser,
                    riskRating: "Low Risk",
                    isSOS: false,
                    isFMH: false,
                    checkinOpen: false,
                    connected: false,
                    location: {
                        longitude: -6.25149902841349,
                        latitude:53.32930489196624
                    }
                } as PhoneUser,
            });
        } else if (role === 'admin') {
            set({
                user: {
                    ...baseUser,
                    riskRating: "Low Risk",
                    managedUsers: [],
                } as AdminUser,
            });
        }
    },
    setManagedUsers: (data: PhoneUser[]) => {
        set(state => {
            if (state.user.role === 'admin') {
                console.log("[setManagedUsers]", data);
                return { user: { 
                    ...state.user, 
                    managedUsers: data 
                } };
            }
            console.error("Trying to update AdminUser data on a PhoneUser.");
            return state;
        });
    },
    getRole: () => get().user.role, 
}));
