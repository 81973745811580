import { Autocomplete, createFilterOptions, TextField, Typography } from "@mui/material";
const filter = createFilterOptions();

export const customDropdown = (options, label, value, setValue, profile) => {
    return <Autocomplete
      disabled={!["Country", "Type", "Location Timezone", "Category Name", "Name", "Type Name"].includes(label) ? Object.keys(profile).length > 0 : false}
      fullWidth
    value={value}
    onChange={(event, newValue) => {
      if (typeof newValue === 'string' && label !== "Asset Type") {
        setValue(newValue);
      } else if (newValue && newValue.inputValue && !["Country", "Type", "Age", "Nationality"].includes(label)) {
        // Create a new value from the user input
        setValue(newValue.inputValue);
      } else if (label === "Asset Type"){
        setValue(newValue || "");
      } else if (["Country", "Type"].includes(label)) {
        setValue(newValue.value || "");
      } else {
        setValue(newValue.value)
      }
    }}
    filterOptions={(options, params) => {
      const filtered = filter(options, params);

      const { inputValue } = params;
      // Suggest the creation of a new value
      const isExisting = options.some((option) => inputValue === option.title);
      if (inputValue !== '' && !isExisting && !["Age", "Nationality", "Incident", "Asset Type"].includes(label)) {
        filtered.push({
          inputValue,
          value: `Add "${inputValue}"`,
        });
      }

      return filtered;
    }}
    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    id="free-solo-with-text-demo"
    options={options}
    getOptionLabel={(option) => {
      // Value selected with enter, right from the input
      if (typeof option === 'string') {
        return option;
      }
      // Add "xxx" option created dynamically
      if (option.inputValue) {
        return option.inputValue;
      }
      // Regular option
      return label === "Incident" ? option.id : option.value;
    }}
    renderOption={(props, option) => <li {...props}>
      {label === "Incident" ?
      <Typography>
      {/* Cat: {option.value},
      ID: {option.id},
      Sum: {option.summary} */}
      </Typography>
      : option.value
  }
      </li>}

    freeSolo
    renderInput={(params) => (
      <TextField required={["Country","Type", "Department"].includes(label)} fullWidth sx={{ paddingBottom: 2 }}{ ...params} label={label} />
    )}
  />

  }