import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import mapboxgl, { GeoJSONSource } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import LayersIcon from "@mui/icons-material/Layers";
import TableRowsIcon from "@mui/icons-material/TableRows";

import {
    Typography,
    Popover,
    Box,
    IconButton,
} from "@mui/material";
import * as turf from '@turf/turf';
import { FeatureCollection, Feature, Point, Polygon, LineString } from 'geojson';

import { useDataStore } from "../stores/dataStore";
import { Asset, FollowMeHomeLocation, IntelligenceFusionIncident, UserIncident } from '../types/data';
import { PhoneUser, AdminUser, useUserStore } from '../stores/userStore';
import { useWebSocketStore } from '../stores/websocketStore';
import CheckboxGroup from '../components/CheckBoxGroup';
import MapTableDrawer from '../components/MapTable';
import CreateIncidentDrawer from '../components/CreateIncidentDrawer';
import CreateAssetDrawer from '../components/CreateAssetDrawer';
import ModalWithSelect from '../components/IncidentModal';
import { toast } from 'sonner';

let REACT_APP_MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY;
if (!REACT_APP_MAPBOX_API_KEY) {
    REACT_APP_MAPBOX_API_KEY = "";
}
mapboxgl.accessToken = REACT_APP_MAPBOX_API_KEY;
const userLocationIconRadius = 20;

function mapUsersToFeatureCollection(users: PhoneUser[]) {
    const activeUsers = users.filter(user => user.is_active);
    const userLocationFeatureCollection = {
        type: "FeatureCollection",
        features: activeUsers.map((managedUser) => {
            return {
                geometry: {
                    coordinates: [managedUser.location.longitude, managedUser.location.latitude],
                    // coordinates: [managedUser.location.latitude, managedUser.location.longitude],
                    type: "Point"
                },
                properties: {
                    id: managedUser.id,
                    email: managedUser.email,
                    firstName: managedUser.firstName,
                    surname: managedUser.surname,
                    role: managedUser.role,
                    gender: managedUser.gender,
                    isSOS: managedUser.isSOS,
                    isFMH: managedUser.isFMH,
                    checkinOpen: managedUser.checkinOpen,
                    riskRating: managedUser.riskRating,
                    connected: managedUser.connected,

                },
                type: "Feature"
            };
        })
    } as FeatureCollection<Point, PhoneUser>;

    return userLocationFeatureCollection;
}

function mapUserIncidentsToFeatureCollection(userIncidents: UserIncident[]) {
    const userIncidentFeatureCollection = {
        type: "FeatureCollection",
        features: userIncidents.map((incident) => {
            return {
                geometry: {
                    coordinates: [incident.longitude, incident.latitude],
                    type: "Point"
                },
                properties: {
                    id: incident.id,
                    category_name: incident.category_name,
                    type_name: incident.type_name,
                    summary: incident.summary,
                    location_timezone: incident.location_timezone,
                    started_at_timestamp: incident.started_at_timestamp,
                    ended_at_timestamp: incident.ended_at_timestamp,
                    impact_rating: incident.impact_rating,
                    impact_short_description: incident.impact_short_description,
                    impact_long_description: incident.impact_long_description,
                    reported_at: incident.reported_at,
                    reviewed_at: incident.reviewed_at,
                    geometry_as_text: incident.geometry_as_text,
                    description: incident.description,
                    latitude: incident.latitude,
                    longitude: incident.longitude,
                },
                type: "Feature"
            };
        })
    } as FeatureCollection<Point, UserIncident>;
    return userIncidentFeatureCollection;
}

function mapAssetsToFeatureCollection(assets: Asset[]) {
    const assetFeatureCollection = {
        type: "FeatureCollection",
        features: assets.map((asset) => {
            return {
                geometry: {
                    coordinates: [asset.longitude, asset.latitude],
                    type: "Point"
                },
                properties: {
                    date_created: asset.date_created,
                    description: asset.description,
                    id: asset.id,
                    latitude: asset.latitude,
                    longitude: asset.longitude,
                    name: asset.name,
                    type_name: asset.type_name,
                },
                type: "Feature"
            };
        })
    } as FeatureCollection<Point, Asset>;
    return assetFeatureCollection;
}

// function mapFollowMeHomeToFeatureCollection(followMeHomeLocations: FollowMeHomeLocation[]) {
//     const followMeHomeFeatureCollection = {
//         type: "FeatureCollection",
//         features: followMeHomeLocations.map((followMeHomeLocation) => {
//             return {
//                 geometry: {
//                     coordinates: [followMeHomeLocation.longitude, followMeHomeLocation.latitude],
//                     type: "Point"
//                 },
//                 properties: {
//                     latitude: followMeHomeLocation.latitude,
//                     longitude: followMeHomeLocation.longitude,
//                     createdAt: followMeHomeLocation.createdAt,
//                     userID: followMeHomeLocation.userID,
//                 },
//                 type: "Feature"
//             };
//         })
//     } as FeatureCollection<Point>;
//     return followMeHomeFeatureCollection;
// }


function mapFollowMeHomeToLineString(followMeHomeLocations: FollowMeHomeLocation[]) {
    const followMeHomeFeatureCollection = {
        type: "FeatureCollection",
        features: followMeHomeLocations.map((followMeHomeLocation) => {
            return {
                geometry: {
                    coordinates: [followMeHomeLocation.longitude, followMeHomeLocation.latitude],
                    type: "Point"
                },
                properties: {
                    latitude: followMeHomeLocation.latitude,
                    longitude: followMeHomeLocation.longitude,
                    createdAt: followMeHomeLocation.createdAt,
                    userID: followMeHomeLocation.userID,
                },
                type: "Feature"
            };
        })
    } as FeatureCollection<Point>;
    followMeHomeFeatureCollection.features.sort((a, b) => new Date(a.properties.createdAt).getTime() - new Date(b.properties.createdAt).getTime());
    const coordinates = followMeHomeFeatureCollection.features.map(f => f.geometry.coordinates);

    const lineString: Feature<LineString> = {
        type: "Feature",
        geometry: {
            type: "LineString",
            coordinates: coordinates
        },
        properties: {}
    };

    const featureCollection: FeatureCollection<LineString> = {
        type: "FeatureCollection",
        features: [lineString]
    };
    return featureCollection;
}
// function mapIntelligenceFusionToFeatureCollection(intelligenceFusion: IntelligenceFusionIncidentFeature[]) {
//   return {
//     type: "FeatureCollection",
//     features: intelligenceFusion as Feature[]
//   } as FeatureCollection
// }

const MapDisplay: React.FC = () => {
    const sendOpenCheckin = useWebSocketStore(state => state.sendOpenCheckin);
    const sendStopSOS = useWebSocketStore(state => state.sendStopSOS);

    const { intelligenceFusionStoreData } = useDataStore((state) => ({
        intelligenceFusionStoreData: state.intelligenceFusion,
    }));
    const [intelligenceFusionData, setIntelligenceFusionData] = useState({
        type: "FeatureCollection",
        features: intelligenceFusionStoreData
    } as FeatureCollection<Point, IntelligenceFusionIncident>);
    // const [intelligenceFusionData, setIntelligenceFusionData] = useState(mapIntelligenceFusionToFeatureCollection(intelligenceFusionStoreData));

    const [filteredIntelligenceFusionData, setFilteredIntelligenceFusionData] = useState<FeatureCollection<Point, IntelligenceFusionIncident>>(intelligenceFusionData);

    const { userIncidents } = useDataStore((state) => ({
        userIncidents: state.userIncidents,
    }));
    const [userIncidentsData, setUserIncidentsData] = useState(mapUserIncidentsToFeatureCollection(userIncidents));
    const [filteredUserIncidentsData, setFilteredUserIncidentsData] = useState<FeatureCollection<Point, UserIncident>>(userIncidentsData);


    const { followMeHomeLocations } = useDataStore((state) => ({
        followMeHomeLocations: state.followMeHomeLocations,
    }));
    const [followMeHomeData, setFollowMeHomeData] = useState(mapFollowMeHomeToLineString(followMeHomeLocations));
    // const [filteredFollowMeHomeData, setFilteredFollowMeHomeData] = useState(followMeHomeData);

    const { assets } = useDataStore((state) => ({
        assets: state.assets,
    }));
    const [assetsData, setAssetsData] = useState(mapAssetsToFeatureCollection(assets));
    const [filteredAssetsData, setFilteredAssetsData] = useState(assetsData);

    const { user } = useUserStore((state) => ({
        user: state.user,
    }));

    const [currentManagedUsers, setCurrentManagedUsers] = useState([] as PhoneUser[]);
    const managedUsersRef = useRef(currentManagedUsers);

    useEffect(() => {
        if (user.role === "admin") {
            setCurrentManagedUsers((user as AdminUser).managedUsers);
            managedUsersRef.current = (user as AdminUser).managedUsers;
        }
    }, [user]);

    useEffect(() => {
        console.log("Map managed users: ", currentManagedUsers);
        managedUsersRef.current = currentManagedUsers;
    }, [currentManagedUsers]);

    const [userLocationsData, setUserLocationsData] = useState(mapUsersToFeatureCollection(currentManagedUsers));
    const [filteredUserLocationsData, setFilteredUserLocationsData] = useState(userLocationsData);

    const mapContainerRef = useRef<HTMLDivElement | null>(null);
    const mapRef = useRef<mapboxgl.Map | null>(null);
    const popupRef = useRef<mapboxgl.Popup | null>(null);
    const [polygonOpen, setPolygonOpen] = useState(false);

    useEffect(() => {
        if (intelligenceFusionStoreData.length > 0) {
            const intelligenceFusionFeatures = intelligenceFusionStoreData as Feature[];
            const intFusionFeatureCollection = {
                type: "FeatureCollection",
                features: intelligenceFusionFeatures
            } as FeatureCollection<Point, IntelligenceFusionIncident>;

            setIntelligenceFusionData(intFusionFeatureCollection);
            if (!polygonOpen) {
                setFilteredIntelligenceFusionData(intFusionFeatureCollection);
            }
        }
    }, [intelligenceFusionStoreData, polygonOpen]);

    useEffect(() => {
        if (userIncidents.length > 0) {
            setUserIncidentsData(mapUserIncidentsToFeatureCollection(userIncidents));
            if (!polygonOpen) {
                setFilteredUserIncidentsData(mapUserIncidentsToFeatureCollection(userIncidents));
            }
        }
    }, [userIncidents, polygonOpen]);
    useEffect(() => {
        if (followMeHomeLocations.length > 0) {
            setFollowMeHomeData(mapFollowMeHomeToLineString(followMeHomeLocations));
        }
    }, [followMeHomeLocations]);
    useEffect(() => {
        if (assets.length > 0) {
            setAssetsData(mapAssetsToFeatureCollection(assets));
            if (!polygonOpen) {
                setFilteredAssetsData(mapAssetsToFeatureCollection(assets));
            }
        }
    }, [assets, polygonOpen]);

    useEffect(() => {
        if (currentManagedUsers.length > 0) {
            setUserLocationsData(() => {
                const newFeatureCollection = mapUsersToFeatureCollection(currentManagedUsers);
                return newFeatureCollection;
            });
            if (!polygonOpen) {
                setFilteredUserLocationsData(mapUsersToFeatureCollection(currentManagedUsers));
            }
        }
    }, [currentManagedUsers, polygonOpen]);

    const draw = useRef(new MapboxDraw({
        displayControlsDefault: false,
        controls: {
            polygon: true,
            trash: true
        }
    })).current;

    const updateFilteredLayers = useCallback(() => {
        setPolygonOpen(true);
        const polygon = draw.getAll();

        if (polygon.features.length > 0) {
            const drawnPolygons = polygon.features;
            const filteredIntelligenceFusion = {
                type: 'FeatureCollection',
                features: intelligenceFusionData.features.filter(feature =>
                    feature.geometry.type === 'Point' &&
                    drawnPolygons.some(drawnPolygon =>
                        turf.booleanPointInPolygon(feature as Feature<Point>, drawnPolygon as Feature<Polygon>)
                    )
                )
            };

            const filteredUserLocations = {
                type: 'FeatureCollection',
                features: userLocationsData.features.filter(feature => {
                    return feature.geometry.type === 'Point' && drawnPolygons.some(drawnPolygon => {
                        return turf.booleanPointInPolygon(feature as Feature<Point>, drawnPolygon as Feature<Polygon>);
                    });
                })
            };

            const filteredUserIncidents = {
                type: 'FeatureCollection',
                features: userIncidentsData.features.filter(feature =>
                    feature.geometry.type === 'Point' && drawnPolygons.some(drawnPolygon =>
                        turf.booleanPointInPolygon(feature as Feature<Point>, drawnPolygon as Feature<Polygon>)
                    )
                )
            };
            const filteredAssets = {
                type: 'FeatureCollection',
                features: assetsData.features.filter(feature =>
                    feature.geometry.type === 'Point' && drawnPolygons.some(drawnPolygon =>
                        turf.booleanPointInPolygon(feature as Feature<Point>, drawnPolygon as Feature<Polygon>)
                    )
                )
            };

            setFilteredIntelligenceFusionData(filteredIntelligenceFusion as FeatureCollection<Point, IntelligenceFusionIncident>);
            setFilteredUserLocationsData(filteredUserLocations as FeatureCollection<Point, PhoneUser>);
            setFilteredUserIncidentsData(filteredUserIncidents as FeatureCollection<Point, UserIncident>);
            setFilteredAssetsData(filteredAssets as FeatureCollection<Point, Asset>);
        }
    }, [intelligenceFusionData, userLocationsData, userIncidentsData, assetsData, draw]);

    const resetLayers = useCallback(() => {
        setFilteredIntelligenceFusionData(intelligenceFusionData as FeatureCollection<Point, IntelligenceFusionIncident>);
        setFilteredUserIncidentsData(userIncidentsData as FeatureCollection<Point, UserIncident>);
        setFilteredAssetsData(assetsData as FeatureCollection<Point, Asset>);
        setFilteredUserLocationsData(userLocationsData as FeatureCollection<Point, PhoneUser>);
        setPolygonOpen(false);
    }, [intelligenceFusionData, userIncidentsData, assetsData, userLocationsData]);


    // const [showContextMenu, setShowContextMenu] = useState(false);
    // const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

    const [drawerPopup, setDrawerPopup] = useState<mapboxgl.Popup | null>(null);
    const [createIncidentDrawerOpen, setCreateIncidentDrawerOpen] = useState(false);
    const [createAssetDrawerOpen, setCreateAssetDrawerOpen] = useState(false);

    const [incidentModalOpen, setIncidentModalOpen] = useState(false);
    const [incidentModalIncident, setIncidentModalIncident] = useState<UserIncident | null>(null);
    const [incidentModalSelectedUserIDs, setIncidentModalSelectedUserIDs] = useState<number[]>([]);
    const incidentModalHandleClose = () => {
        setIncidentModalOpen(false);
        setIncidentModalIncident(null);
        setIncidentModalSelectedUserIDs([]);
    };

    const incidentModalHandleSelectUserChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const { value } = event.target as { value: number[] };
        setIncidentModalSelectedUserIDs(value);
    };

    const [formLatLong, setFormLatLong] = useState({ latitude: 0, longitude: 0 });

    const size = userLocationIconRadius * 10;
    const pulsingDot = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        onAdd: function () {
            const canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            this.context = canvas.getContext('2d');
        },

        render: function () {
            const duration = 1000;
            const t = (performance.now() % duration) / duration;
            const radiusFactor = 0.5;
            const radius = (size / 2) * radiusFactor;
            const outerRadius = (size / 2) * (1 - radiusFactor) * t + radius;
            // const radius = userLocationIconRadius * 1.2;
            // const outerRadius = userLocationIconRadius * 0.7 * t + radius;

            const context = this.context;

            context.clearRect(0, 0, this.width, this.height);
            context.beginPath();
            context.arc(
                this.width / 2,
                this.height / 2,
                outerRadius,
                0,
                Math.PI * 2
            );
            // context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;

            context.fillStyle = `rgba(150, 50, 50, ${1 - t})`; // Darker red
            context.fill();

            context.beginPath();
            context.arc(
                this.width / 2,
                this.height / 2,
                radius,
                0,
                Math.PI * 2
            );
            context.fillStyle = 'rgba(255, 100, 100, 0)'; // Transparent fill for the inner circle
            // context.strokeStyle = 'white';
            context.strokeStyle = 'rgba(150, 50, 50, 1)';
            context.lineWidth = 2 + 4 * (1 - t);
            context.fill();
            context.stroke();

            this.data = context.getImageData(
                0,
                0,
                this.width,
                this.height
            ).data;

            mapRef.current?.triggerRepaint();

            return true;
        }
    };

    useEffect(() => {
        if (mapContainerRef.current && !mapRef.current) {
            mapRef.current = new mapboxgl.Map({
                container: mapContainerRef.current,
                style: "mapbox://styles/mapbox/light-v10",
                center: [0, 0],
                zoom: 2,
            });
            mapRef.current?.addControl(
                new MapboxGeocoder({
                    accessToken: mapboxgl.accessToken,
                    mapboxgl: mapboxgl
                })
            );
            mapRef.current.addControl(new mapboxgl.NavigationControl());
            mapRef.current.addControl(draw);


            mapRef.current.on('contextmenu', (event) => {
                event.preventDefault();
                const coordinates = event.lngLat;

                if (drawerPopup) {
                    drawerPopup.remove(); // Remove any existing popup
                }

                if (!mapRef.current) {
                    return;
                }
                const newPopup = new mapboxgl.Popup()
                    .setMaxWidth("400px")
                    .setLngLat(coordinates)
                    .setHTML(`
                        <div>
                            <button 
                                id="create-incident-button"
                                style="
                                    background-color: #194B4B; 
                                    color: white;             
                                    padding: 7px 15px;       
                                    font-size: 16px;           
                                    border: none;             
                                    border-radius: 5px;        
                                    cursor: pointer;          
                                    display: block;           
                                    margin-bottom: 10px;     
                                    width: 100%;             
                                    box-sizing: border-box;  
                                    font-weight: bold;  
                                "
                            >Create Incident</button>
                            <button 
                                id="create-asset-button"
                                style="
                                    background-color: #194B4B; 
                                    color: white;             
                                    padding: 7px 15px;       
                                    font-size: 16px;           
                                    border: none;             
                                    border-radius: 5px;        
                                    cursor: pointer;          
                                    display: block;      
                                    width: 100%;             
                                    box-sizing: border-box; 
                                    font-weight: bold;
                                "
                            >Create Asset</button>
                        </div>
                        `
                    ).addTo(mapRef.current);

                setDrawerPopup(newPopup);
                const incidentButton = document.getElementById('create-incident-button');
                if (incidentButton) {
                    incidentButton.addEventListener('click', () => {
                        setCreateIncidentDrawerOpen(true);
                        newPopup.remove();
                        setFormLatLong({ longitude: coordinates.lng, latitude: coordinates.lat });
                    });
                } else {
                    console.log("Button not found");
                }

                const assetButton = document.getElementById('create-asset-button');
                if (assetButton) {
                    assetButton.addEventListener('click', () => {
                        setCreateAssetDrawerOpen(true);
                        newPopup.remove();

                        setFormLatLong({ longitude: coordinates.lng, latitude: coordinates.lat });
                    });
                } else {
                    console.log("Button not found");
                }
            });

            mapRef.current.on('load', () => {
                mapRef.current?.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });
                mapRef.current?.on('draw.create', updateFilteredLayers);
                mapRef.current?.on('draw.update', updateFilteredLayers);
                mapRef.current?.on('draw.delete', resetLayers);

                if (!mapRef.current?.getSource('intelligenceFusionSource')) {
                    mapRef.current?.addSource('intelligenceFusionSource', {
                        type: "geojson",
                        data: intelligenceFusionData
                    });
                    const pastTimestamp = Date.now() - 0.01;

                    mapRef.current?.addLayer({
                        id: 'intelligenceFusionLayer',
                        type: 'circle',
                        source: 'intelligenceFusionSource',
                        paint: {
                            'circle-radius': 5,
                            'circle-color': [
                                'match',
                                ['get', 'impact_rating'],
                                // -1, '#FF0000',
                                1, '#FFBA08',
                                2, '#F58607',
                                3, '#E85805',
                                4, '#DB2A02',
                                5, '#D00000',
                                '#0000FF'
                            ],
                            'circle-stroke-width': 2,
                            'circle-stroke-color': "black",
                            'circle-stroke-opacity': [
                                'interpolate',
                                ['linear'],
                                ['to-number', ['get', 'started_at_timestamp']],
                                pastTimestamp,  // Time calculated outside the expression
                                0.5,            // Fully transparent at past time
                                Date.now(),     // Current time
                                1               // Fully opaque at current time
                            ],
                            'circle-opacity': [
                                'interpolate',
                                ['linear'],
                                ['to-number', ['get', 'started_at_timestamp']],
                                pastTimestamp,  // Time calculated outside the expression
                                0.5,            // Fully transparent at past time
                                Date.now(),     // Current time
                                1               // Fully opaque at current time
                            ]

                        }

                    });
                    mapRef.current?.on('click', 'intelligenceFusionLayer', (e: mapboxgl.MapMouseEvent & { features?: Feature[] }) => {
                        if (e.features && e.features.length > 0) {
                            const feature = e.features[0];
                            let coordinates: [number, number];
                            if (feature.geometry.type === 'Point') {
                                coordinates = (feature.geometry as Point).coordinates as [number, number];
                            } else {
                                const lngLat = mapboxgl.LngLat.convert(e.lngLat);
                                coordinates = [lngLat.lng, lngLat.lat] as [number, number];
                            }
                            const properties = feature.properties;

                            const displayFields = [
                                "category_name",
                                "description",
                                "ended_at",
                                "impact_short_description",
                                "started_at",
                                "summary",
                                "type_name",
                            ];
                            const formattedProperties = Object.entries(properties || {})
                                .filter(([key]) => displayFields.includes(key))
                                .map(([key, value]) => {
                                    if (key === 'started_at' || key === 'ended_at') {
                                        const date = new Date(value);
                                        value = date.toLocaleString('en-US', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                        });
                                    }

                                    return `
                                        <div style="margin-bottom: 4px;">
                                          <strong style="color: #555;">${key.replace(/_/g, ' ').toUpperCase()}:</strong><br>
                                          <span>${value}</span>
                                        </div>
                                    `;
                                }).join('');

                            // Ensure the popup is not already open
                            if (popupRef.current) {
                                popupRef.current.remove();
                            }

                            if (mapRef.current) {
                                popupRef.current = new mapboxgl.Popup()
                                    .setMaxWidth("400px")
                                    .setLngLat(coordinates)
                                    .setHTML(`
                                        <div style="font-family: Arial, sans-serif; color: #333;">
                                          <strong style="font-size: 1.2em; color: #0FCD7D;">Kowroo Incident:</strong><br>
                                          ${formattedProperties}
                                        </div>
                                    `).addTo(mapRef.current);
                            }
                        }
                    });

                    mapRef.current?.on('mouseenter', 'intelligenceFusionLayer', () => {
                        mapRef.current!.getCanvas().style.cursor = 'pointer';
                    });
                    mapRef.current?.on('mouseleave', 'intelligenceFusionLayer', () => {
                        mapRef.current!.getCanvas().style.cursor = '';
                    });
                }

                if (!mapRef.current?.getSource('followMeHomeSource')) {
                    mapRef.current?.addSource('followMeHomeSource', {
                        type: "geojson",
                        data: followMeHomeData
                    });
                    mapRef.current?.loadImage('arrow.png', (error, image) => {
                        if (error) throw error;
                        if (mapRef.current && image) {
                            mapRef.current.addImage('arrow-image', image);
                            mapRef.current?.addLayer({
                                "id": "arrowsLayer",
                                "type": "symbol",
                                "source": "followMeHomeSource",
                                "layout": {
                                    "symbol-placement": "line",
                                    "symbol-spacing": 30,
                                    "icon-image": "arrow-image", // Make sure you have this icon in your sprite
                                    "icon-size": 0.05,
                                    "icon-rotate": 270
                                    // "icon-rotate": ["get", "bearing"] // Use this for dynamic orientation
                                }
                            });
                        }
                    });
                    mapRef.current?.addLayer({
                        id: 'followMeHomeDataLayer',
                        type: 'circle',
                        source: 'followMeHomeSource',
                        paint: {
                            'circle-radius': 4.1,
                            'circle-color': 'pink',
                            'circle-stroke-width': 0.1,
                            'circle-stroke-color': "pink"
                        }
                    });

                    mapRef.current?.addLayer({
                        "id": "lineLayer",
                        "type": "line",
                        "source": "followMeHomeSource",
                        "layout": {
                            "line-join": "round",
                            "line-cap": "round"
                        },
                        "paint": {
                            "line-color": "orange",
                            "line-width": 2
                        }
                    });
                }
                if (!mapRef.current?.getSource('userIncidentsSource')) {
                    mapRef.current?.addSource('userIncidentsSource', {
                        type: "geojson",
                        data: userIncidentsData
                    });
                    mapRef.current?.addLayer({
                        id: 'userIncidentsLayer',
                        type: 'circle',
                        source: 'userIncidentsSource',
                        paint: {
                            'circle-radius': 5,
                            'circle-color': "white",
                            'circle-stroke-width': 3,
                            'circle-stroke-color': "#cc0000"
                        }
                    });


                    mapRef.current?.on('click', 'userIncidentsLayer', (e: mapboxgl.MapMouseEvent & { features?: Feature[] }) => {
                        if (e.features && e.features.length > 0) {
                            const feature = e.features[0];
                            let coordinates: [number, number];
                            if (feature.geometry.type === 'Point') {
                                coordinates = (feature.geometry as Point).coordinates as [number, number];
                            } else {
                                const lngLat = mapboxgl.LngLat.convert(e.lngLat);
                                coordinates = [lngLat.lng, lngLat.lat] as [number, number];
                            }
                            const properties = feature.properties;
                            const displayFields = [
                                "category_name",
                                "description",
                                "ended_at_timestamp",
                                "impact_short_description",
                                "started_at_timestamp",
                                "summary",
                                "type_name",
                            ];
                            const formattedProperties = Object.entries(properties || {})
                                .filter(([key]) => displayFields.includes(key))
                                .map(([key, value]) => {
                                    if (key === 'started_at_timestamp' || key === 'ended_at_timestamp' || key === 'reported_at' || key === 'reviewed_at') {
                                        const date = new Date(value);
                                        value = date.toLocaleString('en-US', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                        });
                                    }

                                    return `
                                        <div style="margin-bottom: 4px;">
                                          <strong style="color: #555;">${key.replace(/_/g, ' ').toUpperCase()}:</strong><br>
                                          <span>${value}</span>
                                        </div>
                                    `;
                                }).join('');
                            let incidentID = 0;
                            if (properties) {
                                incidentID = properties.id;
                            }

                            if (popupRef.current) {
                                popupRef.current.remove();
                            }
                            if (mapRef.current) {
                                popupRef.current = new mapboxgl.Popup()
                                    .setMaxWidth("400px")
                                    .setLngLat(coordinates)
                                    .setHTML(`
                                        <div style="font-family: Arial, sans-serif; color: #333;">
                                            <strong style="font-size: 1.2em; color: #0FCD7D;">User Incident:</strong><br>
                                            ${formattedProperties}
                                            <button id="incident-button" incident-id=${incidentID}>Push Incident</button>
                                        </div>
                                    `).addTo(mapRef.current);
                                document.getElementById('incident-button')?.addEventListener('click', (event) => {
                                    const button = event.target as HTMLButtonElement;
                                    const incidentNotificationID = button.getAttribute('incident-id'); // Get the property value
                                    if (!incidentNotificationID) {
                                        return;
                                    }
                                    setIncidentModalIncident(properties as UserIncident);
                                    setIncidentModalOpen(true);
                                });
                            }
                        }
                    });

                    mapRef.current?.on('mouseenter', 'userIncidentsLayer', () => {
                        mapRef.current!.getCanvas().style.cursor = 'pointer';
                    });
                    mapRef.current?.on('mouseleave', 'userIncidentsLayer', () => {
                        mapRef.current!.getCanvas().style.cursor = '';
                    });
                }

                if (!mapRef.current?.getSource('assetsSource')) {
                    mapRef.current?.addSource('assetsSource', {
                        type: "geojson",
                        data: assetsData
                    });

                    mapRef.current?.loadImage('buildings.png', (error, image) => {
                        if (error) throw error;
                        if (mapRef.current && image) {
                            mapRef.current.addImage('assets-image', image);
                            mapRef.current.addLayer({
                                id: "assetsLayer",
                                type: 'symbol',
                                source: "assetsSource",
                                layout: {
                                    'icon-image': 'assets-image',
                                    'icon-size': 0.06,
                                    'icon-allow-overlap': true,
                                },
                            });
                        }

                    }
                    );
                    mapRef.current?.on('click', 'assetsLayer', (e: mapboxgl.MapMouseEvent & { features?: Feature[] }) => {
                        if (e.features && e.features.length > 0) {
                            const feature = e.features[0];
                            let coordinates: [number, number];
                            if (feature.geometry.type === 'Point') {
                                coordinates = (feature.geometry as Point).coordinates as [number, number];
                            } else {
                                const lngLat = mapboxgl.LngLat.convert(e.lngLat);
                                coordinates = [lngLat.lng, lngLat.lat] as [number, number];
                            }
                            const properties = feature.properties;

                            const displayFields = [
                                "date_created",
                                "description",
                                "name",
                                "type_name",
                            ];
                            const formattedProperties = Object.entries(properties || {})
                                .filter(([key]) => displayFields.includes(key))
                                .map(([key, value]) => {
                                    if (key === 'date_created') {
                                        const date = new Date(value);
                                        value = date.toLocaleString('en-US', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                        });
                                    }

                                    return `
                                    <div style="margin-bottom: 4px;">
                                      <strong style="color: #555;">${key.replace(/_/g, ' ').toUpperCase()}:</strong><br>
                                      <span>${value}</span>
                                    </div>
                                `;
                                }).join('');

                            // Ensure the popup is not already open
                            if (popupRef.current) {
                                popupRef.current.remove();
                            }
                            if (mapRef.current) {
                                popupRef.current = new mapboxgl.Popup()
                                    .setMaxWidth("400px")
                                    .setLngLat(coordinates)
                                    .setHTML(`
                                        <div
                                          style="font-family: Poppins, sans-serif; color: #333; padding: 10px; min-width: 200px;"
                                        >
                                          <strong style="font-size: 1.2em; color: #0FCD7D;">Company Asset:</strong><br>
                                          ${formattedProperties}
                                        </div>
                                    `).addTo(mapRef.current);
                            }
                        }
                    });

                    mapRef.current?.on('mouseenter', 'assetsLayer', () => {
                        mapRef.current!.getCanvas().style.cursor = 'pointer';
                    });
                    mapRef.current?.on('mouseleave', 'assetsLayer', () => {
                        mapRef.current!.getCanvas().style.cursor = '';
                    });
                }

                mapRef.current.once('idle', () => {
                    // all layers have been added

                    
                    if (!mapRef.current?.getSource('userLocationsSource')) {
                        mapRef.current?.addSource('userLocationsSource', {
                            type: "geojson",
                            data: userLocationsData
                        });
                        mapRef.current?.addLayer({
                            id: 'userLocationsLayer',
                            type: 'circle',
                            source: 'userLocationsSource',
                            paint: {
                                'circle-radius': userLocationIconRadius,
                                'circle-color': [
                                    'match',
                                    ['get', 'riskRating'],
                                    'Low Risk', '#39fc03',
                                    'Medium Risk', '#F9BD0F',
                                    'High Risk', '#F9380F',
                                    '#888'
                                ],
                                'circle-stroke-width': 3,
                                'circle-stroke-color': [
                                    'case',
                                    ['==', ['get', 'connected'], true],
                                    "#1AA3FF",
                                    '#000'
                                ],
                            }
                        });

                        mapRef.current.addLayer({
                            id: "userLocationsLayer-initials",
                            type: 'symbol',
                            source: "userLocationsSource",
                            layout: {
                                'symbol-placement': 'point',
                                'text-field': ['concat', ['slice', ['get', 'firstName'], 0, 1], ['slice', ['get', 'surname'], 0, 1]],
                                'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
                                'text-size': userLocationIconRadius,
                                'text-anchor': 'center',
                                'icon-allow-overlap': true,
                                'text-allow-overlap': true,
                                'symbol-z-elevate': true,
                            },
                            paint: {
                                "text-halo-color": "white",
                                "text-halo-width": 0.5
                            },
                        });

                        mapRef.current?.on('click', 'userLocationsLayer', (e: mapboxgl.MapMouseEvent & { features?: Feature[] }) => {
                            if (e.features && e.features.length > 0) {
                                const feature = e.features[0];
                                let coordinates: [number, number];
                                if (feature.geometry.type === 'Point') {
                                    coordinates = (feature.geometry as Point).coordinates as [number, number];
                                } else {
                                    const lngLat = mapboxgl.LngLat.convert(e.lngLat);
                                    coordinates = [lngLat.lng, lngLat.lat] as [number, number];
                                }
                                const properties = feature.properties;
                                const formattedProperties = Object.entries(properties || {}).map(([key, value]) => {
                                    return `
                                        <div style="margin-bottom: 4px;">
                                          <strong style="color: #555;">${key.replace(/_/g, ' ').toUpperCase()}:</strong><br>
                                          <span>${value}</span>
                                        </div>
                                    `;
                                }).join('');
                                let userID = 0;
                                if (properties) {
                                    userID = properties.id;
                                }

                                let isSOS = false;
                                if (properties) {
                                    isSOS = properties.isSOS;
                                }
                                // Ensure the popup is not already open
                                if (popupRef.current) {
                                    popupRef.current.remove();
                                }
                                if (mapRef.current) {
                                    popupRef.current = new mapboxgl.Popup()
                                        .setMaxWidth("400px")
                                        .setLngLat(coordinates)
                                        .setHTML(`
                                            <div
                                              style="font-family: Poppins, sans-serif; color: #333; padding: 10px; min-width: 200px;"
                                            >
                                              <strong style="font-size: 1.2em; color: #0FCD7D;">Kowroo User:</strong><br>
                                              ${formattedProperties}
                                              <button
                                                id="checkin-button"
                                                checkin-user-id=${userID}
                                                style="
                                                    background-color: #4CAF50;
                                                    color: white;
                                                "
                                              >Send Checkin</button>
                                              ${isSOS ? `
                                                    <button
                                                        id="sos-button"
                                                        sos-user-id=${userID}
                                                        style="
                                                            background-color: darkred;
                                                            color: white;
                                                        "
                                                    >Stop SOS</button>
                                                ` : ''}
                                            </div>
                                        `).addTo(mapRef.current);
                                    document.getElementById('checkin-button')?.addEventListener('click', (event) => {
                                        const button = event.target as HTMLButtonElement;
                                        const checkinUserID = button.getAttribute('checkin-user-id'); // Get the property value
                                        if (!checkinUserID) {
                                            return;
                                        }
                                        sendOpenCheckin(parseInt(checkinUserID), user.id);
                                        const checkinUser = managedUsersRef.current.find(
                                            (user) => user.id === parseInt(checkinUserID)
                                        );                                  
                                        toast.success("Checkin",
                                            {
                                                description: `Sent checkin to ${checkinUser.firstName} ${checkinUser.surname}!`,
                                                action: {
                                                    label: "Ok",
                                                    onClick: () => {},
                                                },
                                                duration: 1000 * 900,
                                                position: 'bottom-left',
                                                icon: '📌',
                                                closeButton: true,
                                                dismissible: true,
                                            },
                                        );
                                        popupRef.current?.remove();
                                    });
                                    document.getElementById('sos-button')?.addEventListener('click', (event) => {
                                        const button = event.target as HTMLButtonElement;
                                        const sosUserID = button.getAttribute('sos-user-id'); // Get the property value
                                        if (!sosUserID) {
                                            return;
                                        }
                                        sendStopSOS(parseInt(sosUserID), user.id);
                                        
                                        const sosUser = managedUsersRef.current.find(
                                            (user) => user.id === parseInt(sosUserID)
                                        );
                                        toast.success("SOS",
                                            {
                                                description: `Stopped SOS for ${sosUser.firstName} ${sosUser.surname}!`,
                                                action: {
                                                    label: "Ok",
                                                    onClick: () => {},
                                                },
                                                duration: 1000 * 900,
                                                position: 'bottom-left',
                                                icon: '🆘',
                                                closeButton: true,
                                                dismissible: true,
                                            },
                                        );
                                        popupRef.current?.remove();
                                    });
                                }
                            }
                        });

                        mapRef.current?.on('mouseenter', 'userLocationsLayer', () => {
                            mapRef.current!.getCanvas().style.cursor = 'pointer';
                        });
                        mapRef.current?.on('mouseleave', 'userLocationsLayer', () => {
                            mapRef.current!.getCanvas().style.cursor = '';
                        });
                    }

                    mapRef.current?.addLayer({
                        'id': 'sos-layer',
                        'type': 'symbol',
                        'source': 'userLocationsSource',
                        'layout': {
                            'icon-image': 'pulsing-dot',
                            'icon-allow-overlap': true
                        },
                        'filter': ['==', ['get', 'isSOS'], true]
                    });
                });
            });



            return () => {
                if (mapRef.current) {
                    mapRef.current.remove();
                    mapRef.current = null;
                }
            };
        }



        // Cleanup function to remove the map
        return () => {
            if (mapRef.current) {
                mapRef.current.remove();
                mapRef.current = null; // Clear reference
            }
        };
    }, []);


    useEffect(() => {
        const map = mapRef.current;
        if (map) {
            const intelligenceFusionSource = map.getSource('intelligenceFusionSource') as GeoJSONSource | undefined;
            if (intelligenceFusionSource) {
                intelligenceFusionSource.setData(intelligenceFusionData);
            }
        }
    }, [intelligenceFusionData]);

    useEffect(() => {
        const map = mapRef.current;
        if (map) {
            const assetsSource = map.getSource('assetsSource') as GeoJSONSource | undefined;
            if (assetsSource) {
                assetsSource.setData(assetsData);
            }
        }
    }, [assetsData]);


    useEffect(() => {
        const map = mapRef.current;
        if (map) {
            const followMeHomeSource = map.getSource('followMeHomeSource') as GeoJSONSource | undefined;
            if (followMeHomeSource) {
                followMeHomeSource.setData(followMeHomeData);
            }
        }
    }, [followMeHomeData]);

    useEffect(() => {
        const map = mapRef.current;
        if (map) {
            const userIncidentSource = map.getSource('userIncidentsSource') as GeoJSONSource | undefined;
            if (userIncidentSource) {
                userIncidentSource.setData(userIncidentsData);
            }
        }
    }, [userIncidentsData]);


    useEffect(() => {
        const map = mapRef.current;
        if (map) {
            const userLocationsSource = map.getSource('userLocationsSource') as GeoJSONSource | undefined;
            if (userLocationsSource) {
                userLocationsSource.setData(userLocationsData);
            }
        }
    }, [userLocationsData]);


    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClickDataSourceButton = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDisplayTable = () => {
        setTableOpen(true);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const [displayIntelligenceFusion, setDisplayIntelligenceFusion] = useState<boolean>(true);
    const [displayUserLocations, setDisplayUserLocations] = useState<boolean>(true);
    const [displayUserIncidents, setDisplayUserIncidents] = useState<boolean>(true);
    const [displayAssets, setDisplayAssets] = useState<boolean>(true);
    const [displayFollowMeHome, setDisplayFollowMeHome] = useState<boolean>(true);

    useEffect(() => {
        const map = mapRef.current;
        if (map) {
            if (map.getLayer('intelligenceFusionLayer')) {
                map.setLayoutProperty(
                    'intelligenceFusionLayer',
                    'visibility',
                    displayIntelligenceFusion ? 'visible' : 'none'
                );
            }
        }
    }, [displayIntelligenceFusion]);

    useEffect(() => {
        const map = mapRef.current;
        if (map) {
            if (map.getLayer('userLocationsLayer')) {
                map.setLayoutProperty(
                    'userLocationsLayer',
                    'visibility',
                    displayUserLocations ? 'visible' : 'none'
                );
            }
            if (map.getLayer('userLocationsLayer-initials')) {
                map.setLayoutProperty(
                    'userLocationsLayer-initials',
                    'visibility',
                    displayUserLocations ? 'visible' : 'none'
                );
            }
            if (map.getLayer('sos-layer')) {
                map.setLayoutProperty(
                    'sos-layer',
                    'visibility',
                    displayUserLocations ? 'visible' : 'none'
                );
            }
        }
    }, [displayUserLocations]);

    useEffect(() => {
        const map = mapRef.current;
        if (map) {
            if (map.getLayer('assetsLayer')) {
                map.setLayoutProperty(
                    'assetsLayer',
                    'visibility',
                    displayAssets ? 'visible' : 'none'
                );
            }
        }
    }, [displayAssets]);

    useEffect(() => {
        const map = mapRef.current;
        if (map) {
            if (map.getLayer('arrowsLayer')) {
                map.setLayoutProperty(
                    'arrowsLayer',
                    'visibility',
                    displayFollowMeHome ? 'visible' : 'none'
                );
            }
            if (map.getLayer('followMeHomeDataLayer')) {
                map.setLayoutProperty(
                    'followMeHomeDataLayer',
                    'visibility',
                    displayFollowMeHome ? 'visible' : 'none'
                );
            }
            if (map.getLayer('lineLayer')) {
                map.setLayoutProperty(
                    'lineLayer',
                    'visibility',
                    displayFollowMeHome ? 'visible' : 'none'
                );
            }
        }
    }, [displayFollowMeHome]);

    useEffect(() => {
        const map = mapRef.current;
        if (map) {
            if (map.getLayer('userIncidentsLayer')) {
                map.setLayoutProperty(
                    'userIncidentsLayer',
                    'visibility',
                    displayUserIncidents ? 'visible' : 'none'
                );
            }
        }
    }, [displayUserIncidents]);
    const [tableOpen, setTableOpen] = useState(false);
    const [selectedTableDataSource, setSelectedTableDataSource] = useState("userLocations");
    // const handleDrawerOpen = () => {
    //   setShowContextMenu(false); // Hide the context menu
    //   // Logic to open the drawer will be implemented here.
    //   // You could trigger a drawer state or use a callback function.
    // };
    return (
        <div style={{ position: 'relative', width: '100%', height: 'calc(100vh - 80px)', marginTop: 0 }}>
            <MapTableDrawer
                open={tableOpen}
                setOpen={setTableOpen}
                selectedTableDataSource={selectedTableDataSource}
                setSelectedTableDataSource={setSelectedTableDataSource}
                filteredIntelligenceFusionData={filteredIntelligenceFusionData}
                filteredAssetsData={filteredAssetsData}
                filteredUserIncidentsData={filteredUserIncidentsData}
                filteredUserLocationsData={filteredUserLocationsData}
            />
            <div ref={mapContainerRef} style={{ width: '100%', height: '100%', position: 'relative' }}>
                <CreateIncidentDrawer
                    initialLatitude={formLatLong.latitude}
                    initialLongitude={formLatLong.longitude}
                    drawerOpen={createIncidentDrawerOpen}
                    setDrawerOpen={setCreateIncidentDrawerOpen}
                />
                <CreateAssetDrawer
                    initialLatitude={formLatLong.latitude}
                    initialLongitude={formLatLong.longitude}
                    drawerOpen={createAssetDrawerOpen}
                    setDrawerOpen={setCreateAssetDrawerOpen}
                />
                <IconButton
                    id="top-left-button-1"
                    style={{
                        position: 'absolute',
                        top: '10px',
                        left: '10px',
                        backgroundColor: '#fff',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        padding: '5px',
                        marginBottom: '1px',
                        zIndex: 10,
                        boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
                    }}
                    onClick={(e) => handleClickDataSourceButton(e)}
                >
                    <LayersIcon style={{ fontSize: '20px' }} />
                </IconButton>
                <IconButton
                    id="top-left-button-2"
                    size="small"
                    style={{
                        position: 'absolute',
                        top: '60px',
                        left: '10px',
                        backgroundColor: '#fff',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        padding: '5px',
                        marginBottom: '1px',
                        marginTop: '1px',
                        zIndex: 10,
                        boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
                    }}
                    onClick={() => handleDisplayTable()}
                >
                    <TableRowsIcon style={{ fontSize: '20px' }} />
                </IconButton>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Box sx={{ p: 2, width: 300 }}>
                        <Typography variant="h6">Select Options</Typography>
                        <CheckboxGroup
                            displayIntelligenceFusion={displayIntelligenceFusion}
                            setDisplayIntelligenceFusion={setDisplayIntelligenceFusion}
                            displayUserLocations={displayUserLocations}
                            setDisplayUserLocations={setDisplayUserLocations}
                            displayUserIncidents={displayUserIncidents}
                            setDisplayUserIncidents={setDisplayUserIncidents}
                            displayAssets={displayAssets}
                            setDisplayAssets={setDisplayAssets}
                            displayFollowMeHome={displayFollowMeHome}
                            setDisplayFollowMeHome={setDisplayFollowMeHome}
                        />
                    </Box>
                </Popover>
                <ModalWithSelect
                    open={incidentModalOpen}
                    handleClose={incidentModalHandleClose}
                    users={currentManagedUsers}
                    selectedIDs={incidentModalSelectedUserIDs}
                    handleSelectChange={incidentModalHandleSelectUserChange}
                    incident={incidentModalIncident} // Passing the content to the modal
                />
            </div>
        </div>
    );
};

export default MapDisplay;
