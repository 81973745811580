import axios from 'axios';

const NEWS_API_URL = process.env.REACT_APP_NEWS_API_URL;
const NEWS_API_KEY = process.env.REACT_APP_NEWS_API_KEY;


export const getNewsApiData = (categories, countries, top=true, page=null) => {

    let categoryCountrySearchString = '&'

    categories.forEach(element => {
        categoryCountrySearchString += `category=${element}&`
    });

    countries.forEach((element, idx) => {
        categoryCountrySearchString += `country=${element}${idx !== countries.length-1 ? '&' : ''}`
        // if () {

        // }
    });

    return axios.get(
        `${NEWS_API_URL}${top ? 'top-headlines' : 'everything'}/?apikey=${NEWS_API_KEY}${categoryCountrySearchString}`,
        {
            params: {
                // search,
                language: "en",
                page: page,
              },
        },
    ).catch((e) => {
      return e.response;
    });
  };