import React, { useContext, useRef, useEffect, useCallback, useState, useMemo } from 'react';
import * as turf from "@turf/turf";
import "../styles.css"
import {
  getIntelligenceFusGeoJson,
//   getIntelligenceFusGeom,
  createIncidentNotification,
  searchIncidents,
  searchAssets,
  getUserLocations,
  postCheckins
} from "../helpers/api";
import {
  useNavigate,
} from "react-router-dom";
import ReactDOM from 'react-dom';
import { connect } from "react-redux";
import { formatDateTimeString } from "../helpers/utils";
import AssetsPage from "./AssetsPage";
import IncidentsPage from "./IncidentsPage";
import { 
  useSnackbar 
} from 'notistack';

import UserIcon from "../components/UserIcon";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl, { Anchor } from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import LayersIcon from "@mui/icons-material/Layers";
import TableRowsIcon from "@mui/icons-material/TableRows";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import PushIncidentModal from "../components/PushIncidentModal";

import CancelSOSModal from "../components/CancelSOSModal";

import { 
  FormGroup, 
  Grid, 
  IconButton,
  Button, 
  ButtonGroup, 
  ListItem, 
  FormLabel,
  List,
  TableCell,
  styled,
  SwipeableDrawer,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  SelectChangeEvent,
  TableContainer,
  Drawer,
  Card,
  CardHeader,
  ListItemText,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CardActions,
  Snackbar
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import Popover from "@mui/material/Popover";

import Label from "../components/MapLabel";
import UserTooltip from "../components/UserTooltip";
import store from "../store";

import DisplayTable from "../components/DisplayTable";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
} from "material-react-table";
import { useSelector } from 'react-redux';
import { RootState } from '../reducers/rootReducer';

import {
  setSelectedStartDate,
  setSelectedEndDate,
} from "../actions/mapActions";

mapboxgl.accessToken = "pk.eyJ1IjoicGhhbXBodWMiLCJhIjoiY2wzdm9pbjdzMmwzNjNqcnh6ZDIzYjhrdSJ9.h2FdRu6u_p91Am-ZHvQjuA"

const classes = {
  info: {
    display: "inline-block",
    position: "absolute",
    left: 0,
    top: "0px",
    margin: "12px",
    zIndex: "1 !important",
    padding: "6px",
    fontWeight: "bold",
    backgroundColor: "#ffffff",
    color: "#333333",
  },
  showFeature: {
    position: "fixed",
    top: "20%",
    left: "50%",
    zIndex: "1 !important",
    margin: "0px auto",
    wordWrap: "anywhere",
    whiteSpace: "pre-wrap",
    padding: "10px",
    border: "none",
    borderRadius: "3px",
    fontSize: "12px",
    textAlign: "center",
    color: "#222",
    background: "#fff",
  },
  mapContainer: {
    // marginTop: "-1vh",
    // paddingTop: "-5vh",
    // right: 0,
    // bottom: 0,
    height: "90vh",
    width: "100%",
  },
  "#map": {
    // display: "flex",
    // height: "80%",
    // width: "100%",
  },
  btnOnMap: {
    backgroundColor: "rgb(138, 165, 216)",
    position: "absolute",
    zIndex: "1",
    top: "10px",
    left: "10px",
    borderRadius: "3px",
    width: "30px",
    border: "1px solid rgba(0, 0, 0, 0.4)",
  },
  buttonGroup: {
    marginTop: "45px",
    zIndex: "1 !important",
    marginLeft: "10px",
    position: "absolute",
    top: 0,
    left: 0
  },

};

const getdateStr = () => {
  let currentDate = new Date();
  const timePart = currentDate.toTimeString().split(' ')[0];

  const offset = currentDate.getTimezoneOffset()
  currentDate = new Date(currentDate.getTime() - (offset*60*1000))
  let dateStr = currentDate.toISOString().split('T')[0] + " " + timePart
  return dateStr
}


interface MapProps {
  loading: boolean,
  role: string,
  userId: any,
  companyName:any,
  profile: any,
  user: any
}

const MapDisplay = (props: MapProps) => {
  const { role, loading, userId, companyName, profile, user } = props;
  const adminId = useSelector((state: RootState) => state.user.id);
  const userLocations = useSelector((state:RootState) => state.socket.userLocations)
  const followMeHomeLocations = useSelector((state:RootState) => state.socket.followMeLocations)
  
  const followMeHomeInfo = {"source": "follow-me-home", "filter": "Follow Me Home", "colour": "#bf9113"}
  const intFusionInfo = {"source": "intelligence-fusion", "filter": "Security Incidents", "colour": "#000000"}
  const userIncidentInfo = {"source": "user-incidents", "filter": "User Incidents", "colour": '#cc0000'}
  const userAssetInfo = {"source": "main-map-user-assets", "filter": "Assets", "colour": '#008000'}
  const userLocationInfo = {"source": "user-locations", "filter": "Users", "colour": '#1aa3ff'}
  const navigate = useNavigate();

  let incidents_points = useRef([]);
  const intelFusionPoints = useRef({});
  const mapRef = useRef(null);
  const mapContainer = useRef(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);


  const [openIncidentModal, setOpenIncidentModal] = useState(false);
  const [openCancelSOSModal, setOpenCancelSOSModal] = useState(false);
  const [displayCreateAssetPage, setDisplayCreateAssetPage] = useState(false);
  const [displayCreateIncidentPage, setDisplayCreateIncidentPage] = useState(false);

  const [stateTable, setStateTable] = useState({ bottom: false });
  const [drawInit, setDrawInit] = useState(true);

  const [devices, setDevices] = useState(() => ["phone"]);
  const [searchInput, setSearchInput] = useState("");
  const [coordinates, setCoordinates] = useState(null);
  
  const [intFusionData, setIntFusionData] = useState({
    type:"FeatureCollection",
    features:[],
    crs:4326
  });
  const [userIncidentData, setUserIncidentData] = useState({
    type:"FeatureCollection",
    features:[],
    crs:4326
  });
  const [userAssetData, setUserAssetData] = useState({
    type:"FeatureCollection",
    features:[],
    crs:4326
  });
  
  const intFusionDataRef = useRef(intFusionData);
  const userIncidentDataRef = useRef(userIncidentData);
  const userAssetDataRef = useRef(userAssetData);

  const [userLocationData, setUserLocationData] = useState(null);
  const emptyFeatureCollection = {
    crs:null,
    type: 'FeatureCollection',
    features: [],
  }
  const [followMeHomeData, setFollowMeHomeData] = useState(emptyFeatureCollection);

  const [filteredIntFusion, setFilteredIntFusion] = useState(null);
  const [filteredUserIncident, setFilteredUserIncident] = useState(null);
  const [filteredUserAsset, setFilteredUserAsset] = useState(null);
  const [filteredUserLocation, setFilteredUserLocation] = useState(null);

  const [filteredTableData, setFilteredTableData] = useState({
    type: 'FeatureCollection',
    features: [],
  });
  
  const [incidentPage, setIncidentPage] = useState(1);
  const [incidentPageSize, setIncidentPageSize] = useState(5);

  const [tableDataSource, setTableDataSource] = useState(intFusionInfo.source);

  const [activeSOS, setActiveSOS] = useState(false);
  const [countSOS, setCountSOS] = useState(0);
  
  const [enabledSources, setEnabledSources] = useState({
    [intFusionInfo.source]: true,
    [userIncidentInfo.source]: true,
    [userAssetInfo.source]: true,
    [userLocationInfo.source]: true
  })
  const columns = {
    [intFusionInfo.source]: [

      {
        accessorKey: "properties.displayStartedAt",
        header: "Started At",
      },

      {
        accessorKey: "properties.description",
        header: "Description",
        size:50
      },
      {
        accessorKey: "properties.summary",
        header: "Summary",
        size:50
      },
      {
        accessorKey: "properties.type_name",
        header: "Incident Type",
        maxSize:1
      },
      {
        accessorKey: "properties.category_name",
        header: "Incident Category",
        maxSize:1
      },
    ],
    [userAssetInfo.source]: [
      {
      accessorKey: "properties.uid.firstName",
      header: "User",
      Cell: ({ cell, row }) => (
        <>
        <UserTooltip
          // userRole={role}
          users={[row.original.properties.uid]}
          // title="Add"
          placement="top"
          arrow
          describeChild
        >
          <Grid item>
            <UserIcon user={row.original.properties.uid} handleClickProfile={()=>{}}>
            </UserIcon>
          </Grid>
        </UserTooltip>
        </>
        ),
        size:50
      },
      {
        accessorKey: "properties.name",
        header: "Name",
        size:50
      },
      {
        accessorKey: "properties.description",
        header: "Description",
        size:50
      },
    ],
    [userIncidentInfo.source]: [
      {
        accessorKey: "properties.creator.firstName",
        header: "User",
        Cell: ({ cell, row }) => (
          <>
          <UserTooltip
            // userRole={role}
            users={[row.original.properties.creator]}
            // title="Add"
            placement="top"
            arrow
            describeChild
          >
            <Grid item>
              <UserIcon user={row.original.properties.creator} handleClickProfile={()=>{}}>
              </UserIcon>
            </Grid>
          </UserTooltip>
          </>
        ),
        size:50
      },
      {
        accessorKey: "properties.category_name",
        header: "Category",
        size:50
      },
      {
        accessorKey: "properties.type_name",
        header: "Type",
      },
      {
        accessorKey: "properties.summary",
        header: "Summary",
      },
      {
        accessorKey: "properties.started_at_timestamp",
        header: "Start Date",
      },
      {
        accessorKey: "properties.ended_at_timestamp",
        header: "End Date",
      },
    ],
  }
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState("")
  const [sorting, setSorting] = useState([])
  const [pagination, setPagination]= useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  });
  const [rowCount, setRowCount] = useState(5)
  const [isRefetching, setIsRefetching] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleDevices = (
    event: React.MouseEvent<HTMLElement>,
    newDevices: string[]
  ) => {
    if (newDevices.length) {
      setDevices(newDevices);
    }
  };

  const handleChangePage = (event, newPage) => {
    setIncidentPage(newPage);
  };

  const handleChangePageSize = (event) => {
    setIncidentPageSize(parseInt(event.target.value, 10));
    setIncidentPage(0);
  };

  const handleChangeDataType = useCallback((event: SelectChangeEvent) => {
      // console.log("polygon drawn")
      setTableDataSource(event.target.value)
      setIncidentPageSize(5)
      if (event.target.value === userIncidentInfo.source) {
        setFilteredTableData(filteredUserIncident)
      } else if (event.target.value === userAssetInfo.source) {
        setFilteredTableData(filteredUserAsset)
      } else if (event.target.value === userLocationInfo.source) {
        setFilteredTableData(filteredUserLocation)
      } else if (event.target.value === intFusionInfo.source) {
        // console.log("filteredUserLocation", filteredIntFusion)
        // console.log("setting int fusion as table source")
        setFilteredTableData(filteredIntFusion)
      }
    },
    // Not sure if this is good practice 
    [
      filteredUserIncident,
      filteredUserAsset,
      filteredUserLocation,
      filteredIntFusion,
    ]
  );

  const handleEndDateChange = (date) => {
    if (date > new Date()) {
      store.dispatch(setSelectedEndDate(null));
    } else {
      store.dispatch(setSelectedEndDate(date.format("MM/DD/YYYY")));
    }
  };

  const handleStartDateChange = (date) => {
    store.dispatch(setSelectedStartDate(date.format("MM/DD/YYYY")));
  };

  const handleClick = (
    //   event: React.MouseEvent<HTMLButtonElement>
    event  
    ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    // console.log("CLOSING")
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    // Create the map
    // if (mapRef.current) return;
    const map = new mapboxgl.Map({
      container: mapContainer.current, // container ID
      // style: 'mapbox://styles/mapbox/streets-v12', // style URL
      style:"mapbox://styles/mapbox/light-v10",
      center: [-6.2568, 53.3855], // starting position [lng, lat]
      zoom: 5.5 // starting zoom
    });
    mapRef.current = map
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      marker:false
    });
    // map.current.addControl(legend, 'bottom-left');

    mapRef.current.addControl(geocoder);
    
    mapRef.current.on("mousemove", (e) => {
      document.getElementById("info").innerHTML =
        "Longitude: " +
        e.lngLat.lng.toFixed(4) +
        "  Latitude: " +
        e.lngLat.lat.toFixed(4);
    });
  }, []); // The empty dependency array ensures the effect runs only once
  

  useEffect(() =>{
    const fetchIntFusion = async () => {
      const real_incident = await getIntelligenceFusGeoJson(1, 5000, "2019-07-06", "");
      if (real_incident.status === 200) {
        const formattedIntFusion = () => {
          console.log(real_incident)
          return {
            ...real_incident.data.results.rows,
            features: real_incident.data.results.rows.features.map((feature)=>{
              // console.log(feature)
              return ({
              ...feature,
              properties: {
                ...feature.properties, 
                displayStartedAt: formatDateTimeString(feature.properties.started_at)
              }
            })})
          }
        }
        console.log("formattedIntFusion")
        console.log(formattedIntFusion)
        setIntFusionData(formattedIntFusion);
        setFilteredIntFusion(formattedIntFusion);
        setFilteredTableData(formattedIntFusion)
      } else {
        throw new Error(`Data could not be fetched!, ${real_incident}`);
      } 
    }
    fetchIntFusion();
  }, []);

  

  useEffect(() =>{
    // console.log("fetching personal")
    const fetchUserLocations = async () => {
      const user_locations = await getUserLocations();
      console.log("user_locations", user_locations)
      console.log(user_locations)
      
      if (user_locations.status !== 200) {
        return [
          {
            user_id: null,
            longitude: null,
            latitude: null,
            time_entered_location: null,
          },
        ];
      } else {

        // latitude:locationMessage.Latitude,
        // longitude:locationMessage.Longitude,
        // risk_rating:locationMessage.RiskRating,
        // status:locationMessage.Type,
        // time_entered_location:locationMessage.DateTime,
        // user_id:parseInt(locationMessage.Uid)

        user_locations.data.locations.forEach(location=>{
          let message = {
            Latitude:location.latitude,
            Longitude:location.longitude,
            RiskRating:location.risk_rating,
            Type:"Standard",
            DateTime:location.time_entered_location,
            Uid:location.user_id
          }

          store.dispatch({ type: "UPDATE_USER_LOCATION", payload:message})
        })
      }
    } 
    fetchUserLocations() 
  }, []);

  const fetchUserAssets = async () => {
    const userAssets = await searchAssets("", null, null, navigate);
    if (userAssets.status === 200) {
      const features = userAssets.data.results.map((pd) => ({
        type: "Feature",
        properties: {
          id: pd.id,
          date_created: pd.date_created,
          deleted: pd.deleted,
          description: pd.description,
          icon: pd.icon,
          latitude: pd.latitude,
          longitude: pd.longitude,
          name: pd.name,
          uid: pd.uid
        },
        geometry: {
          type: "Point",
          coordinates: [pd.longitude, pd.latitude],
        },
      }));

      const featureCollection = {"type": "FeatureCollection", "crs":null, "features":features} 
      // console.log("User Assets inside fetchdata")
      // console.log(featureCollection)
      setUserAssetData(featureCollection);
      setFilteredUserAsset(featureCollection);
    } else {
      throw new Error(`Data could not be fetched!, ${userAssets.response}`);
    } 
  }

  const fetchUserIncidents = async () => {
    const incidents = await searchIncidents("", null, null, navigate);
    if (incidents.status !== 200 || !incidents.data.results.length) {
      return [
        {
          id: "No incidents",
          creator: {},
          longitude: null,
          latitude: null,
          category_name: null,
          type_name: null,
          started_at_timestamp: null,
          ended_at_timestamp: null,
          impact_long_description: null,
          impact_rating: null,
          location_timezone: null,
          reported_at: null,
          reviewed_at: null,
          summary: null,
          approved: null,
          geometry_as_text: null,
          impact_short_description: null,
        },
      ];
    } else {
      const features = incidents.data.results.map((pd) => ({
        type: "Feature",
        properties: {
          id: pd.id,
          category_name: pd.category_name,
          type_name: pd.type_name,
          started_at_timestamp: pd.started_at_timestamp,
          ended_at_timestamp: pd.ended_at_timestamp,
          displayStartedAt: formatDateTimeString(pd.started_at_timestamp),
          displayEndedAt: formatDateTimeString(pd.ended_at_timestamp),
          summary: pd.summary,
          creator: {
            email: pd.creator.email,
            firstName: pd.creator.firstName,
            surname: pd.creator.surname,
            risk_rating: pd.creator.risk_rating,
            role: pd.creator.role,
          }
        },
        geometry: {
          type: "Point",
          coordinates: [pd.longitude, pd.latitude],
        },
      }));
      const featureCollection = {"type": "FeatureCollection", "crs":null, "features":features} 
      setUserIncidentData(featureCollection);
      setFilteredUserIncident(featureCollection);
    }
  }

  const handleFollowMeHome = (parsedData) => {
    const followMeHomeFeature = {
      type: "Feature",
      properties: {
        UID:parsedData.Uid,
        initials: ""
      },
      geometry: { 
        type: "Point", 
        coordinates:[parsedData.Longitude, parsedData.Latitude],
      }
    }
    const featuresArray = [...followMeHomeData['features'], followMeHomeFeature]
    const updatedFeatureCollection = {
      crs: followMeHomeData['crs'],
      type: followMeHomeData['type'],
      features: featuresArray,
    };
    // console.log("after ", updatedFeatureCollection)
    setFollowMeHomeData(updatedFeatureCollection)
  }

  // const existingOnMessage = websocket.onmessage;
  
  useEffect(() =>{
    // const handleStandard = () => {
      // setUserLocationData(userLocations)
      setFollowMeHomeData(followMeHomeLocations)
      console.log("[followMeHomeLocations]")
      console.log(followMeHomeLocations)
      
  }, [
    // userLocations,
    followMeHomeLocations 
  ]);

  useEffect(() =>{
      setUserLocationData(userLocations)
      // setFollowMeHomeData(followMeHomeLocations)
      console.log("[setUserLocationData]")
      console.log(userLocations)
      
  }, [
    userLocations 
  ]);

  const handleCloseAsset = useCallback((event) => {
      const toggleDrawerAsset = (anchor: Anchor, open: boolean) => {
        if (
          event.type === "keydown" &&
          (event.key === "Tab" || event.key === "Shift")
        ) {
          return;
        }

        setDisplayCreateAssetPage(false);
      };
      toggleDrawerAsset("right", false);
      // console.log("before asset fetch")
      // console.log(userAssetData)
      fetchUserAssets().catch((e) => {
        console.log(e.message);
      });
      // console.log("after asset fetch")
      // console.log(userAssetData)
    },
    [displayCreateAssetPage]
  );
  useEffect(() =>{
    fetchUserAssets();
  }, []);

  const handleCloseIncident = useCallback((event) => {
    const toggleDrawerIncident = (anchor: Anchor, open: boolean) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }

      setDisplayCreateIncidentPage(false);
    };
    toggleDrawerIncident("right", false);
    fetchUserIncidents()
      // .then((res) => {
      //   store.dispatch(setIncidents(res));
      // })
      .catch((e) => {
        console.log(e.message);
      });
    },
    [displayCreateIncidentPage]
    );
    useEffect(() =>{
    
    fetchUserAssets();
    }, []);

  useEffect(() =>{
    
    fetchUserIncidents();
  }, [navigate]);

  useEffect(() => {
    setTimeout(() => {
    // mapRef.current.on('load', () => {
      if (intFusionData.features.length === 0) {
        // console.log("do nothing")
      } else if (mapRef.current.getSource(intFusionInfo.source) === undefined) {
        // console.log("adding int fusion source")
        mapRef.current.addSource(intFusionInfo.source, {
          type: 'geojson',
          data: intFusionData,
        });
        // console.log("int fusion data", intFusionData)
        mapRef.current.getSource(intFusionInfo.source).setData(intFusionData);
        mapRef.current.addLayer({
          id: intFusionInfo.source,
          type: 'circle',
          source: intFusionInfo.source,
          filter: ['!', ['has', 'point_count']],
          layout: {
          },  
          paint: {
            'circle-color': [
              'match',
                ['get', 'impact_rating'],
                -1, '#EAECD7',
                1, '#39fc03',
                2, '#F9E00F',
                3, '#F9BD0F',
                4, '#F9610F',
                5, '#F9380F',
                '#0000FF' // For other values, use blue color
            ],
            'circle-radius': 5,
            'circle-stroke-width': 3,
            'circle-stroke-color': intFusionInfo.colour
          }
        });
        
        // inspect a cluster on click
        mapRef.current.on('click', 'int-fusion-clusters', (e) => {
          const features = mapRef.current.queryRenderedFeatures(e.point, {
          layers: ['int-fusion-clusters']
        });
        
        const clusterId = features[0].properties.cluster_id;
        mapRef.current.getSource(intFusionInfo.source).getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;
            mapRef.current.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            });
          }
        );
        });
        
        mapRef.current.on('click', intFusionInfo.source, (e) => {
          function getIntFusionCard(intFusionRow){
            return (
                <Card sx={{ width: 400}} variant='outlined'>
                  <CardHeader
                    title={intFusionRow.type_name}
                    subheader={intFusionRow.category_name}
                    style={{
                      maxHeight:40,
                      backgroundColor: '#005A31',
                      color: 'white',
                      fontSize: '50px',
                      subheaderTypographyProps:{
                        color:"gray"
                      }
                    }}
                  />
                  <List
                    sx={{
                      width:400,
                      bgcolor: 'background.paper',
                    }}
                  >
                  <ListItem>
                    <ListItemText primary="Started At" secondary={intFusionRow.started_at}/>
                    <ListItemText primary="Impact" secondary={intFusionRow.impact_short_description}/>
                  </ListItem>
                </List>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Incident Description</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {intFusionRow.description}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Incident Summary</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {intFusionRow.summary}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                </Card>
            )
          }
          const PopUpElement = getIntFusionCard(e.features[0].properties)
          const popupContent = document.createElement('div');
          
          ReactDOM.render(PopUpElement, popupContent);
          const locationPopup = new mapboxgl.Popup({
            className: "user-location-map-popup", 
            closeButton:false
          })
          .setLngLat({lng: e.lngLat.lng, lat:e.lngLat.lat})
          .setDOMContent(popupContent);
  
          locationPopup.addTo(mapRef.current);
        });
        
        mapRef.current.on('mouseenter', intFusionInfo.source, () => {
          mapRef.current.getCanvas().style.cursor = 'pointer';
        });
        mapRef.current.on('mouseleave', intFusionInfo.source, () => {
          mapRef.current.getCanvas().style.cursor = '';
        });
        
        mapRef.current.on('dragend', function() {
          // checkAndReplaceData()
        });
        mapRef.current.on('zoomend', function() {
          // checkAndReplaceData()
        });
      }
    // }
    // )
    // Clean up the map instance
    // return () => {
    //   mapRef.current.remove();
    // };
  }, 100); 
  }, [intFusionData, mapRef])

  const [pushIncident, setPushIncident] = useState(null) 
  // function handlePushIncident(incident) {
  //   console.log("PUSHED ")
  //   console.log(incident)
  //   store.dispatch({
  //     type: "socket/sendincident", 
  //     userId:user.id, 
  //     companyName:user.companyName,
  //     adminId:adminId,
  //   })
  //   setOpenIncidentModal(false);
  // }

  const handlePushIncident = async (incident, selectedUsers) => {

    // console.log(user)
    console.log("PUSHED ")
    console.log(selectedUsers)
    console.log(incident)
    for (const user of selectedUsers){
        const res = await createIncidentNotification(
          incident.id,
          user.id
        );
        console.log("WRITIGN TO DB NOTI")
        console.log(res)

        store.dispatch({
          type: "socket/sendincident", 
          userId:user.id, 
          companyName:user.companyName,
          adminId:adminId,
        })
  }
  setOpenIncidentModal(false);
}
  const handleCloseIncidentModal = () => {
    setOpenIncidentModal(false);
    setPushIncident(null)
  };

  const [selectedPushIncidentUsers, setSelectedPushIncidentUsers] = useState([]);
  const invitedUsers = useSelector((state: RootState) => state.socket.invitedUsers);
  const handleSelectUser = (event) => {
    const user = event.target.value
    console.log("user is ",  user)
    setSelectedPushIncidentUsers(user)
  };
  const handleOpenIncidentModal = (row) => {
    console.log("OPEN MODAL!!!")
    console.log(row)
    setPushIncident(row)
    setOpenIncidentModal(true);
  };
  
  const [CancelSOSUser, setCancelSOSUser] = useState(null) 
  const handleOpenCancelSOSModal = (user) => {
    console.log("OPEN MODAL!!!")
    console.log(user)
    setCancelSOSUser(user)
    setOpenCancelSOSModal(true);
  };
  function handleSubmitCancelSOS(message) {
    console.log("PUSHED ")
    console.log(message)
    store.dispatch({
      type: "socket/cancelsos", 
      userId:message.UserList, 
      companyName:user.companyName,
      adminId:userId,
    })
    setOpenCancelSOSModal(false);
  }
  const handleCloseCancelSOSModal = () => {
    setOpenCancelSOSModal(false);
    setCancelSOSUser(null)
  };
  useEffect(() =>{
    intFusionDataRef.current = intFusionData
  }, [intFusionData]);
  
  useEffect(() =>{
    userIncidentDataRef.current = userIncidentData
    
  }, [userIncidentData]);

  useEffect(() =>{
    userAssetDataRef.current = userAssetData
  }, [userAssetData]);


  useEffect(() =>{
    // mapRef.current.on('load', () => {
    setTimeout(()=>{
      if (mapRef.current.getSource(userIncidentInfo.source) === undefined) {
        mapRef.current.addSource(userIncidentInfo.source, {
          type: 'geojson',
          data: userIncidentData,
        });
      }  else {
        mapRef.current.getSource(userIncidentInfo.source).setData(userIncidentData);
        // console.log("adding layer ", userIncidentInfo.source)
      }
      if (mapRef.current.getLayer(userIncidentInfo.source) === undefined) {
        mapRef.current.addLayer({
          id: userIncidentInfo.source,
          type: 'circle',
          source: userIncidentInfo.source,
          layout: {
          },  
          paint: {
            'circle-color': '#ffffff',
            'circle-radius': 5,
            'circle-stroke-width': 3,
            'circle-stroke-color': userIncidentInfo.colour
          }
        });

        mapRef.current.on('click', userIncidentInfo.source, (e) => {
          // console.log("e.features is ", e.features)
          const coordinates = e.features[0].geometry.coordinates.slice();

          function getUserIncidentCard(UserIncidentRow){
            // console.log("UserIncidentRow is ", UserIncidentRow)
            return (
                <>
                <Card sx={{ width: 400}} variant='outlined'>
                  <CardHeader
                    title={UserIncidentRow.type_name}
                    subheader={UserIncidentRow.category_name}
                    action={
                      <Button 
                        color="secondary" 
                        variant="contained" 
                        onClick={()=>handleOpenIncidentModal(UserIncidentRow)}
                        sx={{
                            backgroundColor: 'white !important',
                            // color: '#005A31',
                            color:"#005A31 !important",
                            '&:hover': {
                              backgroundColor: 'green !important', // Change background color on hover if desired
                            },
                          }}
                      >
                        PUSH INCIDENT
                      </Button>
                    }
                    style={{
                      maxHeight:40,
                      backgroundColor: '#005A31',
                      color: 'white',
                      fontSize: '50px',
                      subheaderTypographyProps:{
                        color:"gray"
                      }
                    }}
                  />
                  <List
                    sx={{
                      width:400,
                      bgcolor: 'background.paper',
                    }}
                  >
                  <ListItem>
                    <ListItemText primary="Started At" secondary={UserIncidentRow.started_at_timestamp}/>
                    <ListItemText primary="Ended At" secondary={UserIncidentRow.ended_at_timestamp}/>
                  </ListItem>
                </List>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Incident Summary</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {UserIncidentRow.summary}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                </Card>
                </>
            )
          }
          const PopUpElement = getUserIncidentCard(e.features[0].properties)
          const popupContent = document.createElement('div');
          
          ReactDOM.render(PopUpElement, popupContent);
          const locationPopup = new mapboxgl.Popup({
            className: "user-location-map-popup", 
            closeButton:false
          })
          .setLngLat({lng: coordinates[0], lat:coordinates[1]})
          .setDOMContent(popupContent);
  
          locationPopup.addTo(mapRef.current);

        });
        
        mapRef.current.on('mouseenter', userIncidentInfo.source, () => {
          mapRef.current.getCanvas().style.cursor = 'pointer';
        });
        mapRef.current.on('mouseleave', userIncidentInfo.source, () => {
          mapRef.current.getCanvas().style.cursor = '';
        });
  
        mapRef.current.on('dragend', function() {
          // checkAndReplaceData()
        });
        mapRef.current.on('zoomend', function() {
          // checkAndReplaceData()
        });
      }
    }, 500)
  }, [userIncidentData])

  useEffect(() =>{
    setTimeout(() => {
    // mapRef.current.on('load', () => {
      if (mapRef.current.getSource(userAssetInfo.source) === undefined) {
        mapRef.current.addSource(userAssetInfo.source, {
          type: 'geojson',
          data: userAssetData,
        });
      }  else {
        mapRef.current.getSource(userAssetInfo.source).setData(userAssetData);
      }
      if (mapRef.current.getLayer(userAssetInfo.source) === undefined) {
        mapRef.current.loadImage(
          'buildings.png',
          (error, image) => {
          if (error) throw error;
          
          // Add the image to the map style.
          mapRef.current.addImage('user-assets', image);
          mapRef.current.addLayer({
            id: userAssetInfo.source,
            type: 'symbol', // Use 'symbol' for image markers
            source: userAssetInfo.source,
            layout: {
              'icon-image': 'user-assets', // Specify the image URL as the marker
              'icon-size': 0.08, // Adjust the size of the image marker as needed
              'icon-allow-overlap': true, // Allow markers to overlap
            },
          })
        }
        )
        mapRef.current.on('click', userAssetInfo.source, (e) => {
          // console.log(e.features)
          const coordinates = e.features[0].geometry.coordinates.slice();

          // const name = e.features[0].properties.name;
          // const description = e.features[0].properties.description;
          // const date_created = new Date(e.features[0].properties.date_created);

          // Ensure that if the map is zoomed out such that
          // multiple copies of the feature are visible, the
          // popup appears over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
          function getAssetCard(assetRow){
            const assetCreator = JSON.parse(assetRow.uid)
            return  (
              <Card sx={{ maxWidth: 400 }} variant='outlined'>
                <CardHeader
                  title={assetRow.name}
                  style={{
                    maxHeight:30,
                    backgroundColor: '#005A31',
                    color: 'white',
                    fontSize: '20px',
                    subheaderTypographyProps:{
                      color:"gray"
                    }
                  }}
                />
                <List
                  sx={{
                    width: '100%',
                    maxWidth: 360,
                    minWidth: 360,
                    bgcolor: 'background.paper',
                  }}
                >
                <ListItem>
                  <ListItemText primary={assetRow.description} />
                </ListItem>
                <Divider variant="middle" component="li" />
                <ListItem>
                  <ListItemText primary="Submitted By" secondary={`${assetCreator.firstName} ${assetCreator.surname}`}/>
                </ListItem>
              </List>
              </Card>
          )}
          const PopUpElement = getAssetCard(e.features[0].properties)
          const popupContent = document.createElement('div');
          
          ReactDOM.render(PopUpElement, popupContent);
          const locationPopup = new mapboxgl.Popup({
            className: "user-location-map-popup", 
            closeButton:false
          })
          .setLngLat({lng: coordinates[0], lat:coordinates[1]})
          .setDOMContent(popupContent);
  
          locationPopup.addTo(mapRef.current);
        });
        
        mapRef.current.on('mouseenter', userAssetInfo.source, () => {
          mapRef.current.getCanvas().style.cursor = 'pointer';
        });
        mapRef.current.on('mouseleave', userAssetInfo.source, () => {
          mapRef.current.getCanvas().style.cursor = '';
        });
  
        mapRef.current.on('dragend', function() {
          // checkAndReplaceData()
        });
        mapRef.current.on('zoomend', function() {
          // checkAndReplaceData()
        });
      }
    }, 500)
  }, [userAssetData])

  useEffect(() =>{
    // mapRef.current.on('load', () => {
    setTimeout(() => {
      if (mapRef.current.getSource(userLocationInfo.source) === undefined) {
        // console.log("adding layer data2 :", userLocationData)
        mapRef.current.addSource(userLocationInfo.source, {
          type: 'geojson',
          data: userLocationData,
        });
      }  else {
        // console.log("adding layer data21 :", userLocationData)
        mapRef.current.getSource(userLocationInfo.source).setData(userLocationData);
        // console.log("adding layer data2 :", userLocationData)
        // console.log("adding layer ", userLocationInfo.source)
      }
      if (mapRef.current.getLayer(`${userLocationInfo.source}-marker`) !== undefined) {
        mapRef.current.removeLayer(`${userLocationInfo.source}-marker`)
        mapRef.current.removeLayer(`${userLocationInfo.source}-text`)
      }
      // console.log("adding layer ")
      mapRef.current.addLayer({
        id: userLocationInfo.source + "-marker",
        source: userLocationInfo.source,
        type:"circle",
        paint: {
          'circle-color': [
              'match',
                ['get', 'risk_rating'],
                'Low Risk', '#39fc03',
                'Medium Risk', '#F9BD0F',
                'High Risk', '#F9380F',
                '#0000FF' // For other values, use blue color
            ],
          'circle-radius': 25,
          'circle-stroke-width': 3,
          'circle-stroke-color': [
            'match',
              ['get', 'status'],
              'Standard', userLocationInfo.colour,
              'SOS', '#F9380F',
              userLocationInfo.colour // For other values, use blue color
          ],
        }
      });

      mapRef.current.addLayer({
          id: userLocationInfo.source + "-text",
          type: 'symbol',
          source: userLocationInfo.source,
          layout: {
            'text-field': `{initials}`, // Specify the field name containing the username
            'text-font': ['Open Sans Regular'],
            'text-size': 25,
          //   'text-offset': [0, -1] 
          },  
          paint: {
              "text-halo-color": "white",
              "text-halo-width": 0.5
          }
        });

      mapRef.current.on('click', userLocationInfo.source + "-marker", (e) => {
        console.log("[userLocationInfo.source]")
        console.log(e.features[0].properties)
        const coordinates = [e.features[0].properties['longitude'], e.features[0].properties['latitude']];
        const name = e.features[0].properties['user_name'];
        const risk_rating = e.features[0].properties['risk_rating'];
        const time_entered_location = new Date(e.features[0].properties['time_entered_location']);
        const checkinUserId = e.features[0].properties['user_id']
        // Ensure that if the map is zoomed out such that
        // multiple copies of the feature are visible, the
        // popup appears over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        function getUserLocationCard(UserLocationRow){
          console.log("[UserLocationRow]")
          console.log(UserLocationRow)
          const handleCheckinRequest = (e) =>{
            const MySnackbar = ({ message, handleCheckinClose }) => {  
              return (
                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={true} // Snackbar will be shown when open is true
                  autoHideDuration={1000} // Adjust duration as per your requirement
                  onClose={handleCheckinClose}
                  message={message}
                />
              );
            };
            function handleCheckinClose(event) {
              if (event === 'clickaway') {
                return <></>;
              }
            }

            ReactDOM.render(
              <MySnackbar 
                message={"Checkin request has been sent"} 
                handleCheckinClose={handleCheckinClose} 
              />, document.getElementById('checkin-card')
            );

            // console.log("user is ", user)
            
            const usersStr = UserLocationRow.user_id.toString()
            let dateStr = getdateStr() 
            const jsonObj = {
              type: 'CheckIn',
              company: companyName, 
              uid: checkinUserId.toString(), 
              role: "admin",
              dateTime: dateStr, 
              // Latitude: 12345,
              // Longitude: 12345,
              // Speed: 12345,
              // Altitude: 12345,
              // Accuracy: 12345,
              // Bearing: 12345,
              status: "Initial",
              message:"",
              userList: usersStr
            };

            store.dispatch({type: "socket/sendcheckin", message: jsonObj})
            
            // websocket.then(socket=>{socket.send(JSON.stringify(jsonObj))})
            postCheckins(checkinUserId, UserLocationRow.latitude, UserLocationRow.longitude)
            console.log("Checkin  message sent")
          };

          const handleCancelSOS = (e) =>{
            handleOpenCancelSOSModal(UserLocationRow)
          };
          
          return  (
            <Card 
              sx={{ maxWidth: 400 }} 
              variant='outlined'
            >
              <CardHeader
                title={UserLocationRow.user_name}
                style={{
                  maxHeight:30,
                  backgroundColor: '#005A31',
                  color: 'white',
                  fontSize: '20px',
                  subheaderTypographyProps:{
                    color:"gray"
                  }
                }}
              />
              <List
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  minWidth: 360,
                  bgcolor: 'background.paper',
                }}
              >
              <ListItem>
                <ListItemText primary={UserLocationRow.risk_rating} />
              </ListItem>
              </List>
              {UserLocationRow.status === 'SOS' && (
                <CardActions>
                  <Button 
                    id="extra-button" 
                    className="extra-button" 
                    onClick={(e)=>{handleCancelSOS(e)}}
                  >
                    Cancel SOS
                  </Button>
                </CardActions>
              )}
              <CardActions>
                <Button 
                  id="checkin-button" 
                  className="create-button" 
                  onClick={(e)=>{handleCheckinRequest(e)}}
                >
                  Request Checkin
                </Button>
              </CardActions>
            </Card>
        )}
        const PopUpElement = getUserLocationCard(e.features[0].properties)
        const popupContent = document.createElement('div');
        popupContent.setAttribute("id", "checkin-card")
        
        ReactDOM.render(PopUpElement, popupContent);
        const locationPopup = new mapboxgl.Popup({
          className: "user-location-map-popup", 
          closeButton:true
        })
        .setLngLat({lng: coordinates[0], lat:coordinates[1]})
        .setDOMContent(popupContent);

        locationPopup.addTo(mapRef.current);
      });

      mapRef.current.on('mouseenter', userLocationInfo.source + "-marker", () => {
        mapRef.current.getCanvas().style.cursor = 'pointer';
      });
      mapRef.current.on('mouseleave', userLocationInfo.source + "-marker", () => {
        mapRef.current.getCanvas().style.cursor = '';
      });

      mapRef.current.on('dragend', function() {
        // checkAndReplaceData()
      });
      mapRef.current.on('zoomend', function() {
        // checkAndReplaceData()
      });
      
    }, 500)
  }
  , [userLocationData])

  useEffect(() =>{
    // mapRef.current.on('load', () => {
    setTimeout(() => {
      if (mapRef.current.getSource(followMeHomeInfo.source) === undefined) {
        mapRef.current.addSource(followMeHomeInfo.source, {
          type: 'geojson',
          data: followMeHomeData,
        });
      }  else {
        mapRef.current.getSource(followMeHomeInfo.source).setData(followMeHomeData);
      }
      if (mapRef.current.getLayer(`${followMeHomeInfo.source}-marker`) !== undefined) {
        mapRef.current.removeLayer(`${followMeHomeInfo.source}-marker`)
        mapRef.current.removeLayer(`${followMeHomeInfo.source}-text`)
      }

      mapRef.current.addLayer({
        id: followMeHomeInfo.source + "-marker",
        source: followMeHomeInfo.source,
        type:"circle",
        paint: {
          'circle-color': [
              'match',
                ['get', 'risk_rating'],
                'Low Risk', '#39fc03',
                'Medium Risk', '#F9BD0F',
                'High Risk', '#F9380F',
                '#1fcfdb' // For other values, use blue color
            ],
          'circle-radius': 7,
          'circle-stroke-width': 3,
          'circle-stroke-color': followMeHomeInfo.colour
        }
      });

      mapRef.current.addLayer({
          id: followMeHomeInfo.source + "-text",
          type: 'symbol',
          source: followMeHomeInfo.source,
          layout: {
            'text-field': `{initials}`, // Specify the field name containing the username
            'text-font': ['Open Sans Regular'],
            'text-size': 15,
          },  
          paint: {
              "text-halo-color": "white",
              "text-halo-width": 0.5
          }
        });

      mapRef.current.on('click', followMeHomeInfo.source + "-marker", (e) => {
        const coordinates = [e.features[0].properties['longitude'], e.features[0].properties['latitude']];
        const name = e.features[0].properties['user_name'];
        const risk_rating = e.features[0].properties['risk_rating'];
        const time_entered_location = new Date(e.features[0].properties['time_entered_location']);

        // Ensure that if the map is zoomed out such that
        // multiple copies of the feature are visible, the
        // popup appears over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        
        new mapboxgl.Popup({className: "map-popup", closeButton:false})
        .setLngLat({lng: coordinates[0], lat:coordinates[1]})
        .setHTML(
          `
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
                <h4>Name</h4>
                <p>${name}</p>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
                  <h4>Location Last Updated At</h4>
                  <p>${time_entered_location.toLocaleString()}</p>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
                  <h4>Risk Rating</h4>
                  <p>${risk_rating}</p>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div class="create-button-div">              
                <button id="followMeCheckinBtn" class="create-button">Request Check-In</button><br/>
              </div>
            </Grid>
          </Grid>
          `
        )
        .addTo(mapRef.current);
      });

      mapRef.current.on('mouseenter', followMeHomeInfo.source + "-marker", () => {
        mapRef.current.getCanvas().style.cursor = 'pointer';
      });
      mapRef.current.on('mouseleave', followMeHomeInfo.source + "-marker", () => {
        mapRef.current.getCanvas().style.cursor = '';
      });

      mapRef.current.on('dragend', function() {
        // checkAndReplaceData()
      });
      mapRef.current.on('zoomend', function() {
        // checkAndReplaceData()
      });
      
    }, 500)
  }
  , [userLocationData, followMeHomeData])

  useEffect(() => {
    const draw = new MapboxDraw({
      displayControlsDefault: false,
      // Select which mapbox-gl-draw control buttons to add to the map.
      controls: {
        polygon: true,
        trash: true,
      },
      styles: [
        // ACTIVE (being drawn)
        // line stroke
        {
            "id": "gl-draw-line",
            "type": "line",
            "filter": ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
            "layout": {
              "line-cap": "round",
              "line-join": "round"
            },
            "paint": {
              "line-color": "#D20C0C",
              "line-dasharray": [0.2, 2],
              "line-width": 2
            }
        },
         // polygon fill
        {
          "id": "gl-draw-polygon-fill",
          "type": "fill",
          "filter": ["all", ["==", "$type", "Polygon"], ["==", "active", "true"]],
          "paint": {
            "fill-color": "#D20C0C",
            "fill-outline-color": "#D20C0C",
            "fill-opacity": 0.3
          }
        },
        // vertex point halos
        {
          "id": "gl-draw-polygon-and-line-vertex-halo-active",
          "type": "circle",
          "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"],],
          "paint": {
            "circle-radius": 5,
            "circle-color": "#FFF"
          }
        },
      ]
    });

    if (drawInit ){
      // console.log("drawInit", drawInit, draw)
      mapRef.current.addControl(draw);
      mapRef.current.addControl(new mapboxgl.FullscreenControl());

      mapRef.current.on("contextmenu", (e) => {
        mapRef.current.flyTo({
          center: [e.lngLat.lng, e.lngLat.lat],
          zoom: 13,
          tap: false,
        });
        var coordinates = { lng: e.lngLat.lng, lat: e.lngLat.lat };

        var popup = new mapboxgl.Popup({
          closeOnClick: true,
          closeButton: false,
        })
        .setHTML(
          `
          <div class="create-button-div">
            <button class="create-button" id="assetBtn" >Create Asset</button>
          </div>
          <div class="create-button-div"> 
            <button id="incidentBtn" class="create-button">Create Incident</button>
          </div>
          `
        )
        .setLngLat(coordinates)
        .addTo(mapRef.current);

        setCoordinates(coordinates);

        const btnAsset = document.getElementById("assetBtn");
        btnAsset.addEventListener("click", () => {
          // console.log("changeing display flag", displayCreateAssetPage)
          setDisplayCreateAssetPage(true);
          // console.log("updated display flag", displayCreateAssetPage)
        });

        const btnIncident = document.getElementById("incidentBtn");
        btnIncident.addEventListener("click", () => {
            // console.log("clicked incident button")
          setDisplayCreateIncidentPage(true);
        });
      });

      mapRef.current.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          // When active the map will receive updates to the device's location as it changes.
          trackUserLocation: true,
          // Draw an arrow next to the location dot to indicate which direction the device is heading.
          showUserHeading: true,
        })
      );
      setDrawInit(false);
    }

  
    function updateArea(e) {
      var filterPolygon = turf.polygon(e.features[0].geometry.coordinates)

      const sources = [
        intFusionInfo.source,
        userAssetInfo.source,
        userIncidentInfo.source
      ]
      sources.forEach(function (source){
        console.log("Starting source  ", source)

        let features = []
        if (source===intFusionInfo.source) {
          features = intFusionDataRef.current.features
        } else if (source===userAssetInfo.source) {
          features = userAssetDataRef.current.features
        }if (source===userIncidentInfo.source) {
          features = userIncidentDataRef.current.features
        }

        let filteredFeatures = features.filter(feature=>{
          return !turf.booleanDisjoint(feature, filterPolygon);
        })

        var featureCollection = {
          type: 'FeatureCollection',
          features: filteredFeatures,
        };

        if (source===intFusionInfo.source) {
          setFilteredIntFusion(featureCollection);
        } else if (source===userAssetInfo.source) {
          setFilteredUserAsset(featureCollection);
        }if (source===userIncidentInfo.source) {
          setFilteredUserIncident(featureCollection);
        }
        if (source===tableDataSource) { 
          setFilteredTableData(featureCollection);
        }
      })
    }

    function removePolygon(e) {
      setFilteredIntFusion(intFusionData);
      setFilteredUserAsset(userAssetData);
      setFilteredUserIncident(userIncidentData);
      if (tableDataSource===intFusionInfo.source) { 
        setFilteredTableData((filteredTableData) => {
          return intFusionData
        });
      } else if (tableDataSource===userAssetInfo.source) { 
        setFilteredTableData((filteredTableData) => {
          return userAssetData
        });
      } else if (tableDataSource===userIncidentInfo.source) { 
        setFilteredTableData((filteredTableData) => {
          return userIncidentData
        });
      } 
    }
    mapRef.current.on("style.load", () => {
      mapRef.current.setFog({}); // Set the default atmosphere style
    });

    mapRef.current.on("draw.create", updateArea);
    mapRef.current.on("draw.update", updateArea);
    mapRef.current.on("draw.delete", removePolygon);
    // mapRef.current.addControl(new mapboxgl.FullscreenControl());

  }, [intFusionData, userAssetData, userIncidentData, tableDataSource, drawInit]);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) => (event) =>{
    // (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        // ((event as React.KeyboardEvent).key === "Tab" ||
        //   (event as React.KeyboardEvent).key === "Shift")

        ((event).key === "Tab" ||
          (event).key === "Shift")
      ) {
        return;
      }

      setStateTable({ ...stateTable, [anchor]: open });
    };

    const getSourceInfoFromFilter = (filter) => {
        var currentInfo = {};
        if (filter === intFusionInfo.filter) {
            currentInfo = intFusionInfo;
          } else if (filter === userLocationInfo.filter) {
            currentInfo = userLocationInfo;
          } else if (filter === userAssetInfo.filter) {
            currentInfo = userAssetInfo;
          } else if (filter === userIncidentInfo.filter) {
            currentInfo = userIncidentInfo;
          }
          return currentInfo
    }

    const handleDataSource = (e, filter) => {
      const check = e.target.checked;

      let currentSourceInfo = getSourceInfoFromFilter(filter)
      let currentSource = currentSourceInfo["source"]

      const updatedEnabledSources = {
        ...enabledSources,
        [currentSource]: check,
      };
      setEnabledSources(updatedEnabledSources);
      
      if (mapRef.current) {
        const source = mapRef.current.getSource(currentSource);
        if (!check) {
          if (source) {
              if (currentSource === userLocationInfo.source){
                mapRef.current.setLayoutProperty(currentSource + "-text", "visibility", "none");
                mapRef.current.setLayoutProperty(currentSource + "-marker", "visibility", "none");

              } else{
                mapRef.current.setLayoutProperty(currentSource, "visibility", "none");
              }
          }
        } else {
            if (currentSource === userLocationInfo.source){
                mapRef.current.setLayoutProperty(currentSource + "-text", "visibility", "visible");
                mapRef.current.setLayoutProperty(currentSource + "-marker", "visibility", "visible");
            } else{
                mapRef.current.setLayoutProperty(currentSource, "visibility", "visible");
            }
        }
      }
    }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const BasicTable = useMemo(() => {
    var tableData = [];
    // console.log("filteredTableData", filteredTableData)
    if (filteredTableData === null) {
        // console.log("filteredtabledata not loaded yet")
    } else if (filteredTableData['features'].length !== 0) { 
      // console.log("12345table data is ", filteredTableData)
      tableData = filteredTableData['features']
      // console.log("12345table data is ", tableData)
    }
    // console.log("table data is with length ", tableData.length, tableData)

    const onEditClick = (e) => {}
    const handleOpenDeleteModal = (e) => {} 

    return (
      <Grid>
        <TableContainer component={Paper}>
         {
          <DisplayTable
              columns={columns[tableDataSource]}
              data={tableData}
              onEditClick={onEditClick}
              handleOpenDeleteModal={handleOpenDeleteModal}
              columnFilters={columnFilters}
              globalFilter={globalFilter}
              sorting={sorting}
              pagination={pagination}
              rowCount={rowCount}
              isRefetching={isRefetching}
              isError={isError}
              isLoading={isLoading}
              setColumnFilters={setColumnFilters}
              setGlobalFilter={setGlobalFilter}
              setPagination={setPagination}
              setSorting={setSorting}
              enableRowActions={false}
            />
          }
        </TableContainer>
      </Grid>
    )
  }, [
    filteredTableData,
    // incidentPage,
    // incidentPageSize,
    pagination,
    tableDataSource
  ])


  const IncidentModal = useMemo(() => {

    console.log("IncidentModal")
    console.log(pushIncident)
    console.log(selectedPushIncidentUsers)
    
    const modal = (openIncidentModal) ? (
      <PushIncidentModal
        incident={pushIncident}
        handleClose={handleCloseIncidentModal} 
        handlePush={()=>handlePushIncident(pushIncident, selectedPushIncidentUsers)} 
        selectedUsers={selectedPushIncidentUsers}
        handleSelectUser={handleSelectUser}
        users={invitedUsers}
      />
    ) : (
      <></>
    )
    return (
      modal
    )
  }, [
    openIncidentModal, 
    pushIncident,
    selectedPushIncidentUsers
  ])

  const CancelSOSModalElement = useMemo(() => {

    console.log("CancelSOSModal")
    console.log(CancelSOSUser)
    
    const modal = (openCancelSOSModal) ? (
      <CancelSOSModal
        handleClose={handleCloseCancelSOSModal} 
        handlePush={handleSubmitCancelSOS} 
        selectedUser={CancelSOSUser}
      />
    ) : (
      <></>
    )
    return (
      modal
    )
  }, [
    openCancelSOSModal, 
    CancelSOSUser
  ])

  const PinnedSubheaderList = useMemo(() => {
    const filters = [
      intFusionInfo.filter,
      userLocationInfo.filter,
      userAssetInfo.filter,
      userIncidentInfo.filter,
    ]
    const sourceColour = "red"
    return (
        <List
          sx={{
            zIndex: 0,
            bgcolor: "background.paper",
            position: "relative",
            overflow: "auto",
            "& ul": { padding: 0 },
          }}
          subheader={<li />}
        >        
            <ListItem>
                <FormGroup>
                    <FormLabel id="demo-radio-buttons-group-label">
                    <b>Markers</b>
                    </FormLabel>
                    {
                        filters.map((filter) => {
                            const sourceInfo = getSourceInfoFromFilter(filter);
                            let objectIcon = (
                                <div className="circle" style={{ 
                                    width: "10px",
                                    height: "10px",
                                    borderRadius: "50%",
                                    backgroundColor: 'white',
                                    border: '4px solid '+ sourceInfo['colour']
                                }} ></div>
                            )
                            // console.log("filter is :: ", filter, userAssetInfo.filter)
                            if (filter === userAssetInfo.filter) {
                              // console.log("fileters Mathc", filter)
                              objectIcon = (
                                <div>
                                  <img src="buildings.png" style={{
                                    width: "30px",
                                    height: "30px"
                                  }}/>
                                </div>
                              )
                            }
                            return (
                            <div key={filter} style={{display:"flex", alignItems:"center"}}>
                                <Label
                                    filter={filter}
                                    handleDataSource={handleDataSource}
                                    sourceInfo={sourceInfo}
                                    enabledSources={enabledSources}
                                />
                                {objectIcon}
                            </div>
                            )
                        })
                    }
                </FormGroup>
            </ListItem>
        </List>
      )
    }, [
        enabledSources
    ]);

  
  return (
    <Grid container direction="column">
      <Grid container sx={{ mt: "1vh" }} spacing={1}>
        <Grid
          alignItems="center"
          justifyContent="space-evenly"
          container
          position={"absolute"}
          width={"15%"}
        >
          <React.Fragment key={"bottom"}>
            <SwipeableDrawer
              anchor={"bottom"}
              open={stateTable["bottom"]}
              onClose={toggleDrawer("bottom", false)}
              onOpen={toggleDrawer("bottom", true)}
            >
              <Box
                component="span"
                sx={{ p: 2, border: "1px dashed grey", flexGrow: 1 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Data Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={tableDataSource}
                        label="Data Type"
                        onChange={handleChangeDataType}
                      >
                        <MenuItem value={userIncidentInfo.source}>{userIncidentInfo.filter}</MenuItem>
                        <MenuItem value={userAssetInfo.source}>{userAssetInfo.filter}</MenuItem>
                        <MenuItem value={intFusionInfo.source}>{intFusionInfo.filter}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton onClick={toggleDrawer("bottom", false)}>
                      <CloseFullscreenIcon sx={{ colorSecondary: "black"}}/>
                    </IconButton>
                  </Grid>
                </Grid>
                <br />
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  {BasicTable}
                </Paper>
              </Box>
            </SwipeableDrawer>
            {IncidentModal}
            {CancelSOSModalElement}
          </React.Fragment>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid sx={classes.mapContainer} ref={mapContainer}>
          <>
          <Grid id="info" sx={classes.info}></Grid>
          <ButtonGroup
            sx={classes.buttonGroup}
            orientation="vertical"
            aria-label="vertical outlined button group"
          >
            <Button
                variant="text"
                className="button-map"
                onClick={toggleDrawer("bottom", true)}
              >
                <TableRowsIcon />
              </Button>
            <Button
              aria-describedby={id}
              variant="text"
              className="button-map"
              onClick={handleClick}
            >
              <LayersIcon />
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {PinnedSubheaderList}
          </Popover> 
          </ButtonGroup>
          <Drawer
            anchor="right"
            open={displayCreateAssetPage}
            onClose={handleCloseAsset}
          >
            <AssetsPage coordinates={coordinates} />
          </Drawer>
          <Drawer
            anchor="right"
            open={displayCreateIncidentPage}
            onClose={handleCloseIncident}
          >
            <IncidentsPage coordinates={coordinates} />
          </Drawer>
          </>
          {/* ) : (
            <CircularProgress />
          ) */}
        </Grid>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = ({ user }) => ({
    loading: user.loading,
    companyName: user.companyName,
    profile: user.profile,
    role: user.role,
    newAlerts: user.newAlerts,  
    userId: user.id,
    user: user
    // socket: user.websocket
  });
  
  export default connect(mapStateToProps)(MapDisplay);