import React, {useState, useCallback} from 'react';
import {Grid, TextField, Typography, Button, CircularProgress, Card, CardContent} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import Link from "@mui/material/Link";
import globalConfig from '../config.json';
import {emailRe} from '../helpers/constants';
import {sendForgotPasswordEmail} from '../helpers/api';
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";

const theme = createTheme();

const SendPasswordReset = (props) => {

  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate()

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (emailRe.test(email)) {
      // TODO: Have invite checking for SME login.
      setLoading(true);
      const res = await sendForgotPasswordEmail(email);
      setLoading(false);
      try {
        if (res.status === 204) {
          navigate('/sendpasswordreset/complete');
        } else {
          setErrorText('Unknown error');
        }
      } catch (err) {
        setErrorText('Fatal error');
      }
    } else {
      setErrorText('Please enter a valid email address');
      setSubmitted(true);
    }
    setSubmitted(true);
  }, [email, navigate]);

  return (
    <ThemeProvider theme={theme}>
    <Container component="main" >
      {/* <LeftLanding /> */}
      <CssBaseline />
      {/* <Grid container  item xs={12} sm={12} md={6}> */}
        <TransitionGroup>
          <CSSTransition
            timeout={300}
            in={true}
            classNames="fade"
            appear
          >
                     <Grid alignItems="center" direction="row" justifyContent="center" container  component="main" maxWidth="xs">

<Grid item width="50%"></Grid>
<Grid item
  width="50%"
  sx={{
    // width: "50%",
    // paddingLeft: 10,
    marginTop: {sx: 5, md: 10, lg: 20, xl: 30},
    // display: "flex",
    // flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // right: 0
  }}
>
  <Card elevation={0} sx={{ minWidth: 400 }}>
            <CardContent>
                      <Typography align="left" component="h1" variant="h5">
                          Password Reset
                        </Typography>
                        <Typography align="left" variant="subtitle1">
                          Specify email to send a password reset link
                        </Typography>
                        <Typography>
                          {errorText}
                        </Typography>
                        <Box
                component="form"
                onSubmit={(e) => {handleSubmit(e)}}
                noValidate
                sx={{ mt: 1 }}
              >
                      <Grid item>
                        <TextField
                        fullWidth
                          variant="outlined"
                          label="Email Address"
                          id="emailField"
                          type="email"
                          error={!!errorText || (submitted && !email)}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          InputLabelProps={{
                            style: {
                              color: globalConfig['textColor'],
                            },
                          }}
                          InputProps={{

                          }}

                        />
                      </Grid>
                      <Grid item>
                        {
                          loading ?
                            <CircularProgress /> :
                            <Button fullWidth variant="contained" color="primary" type="submit"     sx={{ mt: 3, mb: 2 }}> Send Reset Request </Button>
                        }
                      </Grid>
                      </Box>
                      <Grid container>
                      <Grid item xs >
                      {/* <Typography variant="body2"> */}
                        <Link id="loginLink" href="/sign_in" variant="body2">
                          Remember Password
                        </Link>
                      {/* </Typography> */}
                    </Grid>
                      <Grid item >
                      {/* <Typography variant="body2"> */}
                        <Link id="signUpLink" href="/register" variant="body2">
                          Sign Up
                        </Link>
                      {/* </Typography> */}
                    </Grid>

                    </Grid>
                    </CardContent>
                    </Card>



            </Grid>
            </Grid>
          </CSSTransition>
        </TransitionGroup>
      {/* </Grid> */}
    </Container>
    </ThemeProvider>
  );
};

SendPasswordReset.propTypes = {
};

SendPasswordReset.displayName = 'SendPasswordReset';
export default SendPasswordReset;
