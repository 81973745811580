import React from "react";

export default function Privacy() {
    return <div className="Privacy" style={{ marginLeft: '20px'}}>
        <p><strong>Privacy Policy for Kowroo</strong></p>
        <p><strong>Effective Date: [1<sup>st</sup> July 2024]</strong></p>
        <p><strong>1. Introduction</strong></p>
        <p>Welcome to Kowroo, your trusted travel risk management platform. At Kowroo, we prioritize your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, handle, and share your data when you use our services. By using Kowroo, you consent to the collection, use, and sharing of your information as described in this Privacy Policy.</p>
        <p><strong>2. Information We Collect</strong></p>
        <p>To provide you with the best travel risk management services, we collect the following types of information:</p>
        <ul>
            <li><p><strong>Personal Information</strong>: This includes your name, email address, phone number, gender, ethnicity, sexual orientation, medical conditions, and other contact details you provide when creating an account or using our services. This information helps us deliver individualized and personalized risk assessments tailored to your specific needs and circumstances.</p></li>
            <li><p><strong>Location Information</strong>: We track your location to offer real-time risk assessments and emergency assistance. This includes GPS data and other location-based information.</p></li>
            <li><p><strong>Usage Data</strong>: Information about how you use our platform, such as access times, pages viewed, and other related data. We use this data to enhance your user experience, optimize our services, ensure security, provide personalized recommendations, and support troubleshooting efforts.</p></li>
        </ul>
        <p><strong>3. How We Use Your Information</strong></p>
        <p>We use your information to:</p>
        <ul>
            <li><p>Provide and improve our travel risk management services.</p></li>
            <li><p>Communicate with you regarding your account, updates, and other relevant information.</p></li>
            <li><p>Ensure your safety by monitoring and responding to potential travel risks in real-time.</p></li>
            <li><p>Analyze usage patterns to enhance the user experience and optimize our services.</p></li>
        </ul>
        <p><strong>4. Data Security</strong></p>
        <p>We take the security of your data seriously and implement various measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. These measures include encryption, secure servers, and access controls.</p>
        <p><strong>5. Data Retention</strong></p>
        <p>We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. Once your data is no longer needed, we will securely delete or anonymize it.</p>
        <p><strong>6. Sharing Your Information</strong></p>
        <p>We do not share your personal information with any third parties except under the following circumstances:</p>
        <ul>
            <li><p><strong>Legal Obligations:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities, such as a court or government agency.</p></li>
            <li><p><strong>Company Use:</strong> If you are using Kowroo through your company, we may share information about your location, SOS alerts, and chat activities with your company, but we will not share your personal information except your identity.</p></li>
        </ul>
        <p><strong>7. Your Rights</strong></p>
        <p>You have the right to:</p>
        <ul>
            <li><p>Access the personal information we hold about you.</p></li>
            <li><p>Request corrections to any inaccurate or incomplete data.</p></li>
            <li><p>Delete your personal information, subject to legal or contractual obligations.</p></li>
            <li><p>Object to the processing of your data under certain circumstances.</p></li>
            <li><p>Withdraw your consent to data processing at any time.</p></li>
        </ul>
        <p>To exercise these rights, please contact us at [Dr Sheelagh Brady, <a href="mailto:sheelagh@kowroo.com">sheelagh@kowroo.com</a>, +353 87 6771373].</p>
        <p><strong>8. Changes to This Privacy Policy</strong></p>
        <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the new policy on our platform and updating the effective date.</p>
        <p><strong>9. Prominent Disclosure </strong></p>
        <p>We provide in-app disclosures of data access, collection, use, and sharing. For instance, &quot;Kowroo collects location data to provide real-time travel risk assessments and emergency assistance, even when the app is closed or not in use.&quot; Consent and runtime permissions are obtained through clear, unambiguous consent dialogs that require affirmative user action.</p>
        <p><strong>10. Third-Party Compliance</strong></p>
        <p>We ensure any third-party code used in our app complies with Google Play Developer Program policies and does not sell personal and sensitive user data.</p>
        <p><strong>11. Contact Us</strong></p>
        <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
        <p>Dr Sheelagh Brady, Kowroo<br />
    [Dublin City University]<br />
    [sheelagh@kowroo.com]<br />
    [+353 87 6771363]</p>
        <p><strong>12. Consent</strong></p>
        <p>By using Kowroo, you consent to the collection, use, and sharing of your information as described in this Privacy Policy.</p>
        <p>Thank you for choosing Kowroo. We are dedicated to ensuring your safety and privacy as you travel.</p>
    
    </div>;
}
