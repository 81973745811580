import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import {
  ages,
  countries,
  ethnicities,
  genders,
  orientations,
  religions,
} from "../helpers/constants";
import { postProfile } from "../helpers/api";
import { connect } from "react-redux";
import { setProfile } from "../actions/userActions";
import { customDropdown } from "../components/CustomDropdown";

const Profile = (props) => {
  const { profile, dispatch, profileId } = props;
  const [gender, setGender] = useState(null);
  const [age, setAge] = useState(null);
  // const [profileId, setProfileId] = useState(profile.id)
  const [nationality, setNationality] = useState(null);
  const [ethnicity, setEthnicity] = useState(null);
  const [religion, setReligion] = useState(null);
  const [orientation, setOrientation] = useState(null);
  const [spokenLangs, setSpokenLangs] = useState(null);
  const [medicalCond, setMedicalCond] = useState(null);
  const [errorText, setErrorText] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [reload, setReload] = useState(true);
  const handleClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setErrorText("");
  };

  const handleCloseError = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
    setErrorText("");
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setSendingData(true);
      const res = await postProfile(
        profileId ? profileId : null,
        gender ? gender.value : null,
        age ? age.value : null,
        nationality ? nationality.value : null,
        ethnicity ? ethnicity.value : null,
        religion ? religion.value : null,
        orientation ? orientation.value : null,
        spokenLangs ? spokenLangs.value : null,
        medicalCond ? medicalCond.value : null
      );
      try {
        if (res.status === 201 || res.status === 200 || res.status === 204) {
          setSendingData(false);
          setErrorText("");
          setOpen(true);
          setReload(true);
        } else if (res.status === 400 && res.data["email"]) {
          setSendingData(false);
          setErrorText("Email: " + res.data["email"]);
          setErrorOpen(true);
        } else if (res.status === 403 && res.data["detail"]) {
          setSendingData(false);
          setErrorText(res.data["detail"]);
          setErrorOpen(true);
        } else if (res.status === 404) {
          setSendingData(false);
          setErrorText("Something went wrong");
          setErrorOpen(true);
        } else {
          setSendingData(false);
          setErrorText("Unknown error");
          setErrorOpen(true);
        }
      } catch (err) {
        setSendingData(false);
        setErrorText("Fatal error");
        setErrorOpen(true);
      }
      setSendingData(false);
    },
    [
      gender,
      age,
      ethnicity,
      nationality,
      religion,
      orientation,
      spokenLangs,
      medicalCond,
      profileId,
    ]
  );

  const dispatchProfile = useCallback(() => {
    setGender(profile.gender);
    setAge(profile.age);
    setNationality(profile.nationality);
    setEthnicity(profile.ethnicity);
    setReligion(profile.religion);
    setOrientation(profile.orientation);
    setSpokenLangs(profile.spoken_lang);
    setMedicalCond(profile.medical_cond);
    dispatch(setProfile(profile));
  }, [dispatch, profile]);

  return (
    <Grid container justifyContent="center" direction="row">
      <Grid
        item
        justifyContent="center"
        // spacing={0}
        // direction="column"
        // alignItems="center"
        // justifyContent="center"
        sx={{ margin: 5 }}
      >
        {/* <CardContent> */}
        <Snackbar
          onClose={handleClose}
          open={open && errorText === "" && !sendingData}
          autoHideDuration={6000}
        >
          <Alert severity="success">
            User Profile info was successfully setup
          </Alert>
        </Snackbar>
        <Snackbar
          onClose={handleCloseError}
          open={errorOpen && errorText !== "" && !sendingData}
          autoHideDuration={6000}
        >
          <Alert severity="error">{errorText}</Alert>
        </Snackbar>

        {/* <Grid item> */}
        <Typography component="h1" variant="h5">
          User Profile
        </Typography>
        {/* </Grid> */}
        {/* <Grid item> */}
        <Grid container direction="column">
          <Grid item>
            <Typography variant="subtitle1">
              {
                "Please choose from dropdown or type in your user profile information"
              }
            </Typography>
          </Grid>
          <Grid item>
            {/* <br></br> */}
            {Object.keys(profile).length > 0 ? (
              <Button
                // disabled={Object.keys(profile).length > 0}
                type="submit"
                onClick={dispatchProfile}
                // fullWidth
                variant="outlined"
                sx={{ mt: 3, mb: 2 }}
                // onClick={handleLogin}
              >
                {sendingData ? (
                  <CircularProgress size="1.55rem" color="inherit" />
                ) : (
                  "Edit"
                )}
              </Button>
            ) : null}
          </Grid>
        </Grid>
        {/* </Grid> */}
        {/* <Grid item> */}
        <Box
          component="form"
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          noValidate
          sx={{ mt: 1 }}
        >
          <Grid container style={{ minWidth: 650 }} direction="column">
            <Grid
              container
              direction="row"
              spacing={2}
              justifyContent="space-beetween"
            >
              <Grid item style={{ width: 350 }}>
                {profile.gender
                  ? customDropdown(
                      genders,
                      "Gender",
                      profile.gender,
                      setGender,
                      profile
                    )
                  : customDropdown(
                      genders,
                      "Gender",
                      gender,
                      setGender,
                      profile
                    )}
              </Grid>
              <Grid item style={{ width: 350 }}>
                {profile.age
                  ? customDropdown(ages, "Age", profile.age, setAge, profile)
                  : customDropdown(ages, "Age", age, setAge, profile)}
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              spacing={2}
              justifyContent="space-beetween"
            >
              <Grid item style={{ width: 350 }}>
                {profile.nationality
                  ? customDropdown(
                      countries,
                      "Nationality",
                      profile.nationality,
                      setNationality,
                      profile
                    )
                  : customDropdown(
                      countries,
                      "Nationality",
                      nationality,
                      setNationality,
                      profile
                    )}
              </Grid>

              <Grid item style={{ width: 350 }}>
                {profile.ethnicity
                  ? customDropdown(
                      ethnicities,
                      "Ethnicity",
                      profile.ethnicity,
                      setEthnicity,
                      profile
                    )
                  : customDropdown(
                      ethnicities,
                      "Ethnicity",
                      ethnicity,
                      setEthnicity,
                      profile
                    )}
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              spacing={2}
              justifyContent="space-beetween"
            >
              <Grid item style={{ width: 350 }}>
                {profile.religion
                  ? customDropdown(
                      religions,
                      "Religion",
                      profile.religion,
                      setReligion,
                      profile
                    )
                  : customDropdown(
                      religions,
                      "Religion",
                      religion,
                      setReligion,
                      profile
                    )}
              </Grid>
              <Grid item style={{ width: 350 }}>
                {profile.orientation
                  ? customDropdown(
                      orientations,
                      "Sexual orientation",
                      profile.orientation,
                      setOrientation,
                      profile
                    )
                  : customDropdown(
                      orientations,
                      "Sexual orientation",
                      orientation,
                      setOrientation,
                      profile
                    )}
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              spacing={2}
              justifyContent="space-beetween"
            >
              <Grid item style={{ width: 350 }}>
                {profile.spoken_lang
                  ? customDropdown(
                      countries,
                      "Spoken languages",
                      profile.spoken_lang,
                      setSpokenLangs,
                      profile
                    )
                  : customDropdown(
                      countries,
                      "Spoken languages",
                      spokenLangs,
                      setSpokenLangs,
                      profile
                    )}
                {/* <TextField
                error={errorText !== '' || (submitted)}
                // value={surname}
                onChange={(e) => {
                  // setSurname(e.target.value);
                }}
                margin="normal"
                required
                fullWidth
                id="last_name"
                label="Spoken languages"
                name="last name"
                // autoComplete="Ethnicity"
                // autoFocus
              /> */}
              </Grid>
              <Grid item style={{ width: 350 }}>
                {profile.medical_cond
                  ? customDropdown(
                      [],
                      "Medical condition",
                      profile.medical_cond,
                      setMedicalCond,
                      profile
                    )
                  : customDropdown(
                      [],
                      "Medical condition",
                      medicalCond,
                      setMedicalCond,
                      profile
                    )}
              </Grid>
            </Grid>

            <Grid container>
              <Button
                disabled={Object.keys(profile).length > 0}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                // onClick={handleLogin}
              >
                {sendingData ? (
                  <CircularProgress size="1.55rem" color="inherit" />
                ) : (
                  "Update User Profile"
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
        {/* </Grid> */}
        {/* </CardContent> */}
      </Grid>
      {/* <Grid item width="50%" sx={{margin: 5}}> */}
      {/* </Grid> */}
    </Grid>
  );
};

const mapStateToProps = ({ user }) => ({
  loading: user.loading,
  profile: user.profile,
  profileId: user.profileId,
});
export default connect(mapStateToProps)(Profile);
