import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import {
  Autocomplete,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  getCountriesOfInterest,
  setCountriesOfInterestApi,
} from "../helpers/api";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Search } from "@mui/icons-material";
import MapIcon from "@mui/icons-material/Map";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import {
  countries,
  newsAPICategories,
  newsAPICountriesFiltered,
  newsAPIData,
} from "../helpers/constants";
import { getNewsApiData } from "../helpers/sources";
import NewsCard from "../components/NewsCard";
const News = () => {
  const [countryState, setCountryState] = useState(
    newsAPICountriesFiltered.map((country) => {
      return {
        code: country.code,
        value: country.value,
        checked: ["GB", "IE", "US"].includes(country.code),
      };
    })
  );
  const [categoryState, setCategoryState] = useState(
    newsAPICategories.map((category) => {
      return {
        value: category,
        checked: category !== "General" ? false : true,
      };
    })
  );
  const [newsAPI, setNewsAPI] = useState([]);
  const [open, setOpen] = useState(false);

  const [countriesOfInterest, setCountriesOfInterest] = useState([]);

  const filters = useMemo(() => {
    return {
      Type: [
        { value: "News API", checked: true },
        { value: "GKG News", checked: false },
      ],
      Category: categoryState,
      Country: countryState,
    };
  }, [categoryState, countryState]);

  const getData = useCallback(async () => {
    const checkedCountries = countryState
      .filter((country) => country.checked)
      .map((country) => country.code);
    const checkedCategories = categoryState
      .filter((category) => category.checked)
      .map((category) => category.value);
    const res = await getNewsApiData(checkedCategories, checkedCountries);

    if (res.status === 200) {
      setNewsAPI(res.data.articles);
    } else {
      setNewsAPI(newsAPIData.articles);
    }
  }, [categoryState, countryState]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    const handleData = async () => {
      const res = await getCountriesOfInterest();

      if (res.status === 201) {
        const cs = res.data.countries;

        setCountriesOfInterest(
          countries.filter((c) => cs.includes(c)).map((ccc) => ccc.value)
        );
      }
    };
    if (open) {
      handleData();
    }
  }, [open, countriesOfInterest]);

  const handleChange = useCallback(
    (filter, sectionId) => {
      if (sectionId === "Type") {
        return;
      }
      filters[sectionId].checked = !filter.checked;
      if (sectionId === "Country") {
        setCountryState(filters[sectionId]);
      } else {
        setCategoryState(filters[sectionId]);
      }
      // getData()
    },
    [filters]
  );

  const PinnedSubheaderList = () => {
    return (
      <List
        sx={{
          marginTop: 5,
          zIndex: 0,
          width: "100%",
          maxWidth: "15%",
          bgcolor: "background.paper",
          // position: 'relative',
          overflow: "auto",
          maxHeight: "80vh",
          // height: "100%",
          "& ul": { padding: 0 },
        }}
        subheader={<li />}
      >
        {["Type", "Category", "Country"].map((sectionId) => (
          <li key={`section-${sectionId}`}>
            <ul>
              <ListItem>
                <RadioGroup>
                  <FormLabel id="demo-radio-buttons-group-label">
                    {sectionId}
                  </FormLabel>
                  {filters[sectionId].map((filter, idx) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => {
                            handleChange(filter, sectionId);
                          }}
                          defaultChecked={filter.checked}
                        />
                      }
                      label={filter.value}
                    />
                  ))}
                  {/* // <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
                </RadioGroup>
              </ListItem>
            </ul>
          </li>
        ))}
      </List>
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = useCallback(async () => {
    const country_codes = countries
      .map((country) => country.code)
      .filter((c) => countriesOfInterest.includes(c));
    const res = await setCountriesOfInterestApi(country_codes);

    if (res.status === 201) {
    }
  }, [countriesOfInterest]);

  return (
    <Grid container direction="column">
      <Grid container sx={{ mt: "1vh" }} spacing={1}>
        <Grid
          alignItems="center"
          justifyContent="space-evenly"
          container
          width={"15%"}
        >
          <Grid item>
            <IconButton>
              <MapIcon></MapIcon>
              {/* <Typography>MAP</Typography> */}
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton>
              <FormatListNumberedIcon></FormatListNumberedIcon>
              {/* <Typography>LIST</Typography> */}
            </IconButton>
          </Grid>
        </Grid>
        <Grid item sx={{ ml: 2 }}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            sx={{ width: "30vh" }}
            label="Search"
          ></TextField>
        </Grid>
        <Grid item>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            sx={{ width: "30vh" }}
            label="Location"
          ></TextField>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              setOpen(true);
            }}
            variant="outlined"
            size="large"
            sx={{ height: "60px" }}
          >
            + Select Countries of interest
          </Button>
          <Dialog
            maxWidth="lg"
            classes={
              {
                // paper: classes.dialogPaper,
              }
            }
            open={open}
            // onClose={handleCloseSmeInvite}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle>
              <Grid>
                <Typography variant="h6" color="primary">
                  Select Countries of Interest
                </Typography>
              </Grid>
            </DialogTitle>
            <DialogContent>
              {/* {createSmeInvite} */}
              <br></br>
              <Grid container spacing={3} direction="column" width="100vh">
                <Grid item>
                  <Autocomplete
                    // classes={MuiAutocomplete: { paper: { "& ul": { maxHeight: "250px" } } }}
                    multiple
                    // sx={{width: "50vh" }}
                    onChange={(e, v) => {
                      setCountriesOfInterest(v);
                    }}
                    value={countriesOfInterest}
                    id="tags-filled"
                    options={countries.map((option) => option.value)}
                    // defaultValue={[customerO[13].title]}
                    // freeSolo
                    // inputValue={customerEmail}
                    onInputChange={(e, v) => {
                      // setCustomerEmail(v);
                    }}
                    fullWidth
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      // sx={{ml: 2}}>
                      <TextField
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          ),
                        }}
                        // error={submitted && !department}
                        {...params}
                        variant="outlined"
                        label="Countries of Interest"
                        placeholder="Search and select countries"
                        // onChange={handleUsersChange}
                        // label="Users"
                        fullWidth
                        // sx={{ width: "40vh", paddingBottom: 2 }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={handleSubmit}
                color="primary"
              >
                Submit
              </Button>
              <Button variant="outlined" onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <PinnedSubheaderList></PinnedSubheaderList>
        <Grid container sx={{ height: "80%", width: "80%" }}>
          <List sx={{ overflow: "auto", maxHeight: "80vh", height: "80vh" }}>
            {newsAPI.map((article) => {
              return <NewsCard article={article}></NewsCard>;
            })}
          </List>
        </Grid>
        <Grid></Grid>
      </Grid>
    </Grid>
  );
};

export default News;
